//*************** user types **************/
export const REACH_SIGNIN_SUCCESS = "REACH_SIGNIN_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
//*************** ERROR DATA **************/
export const ERRORDATA = "ERRORDATA";
export const ERRORDATA_RESET = "ERRORDATA_RESET";
//phone verification
export const GET_OTP = "GET_OTP";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAILURE = "GET_OTP_FAILURE";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const OTP_RESET_SUCCESS_FLAG = "OTP_RESET_SUCCESS_FLAG";
//*************** landing types **************/
export const WEATHER_UPDATE = "WEATHER_UPDATE";
export const APPOINTMENT_UPDATE = "APPOINTMENT_UPDATE";
//*************** home types **************/
export const GET_EMPLOYEE_DETAILS_CHANGE = "GET_EMPLOYEE_DETAILS_CHANGE";
export const EMPLOYEE_PARTNERS_DETAILS = "EMPLOYEE_PARTNERS_DETAILS";
export const EMPLOYEE_PARTNERS_FLAG = "EMPLOYEE_PARTNERS_FLAG";
export const EMPLOYEEINFO_UPDATE = "EMPLOYEEINFO_UPDATE";
export const USERINFO_UPDATE = "USERINFO_UPDATE";
//******************** Booking types ********* */
export const BOOKING_INFO_UPDATE = "BOOKING_INFO_UPDATE";
// export const BOOKINGS_SUCCESS = "BOOKINGS_SUCCESS";
export const BOOKINGS_GETDATA = "BOOKINGS_GETDATA";

export const PROFILE_INFO_UPDATE = "BOOKING_INFO_UPDATE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_IMAGE_SUCCESS = "PROFILE_IMAGE_SUCCESS";
export const PROFILE_GETDATA = "PROFILE_GETDATA";

export const PARTNER_SETTINGS_SUCCESS = "PARTNER_SETTINGS_SUCCESS";
//*************************I INVITATION ***********************/
//list
export const INVITATIONS_LIST = "INVITATIONS_LIST";
export const INVITATIONS_LIST_SUCCESS = "INVITATIONS_LIST_SUCCESS";
export const INVITATIONS_LIST_FAILURE = "INVITATIONS_LIST_FAILURE";
//details
export const INVITATION_DETAIL = "INVITATION_DETAIL";
export const INVITATION_DETAIL_SUCCESS = "INVITATION_DETAIL_SUCCESS";
export const INVITATION_DETAIL_FAILURE = "INVITATION_DETAIL_FAILURE";
export const CLEAR_INVITATION_DETAILS = "CLEAR_INVITATION_DETAILS";
//update
export const INVITATION_UPDATE = "INVITATION_UPDATE";
export const INVITATION_UPDATE_SUCCESS = "INVITATION_UPDATE_SUCCESS";
export const INVITATION_UPDATE_FAILURE = "INVITATION_UPDATE_FAILURE";
export const INVITATION_UPDATE_FLAG = "INVITATION_UPDATE_FLAG";
//********************* LANGUAGE *******************/
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const LOCATION_SEARCH_SUCCESS = "LOCATION_SEARCH_SUCCESS";

//************************* NOTIFICATION *******************/
//GET
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";
//UPDATE
export const NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE";
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_FAILURE = "NOTIFICATION_UPDATE_FAILURE";

//*********************** BOOKING INFO ***********************/
//bookings
export const BOOKINGS = "BOOKINGS";
export const BOOKINGS_SUCCESS = "BOOKINGS_SUCCESS";
export const BOOKINGS_FAILURE = "BOOKINGS_FAILURE";
//booking info
export const BOOKING_INFO = "BOOKING_INFO";
export const BOOKING_INFO_SUCCESS = "BOOKING_INFO_SUCCESS";
export const BOOKING_INFO_FAILURE = "BOOKING_INFO_FAILURE";
export const RESET_BOOKING_INFO = "RESET_BOOKING_INFO";
//UPDATE
export const BOOKING_UPDATE = "BOOKING_UPDATE";
export const BOOKING_UPDATE_SUCCESS = "BOOKING_UPDATE_SUCCESS";
export const BOOKING_UPDATE_FAILURE = "BOOKING_UPDATE_FAILURE";
//******************* WEATHER **********************/
export const WEATHER = "WEATHER";
export const WEATHER_SUCCESS = "WEATHER_SUCCESS";
export const WEATHER_FAILURE = "WEATHER_FAILURE";
//****************** HOME APPOINTMENTS *******************/
export const HOMEAPPOINTMENTS = "HOMEAPPOINTMENTS";
export const HOMEAPPOINTMENTS_SUCCESS = "HOMEAPPOINTMENTS_SUCCESS";
export const HOMEAPPOINTMENTS_FAILURE = "HOMEAPPOINTMENTS_FAILURE";
//**************** PROFILE UPDATE ***************/
//PROFILE DATA
export const PROFILEUPDATE = "PROFILEUPDATE";
export const PROFILEUPDATE_SUCCESS = "PROFILEUPDATE_SUCCESS";
export const PROFILEUPDATE_FAILURE = "PROFILEUPDATE_FAILURE";

//PRIVACY SETTINGS DATA
export const PRIVACY = "PRIVACY";
export const PRIVACY_SUCCESS = "PRIVACY_SUCCESS";
export const PRIVACY_FAILURE = "PRIVACY_FAILURE";

export const PRIVACYUPDATE = "PRIVACYUPDATE";
export const PRIVACYUPDATE_SUCCESS = "PRIVACYUPDATE_SUCCESS";
export const PRIVACYUPDATE_FAILURE = "PRIVACYUPDATE_FAILURE";
//PROFILE IMAGE DATA
export const PROFILEIMAGEUPDATE = "PROFILEIMAGEUPDATE";
export const PROFILEIMAGEUPDATE_SUCCESS = "PROFILEIMAGEUPDATE_SUCCESS";
export const PROFILEIMAGEUPDATE_FAILURE = "PROFILEIMAGEUPDATE_FAILURE";
//*************************** LOCATION SEARCH *********************/
export const LOCATIONSEARCH = "LOCATIONSEARCH";
export const LOCATIONSEARCH_SUCCESS = "LOCATIONSEARCH_SUCCESS";
export const LOCATIONSEARCH_FAILURE = "LOCATIONSEARCH_FAILURE";
export const LOCATIONSEARCH_RESET = "LOCATIONSEARCH_RESET";
//*************************** LANGUAGE*********************/
export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_SUCCESS = "LANGUAGE_SUCCESS";
export const LANGUAGE_FAILURE = "LANGUAGE_FAILURE";
//********************** FIREBASEINFO ***********************/
export const FIREBASEINFO = "FIREBASEINFO";
export const FIREBASEINFO_SUCCESS = "FIREBASEINFO_SUCCESS";
export const FIREBASEINFO_FAILURE = "LANGUAGE_FAILURE";
//********************* AUTH  ***********************/
export const REACHAUTH = "REACHAUTH";
export const REACHAUTH_SUCCESS = "REACHAUTH_SUCCESS";
export const REACHAUTH_FAILURE = "REACHAUTH_FAILURE";
//********************* SIGN IN ******************/
export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
//********************* SIGN IN ******************/
export const EMPLOYEEINFO = "EMPLOYEEINFO";
export const EMPLOYEEINFO_SUCCESS = "EMPLOYEEINFO_SUCCESS";
export const EMPLOYEEINFO_FAILURE = "EMPLOYEEINFO_FAILURE";
//********************* SIGN IN ******************/
export const CREATEUSER = "CREATEUSER";
export const CREATEUSER_SUCCESS = "CREATEUSER_SUCCESS";
export const CREATEUSER_FAILURE = "CREATEUSER_FAILURE";
//********************* OTP******************/
//REQUEST
export const REQUESTOTP = "REQUESTOTP";
export const REQUESTOTP_SUCCESS = "REQUESTOTP_SUCCESS";
export const REQUESTOTP_FAILURE = "REQUESTOTP_FAILURE";
//VALIDATE
export const VALIDATEOTP = "VALIDATEOTP";
export const VALIDATEOTP_SUCCESS = "VALIDATEOTP_SUCCESS";
export const VALIDATEOTP_FAILURE = "VALIDATEOTP_FAILURE";
//***************SIGNOUT**************/
export const SIGNOUT = "SIGNOUT";
//************** REVIEWS ***********************************/
export const REVIEWS = "REVIEWS";
export const REVIEWS_SUCCESS = "REVIEWS_SUCCESS";
export const REVIEWS_FAILURE = "REVIEWS_FAILURE";
//************* PARTNER PROFILE  **************/
export const PARTNERPROFILE = "PARTNERPROFILE";
export const PARTNERPROFILE_SUCCESS = "PARTNERPROFILE_SUCCESS";
export const PARTNERPROFILE_FAILURE = "PARTNERPROFILE_FAILURE";
//************* CUSTOMER LIST  **************/
export const CUSTOMERLIST = "CUSTOMERLIST";
export const CUSTOMERLIST_SUCCESS = "CUSTOMERLIST_SUCCESS";
export const CUSTOMERLIST_FAILURE = "CUSTOMERLIST_FAILURE";
//************* SERVICE LIST  **************/
export const SERVICELIST = "SERVICELIST";
export const SERVICELIST_SUCCESS = "SERVICELIST_SUCCESS";
export const SERVICELIST_FAILURE = "SERVICELIST_FAILURE";

//************* BASKET CUSTOMER  **************/
export const BASKETCUSTOMER = "BASKETCUSTOMER";
export const BASKETCUSTOMER_SUCCESS = "BASKETCUSTOMER_SUCCESS";
export const BASKETCUSTOMER_FAILURE = "BASKETCUSTOMER_FAILURE";
//************* BASKET  **************/
//READ
export const READBASKET = "READBASKET";
export const READBASKET_SUCCESS = "READBASKET_SUCCESS";
export const READBASKET_FAILURE = "READBASKET_FAILURE";
// ADDONS
export const BASKETADDONS = "BASKETADDONS";
export const BASKETADDONS_SUCCESS = "BASKETADDONS_SUCCESS";
export const BASKETADDONS_FAILURE = "BASKETADDONS_FAILURE";
//CREATE
export const CREATEBASKET = "CREATEBASKET";
export const CREATEBASKET_SUCCESS = "CREATEBASKET_SUCCESS";
export const CREATEBASKET_FAILURE = "CREATEBASKET_FAILURE";
//UPDATE
export const UPDATEBASKET = "UPDATEBASKET";
export const UPDATEBASKET_SUCCESS = "UPDATEBASKET_SUCCESS";
export const UPDATEBASKET_FAILURE = "UPDATEBASKET_FAILURE";
export const BASKET_RESET_ERROR = "BASKET_RESET_ERROR";
//FORMDATA
export const FORMDATA = "FORMDATA";
export const FORMDATA_RESET = "FORMDATA_RESET";

//***********bASKET CONFIG APIS */
export const GETSTAFF = "GETSTAFF";
export const GETSTAFF_SUCCESS = "GETSTAFF_SUCCESS";
export const GETSTAFF_FAILURE = "GETSTAFF_FAILURE";

export const EMPLOYEEAVAILABILITY = "EMPLOYEEAVAILABILITY";
export const EMPLOYEEAVAILABILITY_SUCCESS = "EMPLOYEEAVAILABILITY_SUCCESS";
export const EMPLOYEEAVAILABILITY_FAILURE = "EMPLOYEEAVAILABILITY_FAILURE";
//************************ CUSTOMER READ **************/
export const CUSTOMERREADPROFILE = "CUSTOMERREADPROFILE";
export const CUSTOMERREADPROFILE_SUCCESS = "CUSTOMERREADPROFILE_SUCCESS";
export const CUSTOMERREADPROFILE_FAILURE = "CUSTOMERREADPROFILE_FAILURE";
//************************ BUSINESS PROFILE **************/
export const BUSINESSPROFIE = "BUSINESSPROFIE";
export const BUSINESSPROFIE_SUCCESS = "BUSINESSPROFIE_SUCCESS";
export const BUSINESSPROFIE_FAILURE = "BUSINESSPROFIE_FAILURE";
//************************ BUSINESS PROFILE UPDATE**************/
export const BUSINESSPROFIE_UPDATE = "BUSINESSPROFIE_UPDATE";
export const BUSINESSPROFIE_UPDATE_SUCCESS = "BUSINESSPROFIE_UPDATE_SUCCESS";
export const BUSINESSPROFIE_UPDATE_FAILURE = "BUSINESSPROFIE_UPDATE_FAILURE";
//************************ PARTNER INDUSTRY LIST **************/
export const PARTNERINDUSTRY = "PARTNERINDUSTRY";
export const PARTNERINDUSTRY_SUCCESS = "PARTNERINDUSTRY_SUCCESS";
export const PARTNERINDUSTRY_FAILURE = "PARTNERINDUSTRY_FAILURE";
//************************ PARTNER SERVICE LIST **************/
export const PARTNERSERVICE = "PARTNERSERVICE";
export const PARTNERSERVICE_SUCCESS = "PARTNERSERVICE_SUCCESS";
export const PARTNERSERVICE_FAILURE = "PARTNERSERVICE_FAILURE";
//************************ DELETE PARTNER SERVICE LIST **************/
export const DELETEPARTNERSERVICE = "DELETEPARTNERSERVICE";
export const DELETEPARTNERSERVICE_SUCCESS = "DELETEPARTNERSERVICE_SUCCESS";
export const DELETEPARTNERSERVICE_FAILURE = "DELETEPARTNERSERVICE_FAILURE";
//************************ POPULAR INDUSTRY LIST **************/
export const POPULARITEMS = "POPULARITEMS";
export const POPULARITEMS_SUCCESS = "POPULARITEMS_SUCCESS";
export const POPULARITEMS_FAILURE = "POPULARITEMS_FAILURE";
//************************ NETWORK INDUSTRY SEARCH **************/
export const NETWORKINDUSTRY = "NETWORKINDUSTRY";
export const NETWORKINDUSTRY_SUCCESS = "NETWORKINDUSTRY_SUCCESS";
export const NETWORKINDUSTRY_FAILURE = "NETWORKINDUSTRY_FAILURE";
//************************ NETWORK INDUSTRY SEARCH **************/
export const BUSINESSQUERYITEMS = "BUSINESSQUERYITEMS";
export const BUSINESSQUERYITEMS_SUCCESS = "BUSINESSQUERYITEMS_SUCCESS";
export const BUSINESSQUERYITEMS_FAILURE = "BUSINESSQUERYITEMS_FAILURE";
//************************ UPDATE INDUSTRY SEARCH **************/
export const UPDATEINDUSTRY = "UPDATEINDUSTRY";
export const UPDATEINDUSTRY_SUCCESS = "UPDATEINDUSTRY_SUCCESS";
export const UPDATEINDUSTRY_FAILURE = "UPDATEINDUSTRY_FAILURE";
//************************ RESET INDUSTRY DATA **************/
export const RESET_INDUSTRY_DATA = "RESET_INDUSTRY_DATA";
//************************ GET PARTNER GROUP INDUSTRY LEVEL **************/
export const PARTNER_GROUP_INDUSTRY_LEVEL = "PARTNER_GROUP_INDUSTRY_LEVEL";
//************************ PARTNER CREATE SERVICE **************/
export const PARTNERCREATESERVICE = "PARTNERCREATESERVICE";
export const PARTNERCREATESERVICE_SUCCESS = "PARTNERCREATESERVICE_SUCCESS";
export const PARTNERCREATESERVICE_FAILURE = "PARTNERCREATESERVICE_FAILURE";
//************************ GET BUSINESS SERVICE **************/
export const GETBUSINESSSERVICE = "GETBUSINESSSERVICE";
export const GETBUSINESSSERVICE_SUCCESS = "GETBUSINESSSERVICE_SUCCESS";
export const GETBUSINESSSERVICE_FAILURE = "GETBUSINESSSERVICE_FAILURE";
//************************ PARTNER EMPLOYEE LIST **************/
export const PARTNEREMPLOYEELIST = "PARTNEREMPLOYEELIST";
export const PARTNEREMPLOYEELIST_SUCCESS = "PARTNEREMPLOYEELIST_SUCCESS";
export const PARTNEREMPLOYEELIST_FAILURE = "PARTNEREMPLOYEELIST_FAILURE";
//************************ PARTNER EMPLOYEE DETAIL **************/
export const PARTNEREMPLOYEEDETAIL = "PARTNEREMPLOYEEDETAIL";
export const PARTNEREMPLOYEEDETAIL_SUCCESS = "PARTNEREMPLOYEEDETAIL_SUCCESS";
export const PARTNEREMPLOYEEDETAIL_FAILURE = "PARTNEREMPLOYEEDETAIL_FAILURE";
//************************ PARTNER EMPLOYEE UPDATE **************/
export const PARTNEREMPLOYEEUPDATE = "PARTNEREMPLOYEEUPDATE";
export const PARTNEREMPLOYEEUPDATE_SUCCESS = "PARTNEREMPLOYEEUPDATE_SUCCESS";
export const PARTNEREMPLOYEEUPDATE_FAILURE = "PARTNEREMPLOYEEUPDATE_FAILURE";
//************************ EMPLOYEE INVITE **************/
export const EMPLOYEEINVITE = "EMPLOYEEINVITE";
export const EMPLOYEEINVITE_SUCCESS = "EMPLOYEEINVITE_SUCCESS";
export const EMPLOYEEINVITE_FAILURE = "EMPLOYEEINVITE_FAILURE";
//************************ PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE **************/
export const PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE =
  "PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE";
export const PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE_SUCCESS =
  "PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE_SUCCESS";
export const PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE_FAILURE =
  "PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE_FAILURE";
//************************ GETPARTNEROPENHOURS **************/
export const GETPARTNEROPENHOURS = "GETPARTNEROPENHOURS";
export const GETPARTNEROPENHOURS_SUCCESS = "GETPARTNEROPENHOURS_SUCCESS";
export const GETPARTNEROPENHOURS_FAILURE = "GETPARTNEROPENHOURS_FAILURE";
//************************ CREATE PARTNER **************/
export const CREATEPARTNER = "CREATEPARTNER";
export const CREATEPARTNER_SUCCESS = "CREATEPARTNER_SUCCESS";
export const CREATEPARTNER_FAILURE = "CREATEPARTNER_FAILURE";
//************************ BUSINESS LINK **************/
export const BUSINESSLINK = "BUSINESSLINK";
export const BUSINESSLINK_SUCCESS = "BUSINESSLINK_SUCCESS";
export const BUSINESSLINK_FAILURE = "BUSINESSLINK_FAILURE";
//************************ PARTNER BOOKING SETTINGS **************/
export const GETPARTNERBOOKINGSETTINGS = "GETPARTNERBOOKINGSETTINGS";
export const GETPARTNERBOOKINGSETTINGS_SUCCESS =
  "GETPARTNERBOOKINGSETTINGS_SUCCESS";
export const GETPARTNERBOOKINGSETTINGS_FAILURE =
  "GETPARTNERBOOKINGSETTINGS_FAILURE";
//************************ UPDATE PARTNER BOOKING SETTINGS **************/
export const UPDATEPARTNERBOOKINGSETTINGS = "UPDATEPARTNERBOOKINGSETTINGS";
export const UPDATEPARTNERBOOKINGSETTINGS_SUCCESS =
  "UPDATEPARTNERBOOKINGSETTINGS_SUCCESS";
export const UPDATEPARTNERBOOKINGSETTINGS_FAILURE =
  "UPDATEPARTNERBOOKINGSETTINGS_FAILURE";
//************************ GET CUSTOMERS LIST **************/
export const GETCUSTOMERS = "GETCUSTOMERS";
export const GETCUSTOMERS_SUCCESS = "GETCUSTOMERS_SUCCESS";
export const GETCUSTOMERS_FAILURE = "GETCUSTOMERS_FAILURE";
//************************  CUSTOMERS CREATE **************/
export const CUSTOMERCREATE = "CUSTOMERCREATE";
export const CUSTOMERCREATE_SUCCESS = "CUSTOMERCREATE_SUCCESS";
export const CUSTOMERCREATE_FAILURE = "CUSTOMERCREATE_FAILURE";
//************************  CUSTOMERS READ **************/
export const CUSTOMERREAD = "CUSTOMERREAD";
export const CUSTOMERREAD_SUCCESS = "CUSTOMERREAD_SUCCESS";
export const CUSTOMERREAD_FAILURE = "CUSTOMERREAD_FAILURE";
//************************  CUSTOMERS UPDATE **************/
export const CUSTOMERUPDATE = "CUSTOMERUPDATE";
export const CUSTOMERUPDATE_SUCCESS = "CUSTOMERUPDATE_SUCCESS";
export const CUSTOMERUPDATE_FAILURE = "CUSTOMERUPDATE_FAILURE";
//************************  CAMPAIGN **************/
export const CAMPAIGNLIST = "CAMPAIGNLIST";
export const CAMPAIGNDETAILS = "CAMPAIGNDETAILS";
export const CAMPAIGNADD = "CAMPAIGNADD";
export const CAMPAIGN_CREATE = "CAMPAIGN_CREATE";
export const CAMPAIGN_UPDATE = "CAMPAIGN_UPDATE";
//************************  EXTRA INFO **************/
export const PARTNER_EXTRA_INFO = "PARTNER_EXTRA_INFO";
export const RESET_SERVICE_DATA = "RESET_SERVICE_DATA";
