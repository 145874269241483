import React from "react";
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Avatar } from "react-native-paper";
import { AppointmentsInterface } from "../Models/ApiInterfaces";
import LoaderUI from "../Components/Loader";
import TextUI from "../Components/TextUI";
import ImageUI from "../Components/ImageUI";
import { boxShadow } from "../Styles/CommonStyles";
import InfiniteList from "./InfiniteList";
import EnumMapping from "../Utils/Constants/EnumMapping";

interface AppointmentListProps {
  appointmentsInfo: { loading: boolean; data: AppointmentsInterface[] };
  action: (type: string, data?: string) => void;
  noDataMessage?: string;
  options?: { total: number; offset: number };
  getData?: (options) => void;
}

const BookingStatusClone = {
  1: "Unpaid",
  2: "Payment In Progress",
  3: "Payment Failed",
  4: "Payment Received",
  5: "Payment Requested",
  6: "Part Payment Received",
  7: "Payment Captured",
  8: "Payment Declined"
};

function AppointmentList(props: AppointmentListProps) {
  const { data, loading } = props.appointmentsInfo;
  const getProfileImage = (clientInfo) => {
    if (clientInfo.length === 0) {
      return (
        <ImageUI
          source={require("@reachpartner/common/Assets/Images/user-account.png")}
          style={{ width: "100%", height: 90, borderRadius: 4 }}
          resizeMode="contain"
        />
      );
    } else if (clientInfo.length === 1) {
      return (
        <ImageUI
          source={
            clientInfo[0].image ||
            require("@reachpartner/common/Assets/Images/user-account.png")
          }
          style={{ width: "100%", height: 90, borderRadius: 4 }}
          resizeMode="contain"
        />
      );
    }
    return (
      <View style={{ position: "relative", height: 30 }}>
        {clientInfo.map((item, idx) => {
          return (
            <Avatar.Image
              size={24}
              key={idx}
              style={{
                position: "absolute",
                left: idx * 17,
                backgroundColor: "white",
                paddingHorizontal: 2,
              }}
              source={{ uri: item }}
            />
          );
        })}
      </View>
    );
  };
  const getClientNames = (clientInfo) => {
    const clientNames = clientInfo.map(({ name }) => name).join(", ");
    return (
      <TextUI weight="500" size={17} style={{ marginBottom: 3 }}>
        {clientNames}
      </TextUI>
    );
  };
  const getServices = (itemInfo) => {
    return (
      <>
        {itemInfo.slice(0, 2).map((item, idx) => (
          <TextUI style={{ opacity: 0.7 }} key={idx} size={12}>
            {item.itemName}
          </TextUI>
        ))}
        {itemInfo.slice(2).length !== 0 && (
          <TextUI
            style={{
              color: "#007FFF",
              fontWeight: "500",
              marginTop: 10,
              fontSize: 12,
            }}
          >
            +{itemInfo.slice(2).length} more
          </TextUI>
        )}
      </>
    );
  };
  const getDateString = (data) => {
    const date = new Date(data);
    const a = date.getDate();
    const b = new Date(date).toLocaleString("en-us", { month: "long" });
    const c = new Date(date).toLocaleString("en-us", { weekday: "long" });
    const d = new Date(date).getFullYear();
    return `${a} ${b} ${c}, ${d}`;
  };
  const renderAppointment = (item) => {
    const {
      relevance,
      payment: { status = "" },
      totalDuration,
      clientInfo,
      partnerInfo,
      itemInfo,
      status: bookingStatus,
      payment,
      deliveryInfo
    } = item;
    let duration = "";
    if (Math.floor(totalDuration / 60) > 0) {
      duration += `${Math.floor(totalDuration / 60)} hr`;
    }
    let mins = totalDuration % 60;
    if (mins > 0) {
      duration !== "" && (duration += " : ");
      duration += `${mins} Mins`;
    }
    return (
      <TouchableOpacity
        style={AppointmentStyles.card}
        onPress={() => props.action("booking", item.booking)}
      >
        <Text style={AppointmentStyles.bookingDate}>
          {getDateString(item.date)}
        </Text>
        <View style={AppointmentStyles.cardTopElements}>
          <View style={AppointmentStyles.appointmentTimeDetails}>
            <TextUI
              style={{
                fontSize: 11,
                backgroundColor: "#019CF3",
                borderRadius: 2,
                color: "white",
                paddingHorizontal: 5,
                paddingVertical: 5,
              }}
            >
              {item.timing}
            </TextUI>
            <TextUI
              style={{
                fontSize: 11,
                backgroundColor: "#019CF3",
                borderRadius: 2,
                color: "white",
                paddingHorizontal: 5,
                paddingVertical: 5,
                marginHorizontal: 5,
              }}
            >
              {duration}
            </TextUI>
            {/* <TextUI style={{ color: "#019CF3" }}>
              {clientInfo.length > 1 ? "Group Video" : "1-1"}
            </TextUI> */}
            <View style={{ width: 5 }} />
            <ImageUI
              resizeMode="contain"
              source={require("@reachpartner/common/Assets/Images/reach-logo2.png")}
              style={{ width: 20, height: 15 }}
            />
          </View>
          <ImageUI
            style={AppointmentStyles.appointmentLogo}
            source={partnerInfo.image}
          />
        </View>
        <View style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/location-pin.png")}
            height={12}
            width={12}
          /><TextUI>{deliveryInfo?.address}</TextUI>
        </View>
        <View style={AppointmentStyles.cardBody}>
          <View style={AppointmentStyles.clientImages}>
            {getProfileImage(item.clientInfo)}
          </View>
          <View
            style={{
              display: "flex",
              paddingHorizontal: 10,
              flexDirection: "column",
              flex: 1,
              marginTop: 24
            }}
          >
            {getClientNames(clientInfo)}
            {getServices(itemInfo)}
          </View>

          <View style={AppointmentStyles.btns}>
            {relevance?.length > 0 && <View
              style={[
                AppointmentStyles.btn_relevance,
                AppointmentStyles.btnWrapper,
              ]}
            >
              <TextUI size={11} color="white" familyType={"type-2"}>
                {relevance}
              </TextUI>
            </View>}
            {EnumMapping.BookingStatus[bookingStatus]?.length > 0 && <View
              style={[
                AppointmentStyles.btnWrapper,
                {
                  marginBottom: 6,
                  backgroundColor:
                    EnumMapping.BookingStatusColor[bookingStatus],
                },
              ]}
            >
              <TextUI size={11} color="white" familyType={"type-2"}>
                {EnumMapping.BookingStatus[bookingStatus]}
              </TextUI>
            </View>}
            {BookingStatusClone[payment?.status]?.length > 0 && <View
              style={[
                AppointmentStyles.btnWrapper,
                {
                  backgroundColor: EnumMapping.PaymentStatusColor[payment?.status]
                  ,
                }
              ]}
            >
              <TextUI size={11} color="white" familyType={"type-2"}>
                {BookingStatusClone[payment?.status]}
              </TextUI>
            </View>}
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const appointmentList = (data) => {
    if (props.options) {
      return (
        <InfiniteList
          data={data}
          renderItem={renderAppointment}
          options={{
            total: props.options.total,
            offset: props.options.offset,
            isLoading: props.appointmentsInfo.loading,
          }}
          getData={props.getData}
        />
      );
    }
    return (
      <FlatList
        data={data}
        renderItem={({ item }) => {
          return renderAppointment(item);
        }}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  };
  const renderAppointments = () => {
    if (!data.length && loading) {
      return (
        <View style={{ height: 300 }}>
          <LoaderUI />
        </View>
      );
    } else if (!data.length) {
      const messageSplit = props.noDataMessage
        ? props.noDataMessage.split("---")
        : [];
      return (
        <View style={noAppointmentStyles.Wrapper}>
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/noAppointment.png")}
            style={noAppointmentStyles.image}
            resizeMode="contain"
          />
          <View>
            <TextUI
              size={16}
              weight="700"
              style={{ textAlign: "center", marginBottom: 15 }}
            >
              {messageSplit[0] || "No Appointments today"}
            </TextUI>
            <TextUI
              familyType="type-2"
              addOpacity={true}
              style={{ textAlign: "center", lineHeight: 18 }}
            >
              {messageSplit[1] ||
                "There are no upcoming appointments scheduled. When clients book their appointments for the next 3 days it will appear here, hold tight !"}
            </TextUI>
          </View>
        </View>
      );
    }
    return appointmentList(data);
  };
  return (
    <View style={AppointmentStyles.wrapper}>
      {renderAppointments()}
      {/* card body */}
    </View>
  );
}
const AppointmentStyles = StyleSheet.create({
  wrapper: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    flex: 1,
  },

  appointmentDate: {},
  appointmentList: {},
  cardTopElements: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 6
  },
  appointmentTimeDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  time: {
    backgroundColor: "#019CF3",
    color: "#FFFFFF",
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: 4,
    fontSize: 14,
  },
  duration: {
    marginHorizontal: 5,
  },
  groupVideo: {
    color: "#019CF3",
  },
  appointmentLogo: {
    width: 24,
    height: 24,
  },
  appointmentLogoWrapper: {},
  card: {
    marginVertical: 4,
    paddingHorizontal: 12,
    paddingVertical: 12,
    elevation: 10,
    borderRadius: 5,
    ...boxShadow(),
  },
  cardBody: {
    display: "flex",
    flexDirection: "row",
    marginTop: 2,
  },
  clientImages: {
    width: 60,
  },
  clientInfo: {
    flex: 1,
  },
  clientName: {
    fontWeight: "500",
    fontSize: 18,
  },
  category: {
    opacity: 0.5,
  },
  btns: {
    width: 85,
    display: "flex",
    flexDirection: "column",
  },
  btnWrapper: {
    marginBottom: 5,
    borderRadius: 4,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  btn: {
    // fontWeight: "500",
    // fontSize: 11,
    // paddingHorizontal: 0,
    // height: 25,
    // lineHeight: 25,
    // color: "white",
    // textAlign: "center",
    // alignItems: "center",
    // justifyContent: "center",
    // display: "flex",
    // letterSpacing: 0.5,
  },
  bookingDate: {
    fontSize: 14,
    fontWeight: "500",
    marginBottom: 5,
  },

  btn_label: {
    marginHorizontal: 0,
  },
  btn_relevance: {
    backgroundColor: "#5CBEDE",
  },
  btn_source: {
    backgroundColor: "#497ED6",
  },
  btn_status: {
    backgroundColor: "#5AA44B",
    marginBottom: 0,
  },
});
const noAppointmentStyles = StyleSheet.create({
  Wrapper: {
    paddingVertical: 15,
    paddingHorizontal: "5%",
    paddingTop: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    textAlign: "center",
    fontWeight: "700",
  },
  description: {
    textAlign: "center",
    opacity: 0.5,
    marginTop: 10,
  },
  image: {
    height: 120,
    width: 150,
    marginBottom: 25,
  },
});
export default AppointmentList;
