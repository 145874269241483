import React, { useState } from "react";
import { View, ScrollView } from "react-native";
import AppBar from "../Components/Appbar";
import ReviewOverview from "./Components/ReviewOverview";
import ReviewFilter from "./Components/ReviewFilter";
import ReviewCard from "./Components/ReviewCard";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { NavigationProps } from "../Models/interface";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";
import Loader from "../Components/Loader";
import TextUI from "../Components/TextUI";
import ImageUI from "../Components/ImageUI";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
interface IProps extends NavigationProps {
  getReviews: (accountId, accountType, offset, limit) => void;
  business: IBusinessStore;
}

function Reviews(props: IProps) {
  const { rating, data = [], total } = props.business.reviews;
  const { average } = rating || {};

  const [selectedFilters, setSelectedFilters] = useState([]);
  var limit = 10;
  var offset = 0;

  useFocusEffect(
    React.useCallback(() => {
      getReviewList();
    }, [])
  );
  async function getReviewList() {
    let linkedId = await LocalStorage.get("linkedId");
    props.getReviews(linkedId, "employee", offset, limit);
  }

  const filteredData = !selectedFilters.length
    ? data
    : data.filter((item: any) =>
        selectedFilters.includes(Math.floor(item.rating))
      );
  return (
    <>
      <AppBar title={"Reviews"} />
      {props.business.reviews.isLoading ? (
        <Loader />
      ) : data.length ? (
        <View
          style={{
            flex: 1,
            backgroundColor: "white",
          }}
        >
          <ScrollView>
            <View style={{ height: 5, backgroundColor: "#f2f2f2" }} />
            <ReviewOverview average={average} noOfReviews={total} />
            <View style={{ height: 8, backgroundColor: "#f2f2f2" }} />
            <ReviewFilter
              rating={rating}
              noOfReviews={total}
              handleChange={(val) => setSelectedFilters(val)}
              selectedFilters={selectedFilters}
            />
            <View style={{ height: 8, backgroundColor: "#f2f2f2" }} />
            <View style={{ padding: 20, backgroundColor: "white" }}>
              {filteredData.map((item, idx) => (
                <ReviewCard key={idx} review={item} />
              ))}
            </View>
          </ScrollView>
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
          }}
        >
          <View>
            <ImageUI
              source={require("@reachpartner/common/Assets/Images/holding-stars.png")}
              style={{ height: 200, width: 250 }}
              resizeMode={"contain"}
            />
          </View>
          <TextUI
            style={{
              textAlign: "center",
              fontWeight: "500",
              marginBottom: 10,
              fontSize: 18,
            }}
          >
            No Reviews yet
          </TextUI>
          <TextUI
            style={{
              textAlign: "center",
              opacity: 0.7,
              lineHeight: 21,
              fontSize: 16,
            }}
          >
            {`Once customer writes review about\n you, it will appear here.`}
          </TextUI>
        </View>
      )}
    </>
  );
}

const mapStateToProps = (store: any) => {
  return {
    business: store.business,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  getReviews: (accountId, accountType, offset, limit) =>
    dispatch(Actions.getReviews(accountId, accountType, offset, limit)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
