import * as landingActions from "./landingAction";
import * as appointmentActions from "./appointmentActions";
import * as profileActions from "./profileActions";
import * as authActions from "./authActions";
import * as phoneVerificationActions from "./phoneVerificationActions";
import * as invitationActions from "./invitationActions";
import * as businessActions from "./businessActions";
import * as basketActions from "./basketActions";
const Actions = {
  ...landingActions,
  ...appointmentActions,
  ...profileActions,
  ...authActions,
  ...phoneVerificationActions,
  ...invitationActions,
  ...businessActions,
  ...basketActions,
};
export default Actions;
