import React, { useState, useCallback } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { NavigationProps } from "../Models/interface";
import { connect } from "react-redux";
import Actions from "../Actions";
import { IBasketStore } from "../Models";
import RouteNames from "../Utils/Constants/RouteNames";
import TextUI from "../Components/TextUI";
import Page from "../Components/Page";
import Appbar from "../Components/Appbar";
import ButtonUI from "../Components/ButtonUI";
import Row from "../Components/Row";
import Checkbox from "../Components/Checkbox";
import LoaderUI from "../Components/Loader";

interface IProps extends NavigationProps {
  basketStore: IBasketStore;
  updateBasket: (
    basketId: string,
    payloadLany,
    callback: (data: any) => void
  ) => void;
  storeBasketFormdata: (data) => void;
}

function BasketNew(props: IProps) {
  const basketId: string = props.route.params.pid || "";
  const [selectedServices, setSelectedServices] = useState<Array<string>>([...props.basketStore.basketFormData.selectedAddons]);
  //state and props;

  // useFocusEffect(
  //   useCallback(() => {
  //     const addOns = [...new Set(props.basketStore.addons.data.map(({ id }) => id))];
  //     console.log("boolen", props.basketStore.basketDetails.data.itemInfo)
  //     console.log("addons", addOns)
  //     const selectedServices = props.basketStore.basketDetails.data.itemInfo
  //       ? props.basketStore.basketDetails.data.itemInfo.reduce((acc, item) => {
  //         console.log("item", item)
  //         if (addOns.includes(item.item) && !acc.includes(item.item)) {
  //           acc.push(item.item);
  //         }
  //         return acc;
  //       }, [])
  //       : addOns;
  //     console.log("selectedServices", selectedServices)
  //     setSelectedServices(selectedServices);
  //   }, [])
  // );

  //create update command object
  function commandObj(category: string, command?: string, ...rest) {
    const [p1 = "", p2 = "", p3 = "", p4 = "", p5 = "", p6 = ""] = rest;
    return {
      category: category,
      command: command || "",
      param1: p1,
      param2: p2,
      param3: p3,
      param4: p4,
      param5: p5,
      param6: p6,
    };
  }

  //update basket call
  function updateBasket() {
    const basketItemIds = props.basketStore.basketFormData.selectedAddons;
    const newItems = selectedServices.filter(
      (id) => !basketItemIds.includes(id)
    );
    const operations: any[] = [];
    newItems.forEach((id) => {
      operations.push(commandObj("item", "add", "1", id, "1", "1"));
    });
    const removedItems = basketItemIds.filter((id) => !selectedServices.includes(id))
    removedItems.forEach((id) => {
      operations.push(commandObj("item", "remove", "1", id));
    });
    if (operations.length) {
      props.updateBasket(basketId, operations, (response) => {
        if (response && !response.fail) {
          goToViewBasket(response.basket);
          props.storeBasketFormdata({
            ...props.basketStore.basketFormData,
            selectedAddons: selectedServices
          })
        }
      });
    } else {
      goToViewBasket(basketId);
    }
  }

  // navigation to next page
  function goToViewBasket(basketId) {
    props.navigation.push(RouteNames.BasketConfig.name, {
      pid: basketId,
    });
  }

  function renderItem(item) {
    return (
      <View style={{ marginBottom: 15 }} key={item.id}>
        <Row>
          <Checkbox
            active={selectedServices.includes(item.id)}
            onPress={() => {
              if (selectedServices.includes(item.id)) {
                setSelectedServices(
                  selectedServices.filter((d) => d !== item.id)
                );
              } else {
                setSelectedServices([...selectedServices, item.id]);
              }
            }}
          />
          <View style={{ width: 15 }} />
          <View style={{}}>
            <TextUI size={14} weight="500" style={{ marginBottom: 5 }}>
              {item.name}
            </TextUI>
            <TextUI size={12} style={{ marginBottom: 5 }}>
              {item.desc}
            </TextUI>
          </View>
        </Row>
      </View>
    );
  }

  return (
    <Page>
      <Appbar title={"Add ons"} />
      <View style={styles.body}>
        <View style={{ flex: 1 }}>
          <ScrollView>
            {[...new Map(props.basketStore.addons.data.map(item =>
              [item["item"], item])).values()].map((item2) => {
                return renderItem(item2);
              })}
          </ScrollView>
        </View>
        <View style={{ backgroundColor: "white", paddingTop: 10 }}>
          <ButtonUI
            label={"View Basket"}
            onPress={updateBasket}
            labelStyle={{ color: "white", fontWeight: "400", fontSize: 16 }}
          />
        </View>
      </View>
      {props.basketStore.basketDetails.isSubmitting && <LoaderUI mode="long" />}
    </Page>
  );
}

const styles = StyleSheet.create({
  body: {
    padding: 20,
    paddingBottom: 10,
    display: "flex",
    flex: 1,
  },
});

const mapStateToProps = (store: any) => {
  return {
    basketStore: store.basket,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBasket: (basketId, payload, callback) =>
      dispatch(Actions.updateBasket({ basketId, payload, callback })),
    storeBasketFormdata: (payload) =>
      dispatch(Actions.storeBasketFormData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketNew);
