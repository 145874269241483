import { produce } from "immer";
import {
  BOOKING_INFO_UPDATE,
  BOOKINGS_SUCCESS,
  BOOKING_INFO_SUCCESS,
  RESET_BOOKING_INFO,
  BOOKINGS,
  BOOKING_INFO,
  BOOKING_INFO_FAILURE,
} from "../../Actions/types";
import {
  BookingReducerInterface,
  ReducerActionInterface,
} from "../../Models/StoreInterfaces";
import moment, { Moment } from "moment";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import { formatToTime, sortFunction } from "../../Utils";

const initialState: BookingReducerInterface = {
  viewBookingInfo: { loading: false, data: {} },
  appointments: {
    isLoading: true,
    data: {
      offset: 0,
      result: [],
      key: "today",
      total: 999,
    },
  },
  partnerSettings: {},
  newBasket: {
    customerNames: [],
  },
};

const booking = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case BOOKING_INFO_UPDATE:
      return produce(state, (draft) => {
        draft.viewBookingInfo = action.payload;
      });
    case BOOKINGS:
      return produce(state, (draft) => {
        draft.appointments = { ...draft.appointments, isLoading: true };
      });
    case BOOKINGS_SUCCESS:
      const { total, result, key, offset } = parseBookingsResponse(
        action.payload
      );
      const appointmentState = { ...state.appointments };
      let data = JSON.parse(
        JSON.stringify(offset === 0 ? [] : appointmentState.data)
      );
      appointmentState.isLoading = false;
      const found = data.key === key;
      if (!found) {
        data = { result, total, key, offset };
      } else {
        data = { result: [...result, ...data.result], offset, total, key };
      }
      appointmentState.data = data;
      return produce(state, (draft) => {
        draft.appointments = { ...appointmentState };
      });
    case RESET_BOOKING_INFO:
      return produce(state, (draft) => {
        draft.viewBookingInfo = {
          ...draft.viewBookingInfo,
          data: {},
        };
      });
    case BOOKING_INFO:
      return produce(state, (draft) => {
        draft.viewBookingInfo = {
          ...draft.viewBookingInfo,
          loading: true,
        };
      });
    case BOOKING_INFO_SUCCESS:
      return produce(state, (draft) => {
        draft.viewBookingInfo = {
          loading: false,
          data: parseBookingInfoResponse(action.payload),
        };
      });
    case BOOKING_INFO_FAILURE:
      return produce(state, (draft) => {
        draft.viewBookingInfo = {
          ...draft.viewBookingInfo,
          loading: false,
        };
      });

    default:
      return state;
  }
};

export default booking;
const parseBookingsResponse = (data: any) => {
  let { result, total, extraParams, offset } = data;
  result = result.map((item: any) => {
    // const date = moment(item.date).utcOffset(0);
    item.relevance = EnumMapping.PartnerClientRelevance[item.relevance] || "";
    item.source = EnumMapping.PartnerClientSource[item.source] || "";
    item.status = item.status;
    // item.timing = date.format("h:mm A") + " to " + date.add(item.totalDuration, "minutes").format("h:mm A");
    const details = item.itemInfo.reduce(
      (acc, item) => {
        return {
          ...acc,
          totalDuration: acc.totalDuration + item.duration,
          start: item.time < acc.start ? item.time : acc.start,
          end:
            item.time + item.duration > acc.end
              ? item.time + item.duration
              : acc.end,
        };
      },
      {
        totalDuration: 0,
        start: 9999,
        end: 0,
      }
    );
    item.timing =
      formatToTime(details.start) + " - " + formatToTime(details.end);
    return item;
  });
  result = result.sort((a, b) =>
    sortFunction(a, b, extraParams.key === "past")
  );
  return {
    result,
    total,
    offset,
    key: extraParams.key || "",
  };
};
const parseBookingInfoResponse = (data: any) => {
  const getDuration = (input: number) => {
    let duration = "";
    if (Math.floor(input / 60) > 0) {
      duration += `${Math.floor(input / 60)} hr`;
    }
    let mins = input % 60;
    if (mins > 0) {
      duration !== "" && (duration += " : ");
      duration += `${mins} Mins`;
    }
    return duration;
  };
  const getTiming = (date: Moment, duration: number, count: number) => {
    const fromTime = moment(date).utcOffset(0);

    const toTime = moment(date)
      .utcOffset(0)
      .add(duration * count, "minutes");
    return fromTime.format("hh:mm A") + " - " + toTime.format("hh:mm A");
  };
  const {
    reference,
    booking,
    currency,
    date,
    itemInfo,
    totalDuration,
    taxInfo,
    totalCost,
    clientInfo = {},
    relevance,
    payment,
    status: { type, reason },
    summary,
    partnerInfo,
    deliveryInfo,
    client,
    customer
  } = data;
  const { mode } = payment;
  let finalData: any = {
    reference,
    booking,
    currency,
    taxInfo,
    totalCost,
    clientInfo,
    partnerInfo
  };
  finalData.bookingStatusType = type;
  const details = itemInfo.reduce(
    (acc, item) => {
      return {
        ...acc,
        start: item.time < acc.start ? item.time : acc.start,
        end:
          item.time + item.duration > acc.end
            ? item.time + (item.duration)
            : acc.end,
      };
    },
    {
      totalDuration,
      start: 9999,
      end: 0,
    }
  );
  finalData.timing =
    formatToTime(details.start) + " - " + formatToTime(details.end);
  finalData.duration = getDuration(totalDuration);
  finalData.date = moment(date).utcOffset(0).format("DD/MM/YYYY");
  finalData.bookingDateTime = date;
  finalData.dateString = moment(date)
    .utcOffset(0)
    .format("ddd DD MMM")
    .toUpperCase();
  finalData.itemInfo = itemInfo.map((item: any) => {
    var m = moment().utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.add({ hour: Math.floor(item.time / 60), minutes: item.time % 60 });
    item.timing = getTiming(m, item.duration, 1);
    item.duration = getDuration(item.duration);
    item.notes = item.notice;
    item.employeeName = item.employee?.name || "";
    item.image = item.employee.image;
    delete item.time;
    delete item.notice;
    return item;
  });
  finalData.relevanceMsg = relevance === 1 ? "1st Visit" : "";
  finalData.paymentMode = EnumMapping.PaymentMode[mode] || "";
  finalData.bookingStatus = type;
  finalData.reason = reason;
  finalData.payment = payment;
  finalData.summary = summary;
  finalData.deliveryInfo = deliveryInfo;
  finalData.totalDuration = totalDuration;
  finalData.customer = customer;
  finalData.client = client;
  return finalData;
};
