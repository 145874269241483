import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import Modal from "./Modal";
import DropDownUI from "./Dropdown";
import SectionLabel from "./SectionLabel";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

interface IProps {
  children: React.ReactNode;
  value: number;
  onChangeText: (val: string) => void;
}

const hours = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
];
const minutes = [
  { label: 0, value: 0 },
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
  { label: 30, value: 30 },
  { label: 35, value: 35 },
  { label: 40, value: 40 },
  { label: 45, value: 45 },
  { label: 50, value: 50 },
  { label: 55, value: 55 },
  { label: 60, value: 60 },
];
export default function DurationPicker(props: IProps) {
  const [showModal, setShowModal] = useState(false);
  const [hour, setHour] = useState<any>(0);
  const [min, setMin] = useState<any>(0);

  useEffect(() => {
    setHour(Math.floor(props.value / 60));
    setMin(props.value % 60);
  }, [props.value]);

  const onDismiss = () => {
    setShowModal(false);
  };

  const onConfirm = () => {
    setShowModal(false);
    props.onChangeText(hour * 60 + min);
  };

  return (
    <>
      <View>
        <TouchableOpacity
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
          }}
          onPress={() => setShowModal(true)}
        />
        {props.children}
        <MaterialCommunityIcons
          name="timer-outline"
          size={22}
          style={{ position: "absolute", right: 10, top: 13, opacity: 0.7 }}
        />
      </View>
      <Modal
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        shouldShowCloseIcon={false}
        contentContainerStyle={{
          padding: 20,
          width: 270,
          height: 180,
        }}
      >
        <SectionLabel>Select Duration</SectionLabel>
        <View style={{ height: 20 }} />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ width: 100 }}>
            <DropDownUI
              label="Hours"
              list={hours}
              value={hour}
              setValue={(val) => setHour(val)}
            />
          </View>
          <View style={{ width: 30 }} />
          <View style={{ width: 100 }}>
            <DropDownUI
              label="Minutes"
              list={minutes}
              value={min}
              setValue={(val) => setMin(val)}
            />
          </View>
        </View>
        <View style={{ height: 20 }} />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button onPress={onDismiss}>Cancel</Button>
          <Button onPress={onConfirm}>Ok</Button>
        </View>
      </Modal>
    </>
  );
}
const styles = StyleSheet.create({
  body: {},
  header: {
    marginBottom: 10,
    fontWeight: "500",
    fontSize: 18,
  },
  actBtnWrapper: {
    display: "flex",
    flexDirection: "row",
  },
});
