const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyAagFPBRbr-4O4BZDQjeYn7_Ig4KOkkvpc",
    authDomain: "reach-services-network.firebaseapp.com",
    databaseURL: "https://reach-services-network.firebaseio.com",
    projectId: "reach-services-network",
    storageBucket: "reach-services-network.appspot.com",
    messagingSenderId: "278019913924",
    appId: "1:278019913924:web:bc82499ec0bc9eab9e3191",
    measurementId: "G-T43F8WBT7N",
  },
  android_client_id:
    "278019913924-04lm5fs2g3fl3dmq2ongnsghdlo9squh.apps.googleusercontent.com",
  twitter_consumer_key: "OEkVQNIINislsIPeWN3PD3dvS",
  twitter_consumer_secret: "1Y5Y1bE269oBEJoXLo1GMnRWyrX353cvqLsOIDiU83g2qS2uV4",
  api_base_url: "https://api.reach.biz/api",
  origin: "android.reach.biz",
};
export default environment;
