import React from "react";
import { View, Dimensions } from "react-native";
import { getWindowHeight } from "../Utils";

export default function Page(props) {
  const [height, setHeight] = React.useState(getWindowHeight());

  function onLayout(e) {
    setHeight(getWindowHeight());
  }

  return (
    <View style={{ height }} onLayout={onLayout}>
      {props.children}
    </View>
  );
}
