import React from "react";
import {
  Image,
  Platform,
  View,
  ImageStyle,
  ImageResizeMode,
} from "react-native";

interface IProps {
  source: any;
  defaultSource?: any;
  children?: any;
  style?: ImageStyle;
  resizeMode?: ImageResizeMode;
  height?: number,
  width?: number
}
export default function ImageUI(props: IProps) {
  const { source, children, style, height = 50, width = 50, ...rest } = props;
  const styles: ImageStyle = {
    height,
    width,
    borderRadius: 4,
    ...props.style,
  };
  if (!source) {
    return (
      <View
        style={{
          height: style?.height || styles.height,
          width: style?.width || styles.width,
        }}
      />
    );
  }
  let imageSource = {};
  let defaultSource: any = null;
  if (typeof source === "string" && source.includes("http")) {
    //http image
    imageSource = { uri: source };
  } else {
    // local image
    imageSource = Platform.OS === "web" ? source.default : source;
  }

  if (props.defaultSource) {
    if (Platform.OS === "web") {
      defaultSource = {
        uri: props.defaultSource.default,
      };
    } else {
      defaultSource = props.defaultSource;
    }
  }
  // if (typeof source === "string" && source.includes("http")) {
  //   console.log(props.defaultSource?.default);
  //   return (
  //     <Image
  //       defaultSource={{
  //         uri:
  //           require("@reachpartner/common/Assets/Images/review.png").default ||
  //           null,
  //       }}
  //       source={{ uri: source }}
  //       style={styles}
  //       resizeMode={props.resizeMode}
  //     />
  //   );
  // }
  return (
    <Image
      source={imageSource}
      defaultSource={defaultSource}
      style={styles}
      resizeMode={props.resizeMode}
    />
  );
}
