import React from "react";
import { View, TouchableOpacity } from "react-native";
import { StyleTheme } from "../Styles/CommonStyles";

function RadioButton(props: { selected: boolean; onPress?: () => void }) {
  return (
    <TouchableOpacity
      style={[
        {
          height: 18,
          width: 18,
          borderRadius: 12,
          borderWidth: 2,
          padding: 2,
          borderColor: props.selected ? StyleTheme.APP_COLOR : "#d4d4d4",
          alignItems: "center",
          justifyContent: "center",
        },
      ]}
      onPress={props.onPress}
    >
      {props.selected ? (
        <View
          style={{
            // height: 12,
            // width: 12,
            height: "100%",
            width: "100%",
            borderRadius: 6,
            backgroundColor: StyleTheme.APP_COLOR,
          }}
        />
      ) : null}
    </TouchableOpacity>
  );
}

export default RadioButton;
