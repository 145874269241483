import { IAction } from "../Models/interface";
import * as ActionTypes from "./types";
const getPartnerProfile = (partnerId, callback): IAction => {
  return {
    type: ActionTypes.PARTNERPROFILE,
    isApi: true,
    payload: {
      url: `/partner/${partnerId}/public`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const createBasketCustomer = (partnerId, payload, callback): IAction => {
  return {
    type: ActionTypes.BASKETCUSTOMER,
    isApi: true,
    payload: {
      url: `/partner/${partnerId}/customer`,
      method: "POST",
      data: payload,
      callback: callback,
    },
  };
};
const createBasket = ({
  payload,
  partnerId,
  customerId,
  callback,
}): IAction => {
  return {
    type: ActionTypes.CREATEBASKET,
    isApi: true,
    payload: {
      url: `/booking/customer/${customerId}/partner/${partnerId}/basket`,
      method: "POST",
      data: payload,
      callback,
    },
  };
};
const updateBasket = ({ basketId, payload, callback }): IAction => {
  return {
    type: ActionTypes.UPDATEBASKET,
    isApi: true,
    payload: {
      url: `/basket/${basketId}`,
      method: "PATCH",
      data: payload,
      callback,
    },
  };
};
const getBasket = ({
  basketId = "",
  partnerId = "",
  customerId = "",
  callback = () => { },
}): IAction => {
  let url = "";
  if (basketId) {
    url = `/basket/${basketId}`;
  } else {
    url = `/booking/customer/${customerId}/partner/${partnerId}/basket`;
  }
  return {
    type: ActionTypes.READBASKET,
    isApi: true,
    payload: {
      url,
      method: "GET",
      data: {},
      callback: callback,
    },
  };
};

const getStaffList = ({ linkedId }) => {
  return {
    type: ActionTypes.GETSTAFF,
    isApi: true,
    payload: {
      url: `/partner/${linkedId}/employee?offset=0&limit=10`,
      method: "GET",
      data: {},
    },
  };
};
const getEmployeeAvailability = ({
  linkedId,
  employee,
  from,
  to,
  duration,
}) => {
  return {
    type: ActionTypes.EMPLOYEEAVAILABILITY,
    isApi: true,
    payload: {
      url: `/partner/${linkedId}/calendar/availability?employee=${employee}&type=day&from=${from}&to=${to}${duration && "&duration=" + duration
        }`,
      method: "GET",
      data: {},
    },
  };
};
const resetBasketError = () => {
  return {
    type: ActionTypes.BASKET_RESET_ERROR,
  };
};
const storeBasketFormData = (payload) => {
  return {
    type: ActionTypes.FORMDATA,
    payload,
  };
};

const resetBasketFormData = () => {
  return {
    type: ActionTypes.FORMDATA_RESET,
  };
};

const customerRead = (cid, params, callback: () => {}): IAction => {
  let param = "";
  if (params && params.length) {
    param = `?fields=${params.join(",")}`;
  }
  return {
    type: ActionTypes.CUSTOMERREAD,
    isApi: true,
    payload: {
      url: `/customer/${cid}${param}`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const getAddOns = (
  partnerId: string,
  services: Array<string>,
  callback
): IAction => {
  return {
    type: ActionTypes.BASKETADDONS,
    isApi: true,
    payload: {
      url: `/partner/${partnerId}/service/addons?services=${services.join(
        ","
      )}`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

export {
  getPartnerProfile,
  createBasketCustomer,
  createBasket,
  getBasket,
  updateBasket,
  getStaffList,
  getEmployeeAvailability,
  resetBasketError,
  resetBasketFormData,
  storeBasketFormData,
  customerRead,
  getAddOns,
};
