import React from "react";
import { View } from "react-native";
import { Card } from "react-native-paper";
import ImageUI from "../../Components/ImageUI";
import TextUI from "../../Components/TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { filterMockup } from "./ReviewFilter";

interface IProps {
  review: any;
}
const colors = filterMockup.reduce((acc, item) => {
  acc[item.label] = item.color;
  return acc;
}, {});
export default function ReviewCard(props: IProps) {
  const {
    client = {},
    itemInfo = [],
    rating = 0,
    date = "",
    comment = {},
    commentSince = "",
  } = props.review;
  const { name = "", image = "" } = client;
  const { text = "" } = comment;
  const reviewColor = colors[Math.round(rating)] || "#FF3535";
  return (
    <Card
      style={{
        elevation: 10,
        marginVertical: 5,
        paddingVertical: 20,
        paddingHorizontal: 10,
      }}
    >
      <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <View>
          <ImageUI
            source={image}
            style={{ height: 42, width: 42, borderRadius: 100 }}
          />
        </View>
        <View style={{ flex: 1, marginLeft: 12 }}>
          <TextUI style={{ fontSize: 16, fontWeight: "500", marginBottom: 5 }}>
            {name}
          </TextUI>
          <>
            {itemInfo.map((info, idx) => (
              <TextUI
                style={{ fontSize: 12, opacity: 0.7, marginBottom: 5 }}
                key={idx}
              >
                {info.item}
              </TextUI>
            ))}
          </>
          <TextUI style={{ marginBottom: 10, minHeight: 30 }}>{text} </TextUI>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 10,
                alignItems: "center",
              }}
            >
              <MaterialCommunityIcons
                name="thumb-up-outline"
                color="#007FFF"
                size={14}
              />
              <TextUI style={{ marginLeft: 5, color: "#007FFF" }}>Like</TextUI>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 5,
                alignItems: "center",
              }}
            >
              <MaterialCommunityIcons
                name="thumb-down-outline"
                color="#007FFF"
                size={14}
              />
              <TextUI style={{ marginLeft: 5, color: "#007FFF" }}>
                Dislike
              </TextUI>
            </View>
          </View>
        </View>
        {/* top */}
        <View style={{ position: "absolute", right: 0, width: 67 }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: reviewColor,
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: 3,
              borderRadius: 3,
              width: "100%",
              marginBottom: 3,
            }}
          >
            <MaterialCommunityIcons name="star" color="#E1E3E4" size={18} />
            <TextUI style={{ marginLeft: 5, color: "white", fontSize: 14 }}>
              {rating}
            </TextUI>
          </View>
          <TextUI style={{ opacity: 0.6, fontSize: 12, fontWeight: "500" }}>
            {commentSince}
          </TextUI>
        </View>
      </View>
    </Card>
  );
}
