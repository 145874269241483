// ===================================================================
// CUSTOM NETWORK COMPONENT
// ===================================================================
import React from "react";
import { View, StyleSheet } from "react-native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import {
  getEmployeeDetails,
  employeeInvite,
  updatePartnerEmployeeDetails,
  getPartnerGroupIndustryLevelService,
  getPartnerHours,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import AddorUpdateService from "./AddEmployee";
import NetworkService from "./NetworkIndustry";
import DeleteEmployee from "./DeleteEmployee";
import AddEmployee from "./AddEmployee";
import UpdateEmployee from "./UpdateEmployeeDetails";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface INetworkLinkUnLink {
  op: "add" | "remove" | "clone";
  path: "service";
  id: string;
}
interface IMapDispatch {
  getEmployeeDetails: (partnerId, employeeId) => void;
  employeeInvite: (partnerId, employeeId, data, callback) => void;
  updatePartnerEmployeeDetails: (
    partnerId,
    employeeId,
    data: any,
    callback
  ) => void;
  getPartnerGroupIndustryLevelService: (partnerId, callback) => void;
  getPartnerHours: (partnerId, callback) => void;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================

function ServiceOperations(props: IProps) {
  const pageType = React.useMemo(() => {
    const employeeParam = props.route.params.employeeParam;
    if (employeeParam === "add") {
      return "add";
    } else if (employeeParam.length === 24) {
      return "edit";
    } else if (employeeParam === "delete") {
      return "delete";
    } else if (employeeParam === "network") {
      return "network";
    }
  }, [props.route.params.employeeParam]);
  if (pageType === "add") {
    return (
      <AddEmployee
        partnerId={props.route.params.id}
        addEmployee={props.employeeInvite}
        businessStore={props.businessStore}
        navigationPop={() => props.navigation.pop()}
      />
    );
  }
  if (pageType === "edit") {
    return (
      <UpdateEmployee
        employeeData={props.businessStore.employees.data.find(
          (x) => x.id === props.route.params.employeeParam
        )}
        partnerId={props.route.params.id}
        navigationPop={() => props.navigation.pop()}
        businessStore={props.businessStore}
        getEmployeeDetails={props.getEmployeeDetails}
        getPartnerGroupIndustryLevelService={
          props.getPartnerGroupIndustryLevelService
        }
        getPartnerHours={props.getPartnerHours}
        updatePartnerEmployeeDetails={props.updatePartnerEmployeeDetails}
      />
    );
  }
  if (pageType === "delete") {
    return (
      <DeleteEmployee
        businessStore={props.businessStore}
        navigationPop={() => props.navigation.pop()}
        deleteEmployee={() => {}}
        partnerId={props.route.params.id}
        getPartnerServiceList={() => {}}
      />
    );
  }
  return <View />;
}
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getEmployeeDetails: (partnerId, employeeId) =>
      dispatch(getEmployeeDetails(partnerId, employeeId)),
    employeeInvite: (partnerId, employeeId, data, callback) =>
      dispatch(employeeInvite(partnerId, employeeId, data, callback)),
    updatePartnerEmployeeDetails: (partnerId, employeeId, data, callback) =>
      dispatch(
        updatePartnerEmployeeDetails(partnerId, employeeId, data, callback)
      ),
    getPartnerGroupIndustryLevelService: (partnerId, callback) =>
      dispatch(getPartnerGroupIndustryLevelService(partnerId, callback)),
    getPartnerHours: (partnerId, callback) =>
      dispatch(getPartnerHours(partnerId, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceOperations);
