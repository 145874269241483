import { produce } from "immer";
import * as ActionTypes from "../../Actions/types";
import { ReducerActionInterface } from "../../Models/StoreInterfaces";
import { formatToTime, sortFunction } from "../../Utils";
import moment from "moment";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import * as _ from "lodash";
import { AppointmentsInterface } from "../../Models/ApiInterfaces";
export interface IHomeStore {
  weatherInfo: { temperature: string; icon: string };
  notificationInfo: {
    loading: boolean;
    total: any;
    data: { message: string; readOn: string }[];
  };
  appointments: { data: AppointmentsInterface[]; loading: boolean };
  languages: { value: string; label: string }[];
  locationSearchItems: {
    data: string[];
    isLoading: boolean;
    errorMessage: string;
  };
  errorData: {
    code: number;
    mesg: string;
    info: string;
  };
}
const initialState: IHomeStore = {
  weatherInfo: { temperature: "", icon: "" },
  notificationInfo: {
    data: [],
    total: 99,
    loading: true,
  },
  appointments: { loading: true, data: [] },
  languages: [],
  locationSearchItems: { isLoading: false, data: [], errorMessage: "" },
  errorData: {
    code: 0,
    mesg: "",
    info: "",
  },
};

const home = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case ActionTypes.WEATHER_SUCCESS:
      const {
        weather: {
          climate: { icon },
          temperature: { now },
        },
      } = action.payload;
      const weatherInfo = {
        icon,
        temperature: now,
      };
      return produce(state, (draft) => {
        draft.weatherInfo = weatherInfo;
      });
    case ActionTypes.GET_NOTIFICATIONS:
      return produce(state, (draft) => {
        draft.notificationInfo = {
          ...draft.notificationInfo,
          loading: true,
        };
      });
    case ActionTypes.GET_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.notificationInfo = {
          data:
            action.payload?.offset === 0
              ? action.payload.result
              : [...draft.notificationInfo.data, ...action.payload.result],
          loading: false,
          total: action.payload.total,
        };
      });
    case ActionTypes.NOTIFICATION_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.notificationInfo = {
          ...draft.notificationInfo,
          data: [...draft.notificationInfo.data].map((item) => {
            if (item.message === action.payload.message) {
              item.readOn = new Date().toTimeString();
              return item;
            }
            return item;
          }),
        };
      });
    case ActionTypes.HOMEAPPOINTMENTS_SUCCESS:
      const data = parseHomeAppointments(action.payload);
      return produce(state, (draft) => {
        draft.appointments = {
          loading: false,
          data,
        };
      });

    case ActionTypes.LANGUAGE_SUCCESS:
      let languages = action.payload.result || [];
      languages = languages.map((item: any) => {
        return {
          label: item.name,
          value: item.alpha3b,
        };
      });
      return produce(state, (draft) => {
        draft.languages = languages;
      });
    case ActionTypes.LOCATIONSEARCH:
      return produce(state, (draft) => {
        draft.locationSearchItems = {
          data: [],
          errorMessage: "",
          isLoading: true,
        };
      });
    case ActionTypes.LOCATIONSEARCH_SUCCESS:
      const locationResults = action.payload.result || [];
      return produce(state, (draft) => {
        draft.locationSearchItems = {
          data: locationResults,
          errorMessage: locationResults.length === 0 ? "No results found" : "",
          isLoading: false,
        };
      });
    case ActionTypes.LOCATIONSEARCH_FAILURE:
      return produce(state, (draft) => {
        draft.locationSearchItems = {
          data: [],
          errorMessage: "No results found",
          isLoading: false,
        };
      });
    case ActionTypes.LOCATIONSEARCH_RESET:
      return produce(state, (draft) => {
        draft.locationSearchItems = { ...initialState.locationSearchItems };
      });
    case ActionTypes.ERRORDATA:
      const payload = action.payload?.data || action.payload || {};
      if (payload?.response?.status === 403) {
        payload.mesg = "User have already registered for a different primary account"
      }
      const errorData: IHomeStore["errorData"] = {
        code: payload.code || payload.response.status || 0,
        mesg: payload.mesg || "Unexpected error Occured",
        info: payload.info || "",
      };
      return produce(state, (draft) => {
        draft.errorData = errorData;
      });
    case ActionTypes.ERRORDATA_RESET:
      const errorResetData: IHomeStore["errorData"] = {
        code: 0,
        mesg: "",
        info: "",
      };
      return produce(state, (draft) => {
        draft.errorData = errorResetData;
      });
    default:
      return state;
  }
};

const parseHomeAppointments = (data: any) => {
  let { result } = data;
  result = result.map((item: any) => {
    // const date = moment(item.date);
    // .utcOffset(0);
    item.relevance = EnumMapping.PartnerClientRelevance[item.relevance] || "";
    item.source = EnumMapping.PartnerClientSource[item.source] || "";
    item.status = item.status;
    const details = item.itemInfo.reduce(
      (acc, item) => {
        return {
          ...acc,
          totalDuration: acc.totalDuration + item.duration,
          start: item.time < acc.start ? item.time : acc.start,
          end:
            item.time + item.duration > acc.end
              ? item.time + item.duration
              : acc.end,
        };
      },
      {
        totalDuration: 0,
        start: 9999,
        end: 0,
      }
    );
    item.timing =
      formatToTime(details.start) + " - " + formatToTime(details.end);
    // item.timing =
    //   date.format("h:mm A") +
    //   " to " +
    //   date.add(item.totalDuration, "minutes").format("h:mm A");
    return item;
  });
  result = _.sortBy(result, "date");
  result = result.filter((item: any) => {
    const appointmentTime = moment(item.date)
      .add(item.totalDuration, "minutes")
      .utcOffset(0)
      .valueOf();
    const currentTime = moment().utcOffset(0, true).valueOf();
    return appointmentTime > currentTime;
  });
  result = result.sort(sortFunction);
  return result;
};

export default home;
