const CustomerTypes = [
  {
    label: "Existing Customer",
    value: "existing",
  },
  {
    label: "New Customer",
    value: "new",
  },
  {
    label: "Walkin Customer",
    value: "walk-in",
  },
];
const DeliveryLocations = [
  { label: "Home", value: "home" },
  { label: "Venue", value: "venue" },
];
export default {
  CustomerTypes,
  DeliveryLocations,
};
