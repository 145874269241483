import * as ActionTypes from "./types";
import moment from "moment";
import * as _ from "lodash";
import { IAction } from "../Models/interface";

const getNotifications = ({ linkedId, offset, limit }) => {
  const url = `/notification?target=${linkedId}&offset=${offset}&limit=${limit}`;
  const data = {};
  return {
    type: ActionTypes.GET_NOTIFICATIONS,
    isApi: true,
    payload: {
      url,
      method: "GET",
      data,
    },
  };
};

const notificationUpdate = (linkedId, data): IAction => {
  return {
    type: ActionTypes.NOTIFICATION_UPDATE,
    isApi: true,
    payload: {
      url: `/notification?target=${linkedId}`,
      data,
      method: "PUT",
    },
  };
};

const getWeatherInfo = (location): IAction => {
  return {
    type: ActionTypes.WEATHER,
    isApi: true,
    payload: {
      url: `location/weather?locType=address&locData=${location}`,
      method: "GET",
      data: {},
    },
  };
};

const getHomeAppointments = (linkedId: string) => {
  const fromDate = moment().format("DD/MM/yyyy");
  const toDate = moment().add(3, "days").format("DD/MM/YYYY");
  const url = `booking/account/${linkedId}/search?limit=10&employee=&state=&payment=&sort=desc&from=${fromDate}&to=${toDate}&accountType=employee&partner=&offset=0`;
  return {
    type: ActionTypes.HOMEAPPOINTMENTS,
    isApi: true,
    payload: {
      url,
      method: "GET",
      data: {},
    },
  };
};

const errorData = (payload) => {
  return {
    type: ActionTypes.ERRORDATA,
    payload,
  };
};
const errorResetData = () => {
  return {
    type: ActionTypes.ERRORDATA_RESET,
  };
};

export {
  getNotifications,
  notificationUpdate,
  getWeatherInfo,
  getHomeAppointments,
  errorData,
  errorResetData,
};
