import React from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import LoaderUI from "../../Components/Loader";
import ImageUI from "../../Components/ImageUI";
import Modal from "../../Components/Modal";
import EnumMapping from "../../Utils/Constants/EnumMapping"

interface InvitationDetailModalProps {
  visible: boolean;
  action: (type: string) => void;
  inviteDetails: {
    isLoading: Boolean;
    data: any;
  };
}

function InvitationDetailModal(props: InvitationDetailModalProps) {
  const { visible, inviteDetails } = props;
  const { from = "", status = "" } = props.inviteDetails.data;

  const {
    logo = "",
    designation = "",
    phone = [],
    message = "",
    name = "",
    address = "",
  } = from || {};
  const messageData = message.split("\n\n");
  const messageTitle = messageData[0] || "";
  const messageDescription = messageData[1] || "";
  const modalContent = () => (
    <>
      <View style={ModalStyles.partnerLogoContainer}>
        <View style={ModalStyles.partnerLogoWrapper}>
          <ImageUI
            style={{ height: "100%", width: "100%" }}
            source={logo}
            resizeMode="cover"
          />
        </View>
      </View>
      <View
        style={{ backgroundColor: EnumMapping.InvitationColor[status] }}
      >
        <Text style={ModalStyles.invitationStatus}>Invitation {
          EnumMapping.InvitationStatus[status]
        }</Text>
      </View>
      <View
        style={{ marginTop: 10, marginBottom: "3%", paddingHorizontal: 20 }}
      >
        <View
          style={{
            display: "flex",
            alignItems: "flex-end",
            // marginBottom: 10,
          }}
        >
          <TouchableOpacity onPress={() => props.action("close")}>
            <MaterialCommunityIcons
              name={"close"}
              style={{ fontSize: 25, color: "#FFFFFF", fontWeight: "900" }}
            />
          </TouchableOpacity>
        </View>

        <Text style={ModalStyles.partnerName}>{name}</Text>
        <Text style={ModalStyles.partnerLocation}>{address}</Text>
        <View>
          {phone.map((item, idx) => (
            <Text key={idx} style={ModalStyles.partnerContact}>
              {item}
            </Text>
          ))}
        </View>
      </View>
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: "3%",
          backgroundColor: "white",
          borderRadius: 4,
        }}
      >
        <ImageUI
          source={require("@reachpartner/common/Assets/Images/golden-badge.png")}
          style={{ height: 40, width: 40 }}
          resizeMode="contain"
        />
        <Text style={ModalStyles.employeeName}>{messageTitle}</Text>
        <Text style={ModalStyles.message}>{messageDescription}</Text>
        <Text style={ModalStyles.joinUs}>Join Us as</Text>
        <Text style={ModalStyles.designation}>{designation}</Text>
        {status === 1 && (
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 25 }}
          >
            <Button
              mode="contained"
              style={ModalStyles.cancelBtn}
              labelStyle={{ color: "black" }}
              onPress={() => props.action("decline")}
            >
              Decline
            </Button>
            <View style={{ width: 20 }} />
            <Button
              mode="contained"
              style={ModalStyles.acceptBtn}
              onPress={() => props.action("accept")}
            >
              Accept
            </Button>
          </View>
        )}
      </View>
    </>
  );
  const getDisplayElement = () => {
    if (inviteDetails.isLoading) {
      return <LoaderUI />;
    }
    return modalContent();
  };
  return (
    <Modal
      visible={visible}
      onDismiss={() => props.action("close")}
      shouldShowCloseIcon
      contentContainerStyle={{
        backgroundColor: "#007FFF",
        padding: 4,
        margin: 20,
        minHeight: 400,
        width: "95%",
      }}
    >
      {getDisplayElement()}
    </Modal>
  );
}
const ModalStyles = StyleSheet.create({
  partnerLogoContainer: {
    position: "absolute",
    right: 0,
    left: 0,
    top: -35,
    display: "flex",
    alignItems: "center",
  },
  partnerLogoWrapper: {
    width: 110,
    height: 58,
    padding: 10,
    backgroundColor: "white",
    borderRadius: 4,
  },
  partnerLogo: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    resizeMode: "cover",
  },
  partnerName: {
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 24,
    textAlign: "center",
  },
  partnerLocation: {
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
  },
  partnerContact: {
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 24,
    textAlign: "center",
  },
  employeeName: {
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 30,
    marginTop: "3%",
    color: "#24272C",
  },
  message: {
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 25,
    color: "#24272C",
    marginBottom: 10,
    opacity: 0.7,
  },
  joinUs: {
    // fontWeight: "500",
    fontSize: 20,
    lineHeight: 30,
    textAlign: "center",
    color: "#24272C",
  },
  designation: {
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 28,
    textAlign: "center",
    color: "#24272C",
  },
  acceptBtn: {
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 19,
    flex: 1,
    backgroundColor: "#007FFF",
  },
  cancelBtn: {
    backgroundColor: "#FFFFFF",
    borderColor: "#D8D9DC",
    color: "black",
    borderWidth: 1,
    flex: 1,
  },
  invitationStatus: {
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 40,
    textAlign: "center",
    color: "white"
  },
});
export default InvitationDetailModal;
