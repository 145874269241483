import { IAction } from "../Models/interface";
import { REQUESTOTP, VALIDATEOTP } from "./types";

const requestOTP = (linkedId, payload): IAction => {
  return {
    type: REQUESTOTP,
    isApi: true,
    payload: {
      method: "POST",
      url: `user/account/${linkedId}/verify?command=request&accountType=employee`,
      data: payload,
    },
  };
};

const validateOTP = (linkedId, payload, callback): IAction => {
  return {
    type: VALIDATEOTP,
    isApi: true,
    payload: {
      method: "POST",
      url: `user/account/${linkedId}/verify?command=validate&accountType=employee`,
      data: payload,
      callback,
    },
  };
};

export { requestOTP, validateOTP };
