import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Card } from "react-native-paper";
import ImageUI from "../../Components/ImageUI";
import { StyleTheme } from "../../Styles/CommonStyles";
import TextUI from "../../Components/TextUI";

interface CustomerDetailsCardProps {
  data: any;
  goToCustomerProfile: any;
  disableViewProfile?: boolean;
}

function CustomerDetailsCard(props: CustomerDetailsCardProps) {
  const { clientInfo } = props.data;
  const { photo = "", name, phone } = clientInfo || {};

  return (
    <Card style={{ elevation: 4, borderRadius: 2 }}>
      <Text style={{
        fontWeight: "600",
        fontSize: 18,
        marginLeft: 18,
        marginTop: 10
      }}>Customer Details</Text>
      <View style={styles.mainWrapper}>
        <ImageUI
          source={
            photo ||
            require("@reachpartner/common/Assets/Images/user-account.png")
          }
          style={styles.image}
          resizeMode="contain"
        />
        <View style={styles.main}>
          <Text style={styles.name}>{name}</Text>
          <View style={styles.content}>
            <TextUI>
              <Text style={styles.customer}>Phone: </Text><Text style={{ fontWeight: "400", fontSize: 14 }}>{phone || "NA"}</Text>
            </TextUI>
          </View>
        </View>
        <TouchableOpacity onPress={props.goToCustomerProfile} disabled={props?.disableViewProfile}>
          <Card style={{ ...StyleSheet.flatten(styles.dateCard), borderColor: props?.disableViewProfile ? StyleTheme.BOX_BORDER_COLOR : StyleTheme.APP_COLOR }}>
            <Text style={{ ...StyleSheet.flatten(styles.dateCardText), color: props?.disableViewProfile ? "gray" : StyleTheme.APP_COLOR }}>
              View
            </Text>
            <Text style={{ ...StyleSheet.flatten(styles.dateCardText), color: props?.disableViewProfile ? "gray" : StyleTheme.APP_COLOR }}>
              Profile
            </Text>
          </Card>
        </TouchableOpacity>
      </View>
    </Card >
  );
}

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    padding: 20,
    flexDirection: "row",
  },
  image: {
    width: 70,
    height: "100%",
    minHeight: 100,
    borderRadius: 4,
  },
  main: {
    flex: 1,
    paddingHorizontal: 10,
    minHeight: 60
  },
  visitbtn: {
    padding: 0,
    borderColor: "#0FA3B1",
    backgroundColor: "white",
    color: "#0FA3B1",
    borderWidth: 2,
    borderRadius: 6,
    textAlign: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  name: {
    fontWeight: "600",
    fontSize: 18,
  },
  customer: {
    fontWeight: "600",
    fontSize: 14,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: 17,
  },
  dateCard: {
    padding: 8,
    maxWidth: 60,
    borderColor: StyleTheme.APP_COLOR,
    backgroundColor: "white",
    borderWidth: 2,
    borderRadius: 6,
  },
  dateCardText: {
    color: StyleTheme.APP_COLOR,
    fontSize: 11,
    fontWeight: "bold",
    textAlign: "center",
  },
});

export default CustomerDetailsCard;
