import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Appointments from "../+Appointments";
import Notifications from "../+Notification";
import Profile from "../+Profile";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Home from "../+Home";
import RouteNames from "../Utils/Constants/RouteNames";
import { StyleSheet } from "react-native";
import { StyleTheme } from "../Styles/CommonStyles";
import TextUI from "../Components/TextUI";
const Tab = createBottomTabNavigator();

const iconSize = 20;
const color = "rgba(36,39,44,0.9)";
const title = "Reach Business App";
function BottomTabNavigator() {
  function getLabel(focused, label) {
    return (
      <TextUI
        style={{ color: focused ? StyleTheme.APP_COLOR : color }}
        size={11}
        weight="500"
      >
        {label}
      </TextUI>
    );
  }
  function getIcon(focused, icon) {
    return (
      <MaterialCommunityIcons
        name={focused ? icon : `${icon}-outline`}
        size={iconSize}
        color={focused ? StyleTheme.APP_COLOR : color}
      />
    );
  }
  return (
    <Tab.Navigator
      tabBarOptions={{
        labelPosition: "below-icon",
        tabStyle: styles.tabStyles,
        labelStyle: styles.labelStyles,
      }}
      screenOptions={{}}
    >
      <Tab.Screen
        name={RouteNames.LandingPage.name}
        component={Home}
        options={{
          title,
          tabBarLabel: ({ focused }) => getLabel(focused, "Home"),
          tabBarIcon: ({ focused }) => getIcon(focused, "home"),
        }}
      />
      <Tab.Screen
        name={RouteNames.Appointments.name}
        component={Appointments}
        options={{
          title,
          tabBarLabel: ({ focused }) => getLabel(focused, "Appointments"),
          tabBarIcon: ({ focused }) => getIcon(focused, "calendar-blank"),
        }}
      />
      <Tab.Screen
        name={RouteNames.Notifications.name}
        component={Notifications}
        options={{
          title,
          tabBarLabel: ({ focused }) => getLabel(focused, "Notifications"),
          tabBarIcon: ({ focused }) => getIcon(focused, "bell"),
        }}
      />
      <Tab.Screen
        name={"Profile"}
        component={Profile}
        options={{
          title,
          tabBarLabel: ({ focused }) => getLabel(focused, "Profile"),
          tabBarIcon: ({ focused }) => getIcon(focused, "account"),
        }}
      />
    </Tab.Navigator>
  );
}
const styles = StyleSheet.create({
  iconSize: {},
  tabStyles: {
    paddingTop: 8,
    paddingBottom: 5,
  },
  labelStyles: {
    fontSize: 11,
    fontWeight: "400",
    color: StyleTheme.DEFAULT_FONT_COLOR,
    fontFamily: "Rubik",
  },
});
export default BottomTabNavigator;
