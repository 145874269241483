import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleTheme } from "../Styles/CommonStyles";
import TextUI from "./TextUI";

export default function Link(props: {
  children?: string;
  onPress: () => void;
}) {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <TextUI style={{ color: StyleTheme.APP_COLOR }} size={12}>
        View Details
      </TextUI>
    </TouchableOpacity>
  );
}
