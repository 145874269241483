import React from "react";
import { View, Modal, TouchableOpacity } from "react-native";
import { boxShadow, StyleTheme } from "../Styles/CommonStyles";
import ContainerView from "./ContainerView";
import TextUI from "./TextUI";
import { connect } from "react-redux";
import { IStore } from "../Models";
import { IHomeStore } from "../Store/Reducers/homeReducer";
import { errorResetData } from "../Actions/landingAction";
import OutlineButton from "./OutlineButton";

interface IMapStates {
  home: IHomeStore;
}
interface IMapDispatch {
  resetErrorData: () => void;
}
type IProps = IMapStates &
  IMapDispatch & {
    children: React.ReactNode;
  };
function ErrorHandler(props: IProps) {
  const { code, mesg } = props.home.errorData;
  const customCodes = [818, 1465];
  const excludeCodes = [503, 401, 404];
  const [showModal, setShowModal] = React.useState(false);
  React.useEffect(() => {
    if (code !== 0 && mesg !== "") {
      if (
        !excludeCodes.includes(code) &&
        ((code > 500 && code < 600) ||
          (code > 401 && code < 500) ||
          customCodes.includes(code))
      ) {
        setShowModal(true);
      }
    }
  }, [code]);
  function handleClose() {
    setShowModal(false);
    props.resetErrorData();
  }
  if (showModal) {
    return (
      <View>
        {props.children}
        <Modal transparent={true} visible={showModal}>
          <ContainerView>
            <TouchableOpacity
              style={{
                backgroundColor: "rgba(174, 174, 174, 0.3)",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "red",
              }}
              onPress={handleClose}
            >
              <View
                style={{
                  width: 600,
                  backgroundColor: "white",
                  maxWidth: "80%",
                  borderRadius: 4,
                  ...boxShadow(),
                }}
              >
                <View
                  style={{
                    height: 40,
                    backgroundColor: StyleTheme.APP_COLOR,
                    paddingHorizontal: 10,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextUI weight="500" style={{ color: "white" }}>
                    Error: {code}
                  </TextUI>
                </View>
                <View style={{ height: 100, padding: 10 }}>
                  <TextUI weight="400">{mesg}</TextUI>
                </View>
                <View style={{ paddingHorizontal: 10 }}>
                  <OutlineButton
                    label="Close"
                    onPress={handleClose}
                    style={{ marginVertical: 15 }}
                  />
                </View>
              </View>
            </TouchableOpacity>
          </ContainerView>
        </Modal>
      </View>
    );
  }
  return <View>{props.children}</View>;
}
const mapStateToProps = (store: IStore): IMapStates => {
  return {
    home: store.home,
  };
};
const mapDispatchToProps = (dispatch): IMapDispatch => {
  return {
    resetErrorData: () => dispatch(errorResetData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
