import React from "react";
import { ImageBackground, Platform } from "react-native";
export default function ImageBackgroundUI(props) {
  const { source, children, ...rest } = props;
  return (
    <ImageBackground
      source={Platform.OS === "web" ? source.default : source}
      {...rest}
    >
      {children}
    </ImageBackground>
  );
}
