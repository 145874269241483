import React from "react";
import Component from "./__";

interface IProps {
  action: (data: any) => void;
  children: React.ReactNode;
}
export default function ImagePicker(props: IProps) {
  return <Component action={props.action}>{props.children}</Component>;
}
