import React from "react";
import { View } from "react-native";
export default function Row(props) {
  const justifyContent = props.spaceBetween
    ? "space-between"
    : props.center
    ? "center"
    : "flex-start";
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent,
        flex: 1,
      }}
    >
      {props.children}
    </View>
  );
}
