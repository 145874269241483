import React, { useRef, useCallback } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Card } from "react-native-paper";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import Appbar from "../Components/Appbar";
import LoaderUI from "../Components/Loader";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
import { NavigationProps } from "../Models/interface";
import ImageUI from "../Components/ImageUI";
import TextUI from "../Components/TextUI";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";
import { IHomeStore } from "../Store/Reducers/homeReducer";
import InfiniteList from "../Components/InfiniteList";
import moment from "moment-timezone";

interface IProps extends NavigationProps {
  home: IHomeStore;
  getNotifications: (linkedId: string, offset: number, limit: number) => void;
  notificationUpdate: (linkedId: string, data: any) => void;
}

function Notifications(props: IProps) {
  var limit = 30;
  const offset = useRef(0);

  useFocusEffect(
    useCallback(() => {
      offset.current = 0;
      getNotifications();
    }, [])
  );

  const getNotifications = async () => {
    const linkedId = await LocalStorage.get("linkedId");
    props.getNotifications(linkedId, offset.current * limit, limit);
  };

  const renderNotifications = () => {
    let notificationLen = props.home.notificationInfo.data.length;
    if (props.home.notificationInfo.loading && notificationLen === 0) {
      return (
        <View style={{ height: 300 }}>
          <LoaderUI />
        </View>
      );
    } else if (notificationLen === 0) {
      return (
        <View style={noAppointmentStyles.Wrapper}>
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/bellicon.jpg")}
            style={noAppointmentStyles.image}
            resizeMode="contain"
          />
          <View>
            <TextUI
              size={16}
              weight="700"
              style={{ textAlign: "center", marginBottom: 15 }}
            >
              {"No Notification yet!"}
            </TextUI>
            <TextUI
              familyType="type-2"
              addOpacity={true}
              style={{ textAlign: "center", lineHeight: 18 }}
            >
              {
                "Stay tuned ! Notifications about your activity will appear here."
              }
            </TextUI>
          </View>
        </View>
      );
    }
    return notificationList(props.home.notificationInfo.data);
  };
  function getData(options) {
    offset.current = options.offset;
    getNotifications();
  }
  const notificationList = (data: any) => {
    return (
      <View style={{ flex: 1, paddingHorizontal: 10 }}>
        <InfiniteList
          data={data}
          renderItem={(item) => (
            <Card
              style={[
                styles.card,
                { paddingVertical: 10 },
                item.readOn == ""
                  ? { borderColor: "#007FFF", backgroundColor: "#E9F3FF" }
                  : styles.cardRead,
              ]}
              onPress={() => viewNotification(item)}
              key={item.message}
            >
              <View style={styles.body}>
                <ImageUI
                  source={item.image}
                  style={styles.serviceImage}
                  resizeMode="cover"
                  defaultSource={require("../Assets/Images/no-image.png")}
                />
                <View style={styles.serviceDetailWrapper}>
                  <Text style={styles.serviceName}>
                    {item.title} - {getDateString(item?.createdOn)}
                  </Text>
                  <View style={styles.content}>
                    <TextUI weight="400" size={13} style={{ lineHeight: 18 }}>
                      {item.body}
                    </TextUI>
                  </View>
                </View>
                <TouchableOpacity
                  style={styles.viewAllWrapper}
                  onPress={() => viewNotification(item)}
                >
                  <MaterialCommunityIcons
                    name={"chevron-right"}
                    style={styles.viewAllIcon}
                  />
                </TouchableOpacity>
              </View>
            </Card>
          )}
          options={{
            isLoading: props.home.notificationInfo.loading,
            total: props.home.notificationInfo.total,
            offset: offset.current,
          }}
          getData={getData}
        />
      </View>
    );
  };

  const viewNotification = async (user: any) => {
    props.navigation.navigate(RouteNames.Booking.name, {
      bid: user.data.value1,
    });
    const linkedId = await LocalStorage.get("linkedId");
    const data = {
      message: user.message,
      read: true,
    };
    props.notificationUpdate(linkedId, data);
  };

  return (
    <>
      <Appbar title={"Notifications"} hideBack={true} />
      {renderNotifications()}
    </>
  );
}

const getDateString = (data: any) => {
  const utcMoment = moment.utc(data);

  const isInCurrentWeek = utcMoment.isSame(moment(), 'week');
  const isToday = utcMoment.isSame(moment(), 'day');

  let formattedTime;

  if (isToday) {
    formattedTime = utcMoment.tz('Europe/London').format("[Today] h:mm A");
  } else if (isInCurrentWeek) {
    formattedTime = utcMoment.tz('Europe/London').format("dddd [at] h:mm A");
  } else {
    formattedTime = utcMoment.tz('Europe/London').format("DD/MM/YYYY h:mm A");
  }

  return formattedTime;
};

const styles = StyleSheet.create({
  card: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginVertical: 4,
    elevation: 0,
    borderRadius: 4,
    minHeight: 70,
    maxHeight: 100,
    borderWidth: 2,
    borderColor: "rgba(36, 39, 44, 0.05)",

    // backgroundColor: "aliceblue",
  },
  cardRead: {
    backgroundColor: "white",
  },

  body: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  serviceImage: {
    width: 52,
    height: "100%",
    borderRadius: 4,
  },
  serviceDetailWrapper: {
    paddingHorizontal: 10,
    flex: 1,
    // height: "100%",
  },
  serviceName: {
    fontSize: 10,
    fontWeight: "600",
    marginBottom: 5,
    opacity: 0.7,
  },
  content: {
    display: "flex",
    flexDirection: "row",
  },
  notificationRead: {
    fontSize: 14,
    fontWeight: "900",
  },
  viewAllIcon: {
    // color: "#007FFF",
    fontSize: 30,
  },
  viewAllWrapper: {
    width: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  field: { fontSize: 12 },
});
const noAppointmentStyles = StyleSheet.create({
  Wrapper: {
    paddingVertical: 15,
    paddingHorizontal: 30,
    paddingTop: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    textAlign: "center",
    fontWeight: "700",
  },
  description: {
    textAlign: "center",
    opacity: 0.5,
    marginTop: 10,
  },
  image: {
    height: 170,
    width: 220,
    marginBottom: 25,
  },
});
const mapStateToProps = (store: any) => {
  return {
    home: store.home,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  getNotifications: (linkedId: string, offset: number, limit: number) =>
    dispatch(Actions.getNotifications({ linkedId, offset, limit })),
  notificationUpdate: (linkedId: string, data: any) =>
    dispatch(Actions.notificationUpdate(linkedId, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
