// ===================================================================
// This component show the services or areas for business identity
// ===================================================================
import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import TextUI from "./TextUI";
import { StyleTheme } from "../Styles/CommonStyles";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
// ===================================================================
// Component
// ===================================================================
export default function Chip(props) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {props.children}
    </View>
  );
}

Chip.Selected = function (props: {
  children: React.ReactNode;
  action: () => void;
  hideIcon?: boolean;
}) {
  return (
    <TouchableOpacity
      style={[
        styles.item,
        {
          backgroundColor: StyleTheme.APP_COLOR,
          borderColor: "white",
        },
      ]}
      onPress={() => {
        props.action();
      }}
      activeOpacity={0.7}
    >
      <TextUI weight="500" color={"white"}>
        {props.children}
      </TextUI>
      {!props.hideIcon && (
        <MaterialCommunityIcons
          name={"delete"}
          style={{
            color: "white",
            fontSize: 16,
            marginLeft: 5,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

Chip.Item = function (props: {
  children: React.ReactNode;
  action: () => void;
  hideIcon?: boolean;
}) {
  return (
    <TouchableOpacity
      style={[
        styles.item,
        {
          backgroundColor: "white",
          borderColor: StyleTheme.APP_COLOR,
        },
      ]}
      onPress={() => {
        props.action();
      }}
      activeOpacity={0.7}
    >
      <TextUI weight="500" color={StyleTheme.APP_COLOR}>
        {props.children}
      </TextUI>
      {!props.hideIcon && (
        <MaterialCommunityIcons
          name={"plus"}
          style={{
            color: StyleTheme.APP_COLOR,
            fontSize: 16,
            marginLeft: 5,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

Chip.GreyItem = function (props: {
  children: React.ReactNode;
  action: () => void;
  hideIcon?: boolean;
}) {
  return (
    <TouchableOpacity
      style={[
        styles.item,
        {
          backgroundColor: "white",
          borderColor: "#9b9999",
        },
      ]}
      onPress={() => {
        props.action();
      }}
      activeOpacity={0.7}
    >
      <TextUI weight="500" color={"#9b9999"}>
        {props.children}
      </TextUI>
      {!props.hideIcon && (
        <MaterialCommunityIcons
          name={"plus"}
          style={{
            color: StyleTheme.APP_COLOR,
            fontSize: 16,
            marginLeft: 5,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

Chip.Add = function (props) {
  return (
    <TouchableOpacity
      style={[
        styles.item,
        {
          backgroundColor: "white",
          borderColor: StyleTheme.APP_COLOR,
        },
      ]}
      onPress={() => {
        props.action();
      }}
      activeOpacity={0.7}
    >
      <MaterialCommunityIcons
        name={"plus"}
        style={{
          color: StyleTheme.APP_COLOR,
          fontSize: 16,
          marginRight: 5,
        }}
      />
      <TextUI weight="700" color={StyleTheme.APP_COLOR}>
        {props.children}
      </TextUI>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  item: {
    marginRight: 10,
    borderRadius: 20,
    borderWidth: 1,
    padding: 10,
    paddingHorizontal: 25,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
});
