import React, { useState, useMemo, useCallback, useRef } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { IBasketOperation, NavigationProps } from "../Models/interface";
import TextUI from "../Components/TextUI";
import Calender from "../Components/Calendar";
import { useFocusEffect } from "@react-navigation/native";
import Appbar from "../Components/Appbar";
import SelectStaffDetails from "./Components/SelectStaffDetails";
import SelectServiceDetails from "./Components/SelectServiceDetails";
import SelectTimeDetails from "./Components/SelectTimeDeatils";
import ButtonUI from "../Components/ButtonUI";
import Actions from "../Actions";
import { connect } from "react-redux";
import { IBasketStore } from "../Models/StoreInterfaces";
import moment from "moment";
import RouteNames from "../Utils/Constants/RouteNames";
import LoaderUI from "../Components/Loader";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import BottomActionBar from "../Components/BottomActionBar";
import Page from "../Components/Page";
import Modal from "../Components/Modal";
import Row from "../Components/Row";
import OutlineButton from "../Components/OutlineButton";

interface IProps extends NavigationProps {
  business: IBusinessStore;
  basketStore: IBasketStore;
  getBasket: (basketId: string, callback: (data: any) => void) => void;
  updateBasket: (
    basketId: string,
    payloadLany,
    callback: (data: any) => void
  ) => void;
  getEmployeeAvailability: (
    basketId: string,
    employee: string,
    from: string,
    to: string,
    duration: number
    // callback: (data: any) => void
  ) => void;
  storeBasketFormdata: (data) => void;
}

function BasketConfig(props: IProps) {
  const basketId = props.route.params.pid || "";
  const { basketDetails, timesList } = props.basketStore;
  const {
    validity = "",
    itemInfo = [],
    partner = "",
  } = basketDetails.data;
  const date = props.basketStore.basketFormData.date.split("-").reverse().join("-")

  const [formData, setFormData] = useState({
    selectedDate: "",
    itemDetailSelection: {},
    chipSelected: [2],
  });
  const [activeItem, setActiveItem] = useState("");
  const [removeModal, setRemoveModal] = useState(false);
  const [removalData, setRemovalData] = useState<any>({});
  const didMountRef = useRef(false);

  function updateServices(
    type: "change" | "inc_count" | "dec_count" | "remove",
    data
  ) {
    let payload;
    if (type === "change") {
      setActiveItem(data.item);
      const prevItems = formData.itemDetailSelection[data.item] || {};
      setFormData({
        ...formData,
        itemDetailSelection: {
          ...formData.itemDetailSelection,
          [data.item]: { ...prevItems },
        },
      });
      return;
    } else if (type == "inc_count") {
      payload = [
        {
          category: "item",
          command: "count",
          param1: "1",
          param2: data.item,
          param3: (data.count + 1).toString(),
        },
      ];
    } else if (type === "dec_count") {
      payload =
        data.count === 1
          ? confirmCancel(data)
          : data !== 1 && [
            {
              category: "item",
              command: "count",
              param1: "1",
              param2: data.item,
              param3: (data.count - 1).toString(),
            },
          ];
    } else if (type === "remove") {
      payload = [
        {
          category: "item",
          command: "remove",
          param1: "1",
          param2: data.item,
        },
      ];
    }
    //api call to remove item form basket
    if (!payload) {
      return;
    }
    props.updateBasket(basketId, payload, (response) => {
      if (!response.fail) {
        props.getBasket(basketId, (response) => { });
      }
    });
  }

  function confirmCancel(data: any) {
    setRemoveModal(true);
    setRemovalData(data);
  }

  function basketUpdate() {
    const createObj = (
      category: string,
      command?: string,
      ...rest: Array<string>
    ) => {
      const [p1 = "", p2 = "", p3 = "", p4 = "", p5 = "", p6 = ""] = rest;
      return {
        category: category,
        command: command || "",
        param1: p1,
        param2: p2,
        param3: p3,
        param4: p4,
        param5: p5,
        param6: p6,
      };
    };
    let updateList: Array<IBasketOperation> = [];
    updateList.push(
      createObj(
        "date",
        "update",
        moment(formData.selectedDate, "DD-MM-YYYY")
          .utcOffset(0, true)
          .format()
          .replace("Z", ".000Z")
      )
    );
    const emp_time_list = Object.keys(formData.itemDetailSelection).reduce(
      (acc: Array<IBasketOperation>, item) => {
        let obj = formData.itemDetailSelection[item];
        acc.push(
          createObj(
            "employee",
            "set",
            item,
            obj.employee,
            obj.cost.toString(),
            obj.levelId
          )
        );
        acc.push(createObj("time", "update", item, obj.time.toString()));
        return acc;
      },
      []
    );
    updateList = [...updateList, ...emp_time_list];

    props.updateBasket(basketId, updateList, (response) => {
      if (!response.fail) {
        goToCheckout(props.route.params.pid);
      }
    });
  }
  function goToCheckout(basketId) {
    props.navigation.push(RouteNames.BasketCheckout.name, {
      pid: basketId,
    });
  }
  //get initialdata
  useFocusEffect(
    useCallback(() => {
      getInitialData();
    }, [])
  );

  //setting basket details
  useFocusEffect(
    useCallback(() => {
      if (validity && didMountRef.current) {
        setBasketData();
      } else {
        didMountRef.current = true;
      }
    }, [validity])
  );

  //Update timeslist
  useFocusEffect(
    useCallback(() => {
      // To this when we get new slots for employee
      // OLD LOGIC - START
      // const cloneItemDetailSelection = formData.itemDetailSelection;
      // Object.keys(formData.itemDetailSelection).map((item) => {
      //   const employee = formData.itemDetailSelection[item]?.employee || "";
      //   const timeslots = getTimeSlots(employee, item);
      //   timeslots.length &&
      //     (cloneItemDetailSelection[item].time = timeslots[0].value);
      // });
      // handleChange("itemDetailSelection", cloneItemDetailSelection);
      // OLD LOGIC END
      // NEW LOGIC STARTS - Auto asign time for items
      autoAssignTime();
    }, [props.basketStore.timesList.updated])
  );
  function autoAssignTime() {
    const employeesWithTimeslots = props.basketStore.timesList.data;
    const clonedItemDetailSelection = JSON.parse(
      JSON.stringify(formData.itemDetailSelection)
    );
    employeesWithTimeslots.forEach((employeeWithTimeslots) => {
      const itemsCanBeDoneByEmployee: any = Object.keys(
        formData.itemDetailSelection
      )
        .map((itemId) => {
          if (
            formData.itemDetailSelection[itemId].employee ===
            employeeWithTimeslots.employee
          ) {
            return itemId;
          }
          return;
        })
        .filter((item) => item !== undefined);
      // auto assign the time for the items done by the employee
      if (employeeWithTimeslots.slots.length !== 0) {
        let refTimeInMinutes = 0;
        itemsCanBeDoneByEmployee.forEach((itemId, idx) => {
          // assign 1st item from slots returned by api
          // for the rest, assign relative time
          if (idx === 0) {
            clonedItemDetailSelection[itemId].time =
              employeeWithTimeslots.slots[0].value;
            refTimeInMinutes =
              employeeWithTimeslots.slots[0].value +
              clonedItemDetailSelection[itemId].duration *
              clonedItemDetailSelection[itemId].count;
          } else {
            clonedItemDetailSelection[itemId].time = refTimeInMinutes;
            refTimeInMinutes +=
              clonedItemDetailSelection[itemId].duration *
              clonedItemDetailSelection[itemId].count;
          }
        });
      }
    });
    handleChange("itemDetailSelection", clonedItemDetailSelection);
  }
  //setting initial data
  function setBasketData() {
    const itemId = itemInfo[0]?.item || itemInfo[1]?.item || "";
    setActiveItem(itemId);
    const allEmployees = itemInfo.reduce((acc, item) => {
      const itemEmployees = item.cost.actual?.[0]?.employee || [];
      itemEmployees.forEach((e) => {
        if (!acc.includes(e.id)) {
          acc.push(e.id);
        }
      });
      return acc;
    }, []);
    const itemDetailSelection = itemInfo.reduce((acc, item) => {
      const id = item.item;
      const actual = item.cost.actual?.[0] || {};
      const emp = item.employee || actual?.employee?.[0]?.id || "";
      const levelId = actual?.level?.id || "";
      const cost = actual?.value || "";
      acc[id] = {
        employee: emp,
        duration: item.duration,
        count: item.count,
        time:
          formData.itemDetailSelection[activeItem]?.time || item.time || 9999,
        levelId,
        cost,
      };
      return acc;
    }, {});
    setFormData({
      ...formData,
      selectedDate: moment(date).format("DD-MM-YYYY"),
      itemDetailSelection,
    });
    getEmployeeAvailability(
      allEmployees,
      moment(date).format("DD/MM/YYYY"),
      props.basketStore.basketDetails.data.summary.totalDuration
    );
  }

  //initial api's
  const getInitialData = async () => {
    props.getBasket(basketId, (data) => { });
  };

  // get employee availability
  function getEmployeeAvailability(empList, date, duration) {
    const eList = empList.join(",");
    if (eList) {
      props.getEmployeeAvailability(partner, eList, date, date, duration);
    }
  }

  const employeesCanDoItem = useMemo(() => {
    const employees =
      itemInfo.find(({ item }) => item === activeItem)?.cost.actual[0]
        ?.employee || [];

    return employees;
  }, [validity, activeItem]);

  const employeesWithSlots = employeesCanDoItem.filter((empItem) => {
    const employee = timesList.data.find(
      ({ employee }) => employee === empItem.id
    );
    if (!employee) {
      return false;
    }
    if (
      (employee.start === 0 && employee.end === 0) ||
      employee.slots.length === 0
    ) {
      return false;
    }
    return true;
  });

  const getTimeSlots = (selectedEmployee, selectedService) => {
    if (selectedEmployee === "" || selectedService === "") {
      return [];
    }
    const timeSlots = timesList.data.reduce((acc, item) => {
      // Get the time slots for the selected employee
      if (selectedEmployee === item.employee) {
        let slots: any = [...item.slots];
        // const activeItemInfo = itemInfo.find((x) => x.item === selectedService);
        //const duration =
        // (activeItemInfo?.duration || 0) * (activeItemInfo?.count || 0);
        // const elem = Math.floor(duration / 15);
        //if (elem) {
        // Added +1 as backend is already doing -1 slot
        //slots.splice(slots.length - elem + 1);
        //}
        return slots;
      }
      return acc;
    }, []);

    const getAppointmentTimeOfNonSelectedItems = Object.keys(
      formData.itemDetailSelection
    )
      .map((itemId) => {
        if (
          itemId !== selectedService &&
          formData.itemDetailSelection[itemId].employee === selectedEmployee
        ) {
          return {
            time: formData.itemDetailSelection[itemId].time,
            noOfSlots:
              (formData.itemDetailSelection[itemId].duration *
                formData.itemDetailSelection[itemId].count) /
              15,
          };
        }
        return;
      })
      .filter((d) => d);
    getAppointmentTimeOfNonSelectedItems.forEach((slot) => {
      const index = timeSlots.findIndex(
        (timeSlot) => timeSlot.value === slot?.time
      );
      if (index !== -1) {
        timeSlots.splice(index, slot?.noOfSlots);
      }
    });

    //handle duplication
    // Object.keys(formData.itemDetailSelection).forEach((serviceId) => {
    //   const serviceItem = formData.itemDetailSelection[serviceId] || {};
    //   if (
    //     serviceId !== selectedService &&
    //     serviceItem.time !== 9999 &&
    //     selectedEmployee === serviceItem.employee
    //   ) {
    //     // If item #1 is selected, and item #2 has some time is alloted. Then remove the item #2 slots from times list
    //     // this is to avoid the same time selection for both items
    //     const slotsNeedsToBeRemoved = Math.ceil(
    //       (serviceItem.duration * serviceItem.count) / 15
    //     );
    //     const index = timeSlots.findIndex((x) => x.value === serviceItem.time);
    //     if (index !== -1) {
    //       const startTimeValue = timeSlots[index].value;
    //       timeSlots.splice(index, slotsNeedsToBeRemoved);
    //       // handling the window allowance
    //       const currentItemDuration =
    //         formData.itemDetailSelection[selectedService]?.duration;
    //       const reverseSlotsTobeRemoved =
    //         Math.ceil(currentItemDuration / 15) - 1;
    //       //splicing reverse slots
    //       [...Array(reverseSlotsTobeRemoved)].forEach((value, idx) => {
    //         const timeVal = startTimeValue - (idx + 1) * 15;
    //         const index = timeSlots.findIndex((x) => x.value === timeVal);
    //         if (index !== -1) {
    //           timeSlots.splice(index, 1);
    //         }
    //       });
    //     }
    //   }
    // });

    return timeSlots;
  };

  const timeSlots = useMemo(() => {
    const selectedEmployee =
      formData.itemDetailSelection[activeItem]?.employee || "";
    return getTimeSlots(selectedEmployee, activeItem)
  }, [formData, activeItem]);

  function handleChange(name, value) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function staffChange(staffid) {
    if (staffid) {
      const d = { ...formData.itemDetailSelection };
      d[activeItem] = {
        ...d[activeItem],
        employee: staffid,
      };
      handleChange("itemDetailSelection", d);
    }
  }

  function handleTimeSelection(val) {
    const d = { ...formData.itemDetailSelection };
    d[activeItem] = {
      ...d[activeItem],
      time: val,
    };
    handleChange("itemDetailSelection", d);
  }

  function GapBetween() {
    return <View style={{ height: 10, backgroundColor: "#f6f6f6" }} />;
  }

  function dateChanged(currentDate) {
    const d = moment(currentDate, "DD-MM-YYYY")
      .utcOffset(0, true)
      .format("DD/MM/YYYY");
    const employeeList = timesList.data.map(({ employee }) => employee);
    const itemDetailSelection = itemInfo.reduce((acc, item) => {
      const id = item.item;
      const actual = item.cost?.actual?.[0] || {};
      acc[id] = {
        employee: actual?.employee?.[0]?.id || "",
        duration: item.duration,
        count: item.count,
        time: 9999,
        levelId: actual?.level?.id || "",
        cost: actual?.value || "",
      };
      return acc;
    }, {});
    setFormData({
      ...formData,
      selectedDate: currentDate,
      itemDetailSelection,
    });
    getEmployeeAvailability(
      employeeList,
      d,
      props.basketStore.basketDetails.data.summary.totalDuration
    );
    props.storeBasketFormdata({
      ...props.basketStore.basketFormData,
      date: currentDate
    })
  }

  return (
    <Page>
      <Appbar title={"Appointment Basket"} />
      <ScrollView horizontal={false}>
        <View style={{ padding: 20 }}>
          <TextUI size={16} weight={"500"}>
            Select Date
          </TextUI>
          <Calender
            value={formData.selectedDate}
            onChange={(date) => dateChanged(date)}
            minDate={new Date()}
            disabledDayIndexes={props.basketStore.partner.closedDays}
          />
        </View>
        <GapBetween />
        <View style={[styles.section, styles.section1]}>
          <TextUI size={16} weight={"500"}>
            Select Staff
          </TextUI>
          <SelectStaffDetails
            data={employeesWithSlots}
            selected={
              formData.itemDetailSelection?.[activeItem]?.employee || ""
            }
            onStaffChange={(staffid) => staffChange(staffid)}
          />
        </View>
        <GapBetween />
        <View style={[styles.section, styles.section1]}>
          <TextUI size={16} weight={"500"} style={{ marginBottom: 10 }}>
            Select Time
          </TextUI>
          <SelectTimeDetails
            data={timeSlots}
            selected={formData.itemDetailSelection?.[activeItem]?.time || ""}
            selectedChip={(val) => handleTimeSelection(val)}
          />
        </View>
        <GapBetween />
        <View style={styles.section}>
          <TextUI
            size={16}
            weight={"500"}
            style={{ marginLeft: 20, marginBottom: 10 }}>
            Services Selected
          </TextUI>
          <SelectServiceDetails
            data={itemInfo}
            activeItem={activeItem}
            timesList={timesList.data}
            itemDetailSelection={formData.itemDetailSelection}
            symbol={
              props.basketStore.basketDetails?.data?.summary?.currency
                ?.symbol || "£"
            }
            handleChange={(type, service) => updateServices(type, service)}
          />
        </View>
        <GapBetween />
      </ScrollView>
      <BottomActionBar>
        <ButtonUI
          onPress={() => basketUpdate()}
          label="Checkout"
          disabled={
            !(activeItem.length > 0 && formData.itemDetailSelection?.[activeItem]?.time && formData.itemDetailSelection?.[activeItem]?.employee && formData.selectedDate)
          }
        />
      </BottomActionBar>
      {props.basketStore.basketDetails.isSubmitting && <LoaderUI mode="long" />}
      <Modal
        visible={removeModal}
        shouldShowCloseIcon={false}
        contentContainerStyle={{
          width: 300,
        }}>
        <View>
          <TextUI
            style={{
              marginBottom: 25,
              fontWeight: "700",
              lineHeight: 22,
              opacity: 0.9,
              fontSize: 20,
            }}>
            {removalData.name}
          </TextUI>
          <TextUI
            style={{
              marginBottom: 20,
              fontWeight: "500",
              lineHeight: 22,
              opacity: 0.9,
              fontSize: 14,
            }}>
            Are you sure you want to remove this service from your basket ?
          </TextUI>
          <Row style={{ display: "flex" }}>
            <OutlineButton
              label={"Cancel"}
              style={{ flex: 1 }}
              onPress={() => {
                setRemoveModal(false);
                setRemovalData({});
              }}
            />
            <View style={{ width: 20 }} />
            <ButtonUI
              label={"Remove"}
              style={{ flex: 1 }}
              onPress={() => {
                setRemoveModal(false);
                updateServices("remove", removalData);
                setRemovalData({});
                if (Object.keys(formData.itemDetailSelection).length === 1)
                  props.navigation.goBack();
              }}
            />
          </Row>
        </View>
      </Modal>
    </Page>
  );
}
const mapStateToProps = (store: any) => {
  return {
    business: store.business,
    basketStore: store.basket,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getBasket: (basketId, callback) =>
      dispatch(Actions.getBasket({ basketId, callback })),
    getEmployeeAvailability: (linkedId, employee, from, to, duration) =>
      dispatch(
        Actions.getEmployeeAvailability({
          linkedId,
          employee,
          from,
          to,
          duration,
        })
      ),
    updateBasket: (basketId, payload, callback) =>
      dispatch(Actions.updateBasket({ basketId, payload, callback })),
    storeBasketFormdata: (payload) =>
      dispatch(Actions.storeBasketFormData(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasketConfig);
const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  serviceImage: {
    width: 52,
    height: "100%",
    borderRadius: 4,
  },
  checkoutButton: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingVertical: 10,
  },
  section: {
    paddingTop: 22,
    paddingBottom: 15,
  },
  section1: {
    paddingLeft: 20,
  },
});
