import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Calendar } from "react-native-calendars";
import { StyleTheme } from "../Styles/CommonStyles";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import moment from "moment";
import TextUI from "./TextUI";
interface IProps {
  value: string;
  onChange: (val: string) => void;
  minDate?: any;
  disabledDayIndexes: Array<number>;
}
export default function CalendarUI(props: IProps) {
  const { value, onChange, minDate, disabledDayIndexes = [] } = props;
  const formattedDate = value
    ? moment(value, "DD-MM-YYYY").format("YYYY-MM-DD")
    : "";
  const minValue = moment(
    moment(minDate).format("YYYY-MM-DD"),
    "YYYY-MM-DD"
  ).valueOf();

  function getSelectedDate() {
    let temp = {};
    if (value) {
      let formattedDate = moment(value, "DD-MM-YYYY").format("YYYY-MM-DD");
      temp[formattedDate] = { selected: true };
    }
    return temp;
  }

  function handleChange(val) {
    let format = moment(val, "YYYY-MM-DD").format("DD-MM-YYYY");
    onChange(format);
  }
  return (
    <Calendar
      theme={{
        textMonthFontWeight: "500",
        textMonthFontSize: 16,
        dayTextColor: "#24272C",
        textDayFontWeight: "500",
        selectedDayBackgroundColor: StyleTheme.APP_COLOR,
      }}
      disabledDayIndexes={props.disabledDayIndexes}
      pastScrollRange={1}
      onDayPress={(date) => {
        handleChange(date.dateString);
      }}
      renderArrow={(direction) => {
        return (
          <MaterialCommunityIcons
            name={direction === "left" ? "chevron-left" : "chevron-right"}
            size={24}
            color={"#24272C"}
            style={{ fontWeight: "500" }}
          />
        );
      }}
      dayComponent={({ date, state }) => {
        const idx = moment(date.dateString, "YYYY-MM-DD").day();
        const selected = formattedDate && formattedDate === date.dateString;
        const disabled =
          disabledDayIndexes.includes(idx) ||
          moment(date.dateString, "YYYY-MM-DD").valueOf() < minValue;

        return (
          <TouchableOpacity
            activeOpacity={disabled ? 1 : 0.3}
            onPress={() => {
              if (!disabled) {
                handleChange(date.dateString);
              }
            }}
            style={{
              backgroundColor: selected ? StyleTheme.APP_COLOR : "white",
              padding: 5,
              borderRadius: 3,
              width: 30,
              height: 30,
            }}
          >
            <TextUI
              style={{
                textAlign: "center",
                color: disabled ? "grey" : selected ? "white" : "black",
                fontWeight: "500",
                opacity: disabled ? 0.6 : 1,
              }}
            >
              {date.day}
            </TextUI>
          </TouchableOpacity>
        );
      }}
      minDate={minDate || ""}
      markedDates={getSelectedDate()}
      //   dayComponent={({ date, state }) => {
      //     return (
      //       <TouchableOpacity
      //         style={{ height: 25 }}
      //         onPress={() => console.log(date)}
      //       >
      //         <Text
      //           style={{
      //             textAlign: "center",
      //             color:
      //               state === "disabled" ? "rgba(36, 39, 44, 0.4)" : "#24272C",
      //             fontWeight: "500",
      //           }}
      //         >
      //           {date.day}
      //         </Text>
      //       </TouchableOpacity>
      //     );
      //   }}
    />
  );
}
