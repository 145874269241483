import Storage from "react-native-storage";
import AsyncStorage from "@react-native-community/async-storage";

const storage = new Storage({
  // maximum capacity, default 1000
  size: 1000,

  // Use AsyncStorage for RN apps, or window.localStorage for web apps.
  // If storageBackend is not set, data will be lost after reload.
  storageBackend: AsyncStorage, // for web: window.localStorage

  // expire time, default: 1 day (1000 * 3600 * 24 milliseconds).
  // can be null, which means never expire.
  defaultExpires: 1000 * 3600 * 24,

  // cache data in the memory. default is true.
  enableCache: true,

  // if data was not found in storage or expired data was found,
  // the corresponding sync method will be invoked returning
  // the latest data.
  sync: {
    // we'll talk about the details later.
  },
});
type LocalStorageKey =
  | "sessionDetail"
  | "signInProvider"
  | "linkedId"
  | "userId"
  | "tempUserDetails";
const LocalStorage = {
  get: async (key: LocalStorageKey) => {
    try {
      const data = await storage.load({
        key,
      });
      return data;
    } catch (err) {
      console.log(`${key} not found in localstorage`);
      return;
    }
  },
  set: async (key: LocalStorageKey, value: string | boolean | {}) => {
    try {
      const data = await storage.save({
        key,
        data: value,
        expires: null,
      });
      return data;
    } catch (err) {
      console.log(`${key} not found in localstorage`);
      return;
    }
  },
  clearAll: async () => {
    try {
      const keys = await AsyncStorage.getAllKeys();
      await AsyncStorage.multiRemove(keys);
      // await storage.clearMap();

      return;
    } catch (err) {
      console.log(`error occured ${err}`);
      return;
    }
  },
  delete: async (key: LocalStorageKey) => {
    try {
      await AsyncStorage.removeItem(key);
      return;
    } catch (err) {
      console.log(`error occured while deleting the key${err}`);
      return;
    }
  },
};
export default LocalStorage;
