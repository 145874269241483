import { produce } from "immer";
import * as Actions from "../../Actions/types";
import LocalStorage from "../../LocalStorage";
import {
  IUserStore,
  ReducerActionInterface,
} from "../../Models/StoreInterfaces";
import RootNavigation from "../../Navigation/RootNavigation";
import { handleEmployeeInfo } from "../../Utils";

const initialState: IUserStore = {
  signInSuccess: false,
  phoneNumber: "",
  tempUrl: "",
  isLoading: false,
  phoneVerification: {
    success: false,
    message: "",
    isLoading: false,
    timer: 0,
  },
  employeeInfo: {
    country: "",
    firstName: "",
    lastName: "",
    location: "",
    email: "",
    display: "",
    phone: "",
    picture: "",
    date: "",
    postal: "",
    aboutMe: "",
    gender: 1,
    language: [],
    dob: "",
    partners: []
  },
  userInfo: {
    country: "",
    cityTown: "",
  },
};
const user = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case Actions.REACH_SIGNIN_SUCCESS:
      return produce(state, (draft) => {
        draft.signInSuccess = action.payload;
      });

    case Actions.EMPLOYEEINFO_SUCCESS:
      const { employeeInfo, partners } = handleEmployeeInfo(action.payload);
      return produce(state, (draft) => {
        draft.employeeInfo = { ...draft.employeeInfo, ...employeeInfo, partners };
      });

    case Actions.USERINFO_UPDATE:
      return produce(state, (draft) => {
        draft.userInfo = action.payload;
      });
    //otp
    case Actions.REQUESTOTP:
      return produce(state, (draft) => {
        draft.phoneVerification = {
          ...draft.phoneVerification,
          isLoading: true,
        };
      });

    case Actions.REQUESTOTP_SUCCESS:
      const date_now = new Date();
      const date_future = new Date(action.payload.expiry);
      const timeout = Math.round(
        (date_future.getTime() - date_now.getTime()) / 1000
      );
      return produce(state, (draft) => {
        draft.phoneVerification = {
          ...draft.phoneVerification,
          ...{ timer: timeout, isLoading: false },
        };
      });

    case Actions.VALIDATEOTP_SUCCESS:
      return produce(state, (draft) => {
        draft.phoneVerification = { ...draft.phoneVerification };
      });

    case Actions.VALIDATEOTP_FAILURE:
      return produce(state, (draft) => {
        draft.phoneVerification.message = "OTP is Invalid.!";
      });

    case Actions.PROFILE_IMAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.tempUrl = action.payload;
      });
    // case OTP_RESET_SUCCESS_FLAG:
    //   return produce(state, (draft) => {
    //     draft.phoneVerification = { ...draft.phoneVerification, success: false };
    //   });
    case Actions.PROFILEUPDATE_SUCCESS:
      const { employeeInfo: e1 } = handleEmployeeInfo(
        action.payload.extraParams
      );
      return produce(state, (draft) => {
        draft.tempUrl = "";
        draft.employeeInfo = { ...draft.employeeInfo, ...e1 };
      });
    case Actions.FIREBASEINFO_SUCCESS:
      return produce(state, (draft) => {
        draft.employeeInfo = { ...draft.employeeInfo, ...action.payload };
      });

    case Actions.REACHAUTH_SUCCESS:
      const { sessionToken, tokenType, expiresIn, callerInfo } = action.payload;
      const { country, cityTown } = callerInfo;
      LocalStorage.set("sessionDetail", {
        token: sessionToken,
        type: tokenType,
        expiryTime: expiresIn,
      });
      return produce(state, (draft) => {
        draft.userInfo = { country, cityTown };
      });

    case Actions.SIGNIN_SUCCESS:
      const { linked, user, signInProvider } = action.payload;
      LocalStorage.set("linkedId", linked);
      LocalStorage.set("userId", user);
      LocalStorage.set("signInProvider", signInProvider);
      return produce(state, (draft) => {
        draft.isLoading = false;
      });
    case Actions.SIGNIN:
    case Actions.REACHAUTH:
    case Actions.FIREBASEINFO:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case Actions.SIGNIN_FAILURE:
    case Actions.REACHAUTH_FAILURE:
    case Actions.FIREBASEINFO_FAILURE:
      return produce(state, (draft) => {
        draft.isLoading = false;
      });
    case Actions.CREATEUSER_SUCCESS:
      const { id, extraParams } = action.payload;
      LocalStorage.set("linkedId", id);
      const employeeInfoPayload = {
        firstName: extraParams.first,
        lastName: extraParams.last,
        country: extraParams.country,
        phone: extraParams.phone,
        email: extraParams.email,
        location: extraParams.location,
      };
      return produce(state, (draft) => {
        draft.employeeInfo = { ...draft.employeeInfo, ...employeeInfoPayload };
      });
    case "NAVIGATE":
      setTimeout(() => {
        RootNavigation.navigate(action.payload);
      }, 0);
      return state;

    case "RESET":
      setTimeout(() => {
        RootNavigation.navigationReset(action.payload);
      }, 0);
      return state;

    default:
      return state;
  }
};

export default user;
