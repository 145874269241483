import React, { useState } from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import BookingDetailCard from "./BookingDetails";
import BookingUserCard from "./UserInfo";
import CustomerDetailsCard from "./CustomerDetails";
import BookingServiceCard from "./ServiceDetails";
import BusinessDetailsCard from "./BusinessDetails";
import PaymentDetailTable from "./PaymentDetailTable";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import GapBetween from "../../Components/GapBetweenUI";
import BottomActions from "./BottomActions";
import ActionPopup from "./ActionPopups";
import BottomSheet from "../../Components/BottomSheet";
import PaymentOptions from "./PaymentOptions";
import PaymentDetails from "./PaymentDetails";
import { connect } from "react-redux";
import Actions from "../../Actions";
import { IUserStore } from "../../Models";
import EnumMapping from "../../Utils/Constants/EnumMapping";

interface IProps {
  action: (payload, bookingId) => void;
  data: any;
  goToCustomerProfile: () => void;
  user: IUserStore;
  disableViewProfile?: boolean;
}

function BookingInfoComponent(props: IProps) {
  const { data } = props;
  const [bottomSheetType, setBottomSheetType] = useState<
    "" | "change" | "cancel" | "mark-no-show" | "raise-dispute"
  >("");
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const { itemInfo = [] } = data || {};

  const updateBooking = (cat, cmd, reasontext, param2?) => {
    const payload = {
      category: cat,
      command: cmd,
      param1: reasontext,
      param2: param2,
    };
    props.action(payload, data.booking);
    setBottomSheetType("");
  };

  const accessLevel = props?.user?.employeeInfo?.partners?.find((x) => x?.partner === data?.partnerInfo?.partner)?.access

  const handleBottomActions = (category, command?, reason?, id?) => {
    switch (category) {
      case "change":
        setBottomSheetType("change");
        return;
      case "reject":
        setBottomSheetType("cancel");
        return;
      case "pending":
        updateBooking(category, command, reason, id);
        return;
      case "complete":
        updateBooking(category, command, reason, id);
        return;
      case "pay":
        setShowPaymentOptions(true);
      default:
        return;
    }
  };

  const calculatePaymentDetailsHeight = () => {
    let {
      payment: {
        cryptName = "",
        reference = "",
        mode = "",
        status = ""
      },
    } = props.data;
    mode = EnumMapping.PaymentMode[mode];
    return [205, status === 4 ? 50 : 0, reference !== "" ? 40 : 0, cryptName !== "" ? 40 : 0].reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
  }

  return (
    <View style={styles.body}>
      <View style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}>
          <GapBetween />
          <View>
            <BookingDetailCard
              data={data}
              onViewDetails={() => setShowPaymentDetails(true)}
            />
          </View>
          <GapBetween />
          <View>
            <BusinessDetailsCard
              data={data}
              onViewDetails={() => setShowPaymentDetails(true)}
            />
          </View>
          <GapBetween />
          <View>
            <CustomerDetailsCard data={data}
              goToCustomerProfile={props.goToCustomerProfile} disableViewProfile={props?.disableViewProfile} />
          </View>
          <GapBetween />
          <View>
            <BookingUserCard
              data={data}
              goToCustomerProfile={props.goToCustomerProfile}
            />
          </View>
          <GapBetween />
          <View style={styles.servicesWrapper}>
            <View style={styles.servicesHeader}>
              <Text style={styles.servicesLabel}>Services Details</Text>
            </View>
            {itemInfo.map((item, idx) => (
              <View key={item.subbooking} style={{ marginVertical: 5 }}>
                <BookingServiceCard data={item} key={item.item || idx} />
              </View>
            ))}
          </View>
          <GapBetween />
          <View style={{ paddingHorizontal: 20, marginTop: 10 }}>
            <View style={styles.paymentHeader}>
              <Text style={{ fontWeight: "700", fontSize: 18 }}>
                Payment Details
              </Text>
              <View style={styles.cardWrapper}>
                {data.paymentMode === "Cash On Service" ? (
                  <MaterialCommunityIcons
                    name={"cash"}
                    style={styles.cardIcon}
                  />
                ) : (
                  <MaterialCommunityIcons
                    name={"credit-card"}
                    style={styles.cardIcon}
                  />
                )}
                <Text style={styles.cardText}>{data.paymentMode}</Text>
              </View>
            </View>
            <PaymentDetailTable data={data} />
          </View>
          <GapBetween />
        </ScrollView>
      </View>
      <ActionPopup
        data={data}
        type={bottomSheetType}
        changeType={(type) => setBottomSheetType(type)}
        updateBooking={updateBooking} employeeId={itemInfo[0]?.employee?.id} />
      <BottomActions data={data} action={handleBottomActions} />
      <BottomSheet
        isOpen={showPaymentOptions}
        onDismiss={() => {
          setShowPaymentOptions(false);
        }}
        height={325}
      >
        <PaymentOptions
          action={(payload) => {
            setShowPaymentOptions(false);
            props.action(payload, data.booking);
          }}
        />
      </BottomSheet>
      <BottomSheet
        isOpen={showPaymentDetails}
        onDismiss={() => {
          setShowPaymentDetails(false);
        }}
        height={calculatePaymentDetailsHeight()}
      >
        <PaymentDetails
          data={props.data}
          action={() => {
            setShowPaymentDetails(false);
          }}
        />
      </BottomSheet>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
  },
  servicesWrapper: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  servicesHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: 10,
  },
  servicesLabel: { fontWeight: "700", fontSize: 18 },
  bookingStatus: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 6,
    fontWeight: "500",
    color: "white",
    letterSpacing: 0.3,
  },
  paymentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  cardWrapper: {
    borderWidth: 1,
    borderColor: "lightgrey",
    paddingHorizontal: 10,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cardIcon: {
    color: "#007FFF",
    fontSize: 18,
    marginRight: 5,
  },
  cardText: {
    fontWeight: "700",
    fontSize: 10,
  },
  change: {
    flex: 1,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#e9e9e9",
    justifyContent: "center",
  },
  changeText: {
    fontSize: 16,
    fontWeight: "500",
    color: "#24272c",
    alignItems: "center",
    letterSpacing: 0,
  },
  completeText: {
    fontSize: 16,
    fontWeight: "500",
    alignSelf: "center",
    letterSpacing: 0,
  },
  complete: {
    flex: 1,
    backgroundColor: "#1995FC",
    justifyContent: "center",
  },
});

const mapStateToProps = (store: any) => {
  return {
    user: store.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLanguages: () => dispatch(Actions.getLanguages()),
    profileUpdate: (linkedId: string, payload: any, callback: any) =>
      dispatch(Actions.profileUpdate(linkedId, payload, callback)),
    getLocations: (val: any) => dispatch(Actions.getLocations(val)),
    profileImageUpdate: (data: any, callback) =>
      dispatch(Actions.profileImageUpdate(data, callback)),
    locationReset: () => dispatch(Actions.locationReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingInfoComponent);
