import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleTheme } from "../Styles/CommonStyles";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

interface IProps {
  active: boolean;
  onPress?: (val: boolean) => void;
}

export default function Checkbox(props: IProps) {
  return (
    <TouchableOpacity
      style={{
        height: 20,
        width: 20,
        borderWidth: 2,
        borderColor: !props.active ? "#bababa" : StyleTheme.APP_COLOR,
        backgroundColor: !props.active ? "white" : StyleTheme.APP_COLOR,
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      onPress={() => {
        props.onPress && props.onPress(!props.active);
      }}
      activeOpacity={0.6}
    >
      {props.active && (
        <MaterialCommunityIcons
          name={"check"}
          style={{
            color: "white",
            fontSize: 18,
            alignSelf: "center",
          }}
        />
      )}
    </TouchableOpacity>
  );
}
