import React from "react";
import { View, StyleSheet } from "react-native";
import { Card } from "react-native-paper";
import ImageUI from "../../Components/ImageUI";
import TextUI from "../../Components/TextUI";
import { StyleTheme } from "../../Styles/CommonStyles";

export default function BookingServiceCard(props) {
  const { data } = props;
  const { image, name, employeeName, duration, timing, notes, count } = data;

  return (
    <Card style={styles.card}>
      <View style={styles.mainWrapper}>
        <View style={styles.body}>
          <View>
            <ImageUI
              source={image}
              style={styles.serviceImage}
              resizeMode="cover"
              height={80}
              width={80}
              defaultSource={require("@reachpartner/common/Assets/Images/no-image.png")}
            />
            <View
              style={{
                position: "absolute",
                right: -5,
                backgroundColor: StyleTheme.APP_COLOR,
                top: -5,
                borderRadius: 50,
                width: 15,
                height: 15,
              }}
            >
              <TextUI size={12} style={{ color: "white", textAlign: "center" }}>
                {count}
              </TextUI>
            </View>
          </View>
          <View style={styles.serviceDetailWrapper}>
            <TextUI
              familyType="type-2"
              size={12}
              weight="700"
              style={{ marginBottom: 7 }}
              numberOfLines={1}
            >
              {name}
            </TextUI>
            <View style={styles.content}>
              <TextUI familyType="type-2" size={12} weight="500">
                Staff Name:{" "}
              </TextUI>
              <TextUI familyType="type-2" size={12}>
                {employeeName}
              </TextUI>
            </View>
            <View style={styles.content}>
              <TextUI familyType="type-2" size={12} weight="500">
                When:{" "}
              </TextUI>
              <TextUI familyType="type-2" size={12}>
                {timing}
              </TextUI>
            </View>
            <View style={styles.content}>
              <TextUI familyType="type-2" size={12} weight="500">
                Duration:{" "}
              </TextUI>
              <TextUI familyType="type-2" size={12}>
                {duration}
              </TextUI>
            </View>
          </View>
          <View style={styles.checkApp}></View>
        </View>
        {notes ? (
          <View style={styles.notesWrapper}>
            <TextUI>
              <TextUI style={{ fontWeight: "700" }}>Notes: </TextUI>
              <TextUI>
                I have allergy for acrylic nail varnish, so please don't use it.
              </TextUI>
            </TextUI>
          </View>
        ) : (
          <View />
        )}
      </View>
    </Card>
  );
}
const styles = StyleSheet.create({
  card: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    elevation: 6,
    marginBottom: 10,
  },
  mainWrapper: {},
  body: {
    display: "flex",
    flexDirection: "row",
  },
  serviceImage: { width: 80, borderRadius: 4 },
  serviceDetailWrapper: {
    paddingHorizontal: 10,
    flex: 1,
    display: "flex",
  },
  serviceName: {
    fontWeight: "500",
    fontSize: 12,
    marginBottom: 5,
  },
  content: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    width: 100,
    fontSize: 10,
  },
  checkApp: {
    display: "flex",
    flexDirection: "row",
  },
  field: { fontSize: 10 },
  notesWrapper: {
    marginTop: 10,
    backgroundColor: "#F3F5FB",
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 4,
  },
});
