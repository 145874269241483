import React, { useState } from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Calendar from "./Calendar";
import Modal from "./Modal";

export default function CalendarInput(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <View>
        {props.children}
        <TouchableOpacity
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onPress={() => setShowModal(true)}
        />
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 20,
            top: 12,
            height: 25,
            width: 24,
          }}
          onPress={() => setShowModal(true)}
        >
          <MaterialCommunityIcons
            name={"calendar"}
            style={{
              opacity: 0.5,
            }}
            size={24}
          />
        </TouchableOpacity>
      </View>
      <Modal
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        contentContainerStyle={{
          padding: 15,
          width: 300,
          height: 380,
        }}
      >
        <Calendar
          value={props.value}
          onChange={(date) => {
            setShowModal(false);
            props.onChange(date);
          }}
          minDate={new Date()}
          disabledDayIndexes={props.disabledDayIndexes}
        />
      </Modal>
    </>
  );
}
const styles = StyleSheet.create({
  body: {},
  header: {
    marginBottom: 10,
    fontWeight: "500",
    fontSize: 18,
  },
  actBtnWrapper: {
    display: "flex",
    flexDirection: "row",
  },
});
