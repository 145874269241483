import React from "react";
import { Platform, ViewStyle, TextStyle } from "react-native";
import { Button } from "react-native-paper";
import { StyleTheme } from "../Styles/CommonStyles";
import TextUI from "./TextUI";

interface ButtonUIProps {
  label: string;
  onPress: () => void;
  disabled?: boolean;
  style?: ViewStyle;
  labelStyle?: TextStyle;
  mode?: "contained" | "outlined" | "text";
}

function ButtonUI(props: ButtonUIProps) {
  const {
    label = "",
    onPress = () => { },
    disabled = false,
    style = {},
    labelStyle = {},
  } = props;
  let textColor;
  let buttonStyle: ViewStyle = {};

  if (props.mode === "outlined") {
    textColor = StyleTheme.APP_COLOR;
    buttonStyle = {
      borderColor: StyleTheme.APP_COLOR,
    };
  } else {
    textColor = "white";
  }

  return (
    <Button
      mode={props.mode || "contained"}
      onPress={() => {
        if (!disabled) {
          onPress();
        }
      }}
      style={{
        display: "flex",
        width: "100%",
        // flex: 1,
        height: 44,
        maxHeight: 44,
        justifyContent: "center",
        flexDirection: "column",
        opacity: disabled ? 0.5 : 1,
        ...shadow(),
        ...buttonStyle,
        ...style,
        ...(props.disabled && {}),
      }}
      uppercase={false}
    >
      <TextUI style={{ fontWeight: "500", color: textColor, ...labelStyle }}>
        {label}
      </TextUI>
    </Button>
  );
}

function shadow() {
  return Platform.select({
    android: {
      elevation: 10,
    },
    web: {
      boxShadow: `0 3px 12px rgba(0,0,0,0.25)`,
    },
  });
}

export default ButtonUI;
