import { produce } from "immer";
import * as ActionTypes from "../../Actions/types";
import { ReducerActionInterface } from "../../Models/StoreInterfaces";
import moment from "moment";
import * as _ from "lodash";
import { getTimegap, handleEmployeeInfo } from "../../Utils";

export interface IBusinessStore {
  partners: {
    enableForBooking: boolean;
    logo: string;
    name: string;
    partner: string;
  }[];
  reviews: {
    isLoading: boolean;
    data: Array<any>[];
    rating: {
      five: number;
      four: number;
      three: number;
      two: number;
      one: number;
      average: number;
    };
    total: number;
  };
  privacySettings: {
    isLoading: boolean;
    isUpdating: boolean;
    updatedTime: number;
    data: {
      cookie: boolean;
      data: boolean;
      eComm: boolean;
      term: boolean;
    };
  };
  profile: {
    data: {
      partner: string; // business partner object id
      name: string; // business name
      address: string; // business trading address valid UK address
      phone: string[]; // array of valid UK phone number
      email: string[]; // array of email address
      website: string[]; // business website, but if present it must be valid value.  "website" : [] is the acceptable empty field. "website" : ["rush.co.uk", "rush.com", "rush.eu"]
      country: string; // ISO3166-1_Code alpha 3b country code; Ex: "country" : "GBR"
      logo: string; // logo image url
      enableForBooking: boolean; // Enable online booking for customers
      serviceLocation: number[]; // service delivery locations (venue, home, online)
      areaCovered: string[]; // contains the area's covered by business; MANDATORY if serviceLocation array contains ServiceDeliveryLocationClient
      postCovered: string[]; // contains the postcode's covered by business; MANDATORY if serviceLocation array contains ServiceDeliveryLocationClient
      enableRadiusCoverage: boolean; // customer can configure radius coverage from their trading address
      radiusCoverage: number; // radius in miles of coverage from 'address'
      aboutUs: string; // business description
      social: { type: string; url: string }[];
      enableOnlinePayment: boolean;
      readyForBooking: boolean;
      referral: string;
      publicUrl: string;
    };
    isLoading: boolean;
    isUpdading: boolean;
  };
  customers: {
    data: any[];
    total: number;
    offset: number;
    limit: number;
  };
  services: {
    data: {
      id: string;
      image: string;
      name: string;
      oType: number;
      enableForBooking;
    }[];
    total: number;
    limit: number;
    offset: number;
  };
  itemsByQuery: {
    type: string;
    data: {
      name: string;
      image: [string];
      id: string;
      desc: string;
    }[];
  };
  popular: { name: string; image: [string]; id: string; desc: string }[];
  employees: {
    data: {
      access: number;
      enableForBooking: boolean;
      first: string;
      id: string;
      image: string;
      last: string;
      readyForBooking: boolean;
      status: { txt: string }[];
    }[];
    total: number;
    limit: number;
    offset: number;
  };
  industries: {
    data: { id: string; image: string; name: string; oType: number }[];
    total: number;
    limit: number;
    offset: number;
  };
  employeeDetails: {
    employee: string;
    partner: string;
    calling: string;
    designation: string;
    joinedOn: string;
    number: number;
    skill: [
      {
        industry: { id: string; name: string };
        level: { id: string; name: string };
      }
    ];
    service: [{ id: string; name: string }];
    rating: {
      average: number;
      count: number;
    };
    hours: {
      mo: [{ start: number; end: number }];
      tu: [{ start: number; end: number }];
      we: [{ start: number; end: number }];
      th: [{ start: number; end: number }];
      fr: [{ start: number; end: number }];
      sa: [{ start: number; end: number }];
      su: [{ start: number; end: number }];
    };
    access: number;
    holiday: {
      annual: number;
      sickness: number;
      parenting: number;
    };
  };
  isLoading: boolean;
  isUpdating: boolean;
}

export const initialState: IBusinessStore = {
  partners: [],
  reviews: {
    isLoading: false,
    data: [],
    rating: {
      five: 0,
      four: 0,
      three: 0,
      two: 0,
      one: 0,
      average: 0,
    },
    total: 0,
  },
  privacySettings: {
    isLoading: false,
    data: {
      cookie: false,
      data: false,
      eComm: false,
      term: false,
    },
    updatedTime: 0,
    isUpdating: false,
  },
  profile: {
    isLoading: false,
    isUpdading: false,
    data: {
      partner: "", // business partner object id
      name: "", // business name
      address: "", // business trading address valid UK address
      phone: [""], // array of valid UK phone number
      email: [""], // array of email address
      website: [""], // business website, but if present it must be valid value.  "website" : [] is the acceptable empty field. "website" : ["rush.co.uk", "rush.com", "rush.eu"]
      country: "GBR", // ISO3166-1_Code alpha 3b country code; Ex: "country" : "GBR"
      logo: "", // logo image url
      enableForBooking: true, // Enable online booking for customers
      serviceLocation: [1], // service delivery locations (venue, home, online)
      areaCovered: [], // contains the area's covered by business; MANDATORY if serviceLocation array contains ServiceDeliveryLocationClient
      postCovered: [], // contains the postcode's covered by business; MANDATORY if serviceLocation array contains ServiceDeliveryLocationClient
      enableRadiusCoverage: true, // customer can configure radius coverage from their trading address
      radiusCoverage: 1, // radius in miles of coverage from 'address'
      aboutUs: "", // business description
      social: [],
      enableOnlinePayment: true,
      readyForBooking: false,
      referral: "",
      publicUrl: "string",
    },
  },
  customers: {
    data: [],
    total: 999,
    offset: 0,
    limit: 30,
  },
  services: { total: 999, limit: 30, offset: 0, data: [] },
  employees: { total: 999, limit: 30, offset: 0, data: [] },
  popular: [],
  itemsByQuery: { type: "", data: [] },
  industries: {
    total: 99,
    limit: 30,
    offset: 0,
    data: [],
  },
  employeeDetails: {
    number: 0,
    employee: "",
    partner: "",
    calling: "",
    designation: "",
    joinedOn: "",
    skill: [
      {
        industry: { id: "", name: "" },
        level: { id: "", name: "" },
      },
    ],
    service: [{ id: "", name: "" }],
    rating: {
      average: 0,
      count: 0,
    },
    hours: {
      mo: [{ start: 0, end: 0 }],
      tu: [{ start: 0, end: 0 }],
      we: [{ start: 0, end: 0 }],
      th: [{ start: 0, end: 0 }],
      fr: [{ start: 0, end: 0 }],
      sa: [{ start: 0, end: 0 }],
      su: [{ start: 0, end: 0 }],
    },
    access: 0,
    holiday: {
      annual: 0,
      sickness: 0,
      parenting: 0,
    },
  },
  isLoading: false,
  isUpdating: false,
};

const business = (state = initialState, action: ReducerActionInterface) => {
  let dataByType;
  switch (action.type) {
    case ActionTypes.EMPLOYEEINFO_SUCCESS:
      const { partners } = handleEmployeeInfo(action.payload);
      return produce(state, (draft) => {
        draft.partners = partners;
      });
    case ActionTypes.REVIEWS:
      return produce(state, (draft) => {
        draft.reviews = {
          ...initialState.reviews,
          isLoading: true,
        };
      });
    case ActionTypes.REVIEWS_SUCCESS:
      action.payload.result = action.payload.result.map((item) => {
        item.commentSince = getTimegap(
          moment(),
          moment(item.date).utcOffset(0)
        );
        return item;
      });
      return produce(state, (draft) => {
        draft.reviews = {
          isLoading: false,
          data: action.payload.result,
          rating: action.payload.rating,
          total: action.payload.total,
        };
      });
    case ActionTypes.REVIEWS_FAILURE:
      return produce(state, (draft) => {
        draft.reviews = {
          ...draft.reviews,
          isLoading: false,
        };
      });
    //*********************Privacy settingsStart ********* */
    //privacy settings
    case ActionTypes.PRIVACY:
      return produce(state, (draft) => {
        draft.privacySettings = { ...draft.privacySettings, isLoading: true };
      });
    case ActionTypes.PRIVACY_SUCCESS:
      return produce(state, (draft) => {
        draft.privacySettings = {
          ...draft.privacySettings,
          data: action.payload.privacy,
          updatedTime: new Date().getTime(),
          isLoading: false,
        };
      });
    case ActionTypes.PRIVACYUPDATE:
      return produce(state, (draft) => {
        draft.privacySettings = { ...draft.privacySettings, isUpdating: true };
      });
    case ActionTypes.PRIVACYUPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.privacySettings = {
          ...draft.privacySettings,
          data: action.payload.extraParams,
          updatedTime: new Date().getTime(),
          isUpdating: false,
        };
      });
    //profile
    case ActionTypes.BUSINESSPROFIE_SUCCESS:
      return produce(state, (draft) => {
        draft.profile = {
          data: action.payload,
          isUpdading: false,
          isLoading: false,
        };
      });
    case ActionTypes.BUSINESSPROFIE_UPDATE:
      return produce(state, (draft) => {
        draft.profile = { ...draft.profile, isUpdading: true };
      });
    case ActionTypes.BUSINESSPROFIE_UPDATE_FAILURE:
      return produce(state, (draft) => {
        draft.profile = { ...draft.profile, isUpdading: false };
      });
    case ActionTypes.BUSINESSPROFIE_UPDATE_SUCCESS:
      const data: IBusinessStore["profile"]["data"] =
        action.payload.extraParams;
      return produce(state, (draft) => {
        draft.profile = {
          ...draft.profile,
          data,
          isUpdading: false,
        };
        draft.partners = draft.partners.map((item) => {
          if (data.partner === item.partner) {
            item.name = data.name || "";
            item.logo = data.logo || "";
            item.enableForBooking = data.enableForBooking || false;
          }
          return item;
        });
      });

    case ActionTypes.PARTNERINDUSTRY_SUCCESS:
      return produce(state, (draft) => {
        draft.industries = {
          ...draft.industries,
          data: action.payload.result,
        };
        draft.isLoading = false;
      });
    case ActionTypes.PARTNERSERVICE_SUCCESS:
      return produce(state, (draft) => {
        const services =
          action.payload.offset === 0
            ? action.payload.result
            : [...draft.services.data, ...action.payload.result];
        draft.services = {
          ...draft.services,
          data: services,
          offset: services.length,
          total: action.payload.total,
        };
        draft.isLoading = false;
      });

    case ActionTypes.POPULARITEMS_SUCCESS:
      dataByType =
        action.payload.extraParams.type === "service"
          ? state.services.data
          : state.industries.data;
      return produce(state, (draft) => {
        draft.popular = filterBusinessIndustries(
          action.payload.result,
          dataByType,
          action.payload.extraParams.type
        );
        draft.isLoading = false;
      });

    case ActionTypes.BUSINESSQUERYITEMS_SUCCESS:
      dataByType =
        action.payload.extraParams.type === "service"
          ? state.services.data
          : state.industries.data;
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.itemsByQuery = {
          type: action.payload?.extraParams?.type || "",
          data: filterBusinessIndustries(
            action.payload.result,
            dataByType,
            action.payload.extraParams.type
          ),
        };
      });

    case ActionTypes.GETPARTNEROPENHOURS:
      return produce(state, (draft) => {
        draft.industries = {
          ...draft.industries,
        };
        draft.isUpdating = true;
      });
    case ActionTypes.CREATEPARTNER:
    case ActionTypes.BUSINESSLINK:
      return produce(state, (draft) => {
        draft.profile = {
          ...draft.profile,
          isUpdading: true,
        };
      });

    case ActionTypes.CREATEPARTNER_SUCCESS:
    case ActionTypes.CREATEPARTNER_FAILURE:
    case ActionTypes.BUSINESSLINK_SUCCESS:
    case ActionTypes.BUSINESSLINK_FAILURE:
      return produce(state, (draft) => {
        draft.profile = {
          ...draft.profile,
          isUpdading: false,
        };
      });
    case ActionTypes.PARTNEREMPLOYEELIST_SUCCESS:
      return produce(state, (draft) => {
        const employees =
          action.payload.offset === 0
            ? action.payload.result
            : [...draft.employees.data, ...action.payload.result];
        draft.employees = {
          ...initialState.employees,
          data: employees,
          total: action.payload?.total,
          offset: employees.length,
          limit: action.payload.limit,
        };
        draft.isLoading = false;
      });
    case ActionTypes.PARTNEREMPLOYEEDETAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.employeeDetails = action.payload;
        draft.isLoading = false;
      });
    case ActionTypes.RESET_INDUSTRY_DATA:
      return produce(state, (draft) => {
        draft.industries = {
          ...initialState.industries,
        };
      });
    case ActionTypes.RESET_SERVICE_DATA:
      return produce(state, (draft) => {
        draft.services = {
          ...initialState.services,
        };
      });
    case ActionTypes.GETCUSTOMERS_SUCCESS:
      return produce(state, (draft) => {
        const customers =
          action.payload.offset === 0
            ? action.payload.result
            : [...draft.customers.data, ...action.payload.result];
        draft.customers = {
          ...initialState.customers,
          data: customers,
          total: action.payload?.total,
          offset: customers.length,
          limit: action.payload.limit,
        };
        draft.isLoading = false;
      });
    // GENERIC LOADER IMPLEMENTATION
    case ActionTypes.DELETEPARTNERSERVICE_FAILURE:
    case ActionTypes.DELETEPARTNERSERVICE_SUCCESS:
    case ActionTypes.UPDATEINDUSTRY_SUCCESS:
    case ActionTypes.UPDATEINDUSTRY_FAILURE:
    case ActionTypes.EMPLOYEEINVITE_SUCCESS:
    case ActionTypes.EMPLOYEEINVITE_FAILURE:
    case ActionTypes.PARTNEREMPLOYEEUPDATE_SUCCESS:
    case ActionTypes.PARTNEREMPLOYEEUPDATE_FAILURE:
    case ActionTypes.PARTNERCREATESERVICE_SUCCESS:
    case ActionTypes.PARTNERCREATESERVICE_FAILURE:
    case ActionTypes.GETPARTNEROPENHOURS_SUCCESS:
    case ActionTypes.GETPARTNEROPENHOURS_FAILURE:
      return produce(state, (draft) => {
        draft.isUpdating = false;
      });

    case ActionTypes.PARTNERCREATESERVICE:
    case ActionTypes.UPDATEINDUSTRY:
    case ActionTypes.EMPLOYEEINVITE:
    case ActionTypes.PARTNEREMPLOYEEUPDATE:
    case ActionTypes.DELETEPARTNERSERVICE:
      return produce(state, (draft) => {
        draft.isUpdating = true;
      });

    case ActionTypes.GETCUSTOMERS:
    case ActionTypes.PARTNEREMPLOYEELIST:
    case ActionTypes.PARTNEREMPLOYEEDETAIL:
    case ActionTypes.PARTNERSERVICE:
    case ActionTypes.PARTNERINDUSTRY:
    case ActionTypes.NETWORKINDUSTRY:
    case ActionTypes.BUSINESSQUERYITEMS:
    case ActionTypes.POPULARITEMS:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });

    case ActionTypes.GETCUSTOMERS_FAILURE:
    case ActionTypes.PARTNEREMPLOYEELIST_FAILURE:
    case ActionTypes.PARTNEREMPLOYEEDETAIL_FAILURE:
    case ActionTypes.PARTNERINDUSTRY_FAILURE:
      return produce(state, (draft) => {
        draft.isLoading = false;
      });
    default:
      return state;
  }
};
function filterBusinessIndustries(data, selectedData, type) {
  const key = type === "service" ? "name" : "id";
  const ids = selectedData.map((item) => item[key]);
  return data.filter((item) => !ids.includes(item[key]));
}
export default business;
