import React from "react";
import { TouchableOpacity, View } from "react-native";
import { boxShadow, StyleTheme } from "../Styles/CommonStyles";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
// ===================================================================
// INTERFACE
// ===================================================================
interface WithOptions {
  options: React.ReactNode;
  showOptions: boolean;
  action: (val: boolean) => void;
  bottomSpace?: number;
}
type IProps = WithOptions;
// ===================================================================
// COMPONENT
// ===================================================================
export default function FabUI(props: IProps) {
  const bottomSpace = props.bottomSpace || 10;
  const rightSpace = 30;
  return (
    <>
      <TouchableOpacity
        style={{
          backgroundColor: StyleTheme.APP_COLOR,
          height: 40,
          width: 40,
          position: "absolute",
          bottom: bottomSpace,
          right: rightSpace,
          borderRadius: 100,
          ...boxShadow(),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
        }}
        activeOpacity={0.7}
        onPress={() => {
          props.action(!props.showOptions);
        }}
      >
        <MaterialCommunityIcons
          name={props.showOptions ? "close" : "plus"}
          color="white"
          size={props.showOptions ? 24 : 30}
          style={{}}
        />
      </TouchableOpacity>
      {props.showOptions && (
        <TouchableOpacity
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: 9,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            backgroundColor: "rgba(0,0,0,0.2)",
            paddingRight: rightSpace + 10,
            paddingBottom: bottomSpace + 60,
          }}
          onPress={() => props.action(false)}
        >
          <View>{props.options}</View>
        </TouchableOpacity>
      )}
    </>
  );
}
