import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Card } from "react-native-paper";
import ImageUI from "../../Components/ImageUI";
import { StyleTheme } from "../../Styles/CommonStyles";
import TextUI from "../../Components/TextUI";

function BookingUserCard(props) {
  const { partnerInfo } = props.data;
  const { photo = "", name, address } = partnerInfo || {};

  return (
    <Card style={{ elevation: 4, borderRadius: 2 }}>
      <Text style={{
        fontWeight: "600",
        fontSize: 18,
        marginLeft: 18,
        marginTop: 10
      }}>Business Details</Text>
      <View style={styles.mainWrapper}>
        <ImageUI
          source={
            photo ||
            require("@reachpartner/common/Assets/Images/user-account.png")
          }
          style={styles.image}
          resizeMode="contain"
        />
        <View style={styles.main}>
          <Text style={styles.name}>{name}</Text>
          <View style={styles.content}>
            {/* <TextUI>
              <Text style={styles.customer}>Phone: </Text><Text style={{ fontWeight: "400", fontSize: 14 }}>{phone || "NA"}</Text>
            </TextUI> */}
            <TextUI>
              <Text style={styles.customer}>Address: </Text><Text style={{ fontWeight: "400", fontSize: 14 }}>{address || "NA"}</Text>
            </TextUI>
          </View>
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    padding: 20,
    flexDirection: "row",
  },
  image: {
    width: 70,
    height: "100%",
    minHeight: 100,
    borderRadius: 4,
  },
  main: {
    flex: 1,
    paddingHorizontal: 10,
  },
  visitbtn: {
    padding: 0,
    borderColor: "#0FA3B1",
    backgroundColor: "white",
    color: "#0FA3B1",
    borderWidth: 2,
    borderRadius: 6,
    textAlign: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  viewProfileButton: {
    padding: 0,
    width: 100,
    borderColor: StyleTheme.APP_COLOR,
    backgroundColor: "white",
    color: StyleTheme.APP_COLOR,
    borderWidth: 2,
    borderRadius: 6,
    marginTop: 10,
    fontSize: 14,
    textAlign: "center",
    paddingVertical: 2,
    paddingHorizontal: 8,
  },
  name: {
    fontWeight: "600",
    fontSize: 18,
  },
  customer: {
    fontWeight: "600",
    fontSize: 14,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: 17,
  },
});
export default BookingUserCard;
