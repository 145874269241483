// ===================================================================
// BUSINESS INDUSTRIES COMPONENT
// ===================================================================
import React, { useCallback, useEffect } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import TextUI from "../../../Components/TextUI";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Appbar from "../../../Components/Appbar";
import {
  getPartnerIndustryList,
  getPartnerServiceList,
  resetIndustryData,
  resetServiceData,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import ImageUI from "../../../Components/ImageUI";
import FabUI from "../../../Components/FabUI";
import { CommonStyles, StyleTheme } from "../../../Styles/CommonStyles";
import RouteNames from "../../../Utils/Constants/RouteNames";
import ButtonUI from "../../../Components/ButtonUI";
import LoaderUI from "../../../Components/Loader";
import Page from "../../../Components/Page";
import InfiniteList from "../../../Components/InfiniteList";
import { Searchbar } from "react-native-paper";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getPartnerIndustryList: (id: string) => void;
  getPartnerServiceList: (id: string, offset: number, limit: number, search: string) => void;
  resetIndustryData: () => void;
  resetServiceData: () => void;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================
function BusinessIndustries(props: IProps) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  useFocusEffect(
    useCallback(() => {
      props.getPartnerIndustryList(props.route.params.id);
      getServices(true);
    }, [])
  );

  useEffect(() => {
    return () => props.resetIndustryData();
  }, []);

  async function handleEdit(serviceId) {
    props.navigation.navigate(RouteNames.BusinessServicesOperations.name, {
      id: props.route.params.id,
      serviceParam: serviceId,
    });
  }

  function getServices(initialOffset?: boolean, query?: string = "") {
    props.getPartnerServiceList(
      props.route.params.id,
      initialOffset ? 0 : props.businessStore.services.offset,
      props.businessStore.services.limit,
      query
    );
  }

  function renderServices() {
    if (
      props.businessStore.isLoading &&
      props.businessStore.services.data.length === 0
    ) {
      return <LoaderUI mode="long" />;
    }
    if (props.businessStore.services.data.length === 0) {
      return (
        <View style={{ alignSelf: "center", margin: "auto" }}>
          <TextUI style={{ textAlign: "center" }} weight="500">
            No Service found!
          </TextUI>
          <View style={{ height: 10 }} />
          <TextUI style={{ textAlign: "center" }} weight="500">
            Tap on + to create one
          </TextUI>
        </View>
      );
    }

    return (
      <InfiniteList
        data={props.businessStore.services.data}
        renderItem={(item: IBusinessStore["services"]["data"][0]) => {
          const enableForBooking = item.enableForBooking;
          return (
            <TouchableOpacity
              onPress={() => handleEdit(item.id)}
              style={{
                paddingVertical: 12,
                flex: 1,
                opacity: enableForBooking ? 1 : 0.8,
              }}
            >
              <View style={[CommonStyles.rowSpaceBetween]}>
                {enableForBooking && (
                  <>
                    <View
                      style={[
                        CommonStyles.rowSpaceBetween,
                        {
                          position: "absolute",
                          top: 0,
                          left: 25,
                          width: 17,
                          height: 17,
                          zIndex: 10,
                          borderRadius: 30,
                          backgroundColor: StyleTheme.APP_COLOR,
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <MaterialCommunityIcons
                        name={"check"}
                        style={{
                          color: "white",
                          fontSize: 12,
                        }}
                      />
                    </View>
                  </>
                )}
                <View style={styles.listItem}>
                  {item.image ? (
                    <ImageUI
                      defaultSource={require("@reachpartner/common/Assets/Images/no-image.png")}
                      source={item.image}
                      style={{ height: 40, width: 40, borderRadius: 4 }}
                    />
                  ) : (
                    <View
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 4,
                        alignItems: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name={"image-outline"}
                        style={{
                          color: "rgba(36, 39, 44, 0.5)",
                          fontSize: 35,
                        }}
                      />
                    </View>
                  )}
                  <View style={{ width: 10 }} />
                  <View style={{ flex: 1 }}>
                    <TextUI weight="500">{item.name}</TextUI>
                  </View>
                </View>
                <View style={{ width: 30 }}>
                  <MaterialCommunityIcons
                    name={"chevron-right"}
                    style={{
                      color: "rgba(36, 39, 44, 0.5)",
                      fontSize: 30,
                    }}
                  />
                </View>
              </View>
            </TouchableOpacity>
          );
        }}
        options={{
          total: props.businessStore.services.total,
          offset: props.businessStore.services.offset,
          isLoading: props.businessStore.isLoading,
        }}
        getData={(options) => getServices()}
      />
    );
  }

  return (
    <Page>
      <Appbar
        title={"Services"}
        actions={
          <MaterialCommunityIcons
            name="delete-outline"
            size={20}
            color="red"
            onPress={() =>
              props.navigation.navigate(
                RouteNames.BusinessServicesOperations.name,
                {
                  serviceParam: "delete",
                  id: props.route.params.id,
                }
              )
            }
          />
        }
      />
      <Searchbar
        placeholder={`Enter Service`}
        onChangeText={(currentValue) => {
          setSearchQuery(currentValue);
          if (currentValue === "") {
            props.resetServiceData();
            getServices(true, "");
          }
        }}
        value={searchQuery}
        underlineColorAndroid={"transparent"}
        theme={{
          colors: {
            primary: "white",
          },
        }}
        onIconPress={() => {
          props.resetServiceData();
          getServices(true, searchQuery);
        }}

        style={{ borderColor: "transparent" }}
      />
      <View style={styles.body}>{renderServices()}</View>
      <FabUI
        showOptions={showOptions}
        action={(val) => setShowOptions(val)}
        bottomSpace={30}
        options={
          <>
            <TouchableOpacity
              style={styles.fabActionItem}
              onPress={() => {
                setShowOptions(false);
                props.navigation.navigate(
                  RouteNames.BusinessServicesOperations.name,
                  {
                    serviceParam: "network",
                    id: props.route.params.id,
                  }
                );
              }}
            >
              <TextUI color="white">Add Network Services</TextUI>
              <View style={{ width: 10 }} />
              <View
                style={{
                  backgroundColor: StyleTheme.APP_COLOR,
                  borderRadius: 100,
                  padding: 2,
                }}
              >
                <MaterialCommunityIcons
                  name={"plus"}
                  color="white"
                  size={20}
                  style={{}}
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.fabActionItem}
              onPress={() => {
                setShowOptions(false);
                props.navigation.navigate(
                  RouteNames.BusinessServicesOperations.name,
                  {
                    serviceParam: "add",
                    id: props.route.params.id,
                  }
                );
              }}
            >
              <TextUI color="white">Add Custom Services</TextUI>
              <View style={{ width: 10 }} />
              <View
                style={{
                  backgroundColor: StyleTheme.APP_COLOR,
                  borderRadius: 100,
                  padding: 2,
                }}
              >
                <MaterialCommunityIcons
                  name={"plus"}
                  color="white"
                  size={20}
                  style={{}}
                />
              </View>
            </TouchableOpacity>
          </>
        }
      />
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    padding: 10,
    flex: 1,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  fabActionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    backgroundColor: "rgba(0,0,0,0.7)",
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
});
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getPartnerIndustryList: (id: string) =>
      dispatch(getPartnerIndustryList(id)),
    getPartnerServiceList: (id: string, offset, limit, search) =>
      dispatch(getPartnerServiceList(id, offset, limit, search)),
    resetIndustryData: () => dispatch(resetIndustryData()),
    resetServiceData: () => dispatch(resetServiceData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessIndustries);
