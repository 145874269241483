import React from "react";
import { View, TouchableOpacity } from "react-native";
import TextUI from "../../Components/TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { CommonStyles } from "../../Styles/CommonStyles";
import ImageUI from "../../Components/ImageUI";

interface IProps {
  average: number;
  noOfReviews: number;
}
export default function ReviewOverview(props: IProps) {
  const { average, noOfReviews } = props;
  return (
    <View
      style={{
        backgroundColor: "white",
        paddingHorizontal: 15,
        paddingVertical: 25,
      }}
    >
      <View style={[CommonStyles.rowSpaceBetween, { marginBottom: 15 }]}>
        <TextUI weight="700" size={18}>
          Rating and Review
        </TextUI>
        <TouchableOpacity
          style={{
            display: "none",
            flexDirection: "row",
            alignItems: "center",
          }}
          onPress={() => {}}
        >
          <MaterialCommunityIcons name={"share-variant"} />
          <TextUI style={{ marginLeft: 5 }}>Share</TextUI>
        </TouchableOpacity>
      </View>
      <View style={[CommonStyles.rowSpaceBetween]}>
        <ImageUI
          source={require("@reachpartner/common/Assets/Images/review.png")}
          style={{
            height: 65,
            width: 220,
            // width: "100%",
          }}
          resizeMode="contain"
        />
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <MaterialCommunityIcons name={"star"} size={28} color="#FFA236" />
            <TextUI size={28} weight="700" style={{ marginLeft: 10 }}>
              {average}
            </TextUI>
          </View>
          <TextUI weight="500">
            {noOfReviews} User Review{noOfReviews > 1 && "s"}
          </TextUI>
        </View>
      </View>
    </View>
  );
}
