import React from "react";
import { View } from "react-native";
const FormWrapper = (props: {
  children: React.ReactChild[];
  spacing?: number;
}) => {
  return (
    <>
      {props.children.map((child, idx) => (
        <View key={idx}>
          {child}
          <View style={{ height: 10 }} />
        </View>
      ))}
    </>
  );
};
export default FormWrapper;
