import React from "react";
import { View, ViewStyle, Platform, StyleSheet } from "react-native";
import { getWindowHeight, windowHeight } from "../Utils";
//const windowHeight =
//Dimensions.get("window").height - (StatusBar.currentHeight || 0);

interface ContainerViewProps {
  children: any;
  styles?: ViewStyle;
}
function ContainerView(props: ContainerViewProps) {
  const [height, setHeight] = React.useState(getWindowHeight());

  function onLayout(e) {
    setHeight(getWindowHeight());
  }

  return (
    <View
      style={[ScreenStyles.container, props.styles, { height }]}
      onLayout={onLayout}
    >
      {props.children}
    </View>
  );
}
const ScreenStyles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 500,
    margin: "auto",
    ...Platform.select({
      web: {},
    }),
  },
});
export default ContainerView;
