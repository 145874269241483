const fontConfig: { web?: any; default?: any; android?: any; ios?: any } = {
  default: {
    regular: {
      fontFamily: "roboto",
      fontWeight: "normal",
    },
  },
};
fontConfig.web = fontConfig.default;
fontConfig.android = fontConfig.default;
fontConfig.ios = fontConfig.default;
export default fontConfig;
