import React from "react";
import { View, Text, StyleSheet, Image, ScrollView } from "react-native";
import { HelperText } from "react-native-paper";
import { ILocationReqPayload } from "../../Models/interface";
import { useFocusEffect } from "@react-navigation/native";
import TextInputUI from "../../Components/TextInputUI";
import ButtonUI from "../../Components/ButtonUI";
import { IUserStore } from "../../Models/StoreInterfaces";
import Appbar from "../../Components/Appbar";
import DropDown from "../../Components/Dropdown";
import { TextInput } from "react-native-paper";
import { CountryList } from "../../Utils/Constants/AppLevelConfig";
import LocationSearchInputModel from "../../Components/LocationSearchInputModel";
import ImageUI from "../../Components/ImageUI";
import TextUI from "../../Components/TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { validateEmail } from "../../Utils";
import PhoneNumberInput from "../../Components/PhoneNumberInput";
import { IHomeStore } from "../../Store/Reducers/homeReducer";

interface CreateUserPayload {
  first: String;
  last: String;
  email: String;
  phone: String;
  country: String;
  location: String;
}

interface IProps {
  submitForm: (data: CreateUserPayload) => void;
  store: {
    user: IUserStore;
    home: IHomeStore;
  };
  getLocations: (payload: ILocationReqPayload) => void;
  isSignedInWithApple: boolean;
}

function CreateUserComponent(props: IProps) {
  const [name, setName] = React.useState("");
  const [surName, setSurName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [disableEmail, setDisableEmail] = React.useState(false);
  const [country, setCountry] = React.useState("GBR");
  const [location, setLocation] = React.useState("");
  const [initialValidate, setInitialValidate] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  useFocusEffect(
    React.useCallback(() => {
      setInitialValue();
    }, [])
  );
  const setInitialValue = async () => {
    const email = props.store.user.employeeInfo.email;

    if (email) {
      setEmail(email);
      setDisableEmail(true);
    }
    setSurName(props.store.user.employeeInfo.lastName);
    setName(props.store.user.employeeInfo.firstName);
  };
  const hasInputError = (inputName: string, value: string) => {
    if (!initialValidate) {
      return false;
    }
    return hasError(inputName, value);
  };

  const hasError = (inputName: string, value: string): boolean => {
    if (inputName === "email") {
      return !validateEmail(value);
    } else if (inputName === "phone") {
      return value.length < 10;
    }
    return value === "";
  };

  const validate = () => {
    let errorFlags: boolean[] = [
      hasError("country", country),
      hasError("location", location),
      hasError("phone", phoneNumber),
    ];
    if (!props.isSignedInWithApple) {
      errorFlags = [
        ...errorFlags,
        hasError("name", name),
        hasError("surname", surName),
        hasError("email", email),
      ];
    }
    return errorFlags.every((d) => !d);
  };

  const submitForm = async () => {
    if (!validate()) {
      !initialValidate && setInitialValidate(true);
      return;
    }
    const payload: CreateUserPayload = {
      first: name,
      last: surName,
      email: email,
      phone: phoneNumber,
      country: "GBR",
      location: location,
    };
    props.submitForm(payload);
    return;
  };
  const getLocations = (val) => {
    const payload = {
      locData: val,
      locType: "address",
    };
    props.getLocations(payload);
  };
  return (
    <>
      <Appbar title={"Create Profile"} hideBack={true} />
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.imageWrapper}>
            <ImageUI
              source={require("@reachpartner/common/Assets/Images/user-account.png")}
              style={styles.img}
            />
          </View>
          <Text style={styles.title}>
            {props.isSignedInWithApple
              ? "Additional Information"
              : "Update your details"}
          </Text>
          {!props.isSignedInWithApple && (
            <>
              <TextInputUI
                label="First Name"
                value={name}
                error={hasInputError("name", name)}
                onChangeText={(text) => setName(text)}
                errorHelpText={"Name shouldn't be empty!"}
              />
              <View style={{ height: 15 }} />
              <TextInputUI
                label="Surname"
                value={surName}
                error={hasInputError("surname", surName)}
                onChangeText={(text) => setSurName(text)}
                errorHelpText={"SurName shouldn't be empty!"}
              />
              <View style={{ height: 15 }} />
              <TextInputUI
                label="Email address"
                value={email}
                disabled={disableEmail}
                error={hasInputError("email", email)}
                onChangeText={(text) => setEmail(text)}
                errorHelpText={"Email is invalid!"}
              />
            </>
          )}
          <View style={{ height: 15 }} />
          <PhoneNumberInput
            value={phoneNumber}
            onChange={(val: string) => setPhoneNumber(val)}
            error={hasInputError("phone", phoneNumber)}
          />
          {hasInputError("phone", phoneNumber) && (
            <HelperText type="error">Phone number is invalid!</HelperText>
          )}
          <View style={{ height: 15 }} />
          <DropDown
            label={"Country"}
            value={country}
            setValue={(val) => setCountry(val)}
            list={CountryList}
            inputProps={{
              right: <TextInput.Icon name={"menu-down"} />,
            }}
          />
          <View style={{ height: 15 }} />
          <LocationSearchInputModel
            list={props.store.home.locationSearchItems.data}
            onChangeText={(text) => setLocation(text)}
            getData={getLocations}
            label="Search Location"
            placeholder="Enter Location"
            renderItem={(item) => (
              <>
                <MaterialCommunityIcons
                  name={"map-marker"}
                  style={{ width: 20, opacity: 0.5 }}
                  size={14}
                />
                <View style={{ width: 10 }} />
                <TextUI familyType="type-2" weight="500">
                  {item}
                </TextUI>
              </>
            )}
          >
            <TextInputUI
              label="Location"
              value={location}
              error={hasInputError("location", location)}
              // onChangeText={(text) => setLocation(text)}
              errorHelpText={"Location shouldn't be empty!"}
            />
          </LocationSearchInputModel>
          <View style={{ height: 15 }} />
          <ButtonUI
            style={styles.button}
            onPress={() => {
              submitForm();
            }}
            label={"Create"}
          />
        </View>
      </ScrollView>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: "5%",
    paddingVertical: 15,
    justifyContent: "center",
  },
  textinput: {
    backgroundColor: "white",
    marginVertical: 5,
    height: 44,
  },
  button: {
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
  },
  imageWrapper: {
    borderWidth: 5,
    borderColor: "#F8F8F8",
    borderRadius: 100,
    margin: "auto",
    padding: 10,
    width: 86,
    height: 86,
    marginBottom: 30,
    alignSelf: "center",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontWeight: "500",
    fontSize: 16,
    marginBottom: 15,
  },
});
export default CreateUserComponent;
