import React, { useState, useEffect, useMemo } from "react";
import BottomSheet from "../../Components/BottomSheet";
import { View, StyleSheet, Text } from "react-native";
import moment from "moment";
import TextInputUI from "../../Components/TextInputUI";
import ButtonUI from "../../Components/ButtonUI";

interface IProps {
  type: "" | "change" | "cancel" | "mark-no-show" | "raise-dispute" | "mark-complete" | "update-payment";
  data: any;
  employeeId: string;
  changeType: (type: "" | "change" | "cancel" | "mark-no-show" | "raise-dispute" | "mark-complete" | "update-payment") => void;
  updateBooking: (category: string, command: string, param1: string) => void;
}

export default function ActionPopup(props: IProps) {
  const [reason, setReason] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const { bookingDateTime, bookingStatusType, totalDuration } = props.data || {};

  useEffect(() => {
    if (props.type === "") {
      setShowOptions(false);
    } else {
      setShowOptions(true);
    }
  }, [props.type]);
  const isAppointmentStarted = useMemo(() => {
    if (!bookingDateTime) {
      return false;
    }
    const appointmentTime = moment(bookingDateTime).utcOffset(0).valueOf();
    const currentTime = moment().valueOf();
    return currentTime > appointmentTime;
  }, [bookingDateTime]);

  const showCompleteBtn: boolean =
    (bookingStatusType === 3 || bookingStatusType === 6) &&
    moment().valueOf() > moment(bookingDateTime).add(totalDuration, 'minutes').valueOf()

  function renderChangeUI() {
    return (
      <>
        <Text style={{ ...StyleSheet.flatten(bottomPopupStyles.label), marginBottom: undefined }}>Modify Appointment</Text>
        <View style={bottomPopupStyles.items}>
          {bookingStatusType !== 4 && (
            <Text
              style={bottomPopupStyles.item}
              onPress={() => {
                props.changeType("cancel");
              }}
            >
              Cancel Appointment
            </Text>
          )}
          {isAppointmentStarted && (
            <Text
              style={bottomPopupStyles.item}
              onPress={() => {
                props.changeType("mark-no-show");
              }}
            >
              Report Client Didn't Show up
            </Text>
          )}
          <Text
            style={bottomPopupStyles.item}
            onPress={() => {
              props.changeType("raise-dispute");
            }}
          >
            Raise a Dispute With client
          </Text>
          {showCompleteBtn && <Text
            style={bottomPopupStyles.item}
            onPress={() => {
              props.changeType("mark-complete");
            }}
          >
            Mark Appointment as Complete
          </Text>}
        </View>
      </>
    );
  }

  function renderMarkNoShowUI() {
    return (
      <>
        <Text style={bottomPopupStyles.label}>Report Client Didn't Show up</Text>
        <TextInputUI
          label="Reason"
          value={reason}
          onChangeText={(text) => setReason(text)}
          errorHelpText={"Reason is invalid!"}
        />
        <ButtonUI
          style={{ marginTop: 20 }}
          label="Submit"
          onPress={() => props.updateBooking("noshow", "", reason)}
          disabled={reason === ""}
        />
      </>
    );
  }

  function renderCancelUI() {
    return (
      <>
        <Text style={bottomPopupStyles.label}>Cancel Appointment</Text>
        <TextInputUI
          label="Reason"
          value={reason}
          onChangeText={(text) => setReason(text)}
        />
        <ButtonUI
          style={{ marginTop: 20 }}
          label="Submit"
          onPress={() => props.updateBooking("cancel", "", reason)}
          disabled={reason === ""}
        />
      </>
    );
  }

  function renderCompleteAppointmentUI() {
    return (
      <>
        <Text style={bottomPopupStyles.label}>Complete Appointment</Text>
        <Text style={{ marginTop: 10 }}>Are you sure you want to complete appointment?</Text>
        <View style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: 10 }}>
          <ButtonUI
            style={{ flex: 1, marginRight: 10 }}
            label="No"
            mode="outlined"
            onPress={() => props.changeType("")}
          />
          <ButtonUI
            style={{ flex: 1 }}
            label="Yes"
            mode="contained"
            onPress={() => props.updateBooking("complete", "booking", props.employeeId)}
          />
        </View>
      </>
    );
  }

  function renderUpdatePaymentAppointmentUI() {
    return (
      <>
        <Text style={bottomPopupStyles.label}>Update Appointment</Text>
        <TextInputUI
          label="Reason"
          value={reason}
          onChangeText={(text) => setReason(text)}
        />
        <ButtonUI
          style={{ marginTop: 20 }}
          label="Submit"
          onPress={() => props.updateBooking("payment", "", reason)}
          disabled={reason === ""}
        />
      </>
    );
  }

  function renderDisputeUI() {
    return (
      <>
        <Text style={bottomPopupStyles.label}>Raise a dispute with client</Text>
        <TextInputUI
          label="Reason"
          value={reason}
          onChangeText={(text) => setReason(text)}
        />
        <ButtonUI
          style={{ marginTop: 20 }}
          label="Submit"
          onPress={() => props.updateBooking("conflict", "", reason)}
          disabled={reason === ""}
        />
      </>
    );
  }

  function renderUI() {
    if (props.type === "change") {
      return renderChangeUI();
    } else if (props.type === "cancel") {
      return renderCancelUI();
    } else if (props.type === "mark-no-show") {
      return renderMarkNoShowUI();
    } else if (props.type === "raise-dispute") {
      return renderDisputeUI();
    } else if (props.type === "mark-complete") {
      return renderCompleteAppointmentUI();
    } else if (props.type === "update-payment") {
      return renderUpdatePaymentAppointmentUI();
    } else {
      return <View />;
    }
  }

  function calculateSheetHeight() {
    return [bookingStatusType !== 4, isAppointmentStarted, true, showCompleteBtn].reduce(
      (accumulator, currentValue) => accumulator + (currentValue ? 20 : 0),
      210,
    );
  }

  return (
    <>
      <BottomSheet
        isOpen={showOptions}
        onDismiss={() => {
          props.changeType("");
        }}
        height={calculateSheetHeight()}
      >
        {renderUI()}
      </BottomSheet>
    </>
  );
}

const bottomPopupStyles = StyleSheet.create({
  label: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: 10
  },
  items: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginTop: 20,
    // gap: 10
  },
  item: {
    paddingVertical: 13,
    borderColor: "#007FFF",
    color: "#007fff",
    textAlign: "center",
    borderWidth: 3,
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 8,
    borderRadius: 5,
    display: "flex",
    width: "100%",
    marginBottom: 10,
    justifyContent: "center"
  },
});
