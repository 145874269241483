// ===================================================================
// CUSTOM NETWORK COMPONENT
// ===================================================================
import React from "react";
import { View } from "react-native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import {
  getPartnerGroupIndustryLevel,
  getServiceDetails,
  partnerServiceCreate,
  resetIndustryData,
  deletePartnerService,
  getPopularItems,
  updateNetworkIndustries,
  getItemsByQuery,
  getPartnerServiceList,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import AddorUpdateService from "./AddorUpdateService";
import NetworkService from "./NetworkService";
import DeleteService from "./DeleteService";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface INetworkLinkUnLink {
  op: "add" | "remove" | "clone";
  path: "service";
  id: string;
}
interface IMapDispatch {
  getPartnerServiceList: (id: string, offset: number, limit: number) => void;
  resetIndustryData: () => void;
  getPartnerGroupIndustryLevel: (
    partnerId: string,
    industryId: string,
    callback: (data: any) => void
  ) => void;
  createPartnerService: (
    partnerId: string,
    isUpdate: boolean,
    payload: any,
    callback: (data: any) => void
  ) => void;
  getServiceDetails: (partnerId: string, callback: (data: any) => void) => void;
  getPopularServices: (type, industryIds: string[]) => void;
  getItemsByQuery: (
    val: string,
    item: "service",
    industryIds: string[]
  ) => void;
  updateNetworkIndustries: (
    partnerId: string,
    payload: INetworkLinkUnLink,
    callback: (response: any) => void
  ) => void;
  deletePartnerService: (id: string, callback: (response: any) => void) => void;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================

function ServiceOperations(props: IProps) {
  const pageType = React.useMemo(() => {
    const serviceParam = props.route.params.serviceParam;
    if (serviceParam === "add" || serviceParam.length === 24) {
      return "edit";
    } else if (serviceParam === "delete") {
      return "delete";
    } else if (serviceParam === "network") {
      return "network";
    }
  }, [props.route.params.serviceParam]);

  if (pageType === "edit") {
    return (
      <AddorUpdateService
        partnerId={props.route.params.id}
        serviceParam={props.route.params.serviceParam}
        getServiceDetails={props.getServiceDetails}
        businessStore={props.businessStore}
        getPartnerGroupIndustryLevel={props.getPartnerGroupIndustryLevel}
        createPartnerService={props.createPartnerService}
        navigationPop={() => props.navigation.pop()}
      />
    );
  } else if (pageType === "network") {
    return (
      <NetworkService
        getPopularServices={props.getPopularServices}
        getItemsByQuery={props.getItemsByQuery}
        businessStore={props.businessStore}
        navigationPop={() => props.navigation.pop()}
        addService={props.updateNetworkIndustries}
        partnerId={props.route.params.id}
      />
    );
  } else if (pageType === "delete") {
    return (
      <DeleteService
        businessStore={props.businessStore}
        navigationPop={() => props.navigation.pop()}
        deleteService={props.deletePartnerService}
        partnerId={props.route.params.id}
        getPartnerServiceList={props.getPartnerServiceList}
      />
    );
  }
  return <View />;
}
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    resetIndustryData: () => dispatch(resetIndustryData()),
    getPartnerGroupIndustryLevel: (partnerId, industryId, callback) =>
      dispatch(getPartnerGroupIndustryLevel(partnerId, industryId, callback)),
    createPartnerService: (partnerId, isUpdate, payload, callback) =>
      dispatch(partnerServiceCreate(partnerId, isUpdate, payload, callback)),
    getServiceDetails: (partnerId, callback) =>
      dispatch(getServiceDetails(partnerId, callback)),
    getPopularServices: (type, industryIds) =>
      dispatch(getPopularItems(type, industryIds)),
    getItemsByQuery: (val: string, item, industryIds) =>
      dispatch(getItemsByQuery(val, item, industryIds)),
    updateNetworkIndustries: (
      id: string,
      payload: INetworkLinkUnLink,
      callback
    ) => dispatch(updateNetworkIndustries(id, payload, callback)),
    deletePartnerService: (id: string, callback) =>
      dispatch(deletePartnerService(id, callback)),
    getPartnerServiceList: (id: string, offset, limit) =>
      dispatch(getPartnerServiceList(id, offset, limit)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceOperations);
