import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { boxShadow, StyleTheme } from "../../Styles/CommonStyles";
import TextUI from "../../Components/TextUI";
interface IProps {
  data: Array<any>;
  selectedChip(item): void;
  selected: string;
}
export default function SelectTimeDetails(props: IProps) {
  const { data } = props;
  return (
    <View style={styles.main}>
      {data.length !== 0 ? (
        data.map((item, idx) => (
          <View style={{ marginRight: 10, marginBottom: 7 }} key={idx}>
            <TouchableOpacity
              onPress={() => props.selectedChip(item.value)}
              style={[
                item.value === props.selected ? styles.activeChip : {},
                { ...boxShadow() },
                styles.chip,
              ]}
            >
              <TextUI
                style={{
                  color:
                    item.value === props.selected
                      ? "white"
                      : StyleTheme.DEFAULT_FONT_COLOR,
                  fontWeight: "500",
                }}
              >
                {item.label}
              </TextUI>
            </TouchableOpacity>
          </View>
        ))
      ) : (
        <View
          style={{
            minHeight: 100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextUI size={14} weight="500">
            Slots not available. Try some other date.
          </TextUI>
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  main: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  chip: {
    borderRadius: 5,
    paddingVertical: 13,
    width: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeChip: { backgroundColor: StyleTheme.APP_COLOR, color: "white" },
});
