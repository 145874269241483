import React, { useMemo, useEffect, useState } from "react";
import { View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import Appbar from "../Components/Appbar";
import { IUserStore } from "../Models";
import { connect } from "react-redux";
import { useIsFocused } from "@react-navigation/native";
import { TextInput } from "react-native-paper";
import TextInputUI from "../Components/TextInputUI";
import TextUI from "../Components/TextUI";
import DropDown from "../Components/Dropdown";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import ButtonUI from "../Components/ButtonUI";
import LanguageModal from "./Components/LanguageModal";
import EnumMapping from "../Utils/Constants/EnumMapping";
import { CountryList } from "../Utils/Constants/AppLevelConfig";
import LocationSearchInputModel from "../Components/LocationSearchInputModel";
import DatepickerUI from "../Components/DatepickerUI";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";
import moment from "moment";
import { ILocationReqPayload, NavigationProps } from "../Models/interface";
import { isValidPostCode, prepareFileUploadPayload, validateEmail } from "../Utils";
import RouteNames from "../Utils/Constants/RouteNames";
import LanguageList from "./Components/LanguageList";
import LoaderUI from "../Components/Loader";
import PhoneNumberInput from "../Components/PhoneNumberInput";
import { IHomeStore } from "../Store/Reducers/homeReducer";
import BottomActionBar from "../Components/BottomActionBar";
import ImagePickerAvatar from "../Components/ImagePickerAvatar";
import { genderList } from "../Utils/Constants/GenericConstants";
import Page from "../Components/Page";

interface IProps extends NavigationProps {
  profileUpdate: (
    linkedId: string,
    data: any,
    callback: (response: any) => void
  ) => void;
  getLanguages: () => void;
  getLocations: (payload: ILocationReqPayload) => void;
  user: IUserStore;
  home: IHomeStore;
  profileImageUpdate: (data: any, callback) => void;
  locationReset: () => void;
}

function ProfileEdit(props: IProps) {
  const isFocused = useIsFocused();
  const [modalVisible, setModalVisible] = useState(false);
  const [tempImage, setTempImage] = useState<
    Array<{ image: string; display: string }>
  >([]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = React.useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    aboutMe: "",
    display: "",
    phone: "",
    country: "GBR",
    location: "",
    gender: 1,
    picture: props.user.tempUrl ? props.user.tempUrl : "",
    postal: "",
    dob: "",
    language: [],
  });

  const {
    firstName,
    lastName,
    country,
    phone,
    email,
    location,
    aboutMe,
    picture,
    postal,
    display,
    gender,
    dob,
    language,
  } = props.user.employeeInfo;

  useEffect(() => {
    if (isFocused) {
      props.home.languages.length === 0 && props.getLanguages();
      setFormData({
        firstName,
        display,
        lastName,
        country,
        phone,
        email,
        location,
        aboutMe,
        picture,
        gender,
        postal,
        dob,
        language,
      });
    }
  }, [isFocused]);

  const [initialValidate, setInitialValidate] = React.useState(false);

  const appBarTitle = useMemo(
    () => firstName + " " + lastName,
    [firstName, lastName]
  );

  const hasInputError = (inputName: string, value: string) => {
    if (!initialValidate) {
      return false;
    }
    return hasError(inputName, value);
  };

  const hasError = (inputName: string, value: string) => {
    if (inputName === "email") {
      return !validateEmail(value);
    } else if (inputName === "phone") {
      return value.length < 10;
    } else if (inputName === "postal") {
      return !isValidPostCode(value);
    }
    return value === "";
  };

  function handleChange(type: string, value: string) {
    setIsFormDirty(true);
    setFormData((d: any) => ({
      ...d,
      [type]: value,
    }));
  }

  const validate = () => {
    const errorFlags = [
      hasError("firstName", formData.firstName),
      hasError("lastName", formData.lastName),
      hasError("email", formData.email),
      hasError("country", formData.country),
      hasError("location", formData.location),
      hasError("phone", formData.phone),
      hasError("postal", formData.postal),
      !isValidPostCode(formData.postal)
    ];
    return errorFlags.every((d) => !d);
  };

  async function updateProfile() {
    if (!validate()) {
      !initialValidate && setInitialValidate(true);
      return;
    }
    if (!tempImage.length) {
      submit();
      return;
    }
    const result = await prepareFileUploadPayload(tempImage[0].image);
    props.profileImageUpdate(result, (res) => {
      if (!res.fail) {
        handleChange("picture", res.url);
        setTempImage([]);
        submit(res.url);
      }
    });
  }

  async function submit(fileurl?) {
    const { firstName, lastName, phone, ...restall } = formData;
    const uploadData = {
      ...restall,
      first: firstName,
      last: lastName,
      phone: phone,
    };
    if (fileurl) {
      uploadData.picture = fileurl;
    }
    setLoader(true);
    const linkedId = await LocalStorage.get("linkedId");
    props.profileUpdate(linkedId, uploadData, (data) => {
      if (data.fail) {
        return;
      }
      setLoader(false);
      props.navigation.goBack();
    });
    formData.language = props.user.employeeInfo.language;
  }

  function deleteLang(idx: number) {
    setIsFormDirty(true);
    let lang = [...formData.language];
    lang.splice(idx, 1);
    setFormData({ ...formData, language: lang });
  }

  const getLocations = (val: string) => {
    const payload = {
      locData: val,
      locType: "address",
    };
    props.getLocations(payload);
  };

  const getPoscode = (val: string) => {
    let payload = {
      locData: val,
      locType: "postcode",
    };
    props.getLocations(payload);
  };

  const handleUpload = async (images: any[]) => {
    setIsFormDirty(true);
    setTempImage(images);
    if (!images.length) {
      return;
    }
  };

  const handleAddLanguage = (data) => {
    let index = formData.language.findIndex(({ code }) => code === data.code);
    let languages = formData.language;
    if (index !== -1) {
      languages = languages.map((item) => {
        if (item.code === data.code) {
          return data;
        }
        return item;
      });
    } else {
      languages = [...languages, data];
    }
    setFormData({ ...formData, language: languages });
    setIsFormDirty(true);
  };

  const setMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today // Format as 'YYYY-MM-DD'
  };

  return (
    <Page>
      <Appbar title={appBarTitle} />
      <View style={styles.body}>
        <ScrollView>
          <ImagePickerAvatar
            action={handleUpload}
            source={
              tempImage.length > 0 ? tempImage[0].display : formData.picture
            }
            defaultSource={require("@reachpartner/common/Assets/Images/user-account.png")}
          />
          <View style={{ height: 15 }} />
          <TextInputUI
            label="First Name"
            value={formData.firstName}
            error={hasInputError("firstName", formData.firstName)}
            onChangeText={(text) => handleChange("firstName", text)}
            errorHelpText={"Please fill first name"}
          />
          <View style={{ height: 15 }} />
          <TextInputUI
            label="Surname"
            value={formData.lastName}
            error={hasInputError("lastName", formData.lastName)}
            onChangeText={(text) => handleChange("lastName", text)}
            errorHelpText={"Please fill surname"}
          />
          <View style={{ height: 15 }} />
          <TextInputUI
            label="Profile Title"
            value={formData.display}
            // error={hasInputError("profileTitle", formData.aboutMe)}
            onChangeText={(text) => handleChange("display", text)}
          // errorHelpText={"Profile title shouldn't be empty!"}
          />
          <View style={{ height: 15 }} />
          <TextInputUI
            label="Email address"
            value={formData.email}
            disabled={true}
            error={hasInputError("email", formData.email)}
            onChangeText={(text) => handleChange("email", text)}
            errorHelpText={"Email shouldn't be empty!"}
          />
          <View style={{ height: 15 }} />
          <PhoneNumberInput
            value={formData.phone}
            onChange={(text) => handleChange("phone", text)}
          />
          <View style={{ height: 15 }} />
          <DropDown
            label={"Country"}
            value={formData.country}
            setValue={(val) => handleChange("country", val)}
            list={CountryList}
            inputProps={{
              right: <TextInput.Icon name={"menu-down"} />,
            }}
          />
          <View style={{ height: 15 }} />
          <LocationSearchInputModel
            list={props.home.locationSearchItems.data}
            loading={props.home.locationSearchItems.isLoading}
            noDataMessage={props.home.locationSearchItems.errorMessage}
            onChangeText={(text) => handleChange("location", text)}
            getData={getLocations}
            label="Search Location"
            placeholder={`Enter location ex: "Sutton"`}
            onDismiss={() => {
              props.locationReset();
            }}
            renderItem={(item) => (
              <>
                <MaterialCommunityIcons
                  name={"map-marker"}
                  style={{ width: 20, opacity: 0.5 }}
                  size={14}
                />
                <View style={{ width: 10 }} />
                <TextUI familyType="type-2" weight="500">
                  {item}
                </TextUI>
              </>
            )}>
            <TextInputUI
              label="Location"
              value={formData.location}
              error={hasInputError("location", formData.location)}
              errorHelpText={"Location shouldn't be empty!"}
            />
          </LocationSearchInputModel>
          <View style={{ height: 15 }} />
          <DropDown
            label={"Gender"}
            value={formData.gender}
            setValue={(val) => handleChange("gender", val)}
            list={genderList}
            inputProps={{
              right: <TextInput.Icon name={"menu-down"} />,
            }}
          />
          <View style={{ height: 15 }} />
          <TextInputUI
            label="Home Postcode"
            value={formData.postal}
            error={hasInputError("postal", formData.postal)}
            errorHelpText={"Please enter valid postal code"}
            onChangeText={(val) => handleChange("postal", val?.toUpperCase())}
          />
          <View style={{ height: 15 }} />
          <DatepickerUI
            range={{ endDate: setMaxDate() }}
            onChangeText={(date) => handleChange("dob", date)}
            value={formData.dob}>
            <TextInputUI
              label="Date of Birth"
              value={
                formData.dob ? moment(formData.dob).format("DD-MM-YYYY") : ""
              }
              placeholder="DD-MM-YYYY"
              onChangeText={(text) => { }}
              error={hasInputError("dob", formData.dob)}
              errorHelpText={"date of birth shouldn't be empty!"}
            />
          </DatepickerUI>
          <View style={{ height: 15 }} />
          <View style={styles.addLangHeader}>
            <TextUI>Language</TextUI>
            <TouchableOpacity
              onPress={() => setModalVisible(true)}
              style={{ flexDirection: "row", alignItems: "center" }}>
              <MaterialCommunityIcons name={"plus"} size={18} />
              <TextUI>Add Language</TextUI>
            </TouchableOpacity>
          </View>
          <LanguageList
            languages={formData.language}
            deleteLang={deleteLang}
            allLanguages={props.home.languages}
          />
        </ScrollView>
      </View>
      <BottomActionBar>
        <ButtonUI
          style={{ backgroundColor: "white", flex: 1 }}
          labelStyle={{ color: "black" }}
          onPress={() => {
            props.navigation.navigate(RouteNames.Profile.name);
          }}
          label="Cancel"
        />
        <View style={{ width: 30 }} />
        <ButtonUI
          style={{ flex: 1 }}
          disabled={!isFormDirty && !props.user.tempUrl ? true : false}
          onPress={() => updateProfile()}
          label="Update"
        />
      </BottomActionBar>

      <LanguageModal
        modalVisible={modalVisible}
        setModalVisible={(flag) => setModalVisible(flag)}
        languages={props.home.languages}
        submit={handleAddLanguage}
      />
      {loader && <LoaderUI mode="long" />}
    </Page>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    flex: 1,
  },
  profileImage: {
    height: 84,
    width: 83,
    borderRadius: 12,
  },
  acceptBtn: {
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 19,
    flex: 1,
    backgroundColor: "#007FFF",
  },
  cancelBtn: {
    backgroundColor: "white",
    borderColor: "#D8D9DC",
    color: "black",
    borderWidth: 1,
    flex: 1,
  },
  addLangText: {},
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },

  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  actBtnWrapper: {
    display: "flex",
    flexDirection: "row",
    // padding:10
  },
  addLangHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
});

const mapStateToProps = (store: any) => {
  return {
    user: store.user,
    home: store.home,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLanguages: () => dispatch(Actions.getLanguages()),
    profileUpdate: (linkedId: string, payload: any, callback: any) =>
      dispatch(Actions.profileUpdate(linkedId, payload, callback)),
    getLocations: (val: any) => dispatch(Actions.getLocations(val)),
    profileImageUpdate: (data: any, callback) =>
      dispatch(Actions.profileImageUpdate(data, callback)),
    locationReset: () => dispatch(Actions.locationReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
