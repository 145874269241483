import {
  BOOKING_INFO,
  RESET_BOOKING_INFO,
  BOOKINGS,
  BOOKING_UPDATE,
  PARTNERPROFILE,
} from "./types";
import { IAction } from "../Models/interface";

const bookingUpdate = (payload, bookingid, callback): IAction => {
  return {
    type: BOOKING_UPDATE,
    isApi: true,
    payload: {
      url: `/booking/${bookingid}`,
      data: payload,
      method: "PATCH",
      callback,
    },
  };
};

const getBookingInfo = (bookingId) => {
  return {
    type: BOOKING_INFO,
    isApi: true,
    payload: {
      url: `booking/${bookingId}`,
      method: "GET",
      data: {},
    },
  };
};

const resetBookingInfo = (): IAction => {
  return {
    type: RESET_BOOKING_INFO,
  };
};

const getBookings = (payload): IAction => {
  const { linkedId, limit, from, to, offset, key, partner = "" } = payload;
  const url = `booking/account/${linkedId}/search?limit=${limit}&employee=&state=&payment=&sort=desc&from=${from.format(
    "DD/MM/yyyy"
  )}&to=${to.format(
    "DD/MM/yyyy"
  )}&accountType=employee&partner=${partner}&offset=${offset}`;
  return {
    type: BOOKINGS,
    isApi: true,
    payload: {
      url,
      data: {},
      method: "GET",
      extraParams: { key },
    },
  };
};

export { getBookingInfo, resetBookingInfo, getBookings, bookingUpdate };
