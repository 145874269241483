// ===================================================================
// THIS IS THE MAIN APP FILE. IT ALSO HAS ROUTE CONFIGURATION
// ===================================================================
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./Store";
import { createStackNavigator } from "@react-navigation/stack";
import {
  initialWindowMetrics,
  SafeAreaProvider,
} from "react-native-safe-area-context";
import {
  NavigationContainer,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";
import {
  configureFonts,
  Provider as PaperProvider,
  DefaultTheme as PaperDefaultTheme,
} from "react-native-paper";
import { Platform, SafeAreaView } from "react-native";
import InvitationContainer from "@reachpartner/common/+Invitation";
import CreateUser from "@reachpartner/common/+CreateUser";
import PhoneAuthentication from "@reachpartner/common/+PhoneNumberVerification";
import { Text } from "react-native";
import ModeSelection from "@reachpartner/common/+ModeSelection";
import RouteNames from "./Utils/Constants/RouteNames";
import ContainerView from "./Components/ContainerView";
import BookingInfo from "@reachpartner/common/+AppointmentInfo";
import RootNavigation from "./Navigation/RootNavigation";
import SplashScreen from "@reachpartner/common/+SplashScreen";
import fontConfig from "./Navigation/FontConfig";
import BottomTabNavigator from "./Components/BottomTabNavigator";
import SetupBusiness from "./+SetupBusiness";
import ProfileEdit from "./+ProfileEdit";
import { StyleTheme } from "./Styles/CommonStyles";
import PrivacySettings from "./+PrivacySettings";
import Reviews from "./+Reviews";
import NewBasket from "./+Basket-New";
import BasketAddon from "./+Basket-Addons";
import BasketConfig from "./+Basket-Config";
import BasketCheckout from "./+Basket-Checkout";
import BusinessMenu from "./+Business";
import BusinessIdentity from "./+Business/Screens/+Identity";
import BusinessIndustries from "./+Business/Screens/+Industries";
import BusinessServices from "./+Business/Screens/+Services";
import BusinessEmployee from "./+Business/Screens/+Employee";
import BookingSettings from "./+Business/Screens/+BookingSettings";
import Customers from "./+Business/Screens/+Customers";
import Marketing from "./+Business/Screens/+Marketing";
import MarketingDetails from "./+Business/Screens/+MarketingDetails";
import BusinessServicesOperations from "./+Business/Screens/+ServicesOperations";
import BusinessIndustryOperations from "./+Business/Screens/+IndustryOperations";
import BusinessEmployeeOperations from "./+Business/Screens/+EmployeeOperations";
import BusinessCustomerOperations from "./+Business/Screens/+CustomerOperations";
import ErrorHandler from "./Components/ErrorHandler";
import Toast from "./Components/Toast";
import AdditionalInfo from "./+Business/Screens/+AdditionalInfo";
// ===================================================================
// CONSTANTS AND LINKING
// ===================================================================
const Stack = createStackNavigator();
export const MyTheme = {
  ...NavigationDefaultTheme,
  ...PaperDefaultTheme,
  fonts: configureFonts(fontConfig),
  colors: {
    ...NavigationDefaultTheme.colors,
    ...PaperDefaultTheme.colors,
    primary: StyleTheme.APP_COLOR,
    text: StyleTheme.DEFAULT_FONT_COLOR,
    background: "white",
  },
};
interface AppRouterProps {
  children?: any;
}
const linking = {
  prefixes: ["https://localhost:3000/", "localhost:3000/"],
  config: {
    screens: {
      Landing: "",
      CreateUser: "/create-user",
      Invitation: "/invitation",
      VerifyPhoneNumber: "/verify-phone-number",
      Login: "/login",
      EmailForm: "/email-form",
      ModeSelection: "/mode-selection",
      Booking: `/booking/:bid`,
      Signup: `/signup`,
      Appointments: `/appointments`,
      Notifications: `/notifications`,
      Profile: `/profile`,
      ProfileEdit: `/profile-edit`,
      PrivacySettings: `/privacy-settings`,
      SetUpBusiness: `/setup-business`,
      Reviews: `/reviews`,
      // ********************** APPOINTMENT ********************************
      NewBasket: `/basket/:pid/new`,
      BasketAddon: `basket/:pid/addons`,
      BasketConfig: `/basket/:pid/config`,
      BasketCheckout: `/basket/:pid/checkout`,
      // **************************** BUSINESS *********************************
      BusinessMenu: `/business/:id`,
      BusinessIdentity: `/business/:id/profile`,
      BusinessIndustries: `/business/:id/industries`,
      BusinessServices: `/business/:id/services`,
      BusinessEmployee: `/business/:id/employee`,
      BookingSettings: `/business/:id/booking/settings`,
      Customers: `/business/:id/customers`,
      DigitalMarketingList: `/business/:id/campaign`,
      DigitalMarketingEdit: `/business/:businessId/campaign/:campaignId`,
      PartnerExtraInfo: `/business/:id/extra`,
      BusinessServicesOperations: `/business/:id/service/:serviceParam`,
      BusinessIndustryOperations: `/business/:id/industries/:industryParam`,
      BusinessEmployeeOperations: `/business/:id/employee/:employeeParam`,
      BusinessCustomerOperations: `/business/:id/customers/:customerParam`,
    },
  },
};
let routeRedirect: any = "";
// ===================================================================
// COMPONENT
// ===================================================================
function AppRouter(props: AppRouterProps) {
  const [showSplashScreen, setShowSplashScreen] = React.useState(true);
  const [initialChecked, setInitialChecked] = React.useState(false);
  useEffect(() => {
    // momenttz.tz.setDefault("Europe/London");
  });
  const navigationReady = () => {
    let timeout = 0;
    if (routeRedirect !== "") {
      RootNavigation.navigationReset(routeRedirect);
      timeout = 500;
    }
    setTimeout(() => {
      setShowSplashScreen(false);
    }, timeout);
  };
  const navigateTo = (newRoute: any) => {
    setInitialChecked(true);
    if (newRoute) {
      routeRedirect = newRoute;
    }
  };
  const title = (text: string) =>
    Platform.select({ web: `${text}`, default: text });
  const options = { title: title("Reach Parter App") };
  const navigationElement = () => (
    <NavigationContainer
      onReady={navigationReady}
      theme={MyTheme}
      linking={linking}
      fallback={<Text>Loading...</Text>}
      ref={RootNavigation.navigationRef}
    >
      <Stack.Navigator
        initialRouteName={"Landing"}
        screenOptions={{
          headerShown: false,
          title: "Reach Business App",
        }}
      >
        <Stack.Screen
          name={RouteNames.CreateUser.name}
          component={CreateUser}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.ModeSelection.name}
          component={ModeSelection}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.VerifyPhoneNumber.name}
          component={PhoneAuthentication}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.Invitation.name}
          component={InvitationContainer}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.LandingPage.name}
          component={BottomTabNavigator}
          options={options}
        />
        {props.children}
        <Stack.Screen
          name={RouteNames.Booking.name}
          component={BookingInfo}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.SetUpBusiness.name}
          component={SetupBusiness}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.ProfileEdit.name}
          component={ProfileEdit}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.PrivacySettings.name}
          component={PrivacySettings}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.Reviews.name}
          component={Reviews}
          options={options}
        />
        {/* ******************  BELOW THREE ARE APPOINTMENT ROUTES  ****************** */}
        <Stack.Screen
          name={RouteNames.NewBasket.name}
          component={NewBasket}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BasketAddon.name}
          component={BasketAddon}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BasketConfig.name}
          component={BasketConfig}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BasketCheckout.name}
          component={BasketCheckout}
          options={options}
        />
        {/* ******************  BUSINESS ROUTE STARTS  ****************** */}
        <Stack.Screen
          name={RouteNames.BusinessMenu.name}
          component={BusinessMenu}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessIdentity.name}
          component={BusinessIdentity}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessIndustries.name}
          component={BusinessIndustries}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessServices.name}
          component={BusinessServices}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessServicesOperations.name}
          component={BusinessServicesOperations}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessIndustryOperations.name}
          component={BusinessIndustryOperations}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessEmployeeOperations.name}
          component={BusinessEmployeeOperations}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessCustomerOperations.name}
          component={BusinessCustomerOperations}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BusinessEmployee.name}
          component={BusinessEmployee}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.BookingSettings.name}
          component={BookingSettings}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.Customers.name}
          component={Customers}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.DigitalMarketingList.name}
          component={Marketing}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.DigitalMarketingEdit.name}
          component={MarketingDetails}
          options={options}
        />
        <Stack.Screen
          name={RouteNames.PartnerExtraInfo.name}
          component={AdditionalInfo}
          options={options}
        />
        {/* ******************  BUSINESS ROUTE ENDS  ****************** */}
      </Stack.Navigator>
    </NavigationContainer>
  );

  return (
    <Provider store={store}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <PaperProvider theme={MyTheme}>
          <SafeAreaView>
            <Toast>
              <ErrorHandler>
                <ContainerView>
                  {initialChecked && navigationElement()}
                  {showSplashScreen && <SplashScreen navigateTo={navigateTo} />}
                </ContainerView>
              </ErrorHandler>
            </Toast>
          </SafeAreaView>
        </PaperProvider>
      </SafeAreaProvider>
    </Provider>
  );
}
export default AppRouter;
