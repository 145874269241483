// ===================================================================
// Profile Menu screen
// ===================================================================
import React, { useMemo } from "react";
import { View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { IUserStore } from "../Models";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { boxShadow, CommonStyles, StyleTheme } from "../Styles/CommonStyles";
import TextUI from "../Components/TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { NavigationProps } from "../Models/interface";
import RouteNames from "../Utils/Constants/RouteNames";
import ImageUI from "../Components/ImageUI";
import Actions from "../Actions";
import PartnersList from "./Components/PartnersList";
import LogoutButton from "./Components/LogoutButton";
import FlatList from "../Components/FlatList";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import LocalStorage from "../LocalStorage";
import Appbar from "../Components/Appbar";
import { version } from "../../web/package.json"
// ===================================================================
// Constants
// ===================================================================
const UIElementsData = [
  {
    sectionName: "Other Details",
    items: [
      { title: "Reviews", route: RouteNames.Reviews.name },
      { title: "Invitations", route: RouteNames.Invitation.name },
    ],
  },
  {
    sectionName: "User Settings",
    items: [
      { title: "Privacy Settings", route: RouteNames.PrivacySettings.name },
    ],
  },
];
// ===================================================================
// INTERFACE
// ===================================================================
interface IProps extends NavigationProps {
  user: IUserStore;
  signout: () => void;
  business: IBusinessStore;
  getEmployeeInfo: (empId: string) => void;
}
// ===================================================================
// COMPONENT
// ===================================================================
function Profile(props: IProps) {
  const { firstName, lastName, picture } = props.user.employeeInfo;
  const appBarTitle = useMemo(
    () => firstName + " " + lastName,
    [firstName, lastName]
  );

  useFocusEffect(
    React.useCallback(() => {
      getEmployeeInfo();
    }, [])
  );

  async function getEmployeeInfo() {
    const empId = await LocalStorage.get("linkedId");
    props.getEmployeeInfo(empId);
  }

  const viewProfile = () => {
    props.navigation.navigate(RouteNames.ProfileEdit.name);
  };

  const handleClick = (route: string) => {
    if (route !== "") {
      props.navigation.navigate(route);
    }
  };

  function renderAppBar() {
    return <Appbar title={"Profile"} hideBack={true} />;
  }
  return (
    <>
      {renderAppBar()}
      <View style={{ height: 10 }} />
      <View style={[{ height: 50, zIndex: 1 }, boxShadow()]}>
        <TouchableOpacity
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
            alignItems: "center",
            paddingHorizontal: 5,
          }}
          onPress={viewProfile}
          activeOpacity={0.7}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 2,
            }}
          >
            <ImageUI
              style={{ height: 40, width: 55, borderRadius: 4 }}
              defaultSource={require("@reachpartner/common/Assets/Images/user-account.png")}
              source={picture}
              resizeMode="contain"
            />
            <TextUI
              familyType={"type-2"}
              size={15}
              weight="500"
              style={{ marginLeft: 10 }}
            >
              {appBarTitle}
            </TextUI>
          </View>
          <MaterialCommunityIcons
            name={"chevron-right"}
            style={styles.viewAllIcon}
          />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View style={styles.body}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 20,
              paddingVertical: 10,
              flex: 1,
            }}
          >
            <TextUI weight="700" size={16}>
              Associated Business
            </TextUI>
            <TouchableOpacity
              onPress={() => {
                props.navigation.push(RouteNames.BusinessIdentity.name, {
                  id: "new",
                });
              }}
            >
              <TextUI
                weight="500"
                size={16}
                style={{ textDecorationLine: "underline" }}
              >
                + Add Business
              </TextUI>
            </TouchableOpacity>
          </View>
          <PartnersList
            data={props.business.partners}
            action={(item) => {
              props.navigation.push(RouteNames.BusinessMenu.name, {
                id: item.partner,
              });
            }}
          />
          <View style={{ height: 30 }} />
          {UIElementsData.map((section, index) => (
            <View
              key={`section${index}`}
              style={{ marginBottom: 25, paddingHorizontal: 15 }}
            >
              <TextUI size={16} weight="700" style={{ marginBottom: 10 }}>
                {section.sectionName}
              </TextUI>
              <FlatList
                items={section.items}
                action={(item) => {
                  handleClick(item.route);
                }}
              />
            </View>
          ))}
        </View>
      </ScrollView>
      <TextUI size={14} weight="700" style={{ marginBottom: 10, display: "flex", justifyContent: 'center', textAlign: "center" }}>
        App Version {version}
      </TextUI>
      <View style={styles.logoutButtonSection}>
        <LogoutButton action={() => props.signout()} />
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  body: {
    paddingVertical: 15,
    height: "100%",
  },
  viewAllIcon: {
    color: StyleTheme.DEFAULT_FONT_COLOR,
    fontSize: 30,
  },
  viewAllWrapper: {
    position: "absolute",
    width: 12,
    height: 18,
    top: 25,
    left: 329,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  profileImage: {
    width: 57,
    height: 40,
    borderRadius: 4,
  },
  logoutButtonSection: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
    height: 45,
  },
});
const mapStateToProps = (store: any) => {
  return {
    user: store.user,
    business: store.business,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    signout: () => dispatch(Actions.appSignout()),
    getEmployeeInfo: (empId: string) =>
      dispatch(Actions.getEmployeeInfo(empId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
