import React from "react";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import { View, TouchableOpacity } from "react-native";
import { CommonStyles, StyleTheme } from "../../Styles/CommonStyles";
import TextUI from "../../Components/TextUI";
interface IProps {
  languages: Array<any>;
  deleteLang: (id: number) => void;
  allLanguages: Array<any>;
}
export default function LanguageList(props: IProps) {
  function getLanguageProficient() {
    return props.languages.map((item) => {
      let fLang = props.allLanguages.find((x: any) => x.value === item.code);
      if (fLang) {
        fLang = fLang.label || item.code;
      }
      const fLevel =
        EnumMapping.LanguageProficientLevel[`${item.level}`] || item.level;
      return {
        language: fLang,
        level: fLevel,
      };
    });
  }
  const lang = getLanguageProficient();
  if (!props.languages.length) {
    return <View />;
  }
  return (
    <View style={[CommonStyles.boxContainer, { marginBottom: 15 }]}>
      {lang.length !== 0 ? (
        lang.map((item, idx) => (
          <TouchableOpacity
            key={idx}
            style={[
              CommonStyles.rowSpaceBetween,
              {
                borderBottomWidth: idx + 1 < lang.length ? 1 : 0,
                paddingVertical: 10,
                borderColor: StyleTheme.BOX_BORDER_COLOR,
              },
            ]}
          >
            <TextUI>{item.language}</TextUI>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextUI style={{ marginRight: 5 }}>{item.level}</TextUI>
              {lang.length !== 1 ? (
                <MaterialCommunityIcons
                  name={"delete-outline"}
                  size={16}
                  color={StyleTheme.ERROR_COLOR}
                  onPress={() => props.deleteLang(idx)}
                />
              ) : null}
            </View>
          </TouchableOpacity>
        ))
      ) : (
        <TextUI size={13} weight="500" style={{ textAlign: "center" }}>
          No Languages found.!
        </TextUI>
      )}
    </View>
  );
}
