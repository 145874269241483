import * as React from "react";

interface IResetPayload {
  index: number;
  routes: [{ name: string }];
}
const navigationRef: any = React.createRef();

function navigate(name: string, params?: {}) {
  navigationRef.current?.navigate(name, params);
}
function navigationReset(routeInfo: IResetPayload) {
  navigationRef.current?.reset(routeInfo);
}
function goBack() {
  navigationRef.current?.goBack();
}
const RootNavigation = { navigationReset, navigate, navigationRef, goBack };

export default RootNavigation;
