import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { TextInput, HelperText, Button } from "react-native-paper";
import ButtonUI from "../Components/ButtonUI";
import { AppStoreType } from "../Store";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import {
  AccountVerifyRequest,
  AccountVerifyValidate,
  NavigationProps,
} from "../Models/interface";
import { OTP_RESET_SUCCESS_FLAG } from "../Actions/types";
import { IUserStore } from "../Models/StoreInterfaces";
import Appbar from "../Components/Appbar";
import TextUI from "../Components/TextUI";
import { StyleTheme } from "../Styles/CommonStyles";
import RBSheet from "react-native-raw-bottom-sheet";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import LocalStorage from "../LocalStorage";
import Actions from "../Actions";
import PhoneNumberInput from "../Components/PhoneNumberInput";
import BottomActionBar from "../Components/BottomActionBar";

interface IProps extends NavigationProps {
  user: IUserStore;
  resetSuccessFlag: ({ success: boolean }) => void;
  requestOTP: (linkedId, payload) => void;
  validateOTP: (linkedId, payload, callback: (response: any) => void) => void;
  getEmployeeInfo: (linkedId) => void;
}

function PhoneAuthentication(props: IProps) {
  let _RBSheet: any;
  const changePhoneNum = async () => {
    setPhoneNumber(changephoneNumber);
    _RBSheet.close();
    const payload: AccountVerifyRequest = {
      version: "0.2",
      identity: changephoneNumber,
      target: "mobile",
    };
    const linkedId = await LocalStorage.get("linkedId");
    props.requestOTP(linkedId, payload);
  };
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [changephoneNumber, setChangePhoneNumber] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [timer, setTimer] = React.useState(0);

  const { phone } = props.user.employeeInfo;
  // this.intervalId;
  useFocusEffect(
    React.useCallback(() => {
      let code;
      let phNumber;

      setPhoneNumber(phone);
      getAccountVerificationCode();
    }, [])
  );
  React.useEffect(() => {
    if (props.user.phoneVerification.timer !== timer && timer === 0) {
      setTimer(props.user.phoneVerification.timer);
    }
  }, [props.user.phoneVerification.timer]);

  React.useEffect(() => {
    var timeout = setTimeout(() => {
      timer > 0 && setTimer(timer - 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);

  const getAccountVerificationCode = async () => {
    const payload: AccountVerifyRequest = {
      version: "0.2",
      identity: props.user.employeeInfo.phone,
      target: "mobile",
    };
    const linkedId = await LocalStorage.get("linkedId");
    props.requestOTP(linkedId, payload);
  };

  const validateAccountVerificationCode = async () => {
    const phoneNumber = changephoneNumber
      ? changephoneNumber
      : props.user.employeeInfo.phone;
    const payload: AccountVerifyValidate = {
      version: "0.2",
      identity: phoneNumber,
      target: "mobile",
      code: verificationCode,
    };
    const linkedId = await LocalStorage.get("linkedId");
    props.validateOTP(linkedId, payload, async (data) => {
      if (data.fail) {
        return;
      }
      const linkedId = await LocalStorage.get("linkedId");
      props.getEmployeeInfo(linkedId);
    });
  };

  const pad = (val) => {
    return val < 10 ? `0${val}` : val;
  };

  return (
    <>
      <Appbar title="Mobile Number - Verification" hideBack={true} />
      <View style={styles.container}>
        <View style={{}}>
          <TextUI
            size={16}
            weight="500"
            familyType="type-2"
            style={{ marginBottom: 10 }}
          >
            Enter Verification Code
          </TextUI>
          <PhoneNumberInput
            value={phoneNumber}
            onChange={(val) => setPhoneNumber(val)}
          />
          <TextUI onPress={() => _RBSheet.open()} style={styles.changeButton}>
            Change
          </TextUI>
          <View style={{ height: 15 }} />
          <TextUI size={12} familyType="type-2">
            Enter the 6-digits code sent to you via SMS
          </TextUI>
          <View
            style={{
              height: 55,
              width: "100%",
              borderRadius: 4,
              borderColor: StyleTheme.BOX_BORDER_COLOR,
              borderWidth: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 5,
              marginTop: 13,
            }}
          >
            <View style={{ width: "85%" }}>
              <TextInput
                style={{
                  paddingVertical: 0,
                  height: 51,
                  backgroundColor: "white",
                }}
                underlineColor="white"
                theme={{
                  colors: { primary: "white" },
                }}
                placeholder="Enter the code"
                value={verificationCode}
                maxLength={6}
                onChangeText={(text) => setVerificationCode(text)}
              />
            </View>
            <View
              style={{
                width: "14%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text>{`${pad(Math.floor(timer / 60))}:${pad(timer % 60)}`}</Text>
            </View>
          </View>
          <HelperText
            type="error"
            visible={props.user.phoneVerification.message !== ""}
          >
            {props.user.phoneVerification.message}
          </HelperText>
        </View>
        <RBSheet
          ref={(ref) => {
            _RBSheet = ref;
          }}
          height={160}
          openDuration={500}
          customStyles={{
            container: {
              padding: 20,
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            },
            wrapper: {
              width: "100%",
              maxWidth: 500,
              margin: "auto",
            },
          }}
        >
          <MaterialCommunityIcons
            name={"close"}
            style={styles.modalClose}
            size={22}
            onPress={() => _RBSheet.close()}
          />
          <TextUI size={16} familyType={"type-2"} weight="500">
            Update Mobile Number
          </TextUI>
          <PhoneNumberInput
            value={changephoneNumber}
            onChange={(val) => setChangePhoneNumber(val)}
          />
          <View
            style={{ display: "flex", flexDirection: "row", paddingTop: 20 }}
          >
            <View style={{}} />
            <Button
              mode="contained"
              uppercase={false}
              style={{ flex: 1 }}
              // disabled={changephoneNumber.length !== 10 ? true : false}
              onPress={() => changePhoneNum()}
            >
              Change
            </Button>
          </View>
        </RBSheet>
      </View>
      <BottomActionBar>
        <ButtonUI
          onPress={() => validateAccountVerificationCode()}
          label="Verify"
          disabled={verificationCode.length !== 6 ? true : false}
        />
      </BottomActionBar>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 15,
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  modalClose: {
    opacity: 0.8,
    position: "absolute",
    right: 15,
  },
  changeButton: {
    position: "absolute",
    color: StyleTheme.APP_COLOR,
    right: 14,
    top: 40,
  },
});
const mapStateToProps = (state: AppStoreType) => {
  return state;
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    resetSuccessFlag: (payload) => dispatch({ type: OTP_RESET_SUCCESS_FLAG }),
    requestOTP: (linkedId, payload) =>
      dispatch(Actions.requestOTP(linkedId, payload)),
    validateOTP: (linkedId, payload, callback) =>
      dispatch(Actions.validateOTP(linkedId, payload, callback)),
    getEmployeeInfo: (linkedId) => dispatch(Actions.getEmployeeInfo(linkedId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneAuthentication);
