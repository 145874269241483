import React from "react";
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { boxShadow, StyleTheme } from "../../Styles/CommonStyles";
import ImageUI from "../../Components/ImageUI";
import TextUI from "../../Components/TextUI";

export default function PartnersList(props) {
  return (
    <View style={{ width: "100%" }}>
      <ScrollView horizontal={true}>
        <TouchableOpacity
          key="all"
          onPress={() => {
            props.action("");
          }}
          activeOpacity={0.7}
        >
          {props.data.length > 1 && (
            <View
              style={[
                styles.wrapper,
                props.selected === "" ? styles.active : {},
              ]}
            >
              <TextUI weight="700" size={16}>
                ALL
              </TextUI>
            </View>
          )}
        </TouchableOpacity>
        {props.data.map((item) => {
          return (
            <TouchableOpacity
              key={item.partner}
              onPress={() => {
                props.action(item.partner);
              }}
              activeOpacity={0.7}
            >
              <View
                style={[
                  styles.wrapper,
                  props.selected === item.partner ? styles.active : {},
                ]}
              >
                <ImageUI
                  source={item.logo}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 100,
                  }}
                  resizeMode="cover"
                />
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    marginHorizontal: 10,
    marginVertical: 10,
    borderRadius: 100,
    backgroundColor: "#FFFFFF",
    elevation: 10,
    height: 55,
    width: 55,
    padding: 0,
    ...boxShadow(),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    borderWidth: 4,
    borderColor: StyleTheme.APP_COLOR,
  },
});
