// ===================================================================
// THIS COMPONENT IS FOR THE SELECT INDUSTRY/SERVICES
// ===================================================================
import React, { useEffect, useState, useMemo } from "react";
import { View, TouchableOpacity, ScrollView, StyleSheet } from "react-native";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import ContainerView from "./ContainerView";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import FlatList from "./FlatList";
import TextUI from "./TextUI";
import { Searchbar } from "react-native-paper";
import { CommonStyles, StyleTheme } from "../Styles/CommonStyles";
import ButtonUI from "./ButtonUI";
import FullViewModal from "./FullViewModal";
import OutlineButton from "./OutlineButton";
import BottomActionBar from "./BottomActionBar";
// ===================================================================
// INTERFACE
// ===================================================================
interface IProps {
  data: IBusinessStore["industries"]["services"];
  onDismiss: (data?: string[]) => void;
  getData: (val: string) => void;
  headerLabel?: string;
  loading?: boolean;
  noDataMessage?: string;
}
// ===================================================================
// COMPONENT
// ===================================================================
export default function SelectNetworks(props: IProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    let delayDebounceFn: any;
    if (searchQuery.length >= 1) {
      delayDebounceFn = setTimeout(() => {
        props.getData(searchQuery);
      }, 1500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const filteredData = useMemo(() => {
    return props.data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery]);
  // ===================================================================
  // RENDER FUNCTIONS
  // ===================================================================
  function Header() {
    return (
      <View style={modalStyles.header}>
        <TouchableOpacity
          onPress={() => {
            props.onDismiss();
          }}
          style={modalStyles.btn}
        >
          <MaterialCommunityIcons name="close" size={20} />
        </TouchableOpacity>
        <View style={{ width: 10 }} />
        <TextUI style={{}} familyType="type-2" weight="500">
          {props.headerLabel}{" "}
        </TextUI>
      </View>
    );
  }

  function List() {
    const selectedRows = props.data.filter((item) =>
      selected.includes(item.id)
    );

    const unselectedRows = filteredData.filter(
      (item) => !selected.includes(item.id)
    );
    return (
      <ScrollView>
        <View
          style={{
            paddingVertical: 10,
            paddingHorizontal: 20,
            backgroundColor: "white",
          }}
        >
          <FlatList
            items={[...selectedRows, ...unselectedRows]}
            renderItem={(item: IBusinessStore["industries"]["popular"][0]) => (
              <TouchableOpacity
                style={{
                  ...CommonStyles.rowSpaceBetween,
                  flex: 1,
                  paddingVertical: 12,
                }}
                onPress={() => {
                  if (selected.includes(item.id)) {
                    setSelected(selected.filter((id) => id !== item.id));
                  } else {
                    setSelected([...selected, item.id]);
                  }
                }}
              >
                <TextUI weight="500">{item.name}</TextUI>
                <MaterialCommunityIcons
                  name={selected.includes(item.id) ? "check" : "plus"}
                  style={{ color: StyleTheme.APP_COLOR }}
                />
              </TouchableOpacity>
            )}
          />
        </View>
      </ScrollView>
    );
  }

  function Actions() {
    function handleClick(type) {
      if (type === "selectAll") {
        setSelected([
          ...new Set([...selected, ...filteredData.map(({ id }) => id)]),
        ]);
      } else if (type === "submit") {
        props.onDismiss(selected);
      } else if (type === "cancel") {
        props.onDismiss();
      }
    }
    return (
      <BottomActionBar>
        <View
          style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: 'space-between' }}
        >
          <OutlineButton
            label={"Select All"}
            onPress={() => handleClick("selectAll")}
            style={{ flex: 1 }}
          />
          <View style={{ width: 20 }} />
          <ButtonUI label={"Ok"} onPress={() => handleClick("submit")} style={{ flex: 1 }} />
        </View>
      </BottomActionBar>
    );
  }
  return (
    <FullViewModal visible={true} onClose={() => props.onDismiss()}>
      <View style={modalStyles.body}>
        <Header />
        <View
          style={{
            paddingHorizontal: 15,
            paddingBottom: 15,
            backgroundColor: "white",
          }}
        >
          <Searchbar
            placeholder={"Search & Filter"}
            onChangeText={setSearchQuery}
            value={searchQuery}
            underlineColorAndroid={"transparent"}
            theme={{
              colors: {
                primary: "white",
              },
            }}
            style={{
              borderColor: "transparent",
              backgroundColor: "#f8f8f8",
              borderRadius: 50,
            }}
          />
        </View>
        <List />
        <Actions />
      </View>
    </FullViewModal>
  );
}

const modalStyles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#f8f8f8",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    paddingHorizontal: 15,
    height: 50,
  },
  btn: {
    borderColor: StyleTheme.BOX_BORDER_COLOR,
    opacity: 0.7,
  },
});
