// ===================================================================
// CUSTOM  COMPONENT
// ===================================================================
import React, { useCallback } from "react";
import { View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import Appbar from "../../../Components/Appbar";
import { ILocationReqPayload } from "../../../Models/interface";
import { getCurrentTimeStamp } from "../../../Utils";
import CustomerForm from "./CustomerForm";
import { IHomeStore } from "../../../Store/Reducers/homeReducer";
import Page from "../../../Components/Page";

// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  homeStore: IHomeStore;
}

interface IMapDispatch {
  customerRead: (id: string, params: string[], callback) => void;
  customerUpdate: (customerId, payload, callback) => void;
  getLocations: (payload: ILocationReqPayload) => void;
  locationReset: () => void;
  customerId: string;
  navigationPop: () => void;
}

type IProps = IMapState & IMapDispatch;
// ===================================================================
// COMPONENT
// ===================================================================
const tabs = [
  {
    title: "Profile",
    id: "profile",
  },
  {
    title: "Booking",
    id: "booking",
  },
  {
    title: "Serivce",
    id: "service",
  },
  {
    title: "Notes",
    id: "notes",
  },
];

function CustomerDetails(props: IProps) {
  const [customerData, setCustomerData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(false);

  useFocusEffect(
    useCallback(() => {
      getCustomerData();
    }, [])
  );

  function getCustomerData() {
    setIsLoading(true);
    props.customerRead(
      props.customerId,
      ["profile", "booking", "note", "allergen"],
      (data) => {
        if (!data.fail) {
          const { profile, booking, notes } = data;
          setCustomerData({
            profile,
            booking,
            notes,
            timeStamp: getCurrentTimeStamp(),
          });
          setIsLoading(false);
        }
      }
    );
  }
  function updateProfileData(input) {
    setIsLoading(true);
    props.customerUpdate(props.customerId, input, (data) => {
      if (!data.fail) {
        props.navigationPop();
      }
    });
  }

  const getLocation = (val: string) => {
    const payload = {
      locData: val,
      locType: "postcode",
    };
    props.getLocations(payload);
  };

  function renderCustomerForm() {
    const locationConfig = {
      ...props.homeStore.locationSearchItems,
      getLocations: (val) => getLocation(val),
      locationReset: () => props.locationReset(),
    };
    const profileData = customerData.profile?.[0] || {};
    if (!profileData.country) {
      profileData.country = "GBR";
    }
    return (
      <CustomerForm
        onSubmit={updateProfileData}
        onDismiss={() => { props.navigationPop() }}
        isUpdate={true}
        initialState={profileData}
        loader={isLoading}
        locationConfig={locationConfig}
      />
    );
  }

  return (
    <Page>
      <Appbar title={"Customer Details"} />
      <View style={{ height: 20 }} />
      {renderCustomerForm()}
    </Page>
  );
}

export default CustomerDetails;
