import { produce } from "immer";
import {
  CLEAR_INVITATION_DETAILS,
  INVITATIONS_LIST_SUCCESS,
  INVITATION_DETAIL,
  INVITATION_DETAIL_SUCCESS,
  INVITATION_UPDATE_SUCCESS,
} from "../../Actions/types";
import {
  IInvitationStore,
  ReducerActionInterface,
} from "../../Models/StoreInterfaces";
import EnumMapping from "../../Utils/Constants/EnumMapping";
const initialState: IInvitationStore = {
  invitationList: {
    isLoading: true,
    data: [],
  },
  invitationDetails: {
    isLoading: false,
    data: {},
  },
  inviteDetailsUpdated: false,
};
const invitation = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case INVITATIONS_LIST_SUCCESS:
      const { result = [] } = action.payload;
      const updatedResult = result.map((item) => {
        item.invitationMsg = `Invitation ${
          EnumMapping.InvitationStatus[item.status]
        }`;
        return item;
      });
      return produce(state, (draft) => {
        draft.invitationList = { isLoading: false, data: updatedResult };
      });
    case INVITATION_DETAIL:
      return produce(state, (draft) => {
        draft.invitationDetails = {
          ...draft.invitationDetails,
          isLoading: true,
        };
      });
    case INVITATION_DETAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.invitationDetails = { isLoading: false, data: action.payload };
      });
    case CLEAR_INVITATION_DETAILS:
      return produce(state, (draft) => {
        draft.invitationDetails = { isLoading: false, data: {} };
      });
    case INVITATION_UPDATE_SUCCESS:
      return state;
    default:
      return state;
  }
};

export default invitation;
