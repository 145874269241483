import { IAction, ILocationReqPayload } from "../Models/interface";
import * as Actions from "./types";

const profileUpdate = (linkedId: string, data: any, callback: any): IAction => {
  return {
    type: Actions.PROFILEUPDATE,
    isApi: true,
    payload: {
      url: `employee/${linkedId}`,
      method: "PUT",
      data,
      extraParams: data,
      callback,
    },
  };
};

const getLocations = (payload: ILocationReqPayload): IAction => {
  const {
    country = "GBR",
    itemType = "address",
    locType = "address",
    locData = "",
    filter = "",
  } = payload;
  return {
    type: Actions.LOCATIONSEARCH,
    isApi: true,
    payload: {
      url: `location/search?country=${country}&item=${itemType}&locType=${locType}&locData=${locData}&filter=${filter}&offset=0&limit=100`,
      method: "GET",
      data: {},
    },
  };
};
const locationReset = () => {
  return {
    type: Actions.LOCATIONSEARCH_RESET,
  };
};

const getLanguages = (): IAction => {
  return {
    type: Actions.LANGUAGE,
    isApi: true,
    payload: {
      url: "user/language/search?item=name&filter=&offset=0&limit=200",
      method: "GET",
      data: {},
    },
  };
};

const profileImageUpdate = (formData: any, callback): IAction => {
  return {
    type: Actions.PROFILEIMAGEUPDATE,
    isApi: true,
    payload: {
      url: `media/file/upload`,
      method: "POST",
      data: formData,
      callback,
    },
  };
};

export {
  profileUpdate,
  getLocations,
  profileImageUpdate,
  getLanguages,
  locationReset,
};
