// ===================================================================
// THIS COMPONENT IS FOR THE SELECT INDUSTRY/SERVICES
// ===================================================================
import React, { useEffect, useState, useRef } from "react";
import { View, TouchableOpacity, ScrollView, StyleSheet } from "react-native";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { Searchbar } from "react-native-paper";
import { CommonStyles, StyleTheme } from "../../../Styles/CommonStyles";
import TextUI from "../../../Components/TextUI";
import ButtonUI from "../../../Components/ButtonUI";
import FlatList from "../../../Components/FlatList";
import Page from "../../../Components/Page";
import Appbar from "../../../Components/Appbar";
import BottomActionBar from "../../../Components/BottomActionBar";
import LoaderUI from "../../../Components/Loader";
// ===================================================================
// INTERFACE
// ===================================================================
interface IProps {
  getPopularIndustries: (type) => void;
  getItemsByQuery: (val: string, type) => void;
  addIndustry: (id, payload, callback) => void;
  navigationPop: () => void;
  businessStore: IBusinessStore;
  partnerId: string;
}
// ===================================================================
// COMPONENT
// ===================================================================
function AddNetworkIndustry(props: IProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedNetworks, setSelectedNetworks] = useState<any>([]);

  const omitFirstEffect = useRef(true);

  useEffect(() => {
    props.getPopularIndustries("industry");
  }, []);

  useEffect(() => {
    if (omitFirstEffect.current) {
      omitFirstEffect.current = false;
    } else {
      let delayDebounceFn: any;
      delayDebounceFn = setTimeout(() => {
        getData(searchQuery);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchQuery]);

  function getData(val) {
    props.getItemsByQuery(val, "industry");
  }

  function addIndustries(index?: number) {
    if (index < selectedNetworks.length) {
      const callback = (response) => {
        if (!response.fail) {
          addIndustries(index + 1);
        }
      };

      const payload: any = {
        op: "add",
        path: "industry",
        id: selectedNetworks[index].id,
      };
      props.addIndustry(props.partnerId, payload, callback);
    } else {
      // after all the updates are done, go back
      props.navigationPop();
    }
  }

  function renderContent() {
    const dataByQuery =
      searchQuery === ""
        ? props.businessStore.popular
        : props.businessStore.itemsByQuery.type === "industry"
        ? props.businessStore.itemsByQuery.data
        : [];
    const resultData = dataByQuery.filter(
      ({ id }) => !selectedNetworks.map((x) => x.id).includes(id)
    );
    const data = [...selectedNetworks, ...resultData];
    return (
      <ScrollView>
        <View
          style={{
            paddingVertical: 10,
            paddingHorizontal: 20,
          }}
        >
          <FlatList
            items={data}
            renderItem={(item: IBusinessStore["industries"]["popular"][0]) => {
              const isSelected = selectedNetworks.some(
                ({ id }) => id === item.id
              );
              return (
                <TouchableOpacity
                  style={{
                    ...CommonStyles.rowSpaceBetween,
                    flex: 1,
                    paddingVertical: 12,
                  }}
                  activeOpacity={0.7}
                  onPress={() => {
                    if (isSelected) {
                      setSelectedNetworks(
                        selectedNetworks.filter((x) => x.id !== item.id)
                      );
                    } else {
                      setSelectedNetworks([...selectedNetworks, item]);
                    }
                  }}
                >
                  <TextUI weight="500">{item.name}</TextUI>
                  <MaterialCommunityIcons
                    name={isSelected ? "check" : "plus-circle-outline"}
                    style={{ color: StyleTheme.APP_COLOR }}
                    size={18}
                  />
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
    );
  }
  function renderBottomSelectionBar() {
    return (
      <BottomActionBar>
        <TextUI weight="500">
          {selectedNetworks.length === 1
            ? `1 industry selected`
            : selectedNetworks.length + ` industries selected`}
        </TextUI>
        <View style={{ width: 100 }}>
          <ButtonUI label="Add" onPress={() => addIndustries(0)} />
        </View>
      </BottomActionBar>
    );
  }
  return (
    <Page>
      <Appbar title={`Search - Network Industries`} />
      <View style={{ padding: 15 }}>
        <Searchbar
          placeholder={`Enter Industry Name`}
          onChangeText={setSearchQuery}
          value={searchQuery}
          underlineColorAndroid={"transparent"}
          theme={{
            colors: {
              primary: "white",
            },
          }}
          style={{ borderColor: "transparent" }}
        />
      </View>
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <TextUI
          weight="500"
          style={{ paddingTop: 20, paddingHorizontal: 20, fontSize: 18 }}
        >
          {searchQuery === "" ? `Popular Industries` : "Results"}
        </TextUI>
        {renderContent()}
        {selectedNetworks.length >= 1 && renderBottomSelectionBar()}
      </View>
      {props.businessStore.isUpdating && <LoaderUI mode="long" />}
    </Page>
  );
}

export default AddNetworkIndustry;
