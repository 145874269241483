import { produce } from "immer";
import * as ActionTypes from "../../Actions/types";
import {
  IBasketStore,
  ReducerActionInterface,
} from "../../Models/StoreInterfaces";
import * as _ from "lodash";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import moment from "moment";
import { formatToTime } from "../../Utils";

const initialState: IBasketStore = {
  partner: {
    partnerId: "",
    serviceLocation: [],
    closedDays: [],
    updatedTimeStamp: new Date(),
  },
  customers: {
    data: [],
    total: 0,
  },
  services: {
    data: [],
    total: 0,
  },
  basketDetails: {
    isFetching: false,
    data: {},
    isSubmitting: false,
    errorMsg: "",
    updatedTimeStamp: new Date(),
  },
  employeeList: {
    isLoading: false,
    data: [],
    total: 0,
    updatedTimeStamp: new Date(),
  },
  timesList: {
    isLoading: false,
    data: [],
    updated: Date.now(),
  },
  basketFormData: {
    deliveryLocation: 0,
    customerType: "",
    customerName: "",
    firstName: "",
    lastName: "",
    phNumber: "",
    email: "",
    customerAddress: "",
    date: "",
    selectedServices: [],
    basketId: "",
    selectedAddons: []
  },
  addons: {
    data: [],
    isLoading: false,
  },
};

const basket = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    //********************Privacy settings end */
    case ActionTypes.PARTNERPROFILE_SUCCESS:
      const { serviceLocation, closedDays } = partnerProfileParser(
        action.payload
      );
      return produce(state, (draft) => {
        draft.partner = {
          partnerId: action.payload.partner.id,
          serviceLocation,
          closedDays,
          updatedTimeStamp: new Date(),
        };
      });
    case ActionTypes.CUSTOMERLIST_SUCCESS:
      return produce(state, (draft) => {
        draft.customers = {
          ...draft.customers,
          total: action.payload.total,
          data: action.payload.result || [],
        };
      });
    case ActionTypes.SERVICELIST_SUCCESS:
      return produce(state, (draft) => {
        draft.services = {
          ...draft.services,
          total: action.payload.total,
          data: action.payload.result || [],
        };
      });
    case ActionTypes.READBASKET_SUCCESS:
      return produce(state, (draft) => {
        draft.basketDetails = {
          ...draft.basketDetails,
          isFetching: false,
          data: action.payload,
          updatedTimeStamp: new Date(),
        };
      });
    case ActionTypes.READBASKET:
      return produce(state, (draft) => {
        draft.basketDetails = {
          ...draft.basketDetails,
          isFetching: true,
          data: {},
        };
      });
    case ActionTypes.READBASKET_FAILURE:
      return produce(state, (draft) => {
        draft.basketDetails = {
          ...draft.basketDetails,
          isFetching: false,
          data: {},
        };
      });
    case ActionTypes.CREATEBASKET:
    case ActionTypes.BASKETCUSTOMER:
    case ActionTypes.UPDATEBASKET:
      return produce(state, (draft) => {
        draft.basketDetails = { ...draft.basketDetails, isSubmitting: true };
      });
    case ActionTypes.CREATEBASKET_SUCCESS:
    case ActionTypes.BASKETCUSTOMER_SUCCESS:
    case ActionTypes.UPDATEBASKET_SUCCESS:
      return produce(state, (draft) => {
        draft.basketDetails = { ...draft.basketDetails, isSubmitting: false };
      });
    case ActionTypes.CREATEBASKET_FAILURE:
    case ActionTypes.BASKETCUSTOMER_FAILURE:
    case ActionTypes.UPDATEBASKET_FAILURE:
      return produce(state, (draft) => {
        draft.basketDetails = {
          ...draft.basketDetails,
          isSubmitting: false,
          errorMsg: action.payload?.mesg || "",
        };
      });
    case ActionTypes.BASKET_RESET_ERROR:
      return produce(state, (draft) => {
        draft.basketDetails = { ...draft.basketDetails, errorMsg: "" };
      });
    case ActionTypes.GETSTAFF_SUCCESS:
      const employees = action.payload.result.filter(
        ({ enableForBooking }) => enableForBooking
      );
      return produce(state, (draft) => {
        draft.employeeList = {
          updatedTimeStamp: new Date(),
          data: employees,
          isLoading: false,
          total: action.payload.total,
        };
      });

    case ActionTypes.EMPLOYEEAVAILABILITY_SUCCESS:
      const parsedAvailability = employeeAvailabilityParser(action.payload);
      return produce(state, (draft) => {
        draft.timesList = {
          data: parsedAvailability,
          isLoading: false,
          updated: Date.now(),
        };
      });
    case ActionTypes.FORMDATA:
      return produce(state, (draft) => {
        draft.basketFormData = { ...action.payload };
      });
    case ActionTypes.BASKETADDONS_SUCCESS:
      const addons = action.payload.result.reduce((acc, item) => {
        acc = [...acc, ...item.addOns];
        return acc;
      }, []);
      const uniqueServices = addons;
      return produce(state, (draft) => {
        draft.addons = { data: uniqueServices, isLoading: false };
      });
    case ActionTypes.FORMDATA_RESET:
      return initialState;
    default:
      return state;
  }
};
export default basket;

export function partnerProfileParser(payload) {
  const days = ["su", "mo", "tu", "we", "th", "fr", "sa"];

  let { serviceLocation = [], openHour = {}, booking } = payload;
  serviceLocation = serviceLocation.map((item) => ({
    label: EnumMapping.ServiceDeliveryLocation[item],
    type: item,
  }));
  const closedDays =
    Object.keys(openHour).reduce((acc: number[], item) => {
      if (openHour[item][0]?.start === 0 && openHour[item][0]?.end === 0) {
        const idx = days.findIndex((d) => d === item);
        if (idx !== -1) {
          acc.push(idx);
        }
      }
      return acc;
    }, []) || [];
  const isTravelDistance = booking?.distanceCost?.enable || false;
  return { serviceLocation, closedDays, isTravelDistance };
}

function getTimeSlots(start, slot, fromDate) {
  let compareVal = 0;
  // if it's todays date, then don't show pass time slots
  if (moment().format("DD/MM/YYYY") === fromDate) {
    let d = moment();
    compareVal = d.hours() * 60 + d.minutes();
  }
  return slot.reduce((acc, item, index) => {
    const value = start + index * 15;
    if (item === 0 || value < compareVal) {
      return acc;
    }

    return [
      ...acc,
      {
        label: formatToTime(value),
        value,
      },
    ];
  }, []);
}

function employeeAvailabilityParser(payload) {
  const res = payload.availability[0].employeeInfo;
  const from = payload.from;
  return res.map((item) => {
    return {
      employee: item.employee,
      slots: getTimeSlots(item.start, item.slot, from),
      start: item.start,
      end: item.end,
    };
  });
}
