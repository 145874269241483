import React from "react";
import ImageUI from "../../Components/ImageUI";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import TextUI from "../../Components/TextUI";
import { boxShadow, StyleTheme } from "../../Styles/CommonStyles";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

interface IProps {
  data: Array<any>;
  selected: Array<any>;
  onStaffChange(data): void;
}
export default function SelectStaffDetails(props: IProps) {
  const { data, selected } = props;
  const staffSelected = (event) => {
    props.onStaffChange(event.id);
  };

  return (
    <View style={styles.main}>
      {data.length !== 0 ? (
        <ScrollView horizontal={true}>
          {data.map((item, idx) => (
            <View
              key={item.id + idx}
              style={{
                zIndex: 5,
                paddingTop: 7,
                height: 165,
                width: 88,
                marginRight: 13,
              }}
            >
              <TouchableOpacity
                onPress={() => staffSelected(item)}
                style={[
                  {
                    marginBottom: 10,
                    width: "100%",
                    height: 105,
                    borderRadius: 4,
                    position: "relative",
                    zIndex: 5,
                    ...boxShadow(),
                  },
                  selected.includes(item.id) ? styles.staffActive : {},
                ]}
                activeOpacity={0.8}
              >
                <ImageUI
                  source={item.image}
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    borderRadius: selected.includes(item.id) ? 0 : 4,
                  }}
                  resizeMode={"contain"}
                />
                {selected.includes(item.id) && (
                  <View style={styles.check}>
                    <MaterialCommunityIcons
                      name={"check"}
                      style={styles.checkIcon}
                    />
                  </View>
                )}
              </TouchableOpacity>
              <TextUI
                numberOfLines={1}
                size={14}
                weight={"500"}
                style={{ textAlign: "center", flexWrap: "nowrap" }}
              >
                {item.name}
              </TextUI>
            </View>
          ))}
        </ScrollView>
      ) : (
        <View style={styles.Wrapper}>
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/noStaffImg.jpg")}
            style={styles.image}
            resizeMode="contain"
          />
          <View style={{ flex: 1, marginLeft: 10 }}>
            <TextUI size={18} weight="700" style={{ marginBottom: 5 }}>
              No Staffs Available
            </TextUI>
            <TextUI
              familyType="type-2"
              addOpacity={true}
              style={{ flex: 1, flexWrap: "wrap" }}
            >
              For the selected service, no staff is available on the selected
              date, please try a different day
            </TextUI>
          </View>
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  main: {
    paddingTop: 5,
  },
  staffImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: -1,
    bottom: -1,
    borderRadius: 4,
  },
  staffActive: {
    borderWidth: 3,
    borderColor: StyleTheme.APP_COLOR,
    borderRadius: 4,
  },
  selectedStaff: {
    borderRadius: 4,
    borderColor: "red",
  },
  unSelected: {
    borderRadius: 0,
  },
  check: {
    position: "absolute",
    right: -7,
    top: -7,
    backgroundColor: StyleTheme.APP_COLOR,
    padding: 3,
    borderRadius: 50,
    zIndex: 50,
  },
  checkIcon: {
    color: "white",
    fontSize: 12,
  },
  Wrapper: {
    display: "flex",
    flexDirection: "row",
    // flexWrap: "wrap",
    alignItems: "center",
    marginTop: 15,
  },
  image: {
    height: 90,
    width: 60,
    margin: 15,
  },
});
