import { combineReducers } from "redux";
import user from "./userReducer";
import home from "./homeReducer";
import booking from "./bookingReducer";
import invitation from "./invitationReducer";
import business from "./businessReducer";
import basket from "./basketReducer";
import { ICombineReducer } from "../../Models/StoreInterfaces";

const allReducers: ICombineReducer = {
  user,
  home,
  booking,
  invitation,
  business,
  basket,
};
const rootReducer = combineReducers({ ...allReducers });

export default rootReducer;
