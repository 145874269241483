import React, { useState } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import Modal from "./Modal";
import { DatePickerModalContent } from "react-native-paper-dates";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import moment from "moment";

interface IProps {
  children: React.ReactNode;
  value: string;
  onChangeText: (val: string) => void;
  range?: { startDate?: Date; endDate?: Date };
  noRange?: boolean;
}

export default function DatepickerUI(props: IProps) {
  const [showModal, setShowModal] = useState(false);

  const onDismissSingle = () => {
    setShowModal(false);
  };

  const onConfirmSingle = (params) => {
    setShowModal(false);
    const formattedDate = moment(params.date).toISOString();
    props.onChangeText(formattedDate);
  };

  const validRange: any = {};
  if (props.range) {
    if (props.range.startDate) validRange.startDate = props.range.startDate;
    if (props.range.endDate) validRange.endDate = props.range.endDate;
  }
  return (
    <>
      <View>
        <TouchableOpacity
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
          }}
          onPress={() => setShowModal(true)}
        />

        {props.children}
        <MaterialCommunityIcons
          name={"calendar-month-outline"}
          style={styles.calendar}
          size={20}
        />
      </View>
      <Modal
        visible={showModal}
        onDismiss={() => setShowModal(false)}
        shouldShowCloseIcon={false}
        contentContainerStyle={{
          padding: 0,
          minWidth: 320,
          maxHeight: 600,
        }}
      >
        <DatePickerModalContent
          mode={"single"}
          onDismiss={onDismissSingle}
          date={props.value ? new Date(props.value) : new Date()}
          onConfirm={onConfirmSingle}
          validRange={validRange}
          disableSafeTop={false}
          saveLabel="Save" // optional
          label="Select date" // optional
        />
      </Modal>
    </>
  );
}
const styles = StyleSheet.create({
  body: {},
  header: {
    marginBottom: 10,
    fontWeight: "500",
    fontSize: 18,
  },
  actBtnWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  calendar: {
    position: "absolute",
    right: 8,
    top: 13,
    opacity: 0.7,
  },
});
