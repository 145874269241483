import React from "react";
import { View, StyleSheet } from "react-native";
import TextUI from "../../Components/TextUI";

export default function ServiceDetails(props) {
  const { details = {} } = props.data;
  const { date = "", time = "", duration1 = "", duration2 = "" } = details;

  return (
    <View style={styles.section}>
      <TextUI
        weight="500"
        familyType="type-2"
        size={16}
        style={{ marginBottom: 20 }}
      >
        Appointment Details
      </TextUI>
      <View style={styles.app_wrap}>
        <TextUI familyType="type-2" size={12}>
          Date
        </TextUI>
        <TextUI familyType="type-2" weight="500" size={14}>
          {date}
        </TextUI>
      </View>
      <View style={styles.app_wrap}>
        <TextUI familyType="type-2" size={12}>
          Time
        </TextUI>
        <TextUI weight="500" size={14} familyType="type-2">
          {time}
        </TextUI>
      </View>
      <View style={[styles.app_wrap, { borderBottomWidth: 0 }]}>
        <TextUI familyType="type-2" size={12}>
          Duration
        </TextUI>
        <View style={{ display: "flex", alignItems: "flex-end" }}>
          <TextUI familyType="type-2" weight="500" size={14}>
            {duration1}
          </TextUI>
          <TextUI
            familyType="type-2"
            weight="400"
            size={11}
            style={{ opacity: 0.7 }}
          >
            {duration2}
          </TextUI>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  section: { padding: 20 },
  app_wrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
    borderBottomColor: "rgba(36, 39, 44, 0.1)",
    borderBottomWidth: 2,
    alignItems: "center",
  },
});
