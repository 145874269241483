import { IAction, INetworkLinkUnLink } from "../Models/interface";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import * as Types from "./types";

const getReviews = (
  accountId: string,
  accountType: string,
  offset: number,
  limit: number
): IAction => {
  return {
    type: Types.REVIEWS,
    isApi: true,
    payload: {
      url: `review/account/${accountId}?accountType=${accountType}&partner=&employee=&client=&service=&offset=${offset}&limit=${limit}`,
      method: "GET",
      data: {},
    },
  };
};

const privacySettingsUpdate = (linkedId, data): IAction => {
  return {
    type: Types.PRIVACYUPDATE,
    isApi: true,
    payload: {
      url: `employee/${linkedId}/setting/privacy`,
      method: "PUT",
      data,
      extraParams: data.privacy,
    },
  };
};

const getPrivacySettings = (linkedId): IAction => {
  return {
    type: Types.PRIVACY,
    isApi: true,
    payload: {
      url: `employee/${linkedId}/setting/privacy`,
      method: "GET",
      data: {},
    },
  };
};
const getBusinessProfile = (id: string): IAction => {
  return {
    type: Types.BUSINESSPROFIE,
    isApi: true,
    payload: {
      url: `partner/${id}`,
      method: "GET",
      data: {},
    },
  };
};
const updateBusinessProfile = (
  id: string,
  payload: IBusinessStore["profile"]["data"],
  callback
): IAction => {
  const data = { ...payload };
  delete data.partner;
  return {
    type: Types.BUSINESSPROFIE_UPDATE,
    isApi: true,
    payload: {
      url: `partner/${id}`,
      method: "PUT",
      data: data,
      extraParams: payload,
      callback,
    },
  };
};

const getPartnerIndustryList = (
  id: string,
  offset?,
  limit?,
  callback?
): IAction => {
  return {
    type: Types.PARTNERINDUSTRY,
    isApi: true,
    payload: {
      url: `partner/${id}/industry?offset=${offset || 0}&limit=${limit || 30}`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const getPartnerServiceList = (
  id: string,
  offset?: number,
  limit?: number,
  search?: string = ""
): IAction => {
  return {
    type: Types.PARTNERSERVICE,
    isApi: true,
    payload: {
      url: `partner/${id}/service?offset=${offset || 0}&limit=${limit || 250}&filter=service` + (search !== "" ? `&value=${search}` : ""),
      method: "GET",
      data: {},
    },
  };
};

const getPopularItems = (
  type: "industry" | "service",
  industryIds?: string[]
): IAction => {
  const offset = 0;
  const limit = 10;
  const industryParam =
    industryIds && industryIds.length
      ? `&industry=${industryIds.join(",")}`
      : "";
  return {
    type: Types.POPULARITEMS,
    isApi: true,
    payload: {
      url: `industry/popular?type=10&item=${type}${industryParam}&offset=${offset}&limit=${limit}`,
      method: "GET",
      data: {},
      extraParams: {
        type: type,
      },
    },
  };
};

const getItemsByQuery = (
  value,
  item: "industry" | "service",
  industryIds?: string[]
): IAction => {
  const offset = 0;
  const limit = 10;
  const industryParam =
    industryIds && industryIds.length
      ? `&industry=${industryIds.join(",")}`
      : "";
  return {
    type: Types.BUSINESSQUERYITEMS,
    isApi: true,
    payload: {
      url: `industry/search?item=${item}${industryParam}&filter=${value}&offset=${offset}&limit=${limit}`,
      method: "GET",
      data: {},
      extraParams: {
        type: item,
      },
    },
  };
};

const updateNetworkIndustries = (
  partnerId: string,
  payload: INetworkLinkUnLink,
  callback: (response: any) => void
): IAction => {
  return {
    type: Types.UPDATEINDUSTRY,
    isApi: true,
    payload: {
      url: `partner/${partnerId}`,
      method: "PATCH",
      data: payload,
      callback,
    },
  };
};

const resetIndustryData = (): IAction => {
  return {
    type: Types.RESET_INDUSTRY_DATA,
  };
};

const resetServiceData = (): IAction => {
  return {
    type: Types.RESET_SERVICE_DATA,
  };
};

const deletePartnerService = (id: string, callback): IAction => {
  return {
    type: Types.DELETEPARTNERSERVICE,
    isApi: true,
    payload: {
      url: `service/${id}`,
      method: "DELETE",
      data: {},
      callback,
    },
  };
};

const getPartnerGroupIndustryLevel = (
  partnerId,
  industryId,
  callback
): IAction => {
  return {
    type: Types.DELETEPARTNERSERVICE,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/group/industry/level?key=industry&value=${industryId}`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const getPartnerGroupIndustryLevelService = (partnerId, callback): IAction => {
  return {
    type: Types.PARTNER_GROUP_INDUSTRY_LEVEL_SERVICE,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/group/industry/level/service`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const partnerServiceCreate = (pid, isUpdate, payload, callback): IAction => {
  // Create and update
  return {
    type: Types.PARTNERCREATESERVICE,
    isApi: true,
    payload: {
      url: isUpdate ? `service/${pid}` : `partner/${pid}/service`,
      method: isUpdate ? "PUT" : "POST",
      data: payload,
      callback,
    },
  };
};

const getServiceDetails = (serviceId, callback): IAction => {
  return {
    type: Types.GETBUSINESSSERVICE,
    isApi: true,
    payload: {
      url: `service/${serviceId}`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const getPartnerEmployeeList = (
  partnerId,
  offset?,
  limit?,
  callback?
): IAction => {
  return {
    type: Types.PARTNEREMPLOYEELIST,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/employee?&offset=${offset || 0}&limit=${limit || 30
        }`,
      method: "GET",
      data: {},
    },
  };
};

const getEmployeeDetails = (partnerId, employeeId): IAction => {
  return {
    type: Types.PARTNEREMPLOYEEDETAIL,
    isApi: true,
    payload: {
      url: `employee/${employeeId}/partner/${partnerId}`,
      method: "GET",
      data: {},
    },
  };
};

const updatePartnerEmployeeDetails = (
  partnerId,
  employeeId,
  data,
  callback
): IAction => {
  return {
    type: Types.PARTNEREMPLOYEEUPDATE,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/employee/${employeeId}`,
      method: "PUT",
      data,
      callback,
    },
  };
};

const employeeInvite = (partnerId, employeeId, data, callback): IAction => {
  return {
    type: Types.EMPLOYEEINVITE,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/employee${employeeId ? "/" + employeeId : ""
        }/invite`,
      method: "POST",
      data,
      callback,
    },
  };
};

const getPartnerHours = (partnerId, callback): IAction => {
  return {
    type: Types.GETPARTNEROPENHOURS,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/openhours`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const createPartner = (data, callback): IAction => {
  return {
    type: Types.CREATEPARTNER,
    isApi: true,
    payload: {
      url: `partner`,
      method: "POST",
      data,
      extraParams: data,
      callback,
    },
  };
};

const businessLink = (partnerId, data, callback): IAction => {
  return {
    type: Types.BUSINESSLINK,
    isApi: true,
    payload: {
      url: `partner/${partnerId}`,
      method: "PATCH",
      data,
      callback,
    },
  };
};

const getPartnerBookingSettings = (partnerId, callback): IAction => {
  return {
    type: Types.GETPARTNERBOOKINGSETTINGS,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/setting/booking`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const updatePartnerBookingSettings = (
  payload,
  partnerId,
  callback
): IAction => {
  return {
    type: Types.UPDATEPARTNERBOOKINGSETTINGS,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/setting/booking`,
      method: "PUT",
      data: payload,
      callback,
    },
  };
};

const getCustomersList = (
  partnerId,
  offset?: number,
  limit?: number,
  callback?
): IAction => {
  return {
    type: Types.GETCUSTOMERS,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/customer?offset=${offset || 0}&limit=${limit || 250
        }`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const customerCreate = (partnerId, payload, callback): IAction => {
  return {
    type: Types.CUSTOMERCREATE,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/customer`,
      method: "POST",
      data: payload,
      callback,
    },
  };
};

const customerUpdate = (customerId, payload, callback?): IAction => {
  return {
    type: Types.CUSTOMERUPDATE,
    isApi: true,
    payload: {
      url: `customer/${customerId}`,
      method: "PUT",
      data: payload,
      callback,
    },
  };
};

const getCampaignsList = (
  partnerId,
  options: { offset: number; limit: number },
  callback
): IAction => {
  return {
    type: Types.CAMPAIGNLIST,
    isApi: true,
    payload: {
      url: `campaign/account/${partnerId}?accountType=partner&offset=${options.offset}&limit=${options.limit}`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const getCampaignDetails = (campaignId, callback): IAction => {
  return {
    type: Types.CAMPAIGNDETAILS,
    isApi: true,
    payload: {
      url: `campaign/${campaignId}`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

const industrySearch = (
  value,
  item,
  industryIds: string[],
  options: { offset: number; limit: number },
  callback
): IAction => {
  const industryParam = industryIds.length
    ? `&industry=${industryIds.join(",")}`
    : "";
  return {
    type: Types.NETWORKINDUSTRY,
    isApi: true,
    payload: {
      url: `industry/search?item=${item || ""
        }${industryParam}&filter=${value}&offset=${options.offset || 0}&limit=${options.limit || 20
        }`,
      method: "GET",
      data: {},
      extraParams: {
        type: item,
      },
      callback,
    },
  };
};

const createCampaign = (partnerId, payload, callback): IAction => {
  return {
    type: Types.CAMPAIGN_CREATE,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/campaign`,
      method: "POST",
      data: payload,
      callback,
    },
  };
};

const updateCampaign = (campaignId, payload, callback): IAction => {
  return {
    type: Types.CAMPAIGN_UPDATE,
    isApi: true,
    payload: {
      url: `campaign/${campaignId}`,
      method: "PUT",
      data: payload,
      callback,
    },
  };
};

const getPartnerExtraInfo = (partnerId, callback) => {
  return {
    type: Types.PARTNER_EXTRA_INFO,
    isApi: true,
    payload: {
      url: `partner/${partnerId}/extinfo`,
      method: "GET",
      data: {},
      callback,
    },
  };
};

export {
  getReviews,
  getPrivacySettings,
  privacySettingsUpdate,
  getBusinessProfile,
  updateBusinessProfile,
  getPopularItems,
  getItemsByQuery,
  getPartnerIndustryList,
  updateNetworkIndustries,
  resetIndustryData,
  getPartnerServiceList,
  deletePartnerService,
  getPartnerGroupIndustryLevel,
  getPartnerGroupIndustryLevelService,
  partnerServiceCreate,
  getServiceDetails,
  getPartnerEmployeeList,
  getEmployeeDetails,
  updatePartnerEmployeeDetails,
  employeeInvite,
  getPartnerHours,
  createPartner,
  businessLink,
  getPartnerBookingSettings,
  updatePartnerBookingSettings,
  getCustomersList,
  customerCreate,
  customerUpdate,
  getCampaignsList,
  getCampaignDetails,
  industrySearch,
  createCampaign,
  updateCampaign,
  getPartnerExtraInfo,
  resetServiceData
};
