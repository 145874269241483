import React from "react";
import {
  View,
  StyleSheet,
  Linking,
  Text,
  TouchableOpacity,
} from "react-native";
import firebase from "../Firebase";
import { useFocusEffect } from "@react-navigation/native";
import TextInputUI from "../Components/TextInputUI";
import ButtonUI from "../Components/ButtonUI";
import SocialButtonUI from "../Components/SocialButtonUI";
import { StyleTheme } from "../Styles/CommonStyles";
import TextUI from "../Components/TextUI";
import ImageUI from "../Components/ImageUI";
import { RouteProps } from "../Models/interface";
import { validateEmail } from "../Utils";
import LoaderUI from "../Components/Loader";

interface IProps extends RouteProps {
  action: (type: string) => void;
  getFirebaseUser: (user: any) => void;
  isLoading: Boolean;
  handleError: (data: any) => void;
}

function EmailFormAbstract(props: IProps) {
  const [email, setEmail] = React.useState("");
  const [emailExist, setEmailExist] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [initialValidate, setInitialValidate] = React.useState(false);
  const [wrongPassword, setWrongPassword] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  useFocusEffect(
    React.useCallback(() => {
      const { defaultState = "" } = props.route.params;
      setEmailExist(defaultState);
    }, [])
  );

  const checkUseExist = () => {
    const errorFlags = [hasError("email", email)];
    if (errorFlags.includes(true)) {
      !initialValidate && setInitialValidate(true);
      return;
    }
    setLoader(true);
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((res) => {
        if (res && res.length) {
          setEmailExist(res[0]);
          setInitialValidate(false);
        } else {
          setEmailExist("no");
        }
        setInitialValidate(false);
      })
      .catch((err) => console.log("login with email failed", err))
      .finally(() => {
        setLoader(false);
      });
  };

  const emailLogin = () => {
    const errorFlags = [
      hasError("email", email),
      hasError("password", password),
    ];
    if (errorFlags.includes(true)) {
      !initialValidate && setInitialValidate(true);
      return;
    }
    setLoader(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        props.getFirebaseUser(res.user);
      })
      .catch((err) => {
        console.log(err);
        setWrongPassword(err.message);
      })
      .finally(() => setLoader(false));
  };

  const createUser = () => {
    const errorFlags = [
      hasError("email", email),
      hasError("password", password),
      hasError("name", fullName),
    ];
    if (errorFlags.includes(true)) {
      !initialValidate && setInitialValidate(true);
      return;
    }
    setLoader(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (res: any) => {
        res.user
          .updateProfile({
            displayName: fullName,
          })
          .then((updateres: any) => {
            props.getFirebaseUser(res.user);
          });
      })
      .catch((err: any) => {
        const data = err;
        if (err.code === "auth/email-already-in-use") {
          data.code = 400;
          data.mesg = "Email already exists";
        }
        props.handleError(data);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const hasError = (inputName: string, value: string) => {
    if (inputName === "email") {
      return !validateEmail(value);
    } else if (inputName === "password") {
      return value.length < 8;
    }
    return value === "";
  };

  const hasInputError = (inputName: string, value: string) => {
    if (!initialValidate) {
      return false;
    }
    return hasError(inputName, value);
  };

  const renderSocialAuth = () => {
    let signedInMethod;
    if (emailExist === "twitter.com") {
      signedInMethod = "Twitter";
    } else if (emailExist === "facebook.com") {
      signedInMethod = "Facebook";
    } else {
      signedInMethod = "Google";
    }
    const message = `You've already used ${email}. Sign in with ${signedInMethod} to continue`;
    return (
      <>
        <Text style={styles.title}>Log in with Email</Text>
        <TextInputUI
          label="Email"
          value={email}
          disabled={true}
          error={hasInputError("email", email)}
          onChangeText={(email) => setEmail(email)}
          errorHelpText="Email is invalid!"
        />
        <View style={{ height: 15 }} />
        <Text style={{ marginBottom: 10, fontWeight: "500", fontSize: 14 }}>
          {message}
        </Text>
        <View style={styles.loginButtonSection}>
          <SocialButtonUI
            type={signedInMethod.toLowerCase()}
            label={`Sign in with Google`}
            action={() => socialAuth()}
          />
        </View>
      </>
    );
  };

  const renderLoginPassword = () => {
    return (
      <>
        <Text style={styles.title}>Log in with Email</Text>
        <TextInputUI
          label="Email"
          value={email}
          disabled={true}
          error={hasInputError("email", email)}
          onChangeText={(email) => setEmail(email)}
          errorHelpText="Email is invalid!"
        />
        <View style={{ height: 15 }} />
        <TextInputUI
          label="Password"
          value={password}
          onChangeText={(password) => setPassword(password)}
          type={"password"}
          error={hasInputError("password", password) || wrongPassword !== ""}
          errorHelpText={
            wrongPassword !== ""
              ? wrongPassword
              : "Password should be 8 characters"
          }
          secureTextEntry={true}
        />
        <View style={{ height: 15 }} />
        <View style={styles.loginButtonSection}>
          <ButtonUI
            style={{ width: "100%" }}
            onPress={() => emailLogin()}
            label={"Login"}
          />
        </View>
        {/* <TouchableOpacity onPress={() => setEmailExist("signup")}>
          <Text style={styles.signinnow}>Forgot password ?</Text>
        </TouchableOpacity> */}
      </>
    );
  };

  const renderSignUp = () => {
    return (
      <>
        <Text style={styles.title}>Sign up with Email</Text>
        <TextInputUI
          label="Email"
          value={email}
          error={hasInputError("email", email)}
          onChangeText={(email) => setEmail(email)}
          errorHelpText="Email is invalid!"
        />
        <View style={{ height: "2%", maxHeight: 15 }} />
        <TextInputUI
          label="First & last name"
          value={fullName}
          onChangeText={(name) => setFullName(name)}
          error={hasInputError("name", fullName)}
          errorHelpText={"Name shouldn't be empty!"}
        />
        <View style={{ height: "2%" }} />
        <TextInputUI
          label="Password"
          value={password}
          onChangeText={(password) => setPassword(password)}
          type={"password"}
          error={hasInputError("password", password) || wrongPassword !== ""}
          errorHelpText={
            wrongPassword !== ""
              ? wrongPassword
              : "Password should be 8 characters"
          }
          secureTextEntry={true}
        />
        <View style={{ height: "2%" }} />
        <View style={styles.loginButtonSection}>
          <ButtonUI
            label={"SIGN UP"}
            style={{ width: "100%" }}
            onPress={() => createUser()}
          />
        </View>
        <Text style={styles.alreadyAccount}>
          Already have a Partner account?
        </Text>
        <TouchableOpacity onPress={() => setEmailExist("")}>
          <Text style={styles.signinnow}>Sign in</Text>
        </TouchableOpacity>
      </>
    );
  };

  const getLoginElement = () => {
    if (["google.com", "twitter.com", "facebook.com"].includes(emailExist)) {
      return renderSocialAuth();
    } else if (emailExist === "password") {
      return renderLoginPassword();
    } else if (emailExist === "no") {
      return (
        <>
          <Text
            style={{
              marginBottom: 20,
              fontWeight: "500",
              opacity: 0.6,

              textAlign: "center",
              color: StyleTheme.ERROR_COLOR,
            }}
          >
            No account found with that email address!
          </Text>
          <Text style={styles.title}>Log in with Email</Text>
          <TextInputUI
            label="Email"
            value={email}
            // disabled={true}
            error={hasInputError("email", email)}
            onChangeText={(email) => setEmail(email)}
            errorHelpText="Email is invalid!"
          />
          <View style={{ height: 15 }} />

          <View style={styles.loginButtonSection}>
            <ButtonUI
              style={{ width: "100%" }}
              onPress={() => checkUseExist()}
              label={"Next"}
            />
          </View>
          <Text style={styles.alreadyAccount}>
            Don't have a Partner account?
          </Text>
          <TouchableOpacity onPress={() => setEmailExist("signup")}>
            <Text style={styles.signinnow}>Sign up now</Text>
          </TouchableOpacity>
        </>
      );
    } else if (emailExist === "signup") {
      return renderSignUp();
    }
  };

  const socialAuth = () => {
    const type =
      emailExist === "twitter.com"
        ? "twitter"
        : emailExist === "facebook.com"
          ? "facebook"
          : "google";
    props.action(type);
  };

  return (
    <View
      style={{
        paddingVertical: 10,
        paddingHorizontal: 5,
        display: "flex",
        height: "100%",
        alignItems: "center",
      }}
    >
      <View style={styles.logoWrapper}>
        <ImageUI
          resizeMode="contain"
          source={require("@reachpartner/common/Assets/Images/partner_logo.png")}
          style={{ width: 120, height: "80%" }}
        />
      </View>
      <View
        style={{
          width: "100%",
          maxWidth: 300,
          flex: 1,
          justifyContent: "center",
          paddingHorizontal: 20,
        }}
      >
        {emailExist === "" ? (
          <>
            <Text
              style={{
                marginBottom: 20,
                fontWeight: "500",
                opacity: 0.6,

                textAlign: "center",
                color: StyleTheme.ERROR_COLOR,
              }}
            ></Text>
            <Text style={styles.title}>Log in with Email</Text>
            <TextInputUI
              label="Email"
              value={email}
              error={hasInputError("email", email)}
              onChangeText={(email) => setEmail(email)}
              errorHelpText="Email is invalid!"
              onKeyPress={(event: any) => {
                if (event.nativeEvent.key == "Enter") {
                  checkUseExist();
                }
              }}
            />
            <View style={{ height: "2%", maxHeight: 15 }} />
            <View style={styles.loginButtonSection}>
              <ButtonUI
                style={{ width: "100%" }}
                onPress={() => checkUseExist()}
                label={"Next"}
              />
            </View>
            <Text style={styles.alreadyAccount}>
              Don't have a Partner account?
            </Text>
            <TouchableOpacity onPress={() => setEmailExist("signup")}>
              <Text style={styles.signinnow}>Sign up now</Text>
            </TouchableOpacity>
          </>
        ) : (
          getLoginElement()
        )}
      </View>
      <View
        style={{
          // height: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextUI size={13} weight="500" style={{ lineHeight: 25 }}>
          By continuing, you are indicating that you accept our{" "}
        </TextUI>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => Linking.openURL("https://reach.biz/en-gb/terms")}
          >
            <TextUI color="#007FFF" size={13} weight="500">
              Terms of Service
            </TextUI>
          </TouchableOpacity>
          <TextUI size={13} weight="500" style={{ marginHorizontal: 7 }}>
            and
          </TextUI>
          <TouchableOpacity
            onPress={() => Linking.openURL("https://reach.biz/en-gb/privacy")}
          >
            <TextUI color="#007FFF" size={13} weight="500">
              Privacy Policy
            </TextUI>
          </TouchableOpacity>
        </View>
      </View>
      {(loader || props.isLoading) && <LoaderUI mode="long" />}
    </View>
  );
}
const styles = StyleSheet.create({
  textInput: {
    backgroundColor: "white",
    padding: 0,
  },
  loginButtonSection: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    marginBottom: 15,
  },
  logoWrapper: {
    height: "20%",
    marginTop: "5%",
    justifyContent: "center",
    marginBottom: "5%",
  },
  logoImage: {
    height: "100%",
    width: 120,
  },
  title: {
    marginBottom: 15,
    fontWeight: "700",
    fontSize: 18,
  },
  alreadyAccount: {
    textAlign: "center",
    color: "grey",
    fontWeight: "500",
    marginBottom: 5,
    marginTop: 5,
  },
  signinnow: {
    textAlign: "center",
    fontWeight: "500",
    color: "rgb(0, 127, 255)",
  },
});
export default EmailFormAbstract;
