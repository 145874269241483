import React from "react";
import { TouchableOpacity, View } from "react-native";

interface IProps {
  action: (data: any) => void;
  children: React.ReactNode;
}

export default function ImageUpoload(props: IProps) {
  const onSelectFile = (e) => {
    let arrayofFiles = [];
    for (var i = 0; i < e.target.files.length; i++) {
      arrayofFiles.push(e.target.files[i]);
    }
    let images = arrayofFiles.map((img) => {
      return { image: img, display: URL.createObjectURL(img) };
    });
    props.action(images);
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          document.getElementById("profileInputUpload").click();
        }}
        activeOpacity={0.6}
      >
        {props.children}
      </TouchableOpacity>

      <input
        type="file"
        name="file"
        onChange={onSelectFile}
        style={{ display: "none" }}
        id="profileInputUpload"
      />
    </>
  );
}
