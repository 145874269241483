import React from "react";
import { Platform, ViewStyle, TextStyle } from "react-native";
import { Button } from "react-native-paper";
import { StyleTheme } from "../Styles/CommonStyles";
import TextUI from "./TextUI";

interface ButtonUIProps {
  label: string;
  onPress: () => void;
  disabled?: boolean;
  style?: ViewStyle;
  labelStyle?: TextStyle;
  mode?: "contained" | "outlined";
}

function OutlineButton(props: ButtonUIProps) {
  const {
    label = "",
    onPress = () => {},
    disabled = false,
    style = {},
    labelStyle = {},
  } = props;

  return (
    <Button
      mode={"contained"}
      onPress={() => {
        if (!disabled) {
          onPress();
        }
      }}
      style={{
        height: 44,
        display: "flex",
        justifyContent: "center",
        opacity: disabled ? 0.5 : 1,
        flex: 1,
        backgroundColor: "white",
        ...shadow(),
        ...style,
        ...(props.disabled && {}),
      }}
      uppercase={false}
    >
      <TextUI
        style={{
          fontWeight: "500",
          color: StyleTheme.DEFAULT_FONT_COLOR,
          ...labelStyle,
        }}
      >
        {label}
      </TextUI>
    </Button>
  );
}

function shadow() {
  return Platform.select({
    android: {
      elevation: 10,
    },
    web: {
      boxShadow: `0 3px 5px rgba(0,0,0,0.25)`,
    },
  });
}

export default OutlineButton;
