// ===================================================================
// THIS COMPONENT IS BOTTOM BAR WITH ACTIONS;
// IT'S NOT A MODAL;
// THIS HAS ONLY SHADOW TO MAINTAIN CONSISTENCY
// ===================================================================
import React from "react";
import { View, Platform } from "react-native";
import Row from "./Row";
// COMPONENT
export default function BottomActionBar(props) {
  return (
    <View
      style={{
        height: 60,
        paddingHorizontal: 10,
        backgroundColor: "white",
        ...boxShadow(),
      }}
    >
      <Row spaceBetween>{props.children}</Row>
    </View>
  );
}
function boxShadow() {
  return Platform.select({
    android: {
      elevation: 10,
    },
    web: {
      boxShadow: `0px 4px 12px rgba(36, 39, 44, 0.1)`,
    },
    ios: {
      shadowRadius: 2,
      shadowOffset: {
        width: 0,
        height: -1,
      },
      shadowColor: "rgb(205,202,202)",
    },
  });
}
