import React from "react";
import { View, StyleSheet } from "react-native";
import { Card } from "react-native-paper";
import { boxShadow } from "../../Styles/CommonStyles";
import ImageUI from "../../Components/ImageUI";
import TextUI from "../../Components/TextUI";

export default function ServiceDeliveryAddress(props) {
  const { type = 2, deliveryInfo = {}, checkout: { clientInfo } } = props.data || {};
  const { address = "" } = deliveryInfo;

  return (
    <>
      <View style={styles.section}>
        <TextUI
          weight="500"
          familyType="type-2"
          size={18}
          style={{ marginBottom: 15 }}
        >
          Service Delivery Address
        </TextUI>
        <View style={styles.delivery}>
          <Card
            style={{
              paddingVertical: 15,
              paddingHorizontal: 30,
              ...boxShadow(),
              marginRight: 20,
            }}
          >
            <ImageUI
              source={require("@reachpartner/common/Assets/Images/home-delivery.png")}
              style={{ width: 35, height: 35, marginBottom: 10 }}
            />
            <TextUI weight="500" size={13}>
              {type === 3 ? "Venue" : "Home"}
            </TextUI>
          </Card>
          <View style={{ flex: 1 }}>
            <TextUI weight="500" size={16}>
              {address}
            </TextUI>
            <TextUI weight="500" size={12}>
              Customer Name: {clientInfo.name}
            </TextUI>
          </View>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    padding: 20,
  },
  delivery: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
