import React, { useState, useEffect } from "react";
import { View, StyleSheet, Dimensions, Text, Platform } from "react-native";
import ImageBackgroundUI from "../../Components/ImageBackgroundUI";

function SplashScreenComponent() {
  const [version, setVersion] = useState("");
  useEffect(() => {
    let packager = require("@reachpartner/mobile/package.json");
    if (Platform.OS === "web") {
      packager = require("@reachpartner/web/package.json");
    }
    setVersion(packager.version);
  }, []);

  return (
    <View style={styles.component}>
      <ImageBackgroundUI
        style={styles.backgroundImage}
        resizeMode="cover"
        source={require("@reachpartner/common/Assets/Images/reach-partner-splash-screen.png")}
      >
        <View style={styles.appInfoWrapper}>
          <Text style={styles.appName}>Business App</Text>
          <Text style={styles.description}>Just Show Up</Text>
          <Text style={styles.version}>Version {version}</Text>
        </View>
      </ImageBackgroundUI>
    </View>
  );
}
const styles = StyleSheet.create({
  component: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appInfoWrapper: {
    position: "absolute",
    top: Dimensions.get("window").height / 2 + 80,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    paddingVertical: 20,
  },
  backgroundImage: {
    width: "100%",
    height: "100%",

    flex: 1,
  },
  description: {
    color: "#152B41",
    fontSize: 22,
    fontWeight: "500",
    marginTop: 15,
  },
  appName: { color: "#00D2FF", fontSize: 42, fontWeight: "500" },
  version: {
    fontWeight: "bold",
    marginTop: 25,
    bottom: 35,
    position: "absolute",
  },
});
export default SplashScreenComponent;
