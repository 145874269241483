interface IEnumType1 {
  [k: number]: string;
}
const PartnerClientRelevance: IEnumType1 = {
  1: "New Customer",
  2: "Existing Customer",
  3: "New Member",
  4: "Return Member",
};
const PartnerClientSource: IEnumType1 = {
  1: "REACH",
  2: "WALK-IN",
  3: "PARTNER",
};
const BookingStatus: IEnumType1 = {
  1: "Created",
  2: "Pending",
  3: "Confirmed",
  4: "Cancelled",
  5: "Rescheduled",
  6: "Running Late",
  7: "No Show",
  8: "Completed",
  9: "Rejected",
  10: "Approved",
  11: "Dispute",
  12: "Closed",
};
const BookingStatusColor: IEnumType1 = {
  1: "#4CAF50",
  2: "#EFFB347",
  3: "#4CAF50",
  4: "#FF5252",
  5: "#4CAF50",
  6: "#AF87CE",
  7: "#F73668",
  8: "#29B6F6",
  9: "#FD5602",
  10: "#BECEDA",
  11: "#F15BB5",
  12: "#84A59D",
};
const PaymentMode: IEnumType1 = {
  1: "Unpaid",
  2: "Cash",
  3: "Credit Card",
  4: "Debit Card",
  5: "Pay by link",
  6: "Bank Transfer",
  7: "Apple Pay",
  8: "Google Pay",
  9: "Samsung Pay",
  10: "Other Digital Wallet",
  11: "Gift Card",
  12: "Voucher",
  13: "Points",
  14: "Tokens",
  15: "Other"
};
const PaymentGateway: IEnumType1 = {
  1: "None",
  2: "Card Machine",
  3: "Pay 360",
};
const PaymentStatus: IEnumType1 = {
  1: "Payment Unpaid",
  2: "Payment in Progress",
  3: "Payment Failed",
  4: "Payment Received",
  5: "Payment Requested. Awaiting Approval",
  6: "Partial Payment Received",
  7: "Payment Captured",
  8: "Payment Declined"
};
const PaymentStatusColor: IEnumType1 = {
  1: "#800000",
  2: "#FFA500",
  3: "#8B0000",
  4: "#008000",
  5: "#0000FF",
  6: "#90EE90",
  7: "#800080",
  8: "#FF0000"
};
const InvitationStatus: IEnumType1 = {
  1: "Received",
  2: "Expired",
  3: "Accepted",
  4: "Declined",
};
const InvitationColor: IEnumType1 = {
  1: "blue",
  2: "orange",
  3: "green",
  4: "red"
};
const LanguageProficientLevel: IEnumType1 = {
  1: "Basic",
  2: "Intermitent",
  3: "Professional",
  4: "Expert",
};
const ServiceDeliveryLocation: IEnumType1 = {
  1: "Home",
  2: "Venue",
};
const SocialMedia = {
  1: "facebook",
  2: "instagram",
  3: "twitter",
};
const ServiceType = {
  1: "Standalone Service",
  2: "Addon Service",
};
const CostType = {
  1: "Cost Varies On Skill Level",
  2: "Cost Varies On Room Area",
  3: "Cost Varies On Floor Area",
  4: "Cost Varies On Length",
  5: "Fixed Per Day",
  6: "Fixed Cost",
  7: "Enter Total Wall Area in Square Metres",
  8: "Cost Varies Based On House External Wall Area",
  9: "Cost Varies Based On House Internal Wall Area",
  10: "Pricing Based On 15 Minutes Interval",
  11: "Pricing Based On 30 Minutes Interval",
  12: "Pricing Based On 60 Minutes Interval",
  13: "Pricing Based On Total Floor Volume",
  14: "Pricing Based On Number Of units"
}
const ServiceLevel = {
  1: "Standard Electrical",
  2: "Per Square Meter",
  3: "Per Square Meter",
  4: "Per Meter",
  5: "Per Day",
  6: "Fixed Cost",
  7: "Per Square Meter",
  8: "Per Square Meter",
  9: "Per Square Meter",
  10: "Per 15 Min",
  11: "Per 30 Min",
  12: "Per 60 Min",
  13: "Per Cubic Meter",
  14: "Number of Units"
};
const ServiceParent = {
  1: "All Services",
  2: "Selected Services Only",
};
const EmployeeAccessLevel = [
  { value: 1, label: "Invitee" },
  { value: 2, label: "Employee" },
  { value: 3, label: "Custom" },
  { value: 4, label: "Admin" },
  { value: 5, label: "Owner" },
  { value: 6, label: "Receptionist" },
  { value: 7, label: "Reach Admin" },
];
const CampaignTarget = {
  1: "Selected Customer",
  2: "All Customers",
};
const CampaignStatus = {
  1: "Created",
  2: "Currently Executed",
  3: "Attempt Failed",
  4: "Executed Successfully",
  5: "Partial Success",
  6: "Cancelled",
};
const CampaignMedium = {
  2: "Email",
  3: "SMS",
};
const PeriodType = {
  1: "Daily",
  2: "Weekly",
  3: "FortNight",
  4: "Monthly",
  5: "Yearly",
};
const RecurrentEndType = {
  1: "after",
  2: "on date",
  3: "never",
};
const EnumMapping = {
  InvitationStatus,
  InvitationColor,
  BookingStatus,
  BookingStatusColor,
  PartnerClientSource,
  PartnerClientRelevance,
  LanguageProficientLevel,
  ServiceDeliveryLocation,
  SocialMedia,
  ServiceType,
  ServiceParent,
  EmployeeAccessLevel,
  PaymentMode,
  PaymentGateway,
  PaymentStatus,
  CampaignTarget,
  CampaignStatus,
  CampaignMedium,
  PeriodType,
  RecurrentEndType,
  CostType,
  ServiceLevel,
  PaymentStatusColor
};
export default EnumMapping;
