import { StyleSheet, Platform, ViewStyle } from "react-native";
export const StyleTheme = {
  FW_N: "500",
  FW_B: "700",
  FW_S: "200",
  BOX_BORDER_COLOR: "rgba(36, 39, 44, 0.1)",
  ERROR_COLOR: "rgb(176, 0, 32)",
  DEFAULT_FONT_COLOR: "#24272C",
  APP_COLOR: "#007FFF",
};
export const CommonStyles: { [key: string]: ViewStyle } = {
  muted: {
    opacity: 0.7,
  },
  textInput: {
    backgroundColor: "white",
    marginVertical: 5,
    height: 40,
    borderColor: StyleTheme.BOX_BORDER_COLOR,
  },
  boxContainer: {
    borderColor: StyleTheme.BOX_BORDER_COLOR,
    borderRadius: 6,
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
export function boxShadow() {
  return Platform.select({
    android: {
      elevation: 10,
    },
    web: {
      boxShadow: `0px 4px 12px rgba(36, 39, 44, 0.1)`,
    },
    ios: {
      shadowColor: "#000000",
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.25,
      shadowRadius: 3,
    },
  });
}
