// ===================================================================
// BUSINESS INDUSTRIES COMPONENT
// ===================================================================
import React, { useCallback, useEffect } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import TextUI from "../../../Components/TextUI";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Appbar from "../../../Components/Appbar";
import FlatList from "../../../Components/FlatList";
import {
  getPartnerIndustryList,
  resetIndustryData,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import ImageUI from "../../../Components/ImageUI";
import FabUI from "../../../Components/FabUI";
import { StyleTheme } from "../../../Styles/CommonStyles";
import RouteNames from "../../../Utils/Constants/RouteNames";
import LoaderUI from "../../../Components/Loader";
import Page from "../../../Components/Page";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getPartnerIndustryList: (id: string, offset, limit) => void;
  resetIndustryData: () => void;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================
function BusinessIndustries(props: IProps) {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      getIndustries(true);
    }, [])
  );

  useEffect(() => {
    return () => props.resetIndustryData();
  }, []);

  function getIndustries(initialOffset) {
    props.getPartnerIndustryList(
      props.route.params.id,
      initialOffset ? 0 : props.businessStore.industries.offset,
      props.businessStore.industries.limit
    );
  }

  function renderContent() {
    if (props.businessStore.isLoading) {
      return <LoaderUI mode="long" />;
    } else if (!props.businessStore.industries.data.length) {
      return (
        <View style={{ alignSelf: "center", margin: "auto" }}>
          <TextUI style={{ textAlign: "center" }} weight="500">
            No Industry found!
          </TextUI>
          <View style={{ height: 10 }} />
          <TextUI style={{ textAlign: "center" }} weight="500">
            Tap on + to create one
          </TextUI>
        </View>
      );
    } else {
      return (
        <FlatList
          items={props.businessStore.industries.data}
          renderItem={(item: IBusinessStore["industries"]["data"][0]) => (
            <View style={{ paddingVertical: 8 }}>
              <View style={styles.listItem}>
                {item.image ? (
                  <ImageUI
                    defaultSource={require("@reachpartner/common/Assets/Images/no-image.png")}
                    source={item.image}
                    style={{ height: 40, width: 40, borderRadius: 4 }}
                  />
                ) : (
                  <View
                    style={{
                      height: 40,
                      width: 40,
                      borderRadius: 4,
                      alignItems: "center",
                    }}
                  >
                    <MaterialCommunityIcons
                      name={"image-outline"}
                      style={{
                        color: "rgba(36, 39, 44, 0.5)",
                        fontSize: 35,
                      }}
                    />
                  </View>
                )}
                <View style={{ width: 10 }} />
                <TextUI weight="500">{item.name}</TextUI>
              </View>
            </View>
          )}
        />
      );
    }
  }
  return (
    <Page>
      <Appbar
        title={"Industries"}
        actions={
          <MaterialCommunityIcons
            name="delete-outline"
            size={20}
            color="red"
            onPress={() =>
              props.navigation.navigate(
                RouteNames.BusinessIndustryOperations.name,
                {
                  industryParam: "delete",
                  id: props.route.params.id,
                }
              )
            }
          />
        }
      />

      <View style={styles.body}>{renderContent()}</View>
      <FabUI
        showOptions={showOptions}
        action={(val) => setShowOptions(val)}
        bottomSpace={30}
        options={
          <>
            <TouchableOpacity
              style={styles.fabActionItem}
              onPress={() => {
                setShowOptions(false);
                props.navigation.navigate(
                  RouteNames.BusinessIndustryOperations.name,
                  {
                    industryParam: "network",
                    id: props.route.params.id,
                  }
                );
              }}
            >
              <TextUI color="white">Add Network Industry</TextUI>
              <View style={{ width: 10 }} />

              <View
                style={{
                  backgroundColor: StyleTheme.APP_COLOR,
                  borderRadius: 100,
                  padding: 2,
                }}
              >
                <MaterialCommunityIcons
                  name={"plus"}
                  color="white"
                  size={20}
                  style={{}}
                />
              </View>
            </TouchableOpacity>
          </>
        }
      />
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    padding: 10,
    flex: 1,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fabActionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: 5,
  },
});
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getPartnerIndustryList: (id: string) =>
      dispatch(getPartnerIndustryList(id)),
    resetIndustryData: () => dispatch(resetIndustryData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessIndustries);
