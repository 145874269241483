import React from "react";
import { connect } from "react-redux";
import { ILocationReqPayload, NavigationProps } from "../Models/interface";
import { IUserStore } from "../Models/StoreInterfaces";
import CreateUserComponent from "./Components";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";
import RootNavigation from "../Navigation/RootNavigation";
import RouteNames from "../Utils/Constants/RouteNames";
import { IHomeStore } from "../Store/Reducers/homeReducer";

interface ICreateFormProps extends NavigationProps {
  user: IUserStore;
  home: IHomeStore;
  createUser: (
    userId: string,
    payload: any,
    callback: (data: any) => void
  ) => void;
  getLocations: (payload: ILocationReqPayload) => void;
}

const CreateUser = (props: ICreateFormProps) => {
  const [isSignedInWithApple, setIsSignedInWithApple] = React.useState(false);
  React.useEffect(() => {
    setInitialData();
  }, []);
  const setInitialData = async () => {
    const signInProvider = await LocalStorage.get("signInProvider");
    setIsSignedInWithApple(signInProvider === "apple.com");
  };
  //call create user api
  const submitForm = async (payload: any) => {
    const userId = await LocalStorage.get("userId");
    props.createUser(userId, payload, async (data) => {
      if (data.fail) {
        return;
      }
      await LocalStorage.delete("tempUserDetails");
      RootNavigation.navigationReset({
        index: 0,
        routes: [{ name: RouteNames.VerifyPhoneNumber.name }],
      });
    });
    return;
  };
  return (
    <CreateUserComponent
      submitForm={submitForm}
      store={{ home: props.home, user: props.user }}
      getLocations={props.getLocations}
      isSignedInWithApple={isSignedInWithApple}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createUser: (userId, payload, callback) =>
      dispatch(Actions.createUser(userId, payload, callback)),
    getLocations: (payload) => dispatch(Actions.getLocations(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
