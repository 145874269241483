import React from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { Card } from "react-native-paper";
import Link from "../../Components/Link";
import EnumMapping from "../../Utils/Constants/EnumMapping";

function BookingDetailCard(props) {
  const { data } = props;
  const {
    bookingStatus = "",
    reason = "",
    reference = "",
    dateString = "",
    date = "",
    duration = "",
    timing = "",
    paymentMode = "",
    payment: { status = "" },
  } = data || {};

  const isTransactionMade = EnumMapping.PaymentStatus[status] !== "Unpaid";
  const isPaid = EnumMapping.PaymentStatus[status] === "Payment Received";

  return (
    <Card style={{ elevation: 4, borderRadius: 2 }}>
      {bookingStatus && <View
        style={{ backgroundColor: EnumMapping.BookingStatusColor[bookingStatus] }}
      >
        <Text style={styles.bookingStatus} > Booking {EnumMapping.BookingStatus[bookingStatus]}</Text>
      </View>}
      <View style={styles.mainWrapper}>
        <View style={styles.contentWrapper}>
          {reason?.length > 0 ? <View>
            <Text style={styles.name}>Reason:</Text>
            <Text style={styles.field}>{reason}</Text>
          </View> : null}
          <View style={styles.content}>
            <Text style={styles.name}>Reference:</Text>
            <Text style={styles.field}>{reference}</Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.name}>Date:</Text>
            <Text style={styles.field}>{date}</Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.name}>Duration:</Text>
            <Text style={styles.field}>{timing}</Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.name}>{""}</Text>
            <Text style={[styles.field, styles.timing]}>{duration}</Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.name}>Payment Status:</Text>
            <Text style={[styles.field, styles.paymentMode]}>
              {isPaid ? "Paid - " : ""}
              {paymentMode}
            </Text>
            {isTransactionMade && (
              <View style={{ marginTop: 7, marginLeft: 15 }}>
                <Link onPress={props.onViewDetails}>View Details</Link>
              </View>
            )}
          </View>
        </View>
        <Card style={styles.dateCard}>
          <Text style={styles.dateCardText}>
            {dateString.replace(/ /g, "\n")}
          </Text>
        </Card>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    padding: 20,
    flexDirection: "row",

    justifyContent: "space-between",
  },
  contentWrapper: {
    flex: 1,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    height: 24,
  },
  name: {
    width: Platform.OS === "ios" ? 110 : 90,
    fontSize: 14,
    fontWeight: "600",
  },
  field: { fontSize: 14, fontWeight: "400" },
  timing: {
    fontWeight: "400",
    opacity: 0.7,
    fontSize: 11,
  },
  sidebar: {
    width: 80,
  },
  paymentMode: {
    backgroundColor: "#F3F5F5",
    paddingHorizontal: 10,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    color: "#FF383E",
    fontSize: 11,
  },
  dateCard: {
    backgroundColor: "#007FFF",
    padding: 10,
    borderRadius: 4,
    elevation: 4,
    height: 62,
  },
  dateCardText: {
    color: "white",
    fontSize: 11,
    fontWeight: "bold",
    textAlign: "center",
  },
  bookingStatus: {
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 40,
    textAlign: "center",
    color: "white"
  },
});
export default BookingDetailCard;
