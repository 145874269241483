import React from "react";
import { View, StyleSheet } from "react-native";
import { StyleTheme } from "../../Styles/CommonStyles";
import ButtonUI from "../../Components/ButtonUI";
import moment from "moment";
import EnumMapping from "../../Utils/Constants/EnumMapping";

interface IProps {
  data: any;
  action: (
    category: string,
    command?: string,
    reason?: string,
    id?: string
  ) => void;
}

export default function BottomActions(props: IProps) {
  const {
    bookingStatus = "",
    itemInfo = [],
    booking = "",
    payment: { status = "" },
  } = props.data || {};
  const isPayButtonDisabled = [2, 4, 5, 6, 7].includes(status)
  const bookingStatusText = EnumMapping.BookingStatus[bookingStatus].toLowerCase()

  function renderConfirmElements(shouldAllowChange: boolean = true) {
    return (
      <View style={styles.bottomActions}>
        {shouldAllowChange && <ButtonUI
          style={{ flex: 1, backgroundColor: "white" }}
          labelStyle={{ color: StyleTheme.DEFAULT_FONT_COLOR }}
          onPress={() => {
            props.action("change");
          }}
          label={"Change"}
        />}
        <View style={styles.gap} />
        <ButtonUI
          style={{ flex: 1 }}
          onPress={() => {
            props.action("pay", "booking", booking, itemInfo[0].employee.id);
          }}
          label={"Pay"}
          disabled={isPayButtonDisabled}
        />
      </View>
    );
  }

  function renderPendingElements() {
    return (
      <View style={styles.bottomActions}>
        <ButtonUI
          style={{ flex: 1, backgroundColor: "#FF3838" }}
          onPress={() => {
            props.action("reject");
          }}
          label={"Reject"}
        />
        <View style={styles.gap} />
        <ButtonUI
          style={{ flex: 1, backgroundColor: "#5AA44B" }}
          onPress={() => {
            props.action("pending", "accept", "", itemInfo[0].employee.id);
          }}
          label={"Accept"}
        />
      </View>
    );
  }

  if (bookingStatusText === "confirmed") {
    return renderConfirmElements();
  } else if (bookingStatusText === "pending") {
    return renderPendingElements();
  } else if (bookingStatusText === "completed" && !isPayButtonDisabled) {
    return renderConfirmElements(false);
  }
  return null
}
const styles = StyleSheet.create({
  bottomActions: {
    height: 75,
    display: "flex",
    flexDirection: "row",
    paddingVertical: 14,
    paddingHorizontal: 20,
    marginTop: 8,
    backgroundColor: "white",
  },
  gap: { width: 8 },
});
