import React from "react";
import TextUI from "../../Components/TextUI";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { StyleTheme } from "../../Styles/CommonStyles";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import LocationSearchInputModel from "../../Components/LocationSearchInputModel";

interface IProps {
  services: Array<any>;
  selectedServices: Array<any>;
  onSelect: (item: any, type: string) => void;
}

export default function SelectedServices(props: IProps) {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      {props.selectedServices.map((item, idx) => (
        <TouchableOpacity
          style={[
            styles.chip,
            {
              backgroundColor: StyleTheme.APP_COLOR,
              maxWidth: "100%",
            },
          ]}
          key={idx}
          onPress={() => props.onSelect(item, "delete")}
        >
          <TextUI
            style={{
              marginRight: 10,
              color: "white",
            }}
            familyType="type-2"
          >
            {item.name}
          </TextUI>
          <MaterialCommunityIcons
            name="delete-outline"
            color="white"
            size={14}
            style={{ fontWeight: "500" }}
          />
        </TouchableOpacity>
      ))}

      <LocationSearchInputModel
        list={props.services}
        onChangeText={(item) => props.onSelect(item, "add")}
        label={"Select Service"}
        placeholder={"Enter customer name"}
        hideInput={true}
        renderItem={(item) => <TextUI>{item.name}</TextUI>}
      >
        <View
          style={[
            styles.chip,
            {
              backgroundColor: "white",
              borderWidth: 1,
              borderColor: StyleTheme.APP_COLOR,
              paddingVertical: 8,
            },
          ]}
        >
          <MaterialCommunityIcons
            name="plus"
            color={StyleTheme.APP_COLOR}
            size={14}
            style={{ fontWeight: "500" }}
          />
          <TextUI
            style={{
              marginLeft: 5,
              color: StyleTheme.APP_COLOR,
            }}
            familyType="type-2"
          >
            Add Service
          </TextUI>
        </View>
      </LocationSearchInputModel>
    </View>
  );
}
const styles = StyleSheet.create({
  chip: {
    paddingHorizontal: 12,
    borderRadius: 20,
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 8,
    marginBottom: 10,
  },
});
