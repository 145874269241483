import React from "react";
import { View, Linking, Text, StyleSheet, Platform } from "react-native";
import ButtonUI from "../Components/ButtonUI";
import ImageUI from "../Components/ImageUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { useNavigationState } from "@react-navigation/native";
import RootNavigation from "../Navigation/RootNavigation";

function SetupBusiness() {
  const stackLength = useNavigationState((state) => state.routes.length);

  if (Platform.OS !== "web") {
    return (
      <>
        <MaterialCommunityIcons
          name={"arrow-left"}
          style={{
            fontSize: 25,
            fontWeight: "900",
            marginTop: 15,
            marginLeft: 10,
          }}
          onPress={() => stackLength > 1 && RootNavigation.goBack()}
        />
        <View style={styles.container}>
          <Text style={styles.title}>
            Business Setup Feature is currently unavailable in iOS Mobile app
          </Text>
          <Text style={styles.description}>
            Business setup feature on iOS Mobile app will be available from
            February 2022. Our iOS Mobile app currently supports business
            owners/employees to create manual appointments, manage appointments
            booked via REACH Booking app, receive notifications, receive reviews
            etc. We are fully committed for Apple iOS development, we regularly
            add features to iOS app and hoping to have all the current features
            (Other OS App) ready by May 2022. In the meantime if you want full
            business software features please visit our download page for
            alternate OS support or contact us to setup your business by the
            customer support team
          </Text>
          <ButtonUI
            label={"View Options"}
            style={{ width: "100%", marginBottom: 15 }}
            onPress={() =>
              Linking.openURL("https://reach.biz/en-gb/contact-us")
            }
          />
        </View>
      </>
    );
  }
  return (
    <>
      <MaterialCommunityIcons
        name={"arrow-left"}
        style={{
          fontSize: 25,
          fontWeight: "900",
          marginTop: 15,
          marginLeft: 10,
        }}
        onPress={() => stackLength > 1 && RootNavigation.goBack()}
      />
      <View style={styles.container}>
        <Text style={styles.title}>
          You are currently using the REACH Business App Web Lite version
        </Text>
        <Text style={styles.description}>
          To explore and unleash the full potential of the product features,
          please download our app
        </Text>
        <ButtonUI
          label={"Download App"}
          style={{ width: "100%", marginBottom: 15 }}
          onPress={() => Linking.openURL("https://reach.biz/en-gb/contact-us")}
        />
        <View
          style={{
            flex: 1,
          }}
        >
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/appointment-calendar.png")}
            resizeMode="contain"
            style={styles.img}
          />
        </View>
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 35,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    fontSize: 22,
    fontWeight: "700",
    marginBottom: "4%",
    lineHeight: 32,
  },
  description: {
    fontSize: 16,
    fontWeight: "500",
    opacity: 0.6,
    marginBottom: 25,
  },
  img: {
    height: "100%",
  },
});
export default SetupBusiness;
