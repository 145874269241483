const privacySettingsList = [
  {
    label: "Acceptance of the Terms and Conditions and the privacy policy",
    key: "term",
  },
  {
    label: "Consent to personal data processing for app usage and offers",
    key: "data",
  },
  {
    label:
      "Consent to use of cookies to personalise content and ads, to analyse traffic and provide you with a safer user experience",
    key: "cookie",
  },
  {
    label: "Consent for electronic communications",
    key: "eComm",
  },
];
const MockData = { privacySettingsList };
export default MockData;
