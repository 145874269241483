import React from "react";
import Appbar from "../Components/Appbar";
import InvitationList from "./Components/InvitationList";
import InvitationDetailModal from "./Components/InvitationModal";
import { Provider as PaperProvider } from "react-native-paper";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { UpdateInviteRequestInterface } from "../Models/ApiInterfaces";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
import { NavigationProps } from "../Models/interface";
import { IInvitationStore } from "../Models";
import LoaderUI from "../Components/Loader";
import {
  CLEAR_INVITATION_DETAILS,
  INVITATION_UPDATE_FLAG,
} from "../Actions/types";
import { MyTheme } from "../App";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";

interface IProps extends NavigationProps {
  invitationStore: IInvitationStore;
  clearInviteDetails: () => void;
  updateInviteDetailsFlag: (flag: boolean) => void;
  getEmployeeInvitationList: (linkedId: string) => void;
  getInviteDetails: (id) => void;
  updateInviteDetails: (id, payload) => void;
}

function InvitationContainer(props: IProps) {
  const [visible, setVisible] = React.useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  useFocusEffect(
    React.useCallback(() => {
      getList();
    }, [])
  );
  const getList = async () => {
    const linkedId = await LocalStorage.get("linkedId");
    props.getEmployeeInvitationList(linkedId);
  };
  useFocusEffect(
    React.useCallback(() => {
      if (props.invitationStore.inviteDetailsUpdated) {
        props.updateInviteDetailsFlag(false);
        props.navigation.reset({
          index: 0,
          routes: [{ name: RouteNames.LandingPage.name }],
        });
      }
    }, [props.invitationStore.inviteDetailsUpdated])
  );
  const handleModal = (type: string, id?: string) => {
    if (type === "close") {
      hideModal();
      props.clearInviteDetails();
    } else if (type === "open" && id) {
      props.getInviteDetails(id);
      showModal();
    } else if (type === "navigateHome") {
      props.navigation.reset({
        index: 0,
        routes: [{ name: RouteNames.LandingPage.name }],
      });
    } else if (type === "accept" || type === "decline") {
      const date = new Date();
      const {
        from: { partner },
        to: { employee },
        invite,
      }: any = props.invitationStore.invitationDetails.data;
      const payload: UpdateInviteRequestInterface = {
        partner: partner,
        employee: employee,
        status: type === "accept" ? 3 : 4,
        updateOn: date.toISOString(),
      };
      props.updateInviteDetails(invite, payload);
      props.navigation.reset({
        index: 0,
        routes: [{ name: RouteNames.Invitation.name }],
      })
    }
  };
  return (
    <PaperProvider theme={MyTheme}>
      <>
        <Appbar
          title="Join a Business"
        // goBack={() => props.navigation.goBack()}
        />
        {props.invitationStore.invitationList.isLoading ? (
          <LoaderUI />
        ) : (
          <InvitationList
            data={props.invitationStore.invitationList.data}
            action={handleModal}
          />
        )}
        <InvitationDetailModal
          visible={visible}
          action={handleModal}
          inviteDetails={props.invitationStore.invitationDetails}
        />
      </>
    </PaperProvider>
  );
}

const mapStateToProps = (store: any) => {
  return {
    invitationStore: store.invitation,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  clearInviteDetails: () => {
    dispatch({ type: CLEAR_INVITATION_DETAILS, payload: "" });
  },
  updateInviteDetailsFlag: (flag: boolean) => {
    dispatch({ type: INVITATION_UPDATE_FLAG, payload: flag });
  },
  getEmployeeInvitationList: (linkedId) =>
    dispatch(Actions.getEmployeeInvitationList(linkedId)),
  getInviteDetails: (id) => dispatch(Actions.getInviteDetails(id)),
  updateInviteDetails: (id, payload) =>
    dispatch(Actions.updateInviteDetails(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationContainer);
