import React from "react";
import { DataTable } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import TextUI from "../../Components/TextUI";
import { List } from "react-native-paper";
import Accordion from "../../Components/Accordion";
import Row from "../../Components/Row";

export default function PaymentDetailTable(props) {
  const {
    itemInfo = [],
    totalCost = "",
    payment = {},

    summary,
  } = props.data || {};

  const symbol = summary?.currency?.symbol || "£";
  const [expanded, setExpanded] = React.useState({
    charges: false,
    taxes: false,
    savings: false,
  });

  const handlePress = (type) => {
    const cloneExpanded = { ...expanded };
    cloneExpanded[type] = !cloneExpanded[type];
    setExpanded(cloneExpanded);
  };

  const taxInfoArr = itemInfo.reduce((acc, item) => {
    let arr = item.cost?.taxInfo || item.cost?.select?.taxInfo || [];
    acc = [...acc, ...arr];
    return acc;
  }, []);

  const chargeInfoArr = itemInfo.reduce((acc, item) => {
    let arr = item.cost?.chargeInfo || [];
    acc = [...acc, ...arr];
    return acc;
  }, []);

  const discountInfoArr = itemInfo.reduce((acc, item) => {
    let arr = item.cost?.discountInfo || [];
    if (Array.isArray(arr)) {
      acc = [...acc, ...arr];
    }
    return acc;
  }, []);

  return (
    <>
      <Accordion>
        <Accordion.Section title={`Total Items (${summary?.count} nos)`} />
        <Accordion.Section
          title="Net Cost"
          right={`${symbol} ${summary?.netAmount.toFixed(2)}`}
        />
        <Accordion.Section
          title="Total Taxes"
          onPress={() => handlePress("taxes")}
          expanded={
            summary?.taxAmount > 0 && taxInfoArr.length
              ? expanded.taxes
              : undefined
          }
          right={`${symbol} ${summary?.taxAmount.toFixed(2)}`}
        >
          {taxInfoArr.map((tax, idx) => (
            <Accordion.Item key={idx}>
              <Row spaceBetween>
                <TextUI size={12}>{tax.name}</TextUI>
                <TextUI size={12}>
                  {symbol} {tax.fare.toFixed(2)}
                </TextUI>
              </Row>
            </Accordion.Item>
          ))}
        </Accordion.Section>
        <Accordion.Section
          title="Gross Total"
          right={`${symbol} ${summary?.grossAmount.toFixed(2)}`}
        />
        {summary?.chargeAmount > 0 && (
          <Accordion.Section
            title="Total Charges"
            onPress={() => handlePress("charges")}
            expanded={
              summary?.chargeAmount > 0 && chargeInfoArr.length || summary && summary.chargeAmountInfo
                ? expanded.charges
                : undefined
            }
            right={`${symbol} ${summary?.chargeAmount.toFixed(2)}`}
          >
            {chargeInfoArr.map((x, idx) => (
              <Accordion.Item key={idx}>
                <Row spaceBetween>
                  <TextUI>{x.name}</TextUI>
                  <TextUI>
                    {symbol} {x.fare.toFixed(2)}
                  </TextUI>
                </Row>
              </Accordion.Item>
            ))}
            {summary && summary.chargeAmountInfo.map(({ name, amount }, idx) => (
              <Accordion.Item key={idx}>
                <Row spaceBetween>
                  <TextUI>{name}</TextUI>
                  <TextUI>
                    {symbol} {amount.toFixed(2)}
                  </TextUI>
                </Row>
              </Accordion.Item>
            ))}
          </Accordion.Section>
        )}
        {summary?.discountAmount > 0 && (
          <Accordion.Section
            title="Total Savings"
            onPress={() => handlePress("savings")}
            expanded={
              summary?.discountAmount > 0 && discountInfoArr.length
                ? expanded.savings
                : undefined
            }
            right={`${symbol} ${summary?.discountAmount.toFixed(2)}`}
          >
            {discountInfoArr.map((x, idx) => (
              <Accordion.Item key={idx}>
                <Row spaceBetween>
                  <TextUI>{x.name}</TextUI>
                  <TextUI>
                    {symbol} {x.fare.toFixed(2)}
                  </TextUI>
                </Row>
              </Accordion.Item>
            ))}
          </Accordion.Section>
        )}
      </Accordion>
      <View style={{ height: 15 }} />
      <Row spaceBetween>
        <TextUI weight="700" size={16}>
          Grand Total (to pay)
        </TextUI>
        <TextUI style={{ color: "green" }} weight="500">
          {`${symbol} ${summary?.grandAmount.toFixed(2)}`}
        </TextUI>
      </Row>
      <View style={{ height: 15 }} />
    </>
    // <List.Section title="Accordions" titleStyle={styles.accordian}>
    //   <List.Accordion
    //     title="Total Taxes"
    //     expanded={expanded.taxes}
    //     onPress={() => handlePress("taxes")}
    //   >
    //     <List.Item title="First item" />
    //     <List.Item title="Second item" />
    //   </List.Accordion>

    //   <List.Accordion
    //     title="Total Charges"
    //     expanded={expanded.charges}
    //     onPress={() => handlePress("charges")}
    //   >
    //     <List.Item title="First item" />
    //     <List.Item title="Second item" />
    //   </List.Accordion>
    //   <List.Accordion
    //     title="Total Savings"
    //     expanded={expanded.savings}
    //     onPress={() => handlePress("savings")}
    //   >
    //     <List.Item title="First item" />
    //     <List.Item title="Second item" />
    //   </List.Accordion>
    // </List.Section>
  );
  // return (
  //   <DataTable>
  //     <DataTable.Header style={styles.header}>
  //       <DataTable.Title style={[styles.col1, styles.headTitle]}>
  //         <TextUI size={12} weight="500">
  //           Total Item ({itemInfo.length})
  //         </TextUI>
  //       </DataTable.Title>
  //       <DataTable.Title style={[styles.col2, styles.headTitle]}>
  //         <TextUI size={12} weight="500">
  //           Cost
  //         </TextUI>
  //       </DataTable.Title>
  //       {/* <DataTable.Title
  //         style={[styles.col3, styles.headTitle, styles.borderLeft]}
  //       >
  //         <TextUI size={12} weight="500">
  //           Tax
  //         </TextUI>
  //       </DataTable.Title>
  //       <DataTable.Title
  //         style={[styles.borderLeft, styles.headTitle, styles.col4]}
  //       >
  //         <TextUI size={12} weight="500">
  //           Discount
  //         </TextUI>
  //       </DataTable.Title> */}
  //       <DataTable.Title
  //         style={[styles.col5, styles.headTitle, styles.borderLeft]}
  //       >
  //         <TextUI size={12} weight="500">
  //           To Pay
  //         </TextUI>
  //       </DataTable.Title>
  //     </DataTable.Header>
  //     {itemInfo.map((item) => {
  //       const {
  //         cost: { actual = "", toPay = "", discountInfo = {} },
  //       } = item;
  //       const { discount = "" } = discountInfo;
  //       return (
  //         <DataTable.Row key={item.name} style={styles.row}>
  //           <DataTable.Cell style={styles.col1}>
  //             <TextUI size={11} weight="500">
  //               {item.name}
  //             </TextUI>
  //           </DataTable.Cell>
  //           <DataTable.Cell numeric style={styles.col2}>
  //             <TextUI size={11} weight="500">
  //               {symbol} {actual}
  //             </TextUI>
  //           </DataTable.Cell>
  //           {/* <DataTable.Cell numeric style={[styles.col3, styles.borderLeft]}>
  //             <TextUI size={12} weight="700" color="rgba(33, 150, 83, 1)">
  //               0 %
  //             </TextUI>
  //           </DataTable.Cell>
  //           <DataTable.Cell numeric style={[styles.col4, styles.borderLeft]}>
  //             <TextUI size={12} weight="700" color="rgba(33, 150, 83, 1)">
  //               {discount === "" ? 0 : discount} %
  //             </TextUI>
  //           </DataTable.Cell> */}
  //           <DataTable.Cell numeric style={[styles.col5, styles.borderLeft]}>
  //             <TextUI size={12} weight="700">
  //               {symbol} {toPay}
  //             </TextUI>
  //           </DataTable.Cell>
  //         </DataTable.Row>
  //       );
  //     })}
  //     <View style={styles.totalRow}>
  //       <TextUI weight="700" size={12}>
  //         Total Amount {payment?.status === 4 ? "paid" : "to pay"}
  //       </TextUI>
  //       <View style={styles.totalValue}>
  //         <TextUI weight="700" size={18} color="rgba(3, 192, 185, 1)">
  //           {symbol} {totalCost}
  //         </TextUI>
  //       </View>
  //     </View>
  //   </DataTable>
  // );
}
const styles = StyleSheet.create({
  accordian: {
    fontWeight: "500",
  },
  accordionItem: {
    opacity: 0.7,
    fontSize: 12,
  },
  col1: { flex: 10 },
  col2: { flex: 3, justifyContent: "center" },
  col3: {
    flex: 3,
    justifyContent: "center",
  },
  col4: { flex: 5, justifyContent: "center" },
  col5: {
    flex: 3,
    justifyContent: "center",
    fontWeight: "700",
  },
  borderLeft: {
    borderLeftColor: "lightgrey",
    borderLeftWidth: 1,
  },
  headTitle: {
    paddingVertical: 0,
    margin: 0,
  },
  header: {
    height: 30,
    paddingLeft: 0,
  },
  headerText: { fontWeight: "500", fontSize: 12 },
  row: {
    paddingLeft: 0,
  },
  totalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    height: 50,
    paddingRight: 25,
  },
  totalLabel: {},
  totalValue: {
    marginLeft: 20,
  },
});
