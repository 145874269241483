import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import environment from "../config";
import localStorage from "../LocalStorage";
import { Platform } from "react-native";

const API_URL = environment.api_base_url;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json, text/plain, */*",
  },
});

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (config.url !== "user/auth") {
    const authInfo: { type: string; token: string } = await localStorage.get(
      "sessionDetail"
    );
    config.headers[
      "Aurlis-Authorisation"
    ] = `${authInfo.type} ${authInfo.token}`;
  }
  if (config.url.includes("media/file/upload")) {
    config.headers["content-Type"] =
      "multipart/form-data; boundary=----WebKitFormBoundaryvBrM3UOBqAAJkwmz";
  }
  if (Platform.OS !== "web") {
    //adding this origin for ios
    config.headers["Origin"] = environment.origin;
  }
  return config;
});

axiosInstance.interceptors.response.use((response: AxiosResponse): any => {
  return response.data;
});

export default axiosInstance;
