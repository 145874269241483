import React, { useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { TextInput } from "react-native-paper";
import { StyleTheme } from "../Styles/CommonStyles";

interface PhoneNumberInputProps {
  value: string;
  onChange: (text: string) => void;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  //   countryCode: string;
  //   setCountryCode: (val: string) => void;
}

const height = 44;
export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const { value = "", onChange, disabled = false, error = false } = props;
  let countryCode = "";
  let phoneNumber = "";
  if (value.includes("+")) {
    countryCode = value.substr(0, 3) || "+44";
    phoneNumber = value.substr(3) || "";
  } else {
    countryCode = "+44";
    phoneNumber = value || "";
  }

  const handleOnchange = (val: string) => {
    if ((val === "" || /^[0-9]+$/.test(val)) && val.length <= 11) {
      onChange(countryCode + val);
    }
  };

  const setCountryCode = (val: string) => {
    const newValue = val + phoneNumber;
    props.onChange(newValue);
  };
  return (
    <View
      style={{
        height: height,
        width: "100%",
        borderColor: error
          ? StyleTheme.ERROR_COLOR
          : StyleTheme.BOX_BORDER_COLOR,
        borderWidth: 2,
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: "14%",
          height: "100%",
          borderRightWidth: 2,
          borderRightColor: StyleTheme.BOX_BORDER_COLOR,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            setCountryCode(countryCode === "+44" ? "+91" : "+44");
          }}
          disabled={disabled}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 18,
              color: disabled ? "grey" : "black",
            }}
          >
            {countryCode}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ width: "85%" }}>
        <TextInput
          placeholder={props.placeholder || "Phone Number"}
          style={{
            paddingVertical: 0,
            height: height - 4,
            backgroundColor: "white",
          }}
          value={phoneNumber}
          underlineColorAndroid="white"
          onChangeText={handleOnchange}
          theme={{
            colors: { primary: "white" },
          }}
          disabled={disabled}
          underlineColor="white"
        />
      </View>
    </View>
  );
}
