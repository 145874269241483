import React from "react";
import ButtonUI from "../../Components/ButtonUI";

export default function LogoutButton(props: { action: () => void }) {
  function signout() {
    props.action();
  }
  return (
    <ButtonUI
      style={{ width: "50%" }}
      onPress={() => signout()}
      label="Logout"
    />
  );
}
