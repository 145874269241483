import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import ButtonUI from "../../Components/ButtonUI";
import TextUI from "../../Components/TextUI";
import Appbar from "../../Components/Appbar";
import DropDownUI from "../../Components/Dropdown";
import TextInputUI from "../../Components/TextInputUI";
import MetaData from ".././Data";
import SelectedServices from "./SelecedServices";
import LocationSearchInputModel from "../../Components/LocationSearchInputModel";
import { StyleTheme } from "../../Styles/CommonStyles";
import { validateEmail } from "../../Utils";
import CalendarInput from "../../Components/CalendarInput";
import PhoneNumberInput from "../../Components/PhoneNumberInput";
import Page from "../../Components/Page";
import { IBasketStore } from "../../Models";
import Actions from "../../Actions";
import { connect } from "react-redux";
import moment from "moment";

interface IProps {
  basketStore: IBasketStore;
  formData: any;
  handleChange: (type: string, value: any) => void;
  deliveryLocations: Array<{ label: string; type: number }>;
  customers: Array<any>;
  services: Array<any>;
  getLocationList: (val: string) => void;
  addressList: Array<string>;
  submit: () => void;
  basketId: string;
  showTravelDistance: boolean;
  closedDays: Array<number>;
}

function NewBaskedComponent(props: IProps) {
  const { formData = {}, deliveryLocations = [], basketId = "" } = props;
  const {
    firstName,
    lastName,
    phNumber,
    email,
    deliveryLocation,
    customerType,
    customerName,
    customerAddress,
    date,
    fromTravelAddress,
    toTravelAddress,
  } = formData;

  const customerTypes = MetaData.CustomerTypes.filter((item) => {
    if (deliveryLocation === 1 && item.value === "walk-in") {
      return false;
    }
    return true;
  });

  const handleService = (item, type) => {
    if (type === "add") {
      const isAlreadyPresent = props.formData.selectedServices.find(
        (d) => d.id === item.id
      );
      if (isAlreadyPresent) {
        return;
      }
      props.handleChange("selectedServices", [
        ...props.formData.selectedServices,
        item,
      ]);
    } else if (type === "delete") {
      const filteredArr = props.formData.selectedServices.filter(
        (d) => d.id !== item.id
      );
      props.handleChange("selectedServices", filteredArr);
    }
  };

  function renderNewCustomerElements() {
    return (
      <>
        <View style={{ height: 10 }} />
        <TextInputUI
          label={"First Name"}
          value={firstName}
          onChangeText={(val) => props.handleChange("firstName", val)}
        />
        <View style={{ height: 10 }} />
        <TextInputUI
          label={"Last Name"}
          value={lastName}
          onChangeText={(val) => props.handleChange("lastName", val)}
        />
        <View style={{ height: 10 }} />
        <PhoneNumberInput
          value={phNumber}
          onChange={(val) => props.handleChange("phNumber", val)}
        />
        {/* <TextInputUI
          label={"Phone Number"}
          value={phNumber}
          onChangeText={(val) => props.handleChange("phNumber", val)}
        /> */}
        <View style={{ height: 10 }} />
        <TextInputUI
          label={"Email Id"}
          value={email}
          onChangeText={(val) => props.handleChange("email", val)}
        />
      </>
    );
  }

  function renderExistingCustomerElements() {
    return (
      <>
        <View style={{ height: 10 }} />
        <DropDownUI
          value={customerName}
          setValue={(value) => props.handleChange("customerName", value)}
          list={props.customers}
          label={"Customer Name"}
          keyName={"name"}
          keyId={"id"}
        />
      </>
    );
  }

  function validate(): boolean {
    const commonFields =
      deliveryLocation !== 0 &&
      customerType !== "" &&
      date !== "" &&
      formData.selectedServices.length;
    const address = deliveryLocation === 1 ? customerAddress !== "" : true;
    if (
      props.showTravelDistance &&
      (fromTravelAddress === "" || toTravelAddress === "")
    ) {
      return false;
    }
    if (customerType === "existing") {
      return customerName !== "" && commonFields && address;
    } else if (customerType === "new") {
      return (
        firstName !== "" &&
        lastName !== "" &&
        (phNumber.length >= 10 || validateEmail(email)) &&
        commonFields &&
        address
      );
    } else if (customerType === "walk-in") {
      return commonFields;
    }

    return false;
  }

  function renderTravelDistance() {
    if (!props.showTravelDistance) {
      return;
    }
    return (
      <>
        <View style={{ height: 20 }} />
        <TextUI style={{ marginBottom: 10, fontWeight: "500" }}>
          Service Travel Distance
        </TextUI>
        <>
          <LocationSearchInputModel
            label="Search Location"
            placeholder="Enter postcode"
            list={props.addressList}
            onChangeText={(item) => {
              props.handleChange("fromTravelAddress", item);
            }}
            renderItem={(item) => <TextUI>{item}</TextUI>}
            getData={props.getLocationList}>
            <>
              <TextInputUI
                label="Pickup address (From)"
                value={fromTravelAddress}
              />
              <View
                style={{
                  position: "absolute",
                  top: 2,
                  bottom: 2,
                  right: 3,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  paddingLeft: 5,
                  paddingRight: 5,
                }}>
                <TextUI
                  style={{
                    fontWeight: "500",
                    color: StyleTheme.APP_COLOR,
                    fontSize: 9,
                  }}>
                  Change
                </TextUI>
              </View>
            </>
          </LocationSearchInputModel>
        </>
        <>
          <View style={{ height: 10 }} />
          <LocationSearchInputModel
            label="Search Location"
            placeholder="Enter postcode"
            list={props.addressList}
            onChangeText={(item) => {
              props.handleChange("toTravelAddress", item);
            }}
            renderItem={(item) => <TextUI>{item}</TextUI>}
            getData={props.getLocationList}>
            <>
              <TextInputUI
                label="Delivery address (To)"
                value={toTravelAddress}
              />
              <View
                style={{
                  position: "absolute",
                  top: 2,
                  bottom: 2,
                  right: 3,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  paddingLeft: 5,
                  paddingRight: 5,
                }}>
                <TextUI
                  style={{
                    fontWeight: "500",
                    color: StyleTheme.APP_COLOR,
                    fontSize: 9,
                  }}>
                  Change
                </TextUI>
              </View>
            </>
          </LocationSearchInputModel>
        </>
      </>
    );
  }

  return (
    <Page>
      <Appbar title={"New Appointment"} />
      <View style={styles.body}>
        <View style={{ flex: 1 }}>
          <ScrollView>
            <TextUI style={{ marginBottom: 10, fontWeight: "500" }}>
              Appointment Details
            </TextUI>
            <DropDownUI
              value={deliveryLocation}
              setValue={(value) =>
                props.handleChange("deliveryLocation", value)
              }
              list={deliveryLocations}
              label={"Select Service Delivery Location"}
              keyName={"label"}
              keyId={"type"}
            />
            <View style={{ height: 10 }} />
            <DropDownUI
              value={customerType}
              setValue={(value) => props.handleChange("customerType", value)}
              list={customerTypes}
              label={"Customer Type"}
            />

            {customerType === "new" ? (
              renderNewCustomerElements()
            ) : customerType === "existing" ? (
              renderExistingCustomerElements()
            ) : (
              <View />
            )}
            {deliveryLocation === 1 && (
              <>
                <View style={{ height: 10 }} />
                <LocationSearchInputModel
                  label="Search Location"
                  placeholder="Enter postcode"
                  list={props.addressList}
                  onChangeText={(item) => {
                    props.handleChange("customerAddress", item);
                  }}
                  renderItem={(item) => <TextUI>{item}</TextUI>}
                  getData={props.getLocationList}>
                  <>
                    <TextInputUI
                      label="Customer Address"
                      value={customerAddress}
                    />
                    <View
                      style={{
                        position: "absolute",
                        top: 2,
                        bottom: 2,
                        right: 3,
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}>
                      <TextUI
                        style={{
                          fontWeight: "500",
                          color: StyleTheme.APP_COLOR,
                          fontSize: 9,
                        }}>
                        Change
                      </TextUI>
                    </View>
                  </>
                </LocationSearchInputModel>
              </>
            )}
            <View style={{ height: 10 }} />
            <CalendarInput
              value={date}
              onChange={(val) => props.handleChange("date", val)}
              disabledDayIndexes={props.closedDays}>
              <TextInputUI
                label="Enter Date"
                value={props.basketStore.basketFormData.date.length ? props.basketStore.basketFormData.date : moment().format("DD-MM-YYYY")}
                onChangeText={(val) => props.handleChange("date", val)}
                placeholder="Ex: 31/08/2021"
              />
            </CalendarInput>
            {renderTravelDistance()}
            <View style={{ height: 20 }} />
            <TextUI weight="500">Select Services</TextUI>
            <View style={{ height: 10 }} />
            <SelectedServices
              services={props.services}
              selectedServices={formData.selectedServices}
              onSelect={handleService}
            />
          </ScrollView>
        </View>
        <View style={{ backgroundColor: "white", paddingTop: 10 }}>
          <ButtonUI
            label={basketId ? "View Basket" : "Create Basket"}
            onPress={props.submit}
            labelStyle={{ color: "white", fontWeight: "400", fontSize: 16 }}
            disabled={!validate()}
          />
        </View>
      </View>
    </Page>
  );
}

const mapStateToProps = (store: any) => {
  return {
    basketStore: store.basket,
  };
};

const styles = StyleSheet.create({
  body: {
    padding: 20,
    paddingBottom: 10,
    display: "flex",
    flex: 1,
  },
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBasket: (basketId, payload, callback) =>
      dispatch(Actions.updateBasket({ basketId, payload, callback })),
    storeBasketFormdata: (payload) =>
      dispatch(Actions.storeBasketFormData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBaskedComponent);
