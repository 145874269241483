import React from "react";
import { connect } from "react-redux";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { Button, Card } from "react-native-paper";
import RBSheet from "react-native-raw-bottom-sheet";
import Appbar from "../Components/Appbar";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import TextUI from "../Components/TextUI";
import ImageUI from "../Components/ImageUI";
import { NavigationProps } from "../Models/interface";

interface IProps extends NavigationProps {}

function ModeSelection(props: IProps) {
  let _RBSheet: any;

  const routeToInvigationPage = () => {
    _RBSheet.close();
    props.navigation.navigate(RouteNames.Invitation.name);
  };
  return (
    <>
      <Appbar title={"Business Register"} hideBack={true} />
      <ScrollView>
        <View style={{ paddingHorizontal: "6%" }}>
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/partner_logo.png")}
            style={styles.image}
          />
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate(RouteNames.SetUpBusiness.name);
            }}
            activeOpacity={0.9}
          >
            <Card elevation={3} style={styles.cardContainer}>
              <ImageUI
                source={require("@reachpartner/common/Assets/Images/setup-business.png")}
                style={styles.cardImage}
                resizeMode="cover"
              />
              <View style={styles.cardContentWrapper}>
                <TextUI size={16} familyType={"type-2"} weight="500">
                  Setup Business
                </TextUI>
                <MaterialCommunityIcons
                  name={"chevron-right"}
                  style={styles.cardIcon}
                  size={25}
                />
              </View>
            </Card>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => _RBSheet.open()} activeOpacity={0.9}>
            <Card elevation={3} style={styles.cardContainer}>
              <ImageUI
                source={require("@reachpartner/common/Assets/Images/join-business.png")}
                style={styles.cardImage}
                resizeMode="cover"
              />
              <View style={styles.cardContentWrapper}>
                <TextUI size={16} familyType={"type-2"} weight="500">
                  Join Business
                </TextUI>
                <MaterialCommunityIcons
                  name={"chevron-right"}
                  style={styles.cardIcon}
                  size={25}
                />
              </View>
            </Card>
          </TouchableOpacity>
          <RBSheet
            ref={(ref) => {
              _RBSheet = ref;
            }}
            height={155}
            openDuration={500}
            customStyles={{
              container: {
                padding: 20,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
              },
              wrapper: {
                width: "100%",
                maxWidth: 500,
                margin: "auto",
              },
            }}
          >
            <MaterialCommunityIcons
              name={"close"}
              style={styles.modalClose}
              size={22}
              onPress={() => _RBSheet.close()}
            />
            <TextUI size={16} familyType={"type-2"} weight="500">
              Join Business
            </TextUI>
            <TextUI
              familyType={"type-2"}
              addOpacity={true}
              weight="500"
              style={{ marginBottom: 20, marginTop: 10 }}
            >
              Do you want to join an existing Reach Network Business as employee
              ?
            </TextUI>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Button
                mode="contained"
                uppercase={false}
                style={{ flex: 1, backgroundColor: "white" }}
                labelStyle={{ color: "black" }}
                onPress={() => _RBSheet.close()}
              >
                Cancel
              </Button>
              <View style={{ width: 30 }} />
              <Button
                mode="contained"
                uppercase={false}
                style={{ flex: 1 }}
                onPress={() => routeToInvigationPage()}
              >
                Okay
              </Button>
            </View>
          </RBSheet>
        </View>
      </ScrollView>
    </>
  );
}
const styles = StyleSheet.create({
  cardContainer: {
    borderRadius: 4,
    marginBottom: 25,
  },
  image: {
    width: 85,
    height: 110,
    marginTop: 25,
    marginBottom: 35,
    display: "flex",
    alignSelf: "center",
  },
  cardImage: {
    width: "100%",
    height: 150,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  cardContentWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontWeight: "700",
  },
  cardIcon: {},
  modalClose: {
    opacity: 0.8,
    position: "absolute",
    right: 15,
    top: 10,
  },
});
const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModeSelection);
