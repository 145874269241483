// ==============================================================
//   This component shows the image with online/offline status
// ==============================================================
import React from "react";
import { View } from "react-native";
import { boxShadow } from "../Styles/CommonStyles";
import ImageUI from "./ImageUI";
import TextUI from "./TextUI";
// * * * * * * * * * * Interface * * * * *
interface IProps {
  image: string;
  status: boolean;
  size?: number;
}
// * * * * * * * * * * Component * * * * *
export default function ImageWithStatus(props: IProps) {
  const size = props.size || 90;
  return (
    <View
      style={{
        borderRadius: 100,
        backgroundColor: "#FFFFFF",
        elevation: 10,
        height: size,
        width: size,
        padding: 15,
        ...boxShadow(),
      }}
    >
      <ImageUI
        source={props.image}
        style={{
          height: size,
          width: size,
          alignSelf: "center",
          zIndex: -1,
          top: 0,
          position: "absolute",
          borderRadius: 100,
        }}
        resizeMode="cover"
      />
      <View
        style={{
          position: "absolute",
          right: 0,
          zIndex: 2,
          top: 3,
          backgroundColor: props.status ? "#5AA44B" : "#FB3E3E",
          borderRadius: 100,
          paddingHorizontal: 7,
          paddingVertical: 2,
        }}
      >
        <TextUI style={{ color: "white", fontWeight: "400", fontSize: 10 }}>
          {props.status ? "Online" : "Offline"}
        </TextUI>
      </View>
    </View>
  );
}
