import React from "react";
import { Text, TextStyle } from "react-native";
interface IProps {
  weight?: "400" | "500" | "700" | "600";
  size?: number;
  children: any;
  style?: TextStyle;
  addOpacity?: boolean;
  familyType?: "type-1" | "type-2";
  color?: string;
  onPress?: () => void;
  numberOfLines?: number;
}
export default function TextUI(props: IProps) {
  const fontFamily = props.familyType
    ? props.familyType === "type-2"
      ? "Rubik"
      : "Roboto"
    : "Roboto";
  const fontWeight = props.weight || "400";
  const fontSize = props.size || 14;
  const color = props.color || "#24272C";
  return (
    <Text
      style={{
        fontFamily,
        fontWeight,
        fontSize,
        color,
        fontStyle: "normal",
        opacity: props.addOpacity ? 0.7 : 1,
        ...(props.style || {}),
      }}
      numberOfLines={props.numberOfLines || 0}

    // onPress={() => props.onPress && props.onPress()}
    >
      {props.children}
    </Text>
  );
}
