import React from "react";
import TextUI from "./TextUI";
import { View } from "react-native";
import ContainerView from "./ContainerView";

const ToastContext: any = React.createContext(null);

export default function Toast(props) {
  const [data, setData] = React.useState("");
  const toast = {
    show(info: { message: string; type: string }) {
      setData(info.message);
      resetData();
    },
    hide() {
      setData("");
    },
  };
  function resetData() {
    setTimeout(() => {
      setData("");
    }, 2000);
  }

  return (
    <ToastContext.Provider value={toast}>
      {props.children}
      {data && data !== "" ? (
        <View
          style={{
            position: "absolute",
            width: "100%",
          }}
        >
          <ContainerView>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  backgroundColor: "rgb(230, 230, 230)",
                  minWidth: 200,
                  maxWidth: "80%",
                  marginBottom: 50,
                  minHeight: 40,
                  paddingVertical: 10,
                  paddingHorizontal: 25,
                  borderRadius: 50,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextUI weight="500">{data}</TextUI>
              </View>
            </View>
          </ContainerView>
        </View>
      ) : (
        <View />
      )}
    </ToastContext.Provider>
  );
}
export const useToast = () => {
  const toast: any = React.useContext(ToastContext);
  if (!toast) {
    throw new Error("useToast must be used within a ToastProvider.");
  }
  return toast;
};
