// ===================================================================
// This component show the business profile where business can modify the data
// ===================================================================
import React, { useEffect, useCallback } from "react";
import { View, TouchableOpacity, ScrollView, StyleSheet } from "react-native";
import Appbar from "../../../Components/Appbar";
import { CommonStyles, StyleTheme } from "../../../Styles/CommonStyles";
import { connect } from "react-redux";
import TextUI from "../../../Components/TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {
  ILocationReqPayload,
  NavigationProps,
} from "../../../Models/interface";
import TextInputUI from "../../../Components/TextInputUI";
import {
  prepareFileUploadPayload,
  validateEmail,
  windowHeight,
} from "../../../Utils";
import Actions from "../../../Actions";
import DropDown from "../../../Components/Dropdown";
import { CountryList } from "../../../Utils/Constants/AppLevelConfig";
import { TextInput } from "react-native-paper";
import LocationSearchInputModel from "../../../Components/LocationSearchInputModel";
import { IStore, IUserStore } from "../../../Models";
import PhoneNumberInput from "../../../Components/PhoneNumberInput";
import TextArea from "../../../Components/TextArea";
import FlatList from "../../../Components/FlatList";
import ButtonUI from "../../../Components/ButtonUI";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import { IHomeStore } from "../../../Store/Reducers/homeReducer";
import { useFocusEffect } from "@react-navigation/native";
import LoaderUI from "../../../Components/Loader";
import AddFieldActionButton from "./Components/AddFieldActionButton";
import Chip from "../../../Components/Chips";
import EnableForBooking from "../../../Components/EnableForBooking";
import { useToast } from "../../../Components/Toast";
import LocalStorage from "../../../LocalStorage";
import SectionLabel from "../../../Components/SectionLabel";
import ImagePickerAvatar from "../../../Components/ImagePickerAvatar";
import BottomActionBar from "../../../Components/BottomActionBar";
import Row from "../../../Components/Row";
import Checkbox from "../../../Components/Checkbox";
// ===================================================================
// interface
// ===================================================================
interface IConnectStateProps {
  businessStore: IBusinessStore;
  home: IHomeStore;
  user: IUserStore;
}
interface IMapDispatch {
  getBusinessProfile: (id: string) => void;
  getLocations: (payload: ILocationReqPayload) => void;
  locationReset: () => void;
  profileImageUpdate: (data: any, callback) => void;
  updateBusinessProfile: (
    id: string,
    payload: IBusinessStore["profile"]["data"],
    callback
  ) => void;
  createPartner: (data: any, callback) => void;

  businessLink: (partnerId: string, data: any, callback) => void;
}
interface IProps extends NavigationProps, IConnectStateProps, IMapDispatch { }
type FormProps = Omit<
  IBusinessStore["profile"]["data"],
  "partner" | "radiusCoverage" | "enableRadiusCoverage"
> & { residential: boolean };
// ===================================================================
// Constants
// ===================================================================
const socialNetworks = {
  1: {
    color: "#1976D2",
    icon: "facebook",
    label: "Facebook",
    type: "facebook.com/",
  },
  2: {
    color: "#F44336",
    icon: "instagram",
    label: "Instagram",
    type: "instagram.com/",
  },
  3: {
    color: "#55ACEE",
    icon: "twitter",
    label: "Twitter",
    type: "twitter.com/",
  },
};
const locations = [
  { name: "Customer Address", id: 1 },
  { name: "Business Address", id: 2 },
];
// ===================================================================
// Component
// ===================================================================

function BusinessIdentity(props: IProps) {
  const profileData = props.businessStore.profile.data;

  const [initialValidate, setInitialValidate] = React.useState(false);
  const [tempImage, setTempImage] = React.useState([]);
  const [formData, setFormData] = React.useState<FormProps>({
    name: "",
    address: "",
    phone: [""],
    email: [""],
    website: [""],
    country: "GBR",
    areaCovered: [],
    postCovered: [],
    aboutUs: "",
    logo: "",
    social: [],
    enableForBooking: false,
    serviceLocation: [],
    readyForBooking: false,
    referral: "",
    publicUrl: "",
    enableOnlinePayment: false,
    residential: false
  });
  const [socialNetworkSheetOpen, setSocialNetworkSheetOpen] =
    React.useState<boolean>(false);
  const toaster = useToast();
  const partnerId = props.route.params.id;
  const isAddBusiness = partnerId && partnerId === "new";
  //use Effects
  useEffect(() => {
    if (!isAddBusiness) {
      props.getBusinessProfile(partnerId);
    } else {
      const { email, phone } = props.user.employeeInfo;
      handleChange("phone", [phone]);
      handleChange("email", [email]);
    }
  }, []);
  useFocusEffect(
    useCallback(() => {
      const profile = props.businessStore.profile;
      if (!isAddBusiness && profile.data.partner) {
        setProfileData();
      }
    }, [profileData.partner])
  );
  //Functions
  const setProfileData = () => {
    const newFormData = { ...formData };
    Object.keys(formData).map((item) => {
      newFormData[item] = profileData[item];
    });
    setFormData(newFormData);
  };

  const hasInputError = (inputName: string, value: string) => {
    if (!initialValidate) {
      return false;
    }
    return hasError(inputName, value);
  };
  const hasError = (inputName: string, value: string) => {
    if (inputName === "email") {
      return !validateEmail(value);
    } else if (inputName === "phone") {
      return value.length < 13;
    } else if (inputName === "website") {
      const regex = /(http(s)?(:)?)?(\/\/)?(www\.)?(.+)\.(.+)/gm;
      return !regex.test(value.toLowerCase());
    }
    return value === "";
  };
  function handleChange(type: string, value: any) {
    setFormData((d: any) => ({
      ...d,
      [type]: value,
    }));
  }
  const handleUpload = async (images: any[]) => {
    setTempImage(images);
    if (!images.length) {
      return;
    }
  };

  const handleValidation = () => {
    setInitialValidate(true);
    const validations = [
      hasError("name", formData.name),
      hasError("address", formData.address),
      hasError("phone", formData.phone[0]),
      hasError("email", formData.email[0]),
      hasError("website", formData.website[0]),
    ];
    const isAllValid = validations.every((d) => !d);
    if (!isAllValid) {
      return false;
    }
    if (isAddBusiness) {
      // For add business return from here
      return true;
    }
    // Do the below steps only in update case
    if (!formData.serviceLocation.length) {
      toaster.show({ message: "Select services" });
      return false;
    }
    if (formData.serviceLocation.includes(1) && !formData.areaCovered.length) {
      toaster.show({ message: "Add areas" });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) {
      return;
    }
    if (isAddBusiness) {
      createPartner();
      return;
    }
    if (!tempImage.length) {
      submitData();
      return;
    }
    const result = await prepareFileUploadPayload(tempImage[0].image);
    props.profileImageUpdate(result, (res) => {
      if (!res.fail) {
        handleChange("picture", res.url);
        setTempImage([]);
        submitData(res.url);
      }
    });
  };
  const submitData = (newImageUrl?: string) => {
    const data = { ...profileData, ...formData };
    if (newImageUrl) {
      data.logo = newImageUrl;
    }
    props.updateBusinessProfile(props.route.params.id, data, (resp) => {
      if (resp && !resp.fail) {
        props.navigation.pop();
      } else {
        toaster.show({ message: resp.mesg, type: "error" });
      }
    });
  };
  const createPartner = () => {
    const data = {
      name: formData.name,
      address: formData.address,
      phone: formData.phone,
      email: formData.email,
      website: formData.website,
      country: "GBR",
      logo: formData.logo,
      referral: "",
      residential: formData.residential
    };

    props.createPartner(data, (resp) => {
      if (resp && !resp.fail) {
        businessLink(resp);
      } else {
        // toaster.show({ message: resp.mesg, type: "error" });
      }
    });
  };

  const businessLink = async (response) => {
    const data = {
      op: "add",
      path: "employee",
      id: "",
    };
    data.id = await LocalStorage.get("linkedId");
    props.businessLink(response.partner, data, (resp) => {
      if (resp && !resp.fail) {
        props.navigation.pop();
      } else {
        toaster.show({ message: resp?.mesg || "Error", type: "error" });
      }
    });
  };

  const showToastForBooking = () => {
    toaster.show({
      message:
        "Business is not fully setup to go online, check additional information screen for setup status!",
      type: "info",
    });
  };

  //render functions
  const renderPhoneNumbers = () => {
    return (
      <>
        {formData.phone.map((item: string, idx: number) => (
          <View key={"phonenumber" + idx}>
            <PhoneNumberInput
              value={item}
              onChange={(text) => {
                let phone = [...formData.phone];
                phone[idx] = text;
                handleChange("phone", phone);
              }}
              error={hasInputError("phone", item)}
            />
            {idx !== 0 && (
              <DeleteIcon
                action={() => {
                  const phone = formData.phone;
                  phone.splice(idx, 1);
                  handleChange("phone", phone);
                }}
              />
            )}
            <View style={{ height: 15 }} />
          </View>
        ))}
      </>
    );
  };
  const renderLocation = () => {
    return (
      <>
        <LocationSearchInputModel
          list={props.home.locationSearchItems.data}
          loading={props.home.locationSearchItems.isLoading}
          noDataMessage={props.home.locationSearchItems.errorMessage}
          onChangeText={(text) => handleChange("address", text)}
          getData={(val) =>
            props.getLocations({ locData: val, locType: "postcode" })
          }
          label="Search Location"
          placeholder={`Enter full postcode. Ex: "EC1V 2NX"`}
          onDismiss={() => {
            props.locationReset();
          }}
          renderItem={(item) => (
            <>
              <MaterialCommunityIcons
                name={"map-marker"}
                style={{ width: 20, opacity: 0.5 }}
                size={14}
              />
              <View style={{ width: 10 }} />
              <TextUI familyType="type-2" weight="500">
                {item}
              </TextUI>
            </>
          )}
        >
          <TextInputUI
            label="Business Address"
            value={formData.address}
            error={hasInputError("address", formData.address)}
            errorHelpText={"Business address shouldn't be empty!"}
          />
        </LocationSearchInputModel>
        <View style={{ height: 15 }} />
        <View style={{ marginBottom: 16 }}>
          <Row>
            <Checkbox
              active={formData.residential}
              onPress={() => {
                handleChange(
                  "residential",
                  !formData.residential
                );
              }}
            />
            <TextUI weight="700" style={{ marginLeft: 8 }}>Residential Address</TextUI>
          </Row>
        </View>
      </>
    );
  };
  const renderEmails = () => {
    return (
      <>
        {formData.email.map((item: string, idx: number) => (
          <View key={"email" + idx}>
            <TextInputUI
              label="Email address"
              value={item}
              error={hasInputError("email", item)}
              onChangeText={(text) => {
                const businessEmail = [...formData.email];
                businessEmail[idx] = text;
                handleChange("email", businessEmail);
              }}
              errorHelpText={"Enter a valid email"}
            />
            {idx !== 0 && (
              <DeleteIcon
                action={() => {
                  const businessEmail = formData.email;
                  businessEmail.splice(idx, 1);
                  handleChange("email", businessEmail);
                }}
              />
            )}
            <View style={{ height: 15 }} />
          </View>
        ))}
      </>
    );
  };
  const renderWebsites = () => {
    return (
      <>
        {formData.website.map((item: string, idx: number) => (
          <View key={"website" + idx}>
            <TextInputUI
              label="Business website"
              value={item}
              error={hasInputError("website", item)}
              onChangeText={(text) => {
                const website = [...formData.website];
                website[idx] = text;
                handleChange("website", website);
              }}
              errorHelpText={"Enter valid website address!"}
            />
            {idx !== 0 && (
              <DeleteIcon
                action={() => {
                  const website = formData.website;
                  website.splice(idx, 1);
                  handleChange("website", website);
                }}
              />
            )}
            <View style={{ height: 15 }} />
          </View>
        ))}
        {/* <AddFieldActionButton
          action={() => {
            handleChange("website", [...formData.website, ""]);
          }}
          label={"Add Website"}
        />
        <View style={{ height: 15 }} /> */}
      </>
    );
  };
  const renderServices = () => {
    const selectedServices = locations
      .filter(({ id }) => formData.serviceLocation.includes(id))
      .map((x) => x.id);

    return (
      <>
        <TextUI weight="500" size={16}>
          Where do you offer the services ?
        </TextUI>
        <View style={{ height: 15 }} />
        <Chip>
          {locations.map((item, idx) => {
            if (selectedServices.includes(item.id)) {
              return (
                <Chip.Selected
                  key={"selected" + idx}
                  action={() => {
                    handleChange("serviceLocation", [
                      ...formData.serviceLocation.filter((i) => i !== item.id),
                    ]);
                  }}
                  hideIcon={true}
                >
                  {item.name}
                </Chip.Selected>
              );
            }
            return (
              <Chip.Item
                key={"item" + idx}
                action={() => {
                  handleChange("serviceLocation", [
                    ...formData.serviceLocation,
                    item.id,
                  ]);
                }}
                hideIcon={true}
              >
                {item.name}
              </Chip.Item>
            );
          })}
        </Chip>
      </>
    );
  };
  const renderAreas = () => {
    const handleArea = (params: string, type?: "delete") => {
      if (type === "delete") {
        const index = formData.areaCovered.findIndex((i) => i === params);
        const newAreaCovered = formData.areaCovered.filter(
          (item, i) => i !== index
        );
        const newPostCovered = formData.postCovered.filter(
          (item, i) => i !== index
        );
        handleChange("areaCovered", newAreaCovered);
        handleChange("postCovered", newPostCovered);
      } else {
        const areasData = props.home.locationSearchItems.data;
        const postCode: any =
          areasData.find((i: any) => i.name === params) || {};
        handleChange("areaCovered", [...formData.areaCovered, params]);
        handleChange("postCovered", [
          ...formData.postCovered,
          postCode.postOutCode || "",
        ]);
      }
    };

    return (
      <>
        {formData.serviceLocation.includes(1) && (
          <>
            <TextUI weight="500" size={16}>
              Which areas do you cover ?
            </TextUI>
            <View style={{ height: 15 }} />
            <Chip>
              {formData.areaCovered.map((item, idx) => (
                <Chip.Selected
                  action={() => handleArea(item, "delete")}
                  key={idx}
                >
                  {item}
                </Chip.Selected>
              ))}
            </Chip>
            <LocationSearchInputModel
              list={props.home.locationSearchItems.data.map(
                (i: any) => i.name || ""
              )}
              loading={props.home.locationSearchItems.isLoading}
              noDataMessage={props.home.locationSearchItems.errorMessage}
              onChangeText={(text: any) => handleArea(text)}
              getData={(val) =>
                props.getLocations({
                  locData: val,
                  locType: "area",
                  itemType: "area",
                })
              }
              label="Select Area"
              variant="AREA"
              placeholder={`Enter Area. Ex:"Sutton, Kent"`}
              onDismiss={() => {
                props.locationReset();
              }}
              renderItem={(item) => (
                <>
                  <MaterialCommunityIcons
                    name={"map-marker"}
                    style={{ width: 20, opacity: 0.5 }}
                    size={14}
                  />
                  <View style={{ width: 10 }} />
                  <TextUI familyType="type-2" weight="500">
                    {item}
                  </TextUI>
                </>
              )}
            >
              <TouchableOpacity
                style={{
                  backgroundColor: "white",
                  marginRight: 10,
                  borderRadius: 20,
                  borderColor: StyleTheme.APP_COLOR,
                  borderWidth: 1,
                  padding: 10,
                  paddingHorizontal: 15,
                  marginBottom: 10,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  width: 115,
                }}
                onPress={() => {
                  // props.action(item);
                }}
                activeOpacity={0.7}
              >
                <MaterialCommunityIcons
                  name={"plus"}
                  style={{
                    color: StyleTheme.APP_COLOR,
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                <TextUI weight="500" color={StyleTheme.APP_COLOR}>
                  {"Add More"}
                </TextUI>
              </TouchableOpacity>
            </LocationSearchInputModel>
          </>
        )}
      </>
    );
  };
  const renderSocialNetworks = () => {
    return (
      <>
        <View style={[CommonStyles.rowSpaceBetween]}>
          <TextUI weight="500" size={16}>
            Social Media
          </TextUI>
          <AddFieldActionButton
            label="Add Social Media"
            action={() => setSocialNetworkSheetOpen(true)}
          />
        </View>
        <View style={{ height: 15 }} />
        <FlatList
          items={formData.social}
          renderItem={(item) => {
            const label = item.url
              ? item.url.split(socialNetworks[item.type].type)[1] ||
              socialNetworks[item.type].label
              : socialNetworks[item.type].label;
            return (
              <View
                key={item.url}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingVertical: 12,
                }}
              >
                <MaterialCommunityIcons
                  name={socialNetworks[item.type].icon}
                  size={18}
                  color={socialNetworks[item.type].color}
                  style={{ marginRight: 15 }}
                />
                <TextUI weight="500">{label}</TextUI>
              </View>
            );
          }}
        />
        <View style={{ height: 15 }} />
      </>
    );
  };
  return (
    <View style={{ height: windowHeight }}>
      <Appbar
        title={isAddBusiness ? "Create Business" : "Business Information"}
      />
      <View style={{ padding: 10, flex: 1 }}>
        <ScrollView>
          {!isAddBusiness && (
            <ImagePickerAvatar
              action={handleUpload}
              source={
                tempImage.length > 0 ? tempImage[0].display : formData.logo
              }
            />
          )}
          <View style={{ height: 20 }} />
          <SectionLabel>Upload your Business details</SectionLabel>
          <View style={{ height: 20 }} />
          <TextInputUI
            label="Business Name"
            value={formData.name}
            error={hasInputError("businessName", formData.name)}
            onChangeText={(text) => handleChange(`name`, text)}
            errorHelpText={"Business name shouldn't be empty!"}
          />
          <View style={{ height: 15 }} />
          <DropDown
            label={"Country"}
            value={formData.country}
            setValue={(val) => handleChange("country", val)}
            list={CountryList}
            inputProps={{
              right: <TextInput.Icon name={"menu-down"} />,
            }}
            disabled={true}
          />
          <View style={{ height: 15 }} />
          {renderLocation()}
          {renderPhoneNumbers()}
          {renderEmails()}
          {renderWebsites()}
          {!isAddBusiness && (
            <>
              {renderServices()}
              {renderAreas()}
              <SectionLabel>About Us</SectionLabel>
              <View style={{ height: 10 }} />
              <TextArea
                label="Add description"
                onChangeText={(val) => handleChange("aboutUs", val)}
                value={formData.aboutUs}
              />
              <View style={{ height: 15 }} />
              <SectionLabel>Customer Booking Link</SectionLabel>
              <View style={{ height: 10 }} />
              <TextUI>{formData.publicUrl}</TextUI>
              <View style={{ height: 15 }} />
              <SectionLabel>Referral code</SectionLabel>
              <View style={{ height: 10 }} />
              <TextInputUI
                value={formData.referral}
                label={"Referral (optional)"}
                onChangeText={(val) => handleChange("referral", val)}
              />
              <View style={{ height: 15 }} />
              {/* {renderSocialNetworks()} */}
              <EnableForBooking
                disable={!profileData.readyForBooking}
                active={formData.enableForBooking}
                handleChange={(val) => handleChange("enableForBooking", val)}
                disabledAction={showToastForBooking}
              />
              <View style={{ height: 15 }} />
              <EnableForBooking
                disable={!profileData.readyForBooking}
                label={"Enable Online Payment"}
                active={formData.enableOnlinePayment}
                handleChange={(val) => handleChange("enableOnlinePayment", val)}
                disabledAction={showToastForBooking}
              />
              <View style={{ height: 20 }} />
            </>
          )}
        </ScrollView>
      </View>
      <BottomActionBar>
        <ButtonUI
          style={{ flex: 1 }}
          onPress={handleSubmit}
          label={isAddBusiness ? "Create Business" : "Update"}
        />
      </BottomActionBar>
      {props.businessStore.profile.isUpdading && <LoaderUI mode="long" />}
    </View>
  );
}
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (store: IStore): IConnectStateProps => {
  return {
    home: store.home,
    businessStore: store.business,
    user: store.user,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getBusinessProfile: (id: string) =>
      dispatch(Actions.getBusinessProfile(id)),
    getLocations: (val: ILocationReqPayload) =>
      dispatch(Actions.getLocations(val)),
    locationReset: () => dispatch(Actions.locationReset()),
    profileImageUpdate: (data: any, callback) =>
      dispatch(Actions.profileImageUpdate(data, callback)),
    updateBusinessProfile: (
      id: string,
      payload: IBusinessStore["profile"]["data"],
      callback: void
    ) => dispatch(Actions.updateBusinessProfile(id, payload, callback)),
    createPartner: (data, callback) =>
      dispatch(Actions.createPartner(data, callback)),
    businessLink: (partnerId, data, callback) =>
      dispatch(Actions.businessLink(partnerId, data, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessIdentity);
// ===================================================================
// Styles
// ===================================================================
// ===================================================================
// Reuse components
// ===================================================================

function DeleteIcon(props: { action: () => void }) {
  return (
    <TouchableOpacity
      onPress={() => {
        props.action();
      }}
      style={{
        position: "absolute",
        right: 10,
        top: 15,
      }}
    >
      <MaterialCommunityIcons
        name={"delete-outline"}
        style={{
          width: 20,
          opacity: 0.5,
        }}
        size={18}
      />
    </TouchableOpacity>
  );
}
