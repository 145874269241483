import React, { useState, useCallback, useRef } from "react";
import { useFocusEffect } from "@react-navigation/native";
import Actions from "../Actions";
import { connect } from "react-redux";
import { IBasketStore } from "../Models/StoreInterfaces";
import { NavigationProps } from "../Models/interface";
import BasketCheckoutComponent from "./Components";
import { formatBasketResponse } from "../Utils";
import LoaderUI from "../Components/Loader";
import RouteNames from "../Utils/Constants/RouteNames";
import { View } from "react-native";
import TextUI from "../Components/TextUI";
import ButtonUI from "../Components/ButtonUI";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import Modal from "../Components/Modal";
import { IBasketOperation } from "../Models/Appointments";

export interface IRecurringState {
  enabled: boolean;
  frequency: { type: number; count: number };
  start: string;
  end: {
    type: number;
    count: number;
    date: string;
  };
}
interface IProps extends NavigationProps {
  business: IBusinessStore;
  basketStore: IBasketStore;
  getBasket: (basketId: string, callback: (data: any) => void) => void;
  getStaffList: (linkedId: string) => void;
  updateBasket: (
    basketId: string,
    payloadLany,
    callback: (data: any) => void
  ) => void;
}
function BasketCheckout(props: IProps) {
  const [data, setData] = useState({});
  const [timer, setTimer] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [recurringState, setRecurringState] = useState<IRecurringState>({
    enabled: false,
    frequency: { type: 1, count: 1 },
    start: "",
    end: {
      type: 1,
      count: 1,
      date: props.basketStore.basketDetails.data.date,
    },
  });
  const { validity = "", basket = "" } = props.basketStore.basketDetails.data;
  const basketWatchRef = useRef(true);
  const partnerId = props.route.params.pid || "";
  //get initialdata
  useFocusEffect(
    useCallback(() => {
      getInitialData();
      return () => {
        if (basketWatchRef.current) {
          const payload = [createObj("state", "basket")];
          props.updateBasket(partnerId, payload, () => {});
        }
      };
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (validity && basket) {
        const d = formatBasketResponse(props.basketStore.basketDetails.data);
        setData(d);
      }
    }, [validity])
  );

  //initial api's
  const getInitialData = async () => {
    props.getBasket(partnerId, (data) => {
      if (!data.fail) {
      }
    });
    const payload = [createObj("state", "checkout")];
    props.updateBasket(partnerId, payload, () => {
      setTimer(180);
    });
  };

  const createObj = (category: string, command: string, ...rest) => {
    const [p1 = "", p2 = "", p3 = "", p4 = "", p5 = "", p6 = ""] = rest || [];
    return {
      category: category,
      command: command || "",
      param1: p1,
      param2: p2,
      param3: p3,
      param4: p4,
      param5: p5,
      param6: p6,
    };
  };

  function handleRecurringState(keys, value) {
    const data = { ...recurringState };
    if (typeof keys === "object") {
      function setIn(obj = {}, [prop, ...rest]: any, value) {
        obj[prop] = rest.length ? setIn(obj[prop], rest, value) : value;
        return obj;
      }
      setIn(data, keys, value);
    }
    setRecurringState(data);
  }

  function getRecurringStateCommands(): IBasketOperation {
    const params = {
      param1: recurringState.enabled,
      param2: recurringState.frequency.type,
      param3: recurringState.frequency.count,
      param4: recurringState.start,
      param5: recurringState.end.type,
      param6:
        recurringState.end.type === 1
          ? recurringState.end.count
          : recurringState.end.type === 2
          ? recurringState.end.date
          : "",
    };
    Object.keys(params).map((key) => (params[key] = params[key].toString()));
    return {
      category: "recurrent",
      command: "config",
      ...params,
    };
  }

  function handleSubmit(type) {
    let command: IBasketOperation[] = [];
    command.push(getRecurringStateCommands());
    if (type === "pay") {
      command.push(createObj("state", "purchase"));
    } else if (type === "reserve") {
      command.push(createObj("state", "reserve"));
    }
    if (!command) {
      return;
    }
    props.updateBasket(partnerId, command, (data) => {
      if (!data.fail) {
        basketWatchRef.current = false;
        props.navigation.navigate(RouteNames.Booking.name, {
          bid: data.booking,
        });
      }
    });
  }

  return (
    <>
      <BasketCheckoutComponent
        data={data}
        handleSubmit={handleSubmit}
        timer={timer}
        timerRunOut={() => {
          setModalVisible(true);
        }}
        recurringState={recurringState}
        handleRecurringState={handleRecurringState}
      />
      {props.basketStore.basketDetails.isSubmitting && <LoaderUI mode="long" />}
      <Modal
        visible={modalVisible}
        contentContainerStyle={{
          padding: 15,
          width: 300,
        }}
      >
        <View>
          <TextUI
            style={{
              marginBottom: 20,
              fontWeight: "500",
              lineHeight: 22,
              opacity: 0.9,
              fontSize: 14,
            }}
          >
            Checkout timer has run out, you will be automatically returned to
            the basket now
          </TextUI>
          <ButtonUI
            label={"Okay"}
            onPress={() => {
              setModalVisible(false);
              props.navigation.pop();
            }}
          />
        </View>
      </Modal>
    </>
  );
}

const mapStateToProps = (store: any) => {
  return {
    business: store.business,
    basketStore: store.basket,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getBasket: (basketId, callback) =>
      dispatch(Actions.getBasket({ basketId, callback })),
    getStaffList: (linkedId) => dispatch(Actions.getStaffList({ linkedId })),
    updateBasket: (basketId, payload, callback) =>
      dispatch(Actions.updateBasket({ basketId, payload, callback })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasketCheckout);
