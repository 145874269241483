// ===================================================================
// THIS COMPONENT IS FOR THE SELECT INDUSTRY/SERVICES
// ===================================================================
import React, { useState } from "react";
import { View } from "react-native";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import { CommonStyles, StyleTheme } from "../../../Styles/CommonStyles";
import TextUI from "../../../Components/TextUI";
import ButtonUI from "../../../Components/ButtonUI";
import Checkbox from "../../../Components/Checkbox";
import Appbar from "../../../Components/Appbar";
import Page from "../../../Components/Page";
import InfiniteList from "../../../Components/InfiniteList";
import LoaderUI from "../../../Components/Loader";
// ===================================================================
// INTERFACE
// ===================================================================

interface IProps {
  deleteEmployee: (id, payload, callback) => void;
  businessStore: IBusinessStore;
  navigationPop: () => void;
  partnerId: string;
  getPartnerServiceList: (id: string, offset: number, limit: number) => void;
}
// ===================================================================
// COMPONENT
// ===================================================================
function DeleteService(props: IProps) {
  const [selectedNetworks, setSelectedNetworks] = useState<any>([]);

  const data = props.businessStore.employees.data;

  function deleteEmployees(index?: number) {
    if (index < selectedNetworks.length) {
      const callback = (response) => {
        if (!response.fail) {
          deleteEmployees(index + 1);
        }
      };
      const payload: any = {
        op: "remove",
        path: "employee",
        id: selectedNetworks[index].id,
      };
      props.deleteEmployee(props.partnerId, payload, callback);
    } else {
      // after all the updates are done, go back
      props.navigationPop();
    }
  }

  function getEmployees(initialOffset?: boolean) {
    props.getPartnerServiceList(
      props.partnerId,
      initialOffset ? 0 : props.businessStore.employees.offset,
      props.businessStore.employees.limit
    );
  }

  function renderContent() {
    return (
      <InfiniteList
        data={props.businessStore.employees.data}
        renderItem={(item: IBusinessStore["employees"]["data"][0]) => {
          const isSelected = selectedNetworks.some(({ id }) => id === item.id);
          return (
            <View
              style={{
                ...CommonStyles.rowSpaceBetween,
                flex: 1,
                paddingVertical: 8,
                paddingHorizontal: 20,
              }}
            >
              <View style={{ flex: 1 }}>
                <TextUI weight="500">
                  {item.first} {item.last}
                </TextUI>
              </View>
              <View>
                <Checkbox
                  active={isSelected}
                  onPress={() => {
                    if (isSelected) {
                      setSelectedNetworks(
                        selectedNetworks.filter((x) => x.id !== item.id)
                      );
                    } else {
                      setSelectedNetworks([...selectedNetworks, item]);
                    }
                  }}
                />
              </View>
            </View>
          );
        }}
        options={{
          total: props.businessStore.employees.total,
          offset: props.businessStore.employees.offset,
          isLoading: props.businessStore.isLoading,
        }}
        getData={() => getEmployees()}
      />
    );
  }

  function renderBottomSelectionBar() {
    return (
      <View
        style={{
          height: 60,
          paddingHorizontal: 20,
          ...CommonStyles.rowSpaceBetween,
        }}
      >
        <ButtonUI
          label="Delete"
          onPress={() => deleteEmployees(0)}
          style={{ height: 45, width: "100%" }}
        />
      </View>
    );
  }

  return (
    <Page>
      <Appbar title={`Delete Employees`} />
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <View
          style={{
            ...CommonStyles.rowSpaceBetween,
            paddingHorizontal: 20,
            paddingVertical: 10,
            borderBottomColor: StyleTheme.BOX_BORDER_COLOR,
            borderBottomWidth: 2,
          }}
        >
          <TextUI weight="700">Select All</TextUI>
          <Checkbox
            active={data.length === selectedNetworks.length}
            onPress={() => {
              if (data.length === selectedNetworks.length) {
                setSelectedNetworks([]);
              } else {
                setSelectedNetworks(data);
              }
            }}
          />
        </View>
        {renderContent()}
        {selectedNetworks.length >= 1 && renderBottomSelectionBar()}
      </View>
      {props.businessStore.isUpdating && <LoaderUI mode="long" />}
    </Page>
  );
}
export default DeleteService;
