import { IAction } from "../Models/interface";
import {
  INVITATIONS_LIST,
  INVITATION_DETAIL,
  INVITATION_UPDATE,
} from "./types";

const getEmployeeInvitationList = (linkedId): IAction => {
  return {
    type: INVITATIONS_LIST,
    isApi: true,
    payload: {
      method: "GET",
      url: `employee/${linkedId}/invite?offset=0&limit=5`,
      data: {},
    },
  };
};
const getInviteDetails = (inviteId): IAction => {
  return {
    type: INVITATION_DETAIL,
    isApi: true,
    payload: {
      method: "GET",
      url: `invite/${inviteId}`,
      data: {},
    },
  };
};
const updateInviteDetails = (inviteId, payload): IAction => {
  return {
    type: INVITATION_UPDATE,
    isApi: true,
    payload: {
      method: "PUT",
      url: `invite/${inviteId}`,
      data: payload,
    },
  };
};
export { getEmployeeInvitationList, getInviteDetails, updateInviteDetails };
