import React from "react";
import { View } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { CommonStyles, StyleTheme } from "../Styles/CommonStyles";

// ===================================================================
// COMPONENT
// ===================================================================
function ActiveIndicatorCheck() {
  return (
    <>
      <View
        style={[
          CommonStyles.rowSpaceBetween,
          {
            position: "absolute",
            top: 0,
            left: 25,
            width: 17,
            height: 17,
            zIndex: 10,
            borderRadius: 30,
            backgroundColor: StyleTheme.APP_COLOR,
            justifyContent: "center",
          },
        ]}
      >
        <MaterialCommunityIcons
          name={"check"}
          style={{
            color: "white",
            fontSize: 12,
          }}
        />
      </View>
    </>
  );
}
export default ActiveIndicatorCheck;
