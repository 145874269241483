import React from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";
import BottomSheet from "../../../../Components/BottomSheet";
import TextUI from "../../../../Components/TextUI";

interface IProps {
  active: boolean;
  onDismiss: () => void;
  onSelect: (val: any) => void;
  list: any[];
  title: string;
}

export default function WindowSelectPopup(props: IProps) {
  return (
    <BottomSheet isOpen={props.active} onDismiss={props.onDismiss} height={450}>
      <View>
        <TextUI weight="700" size={16} style={{ marginRight: 15 }}>
          {props.title}
        </TextUI>
        <View style={{ height: 15 }} />
        <View style={{ height: 400 }}>
          <ScrollView>
            {props.list.map((item, idx) => {
              return (
                <TouchableOpacity
                  style={{
                    paddingVertical: 13,
                    borderTopWidth: idx == 0 ? 0 : 1,
                    borderColor: "#d8d4d4",
                  }}
                  onPress={() => props.onSelect(item.value)}
                  key={idx}
                >
                  <TextUI weight="700">{item.label}</TextUI>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </View>
    </BottomSheet>
  );
}
