import React, { useState, useCallback } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { connect } from "react-redux";
import Appbar from "../Components/Appbar";
import { NavigationProps } from "../Models/interface";
import { StyleTheme } from "../Styles/CommonStyles";
import ButtonUI from "../Components/ButtonUI";
import TextUI from "../Components/TextUI";
import RouteNames from "../Utils/Constants/RouteNames";
import LocalStorage from "../LocalStorage";
import MockData from "./Data";
import LoaderUI from "../Components/Loader";
import Actions from "../Actions";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import Checkbox from "../Components/Checkbox";

interface IProps extends NavigationProps {
  business: IBusinessStore;
  getPrivacySettings: (linkedId: string) => void;
  privacySettingsUpdate: (linkedId, payload) => void;
}

function PrivacySettings(props: IProps) {
  const isFocused = useIsFocused();
  const [privacyData, setPrivacyData] = useState(
    props.business.privacySettings.data
  );
  async function privacySettingsUpdate() {
    const payload = { privacy: privacyData };
    const linkedId = await LocalStorage.get("linkedId");
    props.privacySettingsUpdate(linkedId, payload);
  }

  useFocusEffect(
    useCallback(() => {
      getPrivacySettings();
    }, [])
  );
  useFocusEffect(
    useCallback(() => {
      setPrivacyData(props.business.privacySettings.data);
    }, [props.business.privacySettings.updatedTime])
  );

  const getPrivacySettings = async () => {
    const linked = await LocalStorage.get("linkedId");
    props.getPrivacySettings(linked);
  };

  return (
    <>
      <Appbar title={"Privacy Settings"} />
      <View style={styles.body}>
        {props.business.privacySettings.isLoading ? (
          <LoaderUI />
        ) : (
          <View style={{ flex: 1 }}>
            <ScrollView>
              {MockData.privacySettingsList.map((item) => (
                <View style={styles.box} key={item.key}>
                  <Checkbox
                    active={privacyData[item.key]}
                    onPress={() => {
                      setPrivacyData({
                        ...privacyData,
                        [item.key]: !privacyData[item.key],
                      });
                    }}
                  />
                  <View style={{ width: 10 }} />
                  <TextUI
                    size={14}
                    weight={"500"}
                    style={{ fontFamily: "Roboto" }}
                  >
                    {item.label}
                  </TextUI>
                </View>
              ))}
            </ScrollView>
          </View>
        )}

        <View style={styles.actBtnWrapper}>
          <ButtonUI
            style={{ backgroundColor: "white", flex: 1 }}
            labelStyle={{ color: "black" }}
            onPress={() => {
              props.navigation.navigate(RouteNames.Profile.name);
            }}
            label="Cancel"
          />
          <View style={{ width: 30 }} />
          <ButtonUI
            style={{ flex: 1 }}
            onPress={() => privacySettingsUpdate()}
            label="Update"
          />
        </View>
      </View>
      {props.business.privacySettings.isUpdating && <LoaderUI mode="long" />}
    </>
  );
}
const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    flex: 1,
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,

    paddingVertical: 10,
    borderColor: StyleTheme.BOX_BORDER_COLOR,
  },
  actBtnWrapper: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 20,
  },
});
const mapStateToProps = (store: any) => {
  return {
    business: store.business,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getPrivacySettings: (linkedId) =>
      dispatch(Actions.getPrivacySettings(linkedId)),
    privacySettingsUpdate: (linkedId, payload) =>
      dispatch(Actions.privacySettingsUpdate(linkedId, payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivacySettings);
