import React from "react";
import { View, ScrollView, TouchableOpacity, Platform } from "react-native";
import { boxShadow } from "../../Styles/CommonStyles";
import ImageUI from "../../Components/ImageUI";
import TextUI from "../../Components/TextUI";
import { IBusinessStore } from "../../Store/Reducers/businessReducer";
import ImageWithStatus from "../../Components/ImageWithStatus";

interface IProps {
  data: IBusinessStore["partners"];
  action: (id) => void;
}

export default function PartnersList(props: IProps) {
  return (
    <View style={{ width: "100%" }}>
      {props.data.length ? (
        <ScrollView horizontal={true}>
          {props.data.map((item, idx) => {
            return (
              <TouchableOpacity
                key={idx}
                onPress={() => props.action(item)}
                style={{
                  marginLeft: idx === 0 ? 20 : 12,
                  marginHorizontal: 12,
                  marginVertical: 10,
                }}
              >
                <ImageWithStatus
                  image={item.logo}
                  status={item.enableForBooking}
                  size={80}
                />
                <View style={{ height: 15 }} />
                <TextUI
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: 12,
                    width: 80,
                    alignSelf: "center",
                  }}
                  numberOfLines={1}
                >
                  {item.name}
                </TextUI>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      ) : (
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100,
          }}
        >
          <TextUI
            weight="500"
            size={14}
            style={{ textAlign: "center", opacity: 0.7 }}
          >
            No business associated {"\n"} Add business to continue
          </TextUI>
        </View>
      )}
    </View>
  );
}
