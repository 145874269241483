// ===================================================================
// This component shows the business menu options
// ===================================================================
import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import Appbar from "../Components/Appbar";
import { connect } from "react-redux";
import TextUI from "../Components/TextUI";
import RouteNames from "../Utils/Constants/RouteNames";
import { NavigationProps } from "../Models/interface";
import FlatList from "../Components/FlatList";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import { getBusinessProfile } from "../Actions/businessActions";
import ImageWithStatus from "../Components/ImageWithStatus";
// ===================================================================
// interface
// ===================================================================
interface IProps extends NavigationProps {
  getBusinessProfile: (id: string) => void;
  businessStore: IBusinessStore;
}
// ===================================================================
// Component
// ===================================================================
function BusinessMenu(props: IProps) {
  const partnerId = props.route.params.id;
  const partnerList = props.businessStore.partners;
  const partnerDetails = partnerList.find(
    ({ partner }) => partner === partnerId
  ) || { enableForBooking: false, logo: "", partner: "", name: "" };

  const UIElementsData = [
    {
      sectionName: "Business Details",
      items: [
        {
          title: "Business Profile",
          route: RouteNames.BusinessIdentity.name,
        },
        {
          title: "Additional Info",
          route: RouteNames.PartnerExtraInfo.name,
        },
        {
          title: "Industries",
          route: RouteNames.BusinessIndustries.name,
        },
        {
          title: "Services",
          route: RouteNames.BusinessServices.name,
        },
        {
          title: "Employees",
          route: RouteNames.BusinessEmployee.name,
        },
        {
          title: "Booking Settings",
          route: RouteNames.BookingSettings.name,
        },
        {
          title: "Customers",
          route: RouteNames.Customers.name,
        },
        {
          title: "Digital Marketing",
          route: RouteNames.DigitalMarketingList.name,
        },
      ],
    },
  ];
  const styles = StyleSheet.create({
    content: { padding: 10, paddingVertical: 25 },
    businessImage: {
      width: 100,
      borderRadius: 100,
      height: 100,
      borderWidth: 5,
      borderColor: "#F8F8F8",
      alignSelf: "center",
    },
  });
  return (
    <>
      <Appbar title={partnerDetails.name} />
      <ScrollView>
        <View style={styles.content}>
          <View
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <ImageWithStatus
              image={partnerDetails.logo}
              status={partnerDetails.enableForBooking}
              size={120}
            />
          </View>
          <View style={{ height: 20 }} />
          <View key={`section${"mmm"}`} style={{ paddingHorizontal: 15 }}>
            <TextUI size={16} weight="700" style={{ marginBottom: 10 }}>
              {UIElementsData[0].sectionName}
            </TextUI>
            <FlatList
              items={UIElementsData[0].items}
              action={(item) => {
                props.navigation.push(item.route, {
                  id: props.route.params.id,
                });
              }}
            />
          </View>
        </View>
      </ScrollView>
    </>
  );
}
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: { business: IBusinessStore }) => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getBusinessProfile: (id: string) => dispatch(getBusinessProfile(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessMenu);
