import React from "react";
import { TouchableOpacity, View, ScrollView } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { CommonStyles, StyleTheme } from "../Styles/CommonStyles";
import TextUI from "../Components/TextUI";
import LoaderUI from "./Loader";

interface IProps {
  items: any[];
  action?: (item: any) => void;
  renderItem?: (item: any) => React.ReactNode;
  isLoading?: boolean;
}
export default function FlatList(props: IProps) {
  function renderList() {
    if (props.renderItem) {
      return (
        <>
          {props.items.map((item, idx) => (
            <View
              key={idx}
              style={[
                CommonStyles.rowSpaceBetween,
                {
                  borderBottomWidth: idx >= props.items.length - 1 ? 0 : 1,
                  borderColor: StyleTheme.BOX_BORDER_COLOR,
                },
              ]}
            >
              {props.renderItem(item)}
            </View>
          ))}
        </>
      );
    }
    return (
      <>
        {props.items.map((item, idx) => {
          return (
            <TouchableOpacity
              key={`section-item${idx}`}
              style={[
                CommonStyles.rowSpaceBetween,
                {
                  borderBottomWidth: idx >= props.items.length - 1 ? 0 : 1,
                  paddingVertical: 12,
                  borderColor: StyleTheme.BOX_BORDER_COLOR,
                },
              ]}
              onPress={() => {
                props.action && props.action(item);
              }}
            >
              <TextUI size={16} familyType={"type-2"} weight="400">
                {item.title}
              </TextUI>
              {props.action && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name={"chevron-right"}
                    size={20}
                    color={StyleTheme.DEFAULT_FONT_COLOR}
                    style={{ opacity: 0.7 }}
                  />
                </View>
              )}
            </TouchableOpacity>
          );
        })}
      </>
    );
  }
  if (props.isLoading && !props.items.length) {
    return <LoaderUI mode="long" />;
  }
  return <ScrollView style={{ flex: 1 }}>{renderList()}</ScrollView>;
}
