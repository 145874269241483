// ===================================================================
// This component show the services or areas for business identity
// ===================================================================
import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import TextUI from "./TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Row from "./Row";
// ===================================================================
// Component
// ===================================================================

export default function Accordion(props) {
  return <View style={{ marginTop: 10 }}>{props.children}</View>;
}

Accordion.Section = function (props: {
  children?: React.ReactNode;
  onPress?: () => void;
  title: string;
  expanded?: boolean;
  right?: string;
}) {
  return (
    <View
      style={{
        paddingVertical: 12,
        borderBottomWidth: 1,
        borderBottomColor: "#dedede",
      }}
    >
      <TouchableOpacity
        style={styles.section}
        onPress={() => {
          props.onPress && props.onPress();
        }}
        activeOpacity={0.7}
      >
        <Row spaceBetween>
          <Row>
            <TextUI weight="500" size={14}>
              {props.title}
            </TextUI>
            {props.expanded !== undefined && (
              <MaterialCommunityIcons
                name={props.expanded ? "chevron-up" : "chevron-down"}
                style={{
                  fontSize: 20,
                  marginLeft: 5,
                }}
                size={24}
              />
            )}
          </Row>
          {props.right && <TextUI weight="500">{props.right}</TextUI>}
        </Row>
      </TouchableOpacity>
      {props.expanded && (
        <View style={{ marginLeft: 10 }}>{props.children}</View>
      )}
    </View>
  );
};

Accordion.Item = function (props: { children: React.ReactNode }) {
  return <View style={{ paddingVertical: 5 }}>{props.children}</View>;
};

const styles = StyleSheet.create({
  section: {
    width: "100%",
    paddingVertical: 0,
  },
  item: {
    marginRight: 10,
    borderRadius: 20,
    borderWidth: 1,
    padding: 10,
    paddingHorizontal: 25,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
});
