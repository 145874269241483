// ===================================================================
// CUSTOM NETWORK COMPONENT
// ===================================================================
import React, { useCallback } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import Appbar from "../../../Components/Appbar";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {
  getPartnerBookingSettings,
  updatePartnerBookingSettings,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import TextInputUI from "../../../Components/TextInputUI";
import SectionLabel from "../../../Components/SectionLabel";
import { validateEmail } from "../../../Utils";
import TextArea from "../../../Components/TextArea";
import DropDownUI from "../../../Components/Dropdown";
import ButtonUI from "../../../Components/ButtonUI";
import TextUI from "../../../Components/TextUI";
import LoaderUI from "../../../Components/Loader";
import BottomActionBar from "../../../Components/BottomActionBar";
import RadioButton from "../../../Components/RadioButton";
import Row from "../../../Components/Row";
import Checkbox from "../../../Components/Checkbox";
import WindowSelectPopup from "./Components/WindowSelectPopup";
import Page from "../../../Components/Page";
import Actions from "../../../Actions";
import FlatList from "../../../Components/FlatList";
import { StyleTheme } from "../../../Styles/CommonStyles";
import ImageUI from "../../../Components/ImageUI";
import OutlineButton from "../../../Components/OutlineButton";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getPartnerExtraInfo: (id: string, callback) => void;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================
const setup = {
  identity: {
    title: "Identity",
    image: require("@reachpartner/common/Assets/Images/extinfo/identity.png"),
  },
  extInfo: {
    title: "Extended Info",
    image: require("@reachpartner/common/Assets/Images/extinfo/extinfo.png"),
  },
  hours: {
    title: "Hours",
    image: require("@reachpartner/common/Assets/Images/extinfo/hours.png"),
  },
  industry: {
    title: "Industry",
    image: require("@reachpartner/common/Assets/Images/extinfo/industry.png"),
  },
  category: {
    title: "Category",
    image: require("@reachpartner/common/Assets/Images/extinfo/category.png"),
  },
  service: {
    title: "Service",
    image: require("@reachpartner/common/Assets/Images/extinfo/service.png"),
  },
  employee: {
    title: "Employee",
    image: require("@reachpartner/common/Assets/Images/extinfo/extinfo.png"),
  },
  onlinePayment: {
    title: "Online Payment",
    image: require("@reachpartner/common/Assets/Images/extinfo/extinfo.png"),
  },
  // "calendar": "Calendar",
  // 'class': 'Class',
  // 'landmark': 'Landmark',
  // 'level': 'Level',
  // 'package': 'Package',
  // 'template': 'Template'
};
interface ISettings {
  partner: string;
  type: number;
  amenity: string[];
  accessibility: string[];
  servicePricing: number;
  readyForBooking: boolean;
  venueRule: string[];
  customVenueRule: string;
  setupValidity: {
    identity: boolean;
    extInfo: boolean;
    hours: boolean;
    landmark: boolean;
    industry: boolean;
    level: boolean;
    category: boolean;
    service: boolean;
    package: boolean;
    class: boolean;
    template: boolean;
    calendar: boolean;
    onlinePayment: boolean;
    employee: boolean;
    employeeValidity: {
      id: string;
      image: string;
      name: string;
      validity: boolean;
    }[];
  };
  identityVerifyStatus: {
    email: number;
    phone: number;
    business: number;
    address: number;
    vat: number;
  };
}

const defaultValues = {
  partner: "",
  type: 3,
  amenity: [],
  accessibility: [],
  servicePricing: 2,
  readyForBooking: false,
  venueRule: [],
  customVenueRule: "",
  setupValidity: {
    identity: false,
    extInfo: false,
    hours: false,
    landmark: false,
    industry: false,
    level: false,
    category: false,
    service: false,
    package: false,
    class: false,
    template: false,
    calendar: false,
    onlinePayment: false,
    employee: false,
    employeeValidity: [],
  },
  identityVerifyStatus: {
    email: 1,
    phone: 1,
    business: 1,
    address: 1,
    vat: 1,
  },
};

function AdditionalInfo(props: IProps) {
  const [formData, setFormData] = React.useState<ISettings>(defaultValues);
  const [isLoading, setIsLoading] = React.useState(true);
  const [setupShowAll, updateSetupShowAll] = React.useState(false);

  useFocusEffect(
    useCallback(() => {
      const partnerId = props.route.params.id || "";
      props.getPartnerExtraInfo(partnerId, (result) => {
        if (!result.fail) {
          console.log(result);
          setFormData({ ...result });
        }
        setIsLoading(false);
      });
    }, [])
  );
  const setupStatusArr: any =
    formData.partner !== ""
      ? Object.keys(setup).reduce(
          (
            acc: {
              id: string;
              label: string;
              status: boolean;
              image: string;
            }[],
            key
          ) => {
            if (formData.setupValidity?.[key] !== undefined) {
              acc.push({
                id: key,
                label: setup[key]?.title,
                status: formData.setupValidity[key],
                image: setup[key].image,
              });
            }
            return acc;
          },
          []
        )
      : [];
  const employeeStatusArr = formData.setupValidity.employeeValidity.map(
    ({ id, name, image, validity }) => ({
      id,
      label: name,
      image,
      status: validity,
    })
  );

  const renderSetupSuccess = (statusItems, type: "employee" | "setup") => {
    return (
      <>
        {statusItems.map((item, idx) => {
          return (
            <View
              style={{
                paddingVertical: 15,
                flex: 1,
                borderBottomWidth: idx >= statusItems.length - 1 ? 0 : 1,
                borderColor: StyleTheme.BOX_BORDER_COLOR,
              }}
              key={item.id}
            >
              <Row spaceBetween>
                <View style={{ flex: 1 }}>
                  <Row>
                    <View
                      style={{
                        padding: 5,
                        backgroundColor: "#D0F4FC",
                        borderRadius: 3,
                        marginRight: 5,
                      }}
                    >
                      <ImageUI
                        source={item.image}
                        style={{
                          height: 20,
                          width: 20,
                        }}
                      />
                    </View>
                    <TextUI>
                      {item.label}
                      {type === "setup" ? " *" : ""}
                    </TextUI>
                  </Row>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <TextUI
                    style={{
                      color: item.status ? "#25AD03" : "#FF3535",
                    }}
                  >
                    {item.status ? "COMPLETED" : "INCOMPLETE"}
                  </TextUI>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MaterialCommunityIcons
                      name={"chevron-right"}
                      size={20}
                      color={StyleTheme.DEFAULT_FONT_COLOR}
                      style={{ opacity: 0.7 }}
                    />
                  </View>
                </View>
              </Row>
            </View>
          );
        })}
      </>
    );
  };

  return (
    <Page>
      <Appbar title={"Additional Info"} />
      <ScrollView style={styles.body}>
        {setupStatusArr.length !== 0 && (
          <SectionLabel>Setup Status</SectionLabel>
        )}
        {renderSetupSuccess(setupStatusArr, "setup")}
        <View style={{ height: 10 }} />
        {employeeStatusArr.length !== 0 && (
          <SectionLabel>Employee Status</SectionLabel>
        )}
        {renderSetupSuccess(employeeStatusArr, "employee")}
      </ScrollView>
      {/* <BottomActionBar>
        <OutlineButton
          label="Cancel"
          onPress={() => props.navigation.pop()}
          style={{ height: 40 }}
          mode="outlined"
        />
        <View style={{ width: 20 }} />
        <ButtonUI label="Submit" onPress={() => {}} style={{ height: 40 }} />
      </BottomActionBar> */}

      {isLoading && <LoaderUI mode="long" />}
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
});
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getPartnerExtraInfo: (id: string, callback) =>
      dispatch(Actions.getPartnerExtraInfo(id, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
