import React from "react";
import { View, ViewStyle, TouchableOpacity, ScrollView } from "react-native";
import { IStore } from "../../../Models";
import { connect } from "react-redux";
import FullViewModal from "../../../Components/FullViewModal";
import ButtonUI from "../../../Components/ButtonUI";
import BottomActionBar from "../../../Components/BottomActionBar";
import TextUI from "../../../Components/TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {
  getEmployeeDetails,
  getPartnerGroupIndustryLevelService,
  getPartnerHours,
  updatePartnerEmployeeDetails,
} from "../../../Actions/businessActions";
import TextInputUI from "../../../Components/TextInputUI";
import {
  initialState,
  IBusinessStore,
} from "../../../Store/Reducers/businessReducer";
import LoaderUI from "../../../Components/Loader";
import EnableForBooking from "../../../Components/EnableForBooking";
import SectionLabel from "../../../Components/SectionLabel";
import DropDownUI from "../../../Components/Dropdown";
import EnumMapping from "../../../Utils/Constants/EnumMapping";
import ImageUI from "../../../Components/ImageUI";
import { CommonStyles } from "../../../Styles/CommonStyles";
import Modal from "../../../Components/Modal";
import Chips from "../../../Components/Chips";
import SelectNetworks from "../../../Components/SelectNetworks";
import { useToast } from "../../../Components/Toast";
import OutlineButton from "../../../Components/OutlineButton";
import Page from "../../../Components/Page";
import Appbar from "../../../Components/Appbar";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getEmployeeDetails: (partnerId, employeeId) => void;
  updatePartnerEmployeeDetails: (
    partnerId,
    employeeId,
    data: any,
    callback
  ) => void;
  getPartnerGroupIndustryLevelService: (partnerId, callback) => void;
  getPartnerHours: (partnerId, callback) => void;
}
type IProps = IMapState &
  IMapDispatch & {
    employeeData: IBusinessStore["employees"]["data"][0];
    partnerId: string;
    navigationPop: () => void;
  };
// ===================================================================
// COMPONENT
// ===================================================================
function UpdateEmployee(props: IProps) {
  const toaster = useToast();
  // ===================================================================
  // State
  // ===================================================================
  const [formData, setFormData] = React.useState<
    IBusinessStore["employeeDetails"] & IBusinessStore["employees"]["data"][0]
  >({ ...initialState.employeeDetails, ...props.employeeData });
  const [isLoading, setIsLoading] = React.useState(true);
  const [serviceLevels, setServiceLevels] = React.useState([]);
  const [tempIndustry, setTempIndustry] = React.useState({ id: "", name: "" });
  const [tempLevel, setTempLevel] = React.useState({ id: "", name: "" });
  const [showSkillModal, setShowSkillModal] = React.useState(false);
  const [showSelectService, setShowSelectService] = React.useState(false);
  // ===================================================================
  // Functions
  // ===================================================================
  React.useEffect(() => {
    props.getEmployeeDetails(props.partnerId, props.employeeData.id);
    props.getPartnerGroupIndustryLevelService(props.partnerId, (data) => {
      if (!data.fail) {
        setServiceLevels([...data.result]);
      }
    });
  }, []);

  React.useEffect(() => {
    if (!props.businessStore.isLoading) {
      setFormData((x) => ({
        ...x,
        ...props.businessStore.employeeDetails,
      }));
      setIsLoading(false);
    }
  }, [props.businessStore.isLoading]);

  function handleChange(type: string, value: any) {
    setFormData((d: any) => ({
      ...d,
      [type]: value,
    }));
  }

  function SpaceBetween() {
    return <View style={{ height: 10 }} />;
  }

  function validateTheUpdate(): boolean {
    if (!formData.enableForBooking) {
      return true;
    }
    if (!formData.skill.length) {
      toaster.show({ message: "Please add skill", type: "info" });
      return false;
    }
    if (!formData.service.length) {
      toaster.show({ message: "Please add service", type: "info" });
      return false;
    }

    return true;
  }
  function validateHours(hours) {
    return Object.keys(hours).reduce((acc, item) => {
      if (acc) {
        return true;
      }
      const time = hours[item];
      if (time[0].start !== 0 || time[0].end !== 0) {
        return true;
      }
      return false;
    }, false);
  }
  function submitData(hours?) {
    const data = {
      calling: formData.calling,
      number: parseInt(formData.number.toString(), 10),
      designation: formData.designation,
      skill: formData.skill.map((item) => ({
        industry: item.industry.id,
        level: item.level.id,
      })),
      service: formData.service.map((item) => item.id),
      hours: hours || formData.hours,
      access: formData.access,
      holiday: formData.holiday,
      enableForBooking: formData.enableForBooking,
    };
    if (validateTheUpdate()) {
      if (validateHours(data.hours)) {
        props.updatePartnerEmployeeDetails(
          props.partnerId,
          props.employeeData.id,
          data,
          (data) => {
            if (data && !data.fail) {
              props.navigationPop();
            } else if (data) {
              toaster.show({ message: data.mesg, type: "info" });
            }
          }
        );
      } else {
        getHours();
      }
    }
  }
  function getHours() {
    props.getPartnerHours(props.partnerId, (res) => {
      if (!res.fail) {
        handleChange("hours", res.hours);
        submitData(res.hours);
      }
    });
  }

  function ServiceLevels() {
    const rowStyles: ViewStyle = {
      paddingHorizontal: 10,
      paddingVertical: 10,
      ...CommonStyles.rowSpaceBetween,
    };
    return (
      <View
        style={[
          { ...CommonStyles.boxContainer },
          { paddingHorizontal: 0, paddingVertical: 0, borderRadius: 4 },
        ]}
      >
        <View style={[rowStyles, { backgroundColor: "rgba(36, 39, 44, 0.1)" }]}>
          <TextUI weight="500" size={16}>
            Industry
          </TextUI>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <TextUI weight="500" size={16}>
              Service Level
            </TextUI>
            <View style={{ width: 40 }} />
          </View>
        </View>
        {formData.skill.map((item, index) => (
          <View style={[rowStyles]} key={item.industry.id + item.level.id}>
            <TextUI weight="500">{item.industry.name}</TextUI>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextUI weight="500">{item.level.name}</TextUI>
              <TouchableOpacity
                style={{ width: 40 }}
                onPress={() => {
                  handleChange("skill", [
                    ...formData.skill.filter((d, idx) => idx !== index),
                  ]);
                }}
              >
                <MaterialCommunityIcons
                  name={"delete-outline"}
                  style={{
                    color: "red",
                    fontSize: 18,
                    alignSelf: "center",
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
        ))}
      </View>
    );
  }
  function Services() {
    return (
      <Chips>
        {formData.service.map(({ name, id }) => (
          <Chips.Selected
            key={id}
            action={() => {
              handleChange(
                "service",
                formData.service.filter((x) => x.id !== id)
              );
            }}
          >
            {name}
          </Chips.Selected>
        ))}
        <Chips.Add action={() => setShowSelectService(true)}>
          Add Service
        </Chips.Add>
      </Chips>
    );
  }
  function renderBody() {
    if (isLoading) {
      return <LoaderUI mode="long" />;
    }

    return (
      <ScrollView style={{ flex: 1, padding: 15 }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ImageUI source={formData.image} style={{ height: 60, width: 60 }} />
          <View style={{ marginLeft: 10 }}>
            <TextUI size={14} weight="700">
              {formData.calling}
            </TextUI>
            <View style={{ marginBottom: 3, marginTop: 3 }}>
              <TextUI size={12} weight="700">
                {formData.rating.average}/5
              </TextUI>
              <TextUI>{""}</TextUI>
            </View>

            <TextUI size={11} weight="700">
              Based on {formData.rating.count} User Reviews
            </TextUI>
          </View>
        </View>
        <SpaceBetween />
        <SpaceBetween />
        <SectionLabel>Update your Business Details</SectionLabel>
        <SpaceBetween />
        <TextInputUI
          label="First Name"
          value={formData.first}
          disabled={true}
        />
        <SpaceBetween />
        <TextInputUI label="Last Name" value={formData.last} disabled={true} />
        <SpaceBetween />
        <TextInputUI
          label="Preffered Name"
          value={formData.calling}
          onChangeText={(val) => handleChange("calling", val)}
        />
        <SpaceBetween />
        <TextInputUI
          label="Email Address"
          value={formData.status?.[0]?.txt || ""}
          disabled={true}
        />
        <SpaceBetween />
        <TextInputUI
          label="Phone Number"
          value={formData.status?.[1]?.txt || ""}
          disabled={true}
        />
        <SpaceBetween />
        <TextInputUI
          label="Employee Number"
          value={formData.number.toString()}
          onChangeText={(val) => handleChange("number", val)}
        />
        <SpaceBetween />
        <TextInputUI
          label="Designation"
          value={formData.designation}
          onChangeText={(val) => handleChange("designation", val)}
        />
        <SpaceBetween />
        <SpaceBetween />
        <View style={[CommonStyles.rowSpaceBetween]}>
          <SectionLabel>Service Skils Level</SectionLabel>
          <TouchableOpacity onPress={() => setShowSkillModal(true)}>
            <TextUI style={{ textDecorationLine: "underline" }}>
              + Add Skill
            </TextUI>
          </TouchableOpacity>
        </View>
        <SpaceBetween />
        <ServiceLevels />
        <SpaceBetween />
        <SpaceBetween />
        <SectionLabel>Services</SectionLabel>
        <SpaceBetween />
        <Services />
        <SpaceBetween />
        <SpaceBetween />
        <SectionLabel>Permission Level</SectionLabel>
        <SpaceBetween />
        <DropDownUI
          label="Permission Level"
          value={formData.access}
          setValue={(val) => handleChange("access", val)}
          list={EnumMapping.EmployeeAccessLevel}
          keyId="value"
          keyName="label"
        />
        <SpaceBetween />
        <EnableForBooking
          active={formData.enableForBooking}
          handleChange={(val) => handleChange("enableForBooking", val)}
        />
      </ScrollView>
    );
  }
  function renderServiceLevelModal() {
    const industries = serviceLevels.map((item) => ({
      id: item.industry,
      name: item.name,
    }));
    const levels =
      tempIndustry.id === ""
        ? []
        : serviceLevels.find(({ industry }) => industry === tempIndustry.id)
          ?.linked?.level || [];
    return (
      <Modal
        visible={showSkillModal}
        contentContainerStyle={{ width: 300 }}
        onDismiss={() => {
          setShowSkillModal(false);
        }}
      >
        <SectionLabel>Service Skill Level</SectionLabel>
        <SpaceBetween />
        <DropDownUI
          value={tempIndustry.id || ""}
          list={industries}
          keyName="name"
          keyId="id"
          label="Industry"
          setValue={(val) => {
            setTempIndustry(industries.find(({ id }) => id === val));
          }}
        />
        <SpaceBetween />
        <DropDownUI
          value={tempLevel.id || ""}
          list={levels}
          keyName="name"
          keyId="id"
          label="Level"
          setValue={(val) => {
            setTempLevel(levels.find(({ id }) => id === val));
          }}
        />
        <SpaceBetween />
        <View style={[CommonStyles.rowSpaceBetween]}>
          <ButtonUI
            label="Cancel"
            onPress={() => setShowSkillModal(false)}
            mode="outlined"
            style={{ width: "47%" }}
          />

          <ButtonUI
            label="Add"
            onPress={() => {
              setShowSkillModal(false);
              const existingSkils = formData.skill.map(
                (item) => item.industry.id + "-" + item.level.id
              );
              const isAllreadyExist = existingSkils.includes(
                tempIndustry.id + "-" + tempLevel.id
              );
              if (!isAllreadyExist) {
                handleChange("skill", [
                  ...formData.skill,
                  { industry: tempIndustry, level: tempLevel },
                ]);
              }
              setTempIndustry({ id: "", name: "" });
              setTempLevel({ id: "", name: "" });
            }}
            disabled={tempIndustry.id === "" || tempLevel.id === ""}
            style={{ width: "47%" }}
          />
        </View>
      </Modal>
    );
  }
  function renderServiceModal() {
    const industries = formData.skill.map(({ industry }) => industry.id);
    const services = serviceLevels.reduce((acc, item) => {
      if (industries.includes(item.industry)) {
        return [...acc, ...item.linked.service];
      }
      return acc;
    }, []);
    const selectedIds = formData.service.map(({ id }) => id);
    const filteredServices = services.filter(
      ({ id }) => !selectedIds.includes(id)
    );
    return (
      <SelectNetworks
        data={filteredServices}
        getData={() => { }}
        onDismiss={(data) => {
          setShowSelectService(false);
          if (data) {
            const serviceObjects = services.filter(({ id }) =>
              data.includes(id)
            );
            handleChange("service", [...formData.service, ...serviceObjects]);
          }
        }}
        headerLabel="Select Services"
      />
    );
  }
  return (
    <Page>
      <Appbar title={`Update Employee Details`} />
      {renderBody()}
      <BottomActionBar>
        <View
          style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: 'space-between' }}
        >
          <OutlineButton
            label="Cancel"
            onPress={() => props.navigationPop()}
            mode="outlined"
            style={{ flex: 1 }}
          />
          <View style={{ width: 20 }} />
          <ButtonUI label="Update" onPress={submitData} style={{ flex: 1 }} />
        </View>
      </BottomActionBar>
      {props.businessStore.isUpdating && <LoaderUI mode="long" />}
      {showSkillModal && renderServiceLevelModal()}
      {showSelectService && renderServiceModal()}
    </Page>
  );
}

export default UpdateEmployee;
