// ===================================================================
// CUSTOM  COMPONENT
// ===================================================================
import React, { useCallback, useEffect } from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import Appbar from "../../../Components/Appbar";
import { NavigationProps } from "../../../Models/interface";
import { validateEmail } from "../../../Utils";
import Actions from "../../../Actions";
import FormWrapper from "../../../Components/FormWrapper";
import SectionLabel from "../../../Components/SectionLabel";
import TextInputUI from "../../../Components/TextInputUI";
import DropDownUI from "../../../Components/Dropdown";
import DatepickerUI from "../../../Components/DatepickerUI";
import moment from "moment";
import TimepickerUI from "../../../Components/TimepickerUI";
import Chip from "../../../Components/Chips";
import TextUI from "../../../Components/TextUI";
import TextArea from "../../../Components/TextArea";
import { StyleTheme } from "../../../Styles/CommonStyles";
import BottomActionBar from "../../../Components/BottomActionBar";
import ButtonUI from "../../../Components/ButtonUI";
import EnumMapping from "../../../Utils/Constants/EnumMapping";
import ImageUI from "../../../Components/ImageUI";
import Row from "../../../Components/Row";
import ActiveIndicatorCheck from "../../../Components/ActiveIndicatorCheck";
import { getCustomersList } from "../../../Actions/businessActions";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import SelectNetworks from "../../../Components/SelectNetworks";
import LoaderUI from "../../../Components/Loader";
import Page from "../../../Components/Page";

// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  business: IBusinessStore;
}

interface IMapDispatch {
  getCampaignDetails: (id: string, callback) => void;
  getCustomers: (id: string, offset: number, limit: number) => void;
  industrySearch: (filter, item, ids: string[], options, callback) => void;
  createCampaign: (partnerId, paload, callback) => void;
  updateCampaign: (partnerId, paload, callback) => void;
}

type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================
const defaultState = {
  name: "",
  date: "",
  type: 1,
  target: 0,
  status: 0,
  mediumInfo: [{ medium: 3, design: "", subject: "", title: "", body: "" }],
  audience: [],
  audienceType: 1,
};

const AudienceList = Object.keys(EnumMapping.CampaignTarget).map((i) => ({
  label: EnumMapping.CampaignTarget[i],
  value: Number(i),
}));

const CampaignMediums = Object.keys(EnumMapping.CampaignMedium).map((i) => ({
  label: EnumMapping.CampaignMedium[i],
  value: Number(i),
}));

function MarketingDetails(props: IProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(defaultState);
  const [initialValidate, setInitialValidate] = React.useState(false);
  const [selectedMedium, setSelectedMedium] = React.useState(3);
  const [industryCampaigns, setIndustryCampaigns] = React.useState([]);
  const [showSelectCustomer, setShowSelectCustomer] = React.useState(false);
  const isAddCampaign = props.route.params.campaignId === "add";

  useFocusEffect(
    useCallback(() => {
      if (props.route.params.campaignId !== "add") {
        getCampaignDetails();
        getIndustryCampaign();
      }
      props.getCustomers(props.route.params.businessId, 0, 30);
    }, [])
  );

  function getIndustryCampaign() {
    props.industrySearch(
      "",
      "campaign",
      [],
      { offset: 0, limit: 20 },
      (data) => {
        if (!data.fail) {
          setIndustryCampaigns(data.result);
        }
      }
    );
  }

  function getCampaignDetails() {
    setIsLoading(true);
    props.getCampaignDetails(props.route.params.campaignId, (data) => {
      if (!data.fail) {
        const {
          audienceInfo,
          date,
          status,
          target,
          type,
          name,
          audienceType,
          mediumInfo,
        } = data;

        setFormData({
          audience: audienceInfo,
          date,
          status,
          target,
          name,
          type,
          audienceType,
          mediumInfo,
        });
        setSelectedMedium(data.mediumInfo?.[0]?.medium || 3);
      }
      setIsLoading(false);
    });
  }

  const hasInputError = (inputName: string, value: string) => {
    if (!initialValidate) {
      return false;
    }
    return value === "";
  };

  function handleChange(type: string, value: any) {
    setInitialValidate(() => true)
    setFormData((d: any) => ({
      ...d,
      [type]: value,
    }));
  }

  function handleDate(value) {
    const newDate = moment(value);
    const newValue = formData.date ? moment(formData.date) : moment();
    newValue.date(newDate.date());
    newValue.month(newDate.month());
    newValue.year(newDate.year());
    handleChange("date", newValue);
  }

  function handleTime({ hours, minutes }) {
    const newValue = moment(formData.date);
    newValue.set("hour", hours);
    newValue.set("minute", minutes);
    handleChange("date", newValue);
  }

  function submitForm() {
    const data: any = { ...formData };
    data.mediumInfo = data.mediumInfo.filter(
      ({ medium }) => medium === selectedMedium
    );
    data.audience = data.audience.map(({ id }) => id);

    if (isAddCampaign) {
      setIsLoading(true);
      data.audienceType = 1;
      delete data.status;
      props.createCampaign(props.route.params.businessId, data, (result) => {
        if (!result.fail) {
          props.navigation.pop();
        }
        setIsLoading(false);
      });
    } else {
      if (hasInputError("title", formData.mediumInfo[0].title) && hasInputError("subject", formData.mediumInfo[0].subject) && hasInputError("body", formData.mediumInfo[0].body) || hasInputError("body", formData.mediumInfo[0].body)) return;
      setIsLoading(true);
      props.updateCampaign(props.route.params.campaignId, data, (result) => {
        if (!result.fail) {
          props.navigation.pop();
        }
        setIsLoading(false);
      });
    }
  }

  function mediumSelection(medium) {
    setSelectedMedium(medium);
    const isMediumExist = formData.mediumInfo.find(
      (item) => medium === item.medium
    );
    if (!isMediumExist) {
      handleChange("mediumInfo", [
        ...formData.mediumInfo,
        { body: "", design: "", medium, subject: "", title: "" },
      ]);
    }
  }

  function renderEmailDesign() {
    let data: any = formData.mediumInfo.find(({ medium }) => medium === 2);
    const handleInputChange = (type, value) => {
      data[type] = value;
      handleChange("mediumInfo", [
        data,
        formData.mediumInfo.filter(({ medium }) => medium !== 2),
      ]);
    };

    return (
      <>
        <TextUI>Email Settings</TextUI>
        <View style={{ height: 10 }} />
        <View
          style={{
            borderWidth: 1,
            borderColor: StyleTheme.BOX_BORDER_COLOR,
            padding: 15,
            borderRadius: 4,
          }}
        >
          <FormWrapper>
            <TextUI weight="500">Select Email Design</TextUI>
            <Row gap={20}>
              {industryCampaigns.map((item, idx) => (
                <TouchableOpacity
                  key={idx}
                  style={{ marginRight: 20 }}
                  onPress={() => {
                    handleInputChange("design", item.id);
                  }}
                  activeOpacity={0.6}
                >
                  {item.id === data.design && <ActiveIndicatorCheck />}
                  <ImageUI
                    source={item.image[0]}
                    style={{ height: 50, width: 40 }}
                  />
                  <View style={{ height: 5 }} />
                  <TextUI size={10}>{item.name}</TextUI>
                </TouchableOpacity>
              ))}
            </Row>
            <TextUI weight="500">Email Content</TextUI>
            <TextInputUI
              label="Title"
              value={data?.title || ""}
              error={hasInputError("name", data?.title || "")}
              onChangeText={(text) => handleInputChange(`title`, text)}
              errorHelpText={"*Required"}
            />
            <TextInputUI
              label="Email Subject"
              value={data?.subject || ""}
              error={hasInputError("subject", data?.subject || "")}
              onChangeText={(text) => handleInputChange(`subject`, text)}
              errorHelpText={"*Required"}
            />
            <TextArea
              label="Email Description"
              value={data?.body || ""}
              error={hasInputError("body", data?.body || "")}
              onChangeText={(text) => handleInputChange(`body`, text)}
              errorHelpText={"*Required"}
            />
          </FormWrapper>
        </View>
      </>
    );
  }

  function renderSmsDesign() {
    const data: any =
      formData.mediumInfo.find(({ medium }) => medium === 3) || {};
    const handleSmsChange = (type, value) => {
      data[type] = value;
      handleChange("mediumInfo", [
        data,
        formData.mediumInfo.filter(({ medium }) => medium !== 3),
      ]);
    };
    return (
      <>
        <TextUI>Sms</TextUI>
        <View style={{ height: 10 }} />
        <View style={{}}>
          <TextArea
            label="Description"
            value={data?.body || ""}
            onChangeText={(text) => handleSmsChange("body", text)}
            error={hasInputError("body", data?.body || "")}
            errorHelpText={"*Required"}
          />
        </View>
      </>
    );
  }
  return (
    <Page>
      <Appbar title={"Marketing Campaign"} />
      <View style={{ height: 20 }} />
      <ScrollView style={{ paddingHorizontal: 15 }}>
        <FormWrapper>
          <SectionLabel>
            {isAddCampaign ? "Add" : "Update"} Campaign Details
          </SectionLabel>
          <TextInputUI
            label="Campaign Name"
            value={formData.name}
            error={hasInputError("name", formData.name)}
            onChangeText={(text) => handleChange(`name`, text)}
            errorHelpText={"*Required"}
          />
          {!isAddCampaign && (
            <TextInputUI
              label="Status"
              disabled={true}
              value={EnumMapping.CampaignStatus[formData.status]}
              error={hasInputError("name", formData.name)}
              onChangeText={(text) => handleChange(`first`, text)}
              errorHelpText={"*Required"}
            />
          )}
          <DropDownUI
            label={"Target Audience"}
            value={formData.target}
            list={AudienceList}
            setValue={(value) => handleChange(`target`, value)}
          />
          {formData.target === 1 && (
            <View>
              <TextUI style={{ fontWeight: "500", fontSize: 13 }}>
                Selected Customers
              </TextUI>
              <View style={{ height: 10 }} />
              <Chip>
                {formData.audience.map((item, idx) => (
                  <Chip.Selected
                    action={() => {
                      const filteredItems = formData.audience.filter(
                        (x) => x.id !== item.id
                      );
                      handleChange(`audience`, filteredItems);
                    }}
                    key={idx}
                  >
                    {item.name}
                  </Chip.Selected>
                ))}
                <Chip.Item action={() => setShowSelectCustomer(true)}>
                  Add Customer
                </Chip.Item>
              </Chip>
            </View>
          )}
          <DatepickerUI
            onChangeText={handleDate}
            value={formData.date}
            range={{ startDate: new Date() }}
          >
            <TextInputUI
              label="Select Campaign Date"
              value={
                formData.date ? moment(formData.date).format("DD-MM-YYYY") : ""
              }
              placeholder="DD-MM-YYYY"
              onChangeText={(text) => { }}
              error={hasInputError("dob", formData.date)}
              errorHelpText={"*Required"}
            />
          </DatepickerUI>
          <TimepickerUI
            onChangeText={(data) => handleTime(data)}
            value={formData.date}
          >
            <TextInputUI
              label="Select Campaign Time"
              value={
                formData.date ? moment(formData.date).format("hh: mm A") : ""
              }
              placeholder="DD-MM-YYYY"
              onChangeText={(text) => { }}
              error={hasInputError("dob", formData.date)}
              errorHelpText={"*Required"}
            />
          </TimepickerUI>
          <Chip>
            {CampaignMediums.map((item, key) => {
              if (item.value === selectedMedium) {
                return (
                  <Chip.Selected
                    action={() => mediumSelection(item.value)}
                    hideIcon={true}
                    key={key}
                  >
                    {item.label}
                  </Chip.Selected>
                );
              }
              return (
                <Chip.Item
                  action={() => mediumSelection(item.value)}
                  hideIcon={true}
                  key={key}
                >
                  {item.label}
                </Chip.Item>
              );
            })}
          </Chip>

          {selectedMedium === 2
            ? renderEmailDesign()
            : selectedMedium === 3
              ? renderSmsDesign()
              : null}
        </FormWrapper>
      </ScrollView>
      <BottomActionBar>
        <ButtonUI
          label={isAddCampaign ? "Add Campaign" : "Update"}
          onPress={submitForm}
        />
      </BottomActionBar>
      {isLoading && <LoaderUI mode="long" />}
      {showSelectCustomer && (
        <SelectNetworks
          data={props.business.customers.data.filter(
            ({ id }) => !formData.audience.includes(id)
          )}
          getData={() => { }}
          onDismiss={(data) => {
            setShowSelectCustomer(false);
            if (!data) {
              return;
            }
            const selectedCustomers = props.business.customers.data
              .filter(({ id }) => data.includes(id))
              .map(({ id, name }) => ({ id, name }));
            if (data) {
              handleChange("audience", [
                ...formData.audience,
                ...selectedCustomers,
              ]);
            }
          }}
          headerLabel="Select Customer"
        />
      )}
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================

// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    business: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getCampaignDetails: (id: string, callback) =>
      dispatch(Actions.getCampaignDetails(id, callback)),
    industrySearch: (filter, item, ids, options, callback) =>
      dispatch(Actions.industrySearch(filter, item, ids, options, callback)),
    getCustomers: (id, offset, limit) =>
      dispatch(getCustomersList(id, offset, limit)),
    createCampaign: (id, payload, callback) =>
      dispatch(Actions.createCampaign(id, payload, callback)),
    updateCampaign: (id, payload, callback) =>
      dispatch(Actions.updateCampaign(id, payload, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MarketingDetails);
