import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { Card } from "react-native-paper";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
import AppointmentList from "../Components/AppointmentList";
import LandingTopBar from "./Components/Topbar";
import TextUI from "../Components/TextUI";
import { NavigationProps } from "../Models/interface";
import { IUserStore } from "../Models";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";
import { IHomeStore } from "../Store/Reducers/homeReducer";

export interface IProps extends NavigationProps {
  user: IUserStore;
  home: IHomeStore;
  getWeatherInfo: (location: string) => void;
  getHomeAppointments: (linkedId: string) => void;
}
function LandingPage(props: IProps) {
  useFocusEffect(
    React.useCallback(() => {
      getHomePageData();
    }, [])
  );

  const getHomePageData = async () => {
    const { userInfo } = props.user;
    const { cityTown, country } = userInfo || {};
    const city_country = cityTown + "," + country;
    cityTown && props.getWeatherInfo(city_country);
    const linkedId = await LocalStorage.get("linkedId");
    linkedId && linkedId !== "" && props.getHomeAppointments(linkedId);
  };

  const handleTopBarActions = (type: string) => {
    if (type === "email") {
      props.navigation.navigate(RouteNames.Invitation.name);
    } else if (type === "notification") {
      props.navigation.navigate(RouteNames.Notifications.name);
    } else if (type === "profile") {
      props.navigation.navigate(RouteNames.ProfileEdit.name);
    }
  };

  const handleAction = (type: string, data?: string) => {
    if (type === "booking") {
      props.navigation.navigate(RouteNames.Booking.name, { bid: data });
    }
  };

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <Card style={Styles.topCard}>
        <LandingTopBar
          action={handleTopBarActions}
          employeeInfo={props.user.employeeInfo}
          weatherInfo={props.home.weatherInfo}
          userInfo={props.user.userInfo}
        />
      </Card>
      <View style={Styles.titleWrapper}>
        <TextUI size={18} weight="700" style={{ flex: 1 }}>
          Upcoming Appointments
        </TextUI>
        <TouchableOpacity
          style={Styles.viewAllWrapper}
          onPress={() =>
            props.navigation.navigate(RouteNames.Appointments.name)
          }
        >
          <Text style={Styles.viewAll}>View All</Text>
          <MaterialCommunityIcons
            name={"chevron-right"}
            style={Styles.viewAllIcon}
          />
        </TouchableOpacity>
      </View>
      <View style={{ flex: 1 }}>
        <AppointmentList
          appointmentsInfo={props.home.appointments}
          action={handleAction}
        />
      </View>
    </View>
  );
}

const Styles = StyleSheet.create({
  screenWrapper: {},
  topCard: {
    paddingHorizontal: "3%",
    paddingVertical: 10,
    elevation: 3,
    borderRadius: 0,
  },
  searchBar: {
    height: 44,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    marginHorizontal: "3%",
    marginTop: 15,
  },

  viewAllWrapper: {
    width: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  viewAll: {
    color: "#007FFF",
    fontWeight: "500",
  },
  viewAllIcon: {
    color: "#007FFF",
    fontSize: 20,
  },
});

const mapStateToProps = (store: any) => {
  return {
    user: store.user,
    home: store.home,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getWeatherInfo: (location) => dispatch(Actions.getWeatherInfo(location)),
  getHomeAppointments: (linkedId) =>
    dispatch(Actions.getHomeAppointments(linkedId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
