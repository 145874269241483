import React from "react";
import ImagePicker from "./ImagePicker";
import { View } from "react-native";
import ImageUI from "./ImageUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

interface IProps {
  action: (data: any[]) => void;
  source: any;
  defaultSource?: any;
}
export default function ImagePickerAvatar(props: IProps) {
  return (
    <ImagePicker action={props.action}>
      <View
        style={{
          width: 100,
          borderRadius: 100,
          height: 100,
          borderWidth: 5,
          borderColor: "#F8F8F8",
          alignSelf: "center",
        }}
      >
        <ImageUI
          source={props.source}
          defaultSource={props.defaultSource}
          style={{
            width: 92,
            height: 92,
            alignSelf: "center",
            // zIndex: -1,
            top: -1,
            position: "absolute",
            borderRadius: 100,
          }}
          resizeMode="cover"
        />

        <View
          style={{
            backgroundColor: "#F8F8F8",
            height: 20,
            width: 20,
            position: "absolute",
            bottom: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 100,
          }}
        >
          <MaterialCommunityIcons name="camera" color="#B6B4B4" size={14} />
        </View>
      </View>
    </ImagePicker>
  );
}
