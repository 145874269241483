import React from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Card } from "react-native-paper";
import ButtonUI from "../../Components/ButtonUI";
import ImageUI from "../../Components/ImageUI";
import TextUI from "../../Components/TextUI";
interface InvitationListProps {
  data: any;
  action: (type: string, id?: string) => void;
}
function InvitationList(props: InvitationListProps) {
  const invitationsList = () => {
    return props.data.map((item, idx) => (
      <TouchableOpacity
        onPress={() => props.action("open", item.invite)}
        activeOpacity={0.5}
        key={item.invite}
      >
        <Card key={idx} style={styles.card}>
          <View style={styles.logoSection}>
            <View style={styles.logoWrapper}>
              <ImageUI style={styles.logo} source={item.logo} />
            </View>
          </View>
          <Text style={styles.name}>{item.name}</Text>
          <Text style={styles.location}>{item.location}</Text>
          <Text style={styles.inviteMsg}>{item.invitationMsg}</Text>
        </Card>
      </TouchableOpacity>
    ));
  };
  const getDisplayElement = () => {
    if (props.data.length) {
      return (
        <ScrollView
          style={{ paddingHorizontal: 20 }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {invitationsList()}
        </ScrollView>
      );
    }
    return (
      <View style={styles.noInviteWrapper}>
        <ImageUI
          source={require("@reachpartner/common/Assets/Images/no-invitation.png")}
          style={{ height: 200, width: 150 }}
          resizeMode="contain"
        />
        <TextUI
          style={{
            textAlign: "center",
            fontSize: 18,
            fontWeight: "700",
            marginBottom: 18,
          }}
        >
          No Invitation yet!
        </TextUI>
        <TextUI
          familyType="type-2"
          weight="700"
          style={{ textAlign: "center", marginBottom: 30 }}
          addOpacity={true}
        >
          Once a Business sends an invitation for you to join them, it will
          appear here.
        </TextUI>
        <View style={{ width: "100%" }}>
          <ButtonUI
            label="HOME"
            onPress={() => {
              props.action("navigateHome");
            }}
          />
        </View>
      </View>
    );
  };
  return (
    <>
      <View style={styles.messageWrapper}>
        <TextUI
          familyType="type-2"
          weight="700"
          style={{ lineHeight: 23 }}
          addOpacity={true}
        >
          Invitations received from Business to join them in the Reach Network
          appears here!
        </TextUI>
      </View>
      <View style={styles.listWrapper}>{getDisplayElement()}</View>
    </>
  );
}
const styles = StyleSheet.create({
  messageWrapper: {
    paddingHorizontal: 20,
    height: 70,
    display: "flex",
    justifyContent: "center",
  },
  message: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 23,
    color: "#24272C",
    opacity: 0.7,
  },
  listWrapper: { flex: 1 },
  card: {
    padding: 20,
    elevation: 5,
    marginVertical: 5,
    // marginHorizontal: 20,
  },
  logoSection: { display: "flex", alignItems: "center" },
  logoWrapper: {
    width: 70,
    height: 70,
  },
  logo: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    resizeMode: "contain",
  },
  name: {
    textAlign: "center",
    fontWeight: "700",
    fontSize: 20,
    lineHeight: 24,
    color: "#24272C",
  },
  location: {
    textAlign: "center",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 12,
    opacity: 0.7,
    color: "#24272C",
  },
  inviteMsg: {
    textAlign: "center",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 22,
    color: "#24272C",
    marginTop: 20,
  },
  noInviteWrapper: {
    display: "flex",
    alignItems: "center",
    paddingHorizontal: "10%",
    // marginTop: "10%",
    justifyContent: "center",
    height: "100%",
    marginBottom: 40,
    flex: 1,
  },
  noInviteImg: { height: 200, width: 115 },
  noInviteTitle: { fontSize: 18, fontWeight: "700", marginBottom: 15 },
  noInviteDesc: {
    fontSize: 12,
    fontWeight: "500",
    opacity: 0.8,
    marginBottom: 15,
  },
  noInviteBtn: { width: "100%" },
});
export default InvitationList;
