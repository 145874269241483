import React from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import BottomActionBar from "../../../Components/BottomActionBar";
import ButtonUI from "../../../Components/ButtonUI";
import DatepickerUI from "../../../Components/DatepickerUI";
import DropDownUI from "../../../Components/Dropdown";
import FormWrapper from "../../../Components/FormWrapper";
import OutlineButton from "../../../Components/OutlineButton";
import PhoneNumberInput from "../../../Components/PhoneNumberInput";
import Row from "../../../Components/Row";
import TextInputUI from "../../../Components/TextInputUI";
import TextUI from "../../../Components/TextUI";
import { StyleTheme } from "../../../Styles/CommonStyles";
import { validateEmail } from "../../../Utils";
import { genderList } from "../../../Utils/Constants/GenericConstants";
import { Switch, TextInput } from "react-native-paper";
import moment from "moment";
import ImageUI from "../../../Components/ImageUI";
import SectionLabel from "../../../Components/SectionLabel";
import { CountryList } from "../../../Utils/Constants/AppLevelConfig";
import LoaderUI from "../../../Components/Loader";
import LocationSearchInputModel from "../../../Components/LocationSearchInputModel";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

const defaultState = {
  first: "",
  last: "",
  email: "",
  mobile: "",
  landline: "",
  dob: "",
  gender: 1,
  marketing: false,
  image: "",
  country: "GBR",
  deliveryAddress: { default: 0, addressInfo: [{ rank: 1, address: "" }] },
};

interface IProps {
  initialState?: any;
  isUpdate: boolean;
  onSubmit: (data) => void;
  onDismiss: () => void;
  loader?: boolean;
  locationConfig?: any;
}
export default function CustomerForm(props: IProps) {
  const [formData, setFormData] = React.useState(defaultState);
  const [initialValidate, setInitialValidate] = React.useState(false);

  React.useEffect(() => {
    if (
      !props.loader &&
      props.isUpdate &&
      props.initialState &&
      Object.keys(props.initialState).length
    ) {
      setFormData(props.initialState);
    }
  }, [props.loader]);

  const hasInputError = (inputName: string, value: string) => {
    if (!initialValidate) {
      return false;
    }
    return hasError(inputName, value);
  };

  const hasError = (inputName: string, value: string) => {
    if (inputName === "email") {
      return !validateEmail(value);
    } else if (inputName === "mobile" || inputName === "landline") {
      return value.length < 13;
    }
    return value === "";
  };

  function handleChange(type: string, value: any) {
    setFormData((d: any) => ({
      ...d,
      [type]: value,
    }));
  }

  function submitForm() {
    props.onSubmit(formData);
  }

  function handleLocation(location) {
    const deliveryInfo = { ...formData.deliveryAddress };
    if (!deliveryInfo.addressInfo.length) {
      deliveryInfo.addressInfo = [{ rank: 1, address: location }];
    } else {
      deliveryInfo.addressInfo[0].address = location;
    }
    handleChange("deliveryAddress", deliveryInfo);
  }

  return (
    <>
      <ScrollView style={styles.body}>
        <Row center>
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/user-account.png")}
            style={{ height: 100, width: 100 }}
            resizeMode="contain"
          />
        </Row>
        <View style={{ height: 20 }} />
        <SectionLabel>Update your details</SectionLabel>
        <View style={{ height: 20 }} />
        <FormWrapper>
          <TextInputUI
            label="First Name"
            value={formData.first}
            error={hasInputError("businessName", formData.first)}
            onChangeText={(text) => handleChange(`first`, text)}
            errorHelpText={"Business name shouldn't be empty!"}
          />
          <TextInputUI
            label="Last Name"
            value={formData.last}
            error={hasInputError("businessName", formData.last)}
            onChangeText={(text) => handleChange(`last`, text)}
            errorHelpText={"Business name shouldn't be empty!"}
          />
          <TextInputUI
            label="Email Address"
            value={formData.email}
            error={hasInputError("businessName", formData.email)}
            onChangeText={(text) => handleChange(`email`, text)}
            errorHelpText={"Business name shouldn't be empty!"}
          />
          <PhoneNumberInput
            value={formData.mobile}
            onChange={(text) => handleChange("mobile", text)}
          />
          <PhoneNumberInput
            placeholder={"Landline Number"}
            value={formData.landline}
            onChange={(text) => handleChange("landline", text)}
          />
          <DropDownUI
            disabled={true}
            label={"Select Country"}
            value={formData.country}
            list={CountryList}
            setValue={() => { }}
          />
          <LocationSearchInputModel
            list={props.locationConfig.data}
            loading={props.locationConfig.isLoading}
            noDataMessage={props.locationConfig.errorMessage}
            onChangeText={(text) => handleLocation(text)}
            getData={props.locationConfig.getLocations}
            label="Search Location"
            placeholder={`Enter full postcode. Ex: "EC1V 2NX"`}
            onDismiss={() => {
              props.locationConfig.locationReset();
            }}
            renderItem={(item) => (
              <>
                <MaterialCommunityIcons
                  name={"map-marker"}
                  style={{ width: 20, opacity: 0.5 }}
                  size={14}
                />
                <View style={{ width: 10 }} />
                <TextUI familyType="type-2" weight="500">
                  {item}
                </TextUI>
              </>
            )}
          >
            <TextInputUI
              label="Location"
              value={formData.deliveryAddress?.addressInfo?.[0]?.address || ""}
            // error={hasInputError("location", formData.location)}
            // errorHelpText={"Location shouldn't be empty!"}
            />
          </LocationSearchInputModel>
          <DatepickerUI
            onChangeText={(date) => handleChange("dob", date)}
            value={formData.dob}
            range={{ endDate: new Date(moment().subtract(18, "year").format("DD-MM-YYYY")) }}
          >
            <TextInputUI
              label="Date of Birth"
              value={
                formData.dob ? moment(formData.dob).format("DD-MM-YYYY") : ""
              }
              placeholder="DD-MM-YYYY"
              onChangeText={(text) => { }}
              error={hasInputError("dob", formData.dob)}
              errorHelpText={"date of birth shouldn't be empty!"}
            />
          </DatepickerUI>
          <DropDownUI
            label={"Gender"}
            value={formData.gender}
            setValue={(val) => handleChange("gender", val)}
            list={genderList}
            inputProps={{
              right: <TextInput.Icon name={"menu-down"} />,
            }}
          />
          <Row spaceBetween>
            <TextUI weight="500">Permission for Marketing</TextUI>
            <Switch
              value={formData.marketing}
              color={StyleTheme.APP_COLOR}
              onValueChange={() =>
                handleChange("marketing", !formData.marketing)
              }
            />
          </Row>
        </FormWrapper>
      </ScrollView>
      <BottomActionBar>
        <OutlineButton
          onPress={() => {
            props.onDismiss();
          }}
          label="Cancel"
        />
        <View style={{ width: 30 }} />
        <ButtonUI
          style={{ flex: 1 }}
          onPress={() => submitForm()}
          label={props.isUpdate ? "Update" : "Create"}
        />
      </BottomActionBar>
      {props.loader && <LoaderUI mode="long" />}
    </>
  );
}
const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  fabActionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    backgroundColor: "rgba(0,0,0,0.7)",
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
});
