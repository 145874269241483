import React from "react";
import TextUI from "./TextUI";

export default function SectionLabel(props) {
  return (
    <TextUI weight="700" size={16}>
      {props.children}
    </TextUI>
  );
}
