import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import TextUI from "../../Components/TextUI";
import { StyleTheme } from "../../Styles/CommonStyles";
import Row from "../../Components/Row";
import { formatToTime } from "../../Utils";
interface IProps {
  data: Array<any>;
  handleChange(
    type: "change" | "remove" | "inc_count" | "dec_count",
    data
  ): void;
  activeItem: string;
  itemDetailSelection: any;
  timesList: Array<any>;
  symbol: string;
}

export default function SelectServiceDetails(props: IProps) {
  const { data } = props;

  return (
    <View style={{ marginBottom: 10 }}>
      {data.length !== 0 ? (
        data.map((item, idx) => {
          const employee = props.itemDetailSelection[item.item]?.employee || "";
          const employeeName =
            item.cost?.actual?.[0]?.employee.find(({ id }) => id === employee)
              ?.name || "";
          let duration = "";
          const selectedTime =
            props.itemDetailSelection[item.item]?.time || 9999;
          // const label =
          //   props.timesList
          //     .find(({ employee }) => employee)
          //     ?.slots?.find(({ value }) => value === selectedTime)?.label || "";
          // selectedTime !== 9999 &&
          //   label &&
          //   (duration = `${label} to ${moment(label, "h:mm A")
          //     .utcOffset(0, true)
          //     .add(item.duration * item.count, "minutes")
          //     .format("h:mm A")}`);
          selectedTime !== 9999 &&
            (duration = `${formatToTime(selectedTime)} to ${formatToTime(
              selectedTime + item.duration * item.count
            )}`);
          return (
            <TouchableOpacity
              style={[
                styles.itemWrapper,
                props.activeItem === item.item ? styles.activeItem : {},
              ]}
              key={idx}
              onPress={() => {
                props.handleChange("change", item);
              }}
              activeOpacity={0.8}>
              <View style={{ width: 80 }}>
                <View style={{ paddingBottom: 5 }}>
                  <Row spaceBetween>
                    <TouchableOpacity
                      style={styles.delete}
                      onPress={() => {
                        props.handleChange("dec_count", item);
                      }}>
                      <MaterialCommunityIcons
                        name={"minus"}
                        style={styles.viewAllIcon}
                      />
                    </TouchableOpacity>
                    <View>
                      <TextUI
                        size={14}
                        weight="500"
                        style={{ marginBottom: 5 }}>
                        {item.count}
                      </TextUI>
                    </View>
                    <TouchableOpacity
                      style={styles.delete}
                      onPress={() => {
                        props.handleChange("inc_count", item);
                      }}>
                      <MaterialCommunityIcons
                        name={"plus"}
                        style={styles.viewAllIcon}
                      />
                    </TouchableOpacity>
                  </Row>
                </View>
                <Row center>
                  <TextUI>
                    {" "}
                    {props.symbol} {(item.count * item.cost.select.value).toFixed(2)}
                  </TextUI>
                </Row>
              </View>
              <View style={styles.details}>
                <TextUI size={14} weight="500" style={{ marginBottom: 5 }}>
                  {item.name}
                </TextUI>
                <TextUI
                  size={10}
                  weight="500"
                  style={{ opacity: 0.7, marginBottom: 3 }}>
                  {duration} ({item.duration * item.count} min)
                </TextUI>
                <TextUI size={10} weight="500" style={{ opacity: 0.7 }}>
                  by {employeeName}
                </TextUI>
              </View>
              <View style={styles.discount}>
                <TextUI weight="500">
                  {props.symbol} {item.cost.select.value.toFixed(2)}
                </TextUI>
              </View>
            </TouchableOpacity>
          );
        })
      ) : (
        <View style={{ minHeight: 100, display: "flex" }}>
          <TextUI size={14} weight="700" style={{ textAlign: "center" }}>
            Services not Found
          </TextUI>
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 8,
  },
  delete: {
    width: 25,
    height: 25,
    backgroundColor: StyleTheme.APP_COLOR,
    // marginLeft: 5,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  details: { flex: 1, paddingLeft: 10 },
  discount: {},
  viewAllIcon: {
    color: "white",
    fontSize: 16,
  },
  viewAllWrapper: {
    width: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  activeItem: {
    backgroundColor: "#EAF4FF",
  },
});
