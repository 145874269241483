import React from "react";
import { Appbar as RNAppbar } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { useNavigationState } from "@react-navigation/native";
import RootNavigation from "../Navigation/RootNavigation";
import RouteNames from "../Utils/Constants/RouteNames";

interface AppbarInterface {
  title: string;
  goBack?: () => void;
  hideBack?: boolean;
  actions?: React.ReactNode;
}

export default function Appbar(props: AppbarInterface) {
  const { title = "", hideBack = false } = props;
  let stackLength = useNavigationState((state) => state.routes.length);
  const customGoBackAction = props.goBack;
  const isPrevRouteExist = stackLength > 1;

  const handleAction = () => {
    if (customGoBackAction) {
      customGoBackAction();
      return;
    } else if (isPrevRouteExist) {
      RootNavigation.goBack();
      return;
    } else {
      RootNavigation.navigationReset({
        index: 0,
        routes: [{ name: RouteNames.LandingPage.name }],
      });
    }
  };

  return (
    <RNAppbar
      style={{
        backgroundColor: "white",
        height: 50,
        zIndex: 1,
        paddingRight: 10,
      }}
    >
      {!hideBack && (
        <MaterialCommunityIcons
          name={"chevron-left"}
          style={{ fontSize: 28, fontWeight: "700" }}
          onPress={handleAction}
        />
      )}

      <RNAppbar.Content
        title={title}
        titleStyle={{
          fontFamily: "Rubik",
          fontWeight: "500",
          fontSize: 15,
          alignItems: "flex-start",
        }}
      />
      {props.actions}
    </RNAppbar>
  );
}
