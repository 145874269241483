import * as React from "react";
import { View, Text, StyleSheet, KeyboardTypeOptions } from "react-native";
import { TextInput } from "react-native-paper";
import { StyleTheme } from "../Styles/CommonStyles";

interface TextInputUIProps {
  error?: Boolean;
  label: string;
  onChangeText?: (text: string) => void;
  errorHelpText?: string;
  value: string;
  disabled?: boolean;
  pointerEvents?: "auto" | "none" | "box-none" | "box-only";
  type?: string;
  secureTextEntry?: boolean;
  onFocus?: () => void;
  onKeyPress?: any;
  placeholder?: string;
  prefix?: string;
  keyboardType?: KeyboardTypeOptions;
}

const TextInputUI = (props: TextInputUIProps) => {
  const { errorHelpText, error = false, ...inputProps } = props;

  return (
    <>
      <View
        style={[
          styles.wrapper,
          {
            borderColor: error
              ? StyleTheme.ERROR_COLOR
              : StyleTheme.BOX_BORDER_COLOR,
          },
        ]}
      >
        <TextInput
          {...inputProps}
          keyboardType={inputProps.keyboardType}
          mode="flat"
          style={[styles.textInput]}
          dense={true}
          underlineColor={"transparent"}
          theme={{
            colors: {
              text: "black",
              primary: "grey",
              placeholder: "grey",
              background: "transparent",
              error: "red",
            },
          }}
          left={
            props.prefix ? (
              <TextInput.Affix
                text={props.prefix}
                textStyle={{
                  position: "absolute",
                  left: 0,
                  top: 2,
                  fontWeight: "400",
                }}
              />
            ) : null
          }
          onFocus={() => props.onFocus && props.onFocus()}
        />
        <View style={styles.overflowLine} />
      </View>
      {error && (
        <Text
          style={[
            styles.helperText,
            {
              color: error
                ? StyleTheme.ERROR_COLOR
                : StyleTheme.BOX_BORDER_COLOR,
            },
          ]}
        >
          {errorHelpText}
        </Text>
      )}
    </>
  );
};
const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 5,
    borderWidth: 2,
    paddingBottom: 2,
    height: 48,
  },
  textInput: {
    backgroundColor: "white",
    flex: 1,
    justifyContent: "center",
    paddingVertical: 0,
    borderWidth: 0,
    borderColor: "transparent",
    lineHeight: 10,
    fontSize: 13,
    fontWeight: "500",
  },
  overflowLine: {
    borderTopColor: "white",
    borderTopWidth: 5,
    position: "absolute",
    width: "100%",
    bottom: 1,
  },
  helperText: {
    fontSize: 12,
    marginTop: 3,
    marginLeft: 10,
  },
});
export default TextInputUI;
