import moment, { Moment } from "moment";
import { Dimensions, Platform } from "react-native";
import LocalStorage from "../LocalStorage";

export const sortFunction = (
  a: { date: string },
  b: { date: string },
  desc?: boolean
) => {
  var dateA = moment(a.date).valueOf();
  var dateB = moment(b.date).valueOf();
  if (desc) {
    return dateA > dateB ? -1 : 1;
  }
  return dateA > dateB ? 1 : -1;
};

export const handleFirebaseInfo = async (payload: any) => {
  const { fire_user, tokenDetails } = payload;
  const user = fire_user;
  const { signInProvider, expirationTime, token } = tokenDetails;
  const { providerData } = user;
  const authInfo = {
    displayName: user.displayName || "",
    email: user.email || providerData[0].email || "",
    url:
      user.photoURL ||
      user.url ||
      "https://lh3.googleusercontent.com/a/AATXAJysGh1Dq5k4Ku1zy6tQxX5XqBWl1zYbKxs4g0vI=s96-c",
    providerId: signInProvider,
    expiryTime: new Date(expirationTime).getTime().toString().substring(0, 10),
    idToken: token,
  };

  let firstName = "";
  let lastName = "";

  //when it's apple signin, need to loop to get the details
  if (signInProvider === "apple.com") {
    authInfo.displayName = "";
    firstName = "unknown";
    lastName = "unknown";
    const userDetails = await LocalStorage.get("tempUserDetails");
    if (userDetails) {
      const { givenName, familyName } = userDetails || {};
      if (!familyName && givenName) {
        const [first, last] = givenName.split(" ");
        firstName = first;
        lastName = last;
      } else if (familyName && givenName) {
        firstName = givenName;
        lastName = familyName;
      }
    }
  } else {
    // non apple sign in
    let nameArr = authInfo.displayName.split(" ");
    if (nameArr.length > 1) {
      lastName = nameArr.pop();
    }
    firstName = nameArr.join(" ");
  }

  const data = {
    firstName,
    lastName,
    email: authInfo.email,
  };

  return { data, authInfo };
};

export const windowHeight =
  Platform.OS === "web" ? Dimensions.get("window").height : "100%";

export function getWindowHeight() {
  return Platform.OS === "web" ? Dimensions.get("window").height : "100%";
}

export const handleEmployeeInfo = (data) => {
  const {
    partners = [],
    country,
    first,
    last,
    location,
    email,
    phone,
    picture,
    aboutMe,
    dob,
    language,
    postal,
    gender,
    display,
  } = data;
  const employeeInfo = {
    country: country || "",
    firstName: first,
    lastName: last,
    location,
    email,
    phone,
    picture,
    aboutMe,
    dob,
    language,
    postal,
    gender,
    display,
    date: getDateString(),
  };
  return { employeeInfo, partners };
};

const getDateString = () => {
  const date = new Date();
  const a = new Date(date).toLocaleString("en-us", { weekday: "long" });
  const b = date.getDate();
  const c = new Date(date).toLocaleString("en-us", { month: "long" });
  return `${a}, ${b} ${c}`;
};

export const getTimegap = (d1: Moment, d2: Moment) => {
  let t = Math.floor(d1.diff(d2, "years", true));
  if (t >= 1) {
    return `${t} yr${t > 1 ? "s" : ""} ago`;
  }
  t = Math.floor(d1.diff(d2, "months", true));
  if (t >= 1) {
    return `${t} mo${t > 1 ? "s" : ""} ago`;
  }
  t = Math.floor(d1.diff(d2, "days", true));
  if (t >= 1) {
    return `${t} day${t > 1 ? "s" : ""} ago`;
  }
  t = Math.floor(d1.diff(d2, "hours", true));
  if (t >= 1) {
    return `${t} hr${t > 1 ? "s" : ""} ago`;
  }
  t = Math.floor(d1.diff(d2, "minutes", true));
  if (t >= 1) {
    return `${t} min${t > 1 ? "s" : ""} ago`;
  }
  t = Math.floor(d1.diff(d2, "seconds", true));
  if (t >= 1) {
    return `${t} sec${t > 1 ? "s" : ""} ago`;
  }
  return "";
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const formatBasketResponse = (data: any) => {
  const cloned = { ...data };
  const { itemInfo, summary = {} } = data;

  const getDuration = (input: number) => {
    let duration = "";
    if (Math.floor(input / 60) > 0) {
      duration += `${Math.floor(input / 60)} hr`;
    }
    let mins = input % 60;
    if (mins > 0) {
      duration !== "" && (duration += " : ");
      duration += `${mins} Mins`;
    }
    return duration;
  };
  const getTiming = (date: Moment, duration: number) => {
    const fromTime = moment(date).utcOffset(0);

    const toTime = moment(date).utcOffset(0).add(duration, "minutes");
    return fromTime.format("hh:mm A") + " - " + toTime.format("hh:mm A");
  };
  cloned.itemInfo = itemInfo.map((d: any) => {
    const {
      reference = "",
      employee = "",
      image = "",
      name = "",
      cost = {},
      count,
    } = d;
    let item: any = { reference, employee, image, name, count };
    var m = moment().utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.add({ hour: Math.floor(d.time / 60), minutes: d.time % 60 });
    item.id = d.item;
    item.timing = getTiming(m, d.duration * d.count);
    item.duration = getDuration(d.duration * d.count);
    item.notes = d.notice;
    item.cost = {
      ...cost,
      actual: cost?.select?.value || "",
      toPay: cost?.select?.toPay || "",
      discountInfo: {
        discount: cost?.select?.discount?.value || "",
      },
    };
    item.employeeName =
      cost?.actual?.[0]?.employee.find((_x) => _x.id === employee)?.name || "";
    return item;
  });
  const details = itemInfo.reduce(
    (acc, item) => {
      return {
        ...acc,
        totalDuration: acc.totalDuration + (item.duration * item.count),
        start: item.time < acc.start ? item.time : acc.start,
        end:
          item.time + item.duration > acc.end
            ? item.time + (item.duration * item.count)
            : acc.end,
      };
    },
    {
      totalDuration: 0,
      start: 9999,
      end: 0,
    }
  );
  cloned.details = {
    date: moment(cloned.date).format("dddd, DD MMMM YYYY"),
    time: formatToTime(details.start),
    duration1: formatToTime(details.start) + " - " + formatToTime(details.end),
    duration2: formatGetDuration(details.totalDuration),
  };
  cloned.currency = summary.currency || {};
  cloned.totalCost = summary?.total || 0;
  return cloned;
};

export const formatToTime = (d: number) => {
  if (d >= 1440) {
    return "";
  }
  const h = Math.floor(d / 60) % 12;
  const r = d % 60;
  const format = d >= 720 ? "PM" : "AM";
  return `${h === 0 ? "12" : h >= 10 ? h : "0" + h}:${r >= 10 ? r : "0" + r
    } ${format}`;
};

export function formatGetDuration(d) {
  // 150 ------>  2 Hrs 30 Mins
  const hours = Math.floor(d / 60);
  const minutes = d % 60;
  const hrString =
    hours === 1 ? hours + " Hr" : hours > 1 ? hours + " Hrs" : "";
  const minString =
    minutes === 1 ? minutes + " Min" : minutes >= 0 ? minutes + " Mins" : "";
  return `${hrString}${hrString && minString ? " : " : ""}${minString}`;
}

export async function prepareFileUploadPayload(data: any) {
  const linkedId = await LocalStorage.get("linkedId");
  const formData = new FormData();
  const file = data;
  formData.append("mediaFile", file);
  let payload: any = {
    public: 1,
    folder: "profile",
    name: data.name,
    owner: {
      id: linkedId,
      type: 2,
    },
    type: 2,
    tag: ["#profile"],
  };
  formData.append("mediaInfo", JSON.stringify(payload));
  return formData;
}

export function getEnumReverseMapping(enums) {
  return Object.entries(enums).reduce((acc, item: any) => {
    const key = item[1].toLowerCase().replaceAll(" ", "_");
    acc[key] = item[0];
    return acc;
  }, {});
}

export function getCurrentTimeStamp() {
  return new Date().getTime();
}

export const isValidPostCode = (input: string) => {
  const regexPattern = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
  const isValid = regexPattern.test(input);
  return isValid;
}
