import React from "react";
import { connect } from "react-redux";
import firebase from "@reachpartner/common/Firebase";
import AbstractLogin from "@reachpartner/common/+Login";
import Actions from "@reachpartner/common/Actions";
import { IUserStore } from "@reachpartner/common/Models";
import { NavigationProps } from "@reachpartner/common/Models/interface";

interface IProps extends NavigationProps {
  user: IUserStore;
  getFirebaseUserInfo: () => void;
  reachSignInSuceess: (flag: boolean) => void;
}

function Login(props: IProps) {
  const googleSignin = async () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    socialAuthSigninWeb(googleAuthProvider);
    return;
  };
  const twitterSignin = () => {
    const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
    socialAuthSigninWeb(twitterAuthProvider);
  };
  const facebookSignin = () => {
    const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
    socialAuthSigninWeb(facebookAuthProvider);
  };
  const socialAuthSigninWeb = async (provider: any) => {
    try {
      await firebase.auth().signInWithPopup(provider);
      props.getFirebaseUserInfo();
    } catch (err) {
      console.log(err);
    }
  };
  const handleAction = (type: string) => {
    if (type === "google") {
      googleSignin();
    } else if (type === "facebook") {
      facebookSignin();
    } else if (type === "twitter") {
      twitterSignin();
    }
  };
  return <AbstractLogin {...props} action={handleAction} />;
}

const mapStateToProps = (store: any) => {
  return {
    user: store.user,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  reachSignInSuceess: (flag: boolean) =>
    dispatch(Actions.reachSignInSuceess(flag)),
  getFirebaseUserInfo: () => dispatch(Actions.getFirebaseUserInfo()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
