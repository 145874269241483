import React, { useCallback, useState } from "react";
import { View, StyleSheet } from "react-native";
import { StyleTheme } from "../../Styles/CommonStyles";
import { useFocusEffect } from "@react-navigation/native";
import TextUI from "../../Components/TextUI";

interface IProps {
  percent: number;
  children?: React.ReactNode;
  size: number;
  timerRunOut: () => void;
}
const BACKGROUND_COLOR = "#E9E9FF";
const ACTIVE_COLOR = StyleTheme.APP_COLOR;
export default function CircularProgressUI(props: IProps) {
  const { percent, size } = props;
  const [count, setCount] = useState(0);
  const bw = 10;

  useFocusEffect(
    useCallback(() => {
      if (props.percent !== 0) {
        setCount(props.percent);
      }
    }, [props.percent])
  );
  useFocusEffect(
    useCallback(() => {
      let timer;
      if (count !== 0) {
        timer = setTimeout(() => {
          if (count === 1) {
            props.timerRunOut();
          }
          setCount((t) => t - 1);
        }, 1000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [count])
  );

  const counter = (count / 180) * 100;
  const q = Math.floor(count / 60);
  const r = count % 60;
  const time = `0${q}:${r >= 10 ? r : "0" + r}`;
  const propStyle = (percent, base_degrees) => {
    const rotateBy = base_degrees + percent * 3.6;
    return {
      transform: [{ rotateZ: `${rotateBy}deg` }],
    };
  };
  const renderThirdLayer = (percent) => {
    if (percent > 50) {
      /**
       * Third layer circle default is 45 degrees, so by default it occupies the right half semicircle.
       * Since first 50 percent is already taken care  by second layer circle, hence we subtract it
       * before passing to the propStyle function
       **/
      return (
        <View
          style={[
            styles.secondProgressLayer,
            propStyle(percent - 50, 45),
            { height: size, width: size, borderWidth: bw },
          ]}
        ></View>
      );
    } else {
      return (
        <View
          style={[
            styles.offsetLayer,
            { height: size, width: size, borderWidth: bw },
          ]}
        ></View>
      );
    }
  };
  let firstProgressLayerStyle;
  if (counter > 50) {
    firstProgressLayerStyle = propStyle(50, -135);
  } else {
    firstProgressLayerStyle = propStyle(counter, -135);
  }

  return (
    <View
      style={[styles.container, { height: size, width: size, borderWidth: bw }]}
    >
      <View
        style={[
          styles.firstProgressLayer,
          firstProgressLayerStyle,
          { height: size, width: size, borderWidth: bw },
        ]}
      ></View>
      {renderThirdLayer(counter)}
      <View>
        <TextUI>{time}</TextUI>
      </View>
      {/* <View style={{ backgroundColor: "red" }}>{props.children}</View> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 100,
    borderColor: BACKGROUND_COLOR,
    justifyContent: "center",
    alignItems: "center",
  },
  firstProgressLayer: {
    borderRadius: 100,
    position: "absolute",
    borderLeftColor: "transparent",
    borderBottomColor: "transparent",
    borderRightColor: ACTIVE_COLOR,
    borderTopColor: ACTIVE_COLOR,
    transform: [{ rotateZ: "-135deg" }],
  },
  secondProgressLayer: {
    position: "absolute",
    borderRadius: 100,
    borderLeftColor: "transparent",
    borderBottomColor: "transparent",
    borderRightColor: ACTIVE_COLOR,
    borderTopColor: ACTIVE_COLOR,
    transform: [{ rotateZ: "45deg" }],
  },
  offsetLayer: {
    position: "absolute",
    borderRadius: 100,
    borderLeftColor: "transparent",
    borderBottomColor: "transparent",
    borderRightColor: BACKGROUND_COLOR,
    borderTopColor: BACKGROUND_COLOR,
    transform: [{ rotateZ: "-135deg" }],
  },
});
