import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { CommonStyles } from "../../Styles/CommonStyles";
import TextUI from "../../Components/TextUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Checkbox from "../../Components/Checkbox";

export const filterMockup = [
  {
    color: "#03C25A",
    label: 5,
    id: "five",
  },
  {
    color: "#03C25A",
    label: 4,
    id: "four",
  },
  {
    color: "#FFA236",
    label: 3,
    id: "three",
  },
  {
    color: "#FF3535",
    label: 2,
    id: "two",
  },
  {
    color: "#FF3535",
    label: 1,
    id: "one",
  },
];

interface IProps {
  rating: any;
  noOfReviews: number;
  handleChange: (val: Array<any>) => void;
  selectedFilters: Array<any>;
}
export default function ReviewFilter(props: IProps) {
  const { noOfReviews, rating } = props;
  const [expanded, setExpanded] = useState(true);

  function handleCheckBox(key) {
    if (props.selectedFilters.includes(key)) {
      props.handleChange([...props.selectedFilters.filter((i) => i !== key)]);
    } else {
      props.handleChange([...props.selectedFilters, key]);
    }
  }
  return (
    <View style={{ padding: 20, backgroundColor: "white" }}>
      <View style={[CommonStyles.rowSpaceBetween]}>
        <TextUI>Filters</TextUI>
        <TouchableOpacity
          onPress={() => {
            setExpanded(!expanded);
          }}
          activeOpacity={0.9}
        >
          <MaterialCommunityIcons name={"chevron-down"} size={24} />
        </TouchableOpacity>
      </View>
      {expanded &&
        filterMockup.map((item, idx) => {
          const percRating = (rating[item.id] / noOfReviews) * 100;
          return (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              key={idx}
            >
              <Checkbox
                active={props.selectedFilters.includes(item.label)}
                onPress={() => {
                  handleCheckBox(item.label);
                }}
              />
              <TextUI style={{ marginLeft: 5, marginRight: 5 }}>
                {item.label}
              </TextUI>
              <MaterialCommunityIcons name="star" color="#E1E3E4" size={14} />
              <View
                style={{
                  flex: 1,
                  height: 10,
                  marginHorizontal: 5,
                  backgroundColor: "#E1E3E4",
                  borderRadius: 5,
                }}
              >
                <View
                  style={{
                    height: "100%",
                    width: noOfReviews
                      ? `${percRating > 100 ? 100 : percRating}%`
                      : "0%",
                    backgroundColor: item.color,
                    borderRadius: 5,
                  }}
                />
              </View>
              <TextUI>{rating[item.id]}</TextUI>
            </View>
          );
        })}
    </View>
  );
}
