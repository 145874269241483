import React from "react";
import { View, ActivityIndicator, StyleSheet } from "react-native";
import { boxShadow, StyleTheme } from "../Styles/CommonStyles";

interface IProps {
  mode?: "long" | "small";
  size?: number;
}
function LoaderUI(props: IProps) {
  const element = (
    <View
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <View style={[props.mode === "long" ? styles.shadowBackground : {}]}>
        <ActivityIndicator
          size={props.size || "large"}
          color={StyleTheme.APP_COLOR}
        />
      </View>
    </View>
  );
  if (props.mode === "long") {
    return (
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        {element}
      </View>
    );
  }
  return element;
}
const styles = StyleSheet.create({
  shadowBackground: {
    backgroundColor: "white",
    padding: 20,
    paddingHorizontal: 30,
    borderRadius: 4,
    ...boxShadow(),
  },
});
export default LoaderUI;
