import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import moment from "moment";
import {
  BookingReducerInterface,
  IBasketStore,
} from "../Models/StoreInterfaces";
import AppointmentList from "../Components/AppointmentList";
import Appbar from "../Components/Appbar";
import { NavigationProps } from "../Models/interface";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
import TextUI from "../Components/TextUI";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";
import PartnersList from "./Components/PartnersList";
import FabUI from "../Components/FabUI";
import { useIsFocused } from "@react-navigation/native";
import { IBusinessStore } from "../Store/Reducers/businessReducer";

interface IAppointmentsProps extends NavigationProps {
  getBookings: (payload: any) => void;
  booking: BookingReducerInterface;
  business: IBusinessStore;
  basket: IBasketStore;
  resetBasket: () => void;
}
interface ITab {
  key: string;
  title: string;
  from: moment.Moment;
  to: moment.Moment;
  message: string;
}
const initialTabs: Array<ITab> = [
  {
    key: "today",
    title: "Today",
    from: moment(),
    to: moment(),
    message:
      "No Appointments---No appointments for today. When an appointment is created it will appear here",
  },
  {
    key: "3days",
    title: "+3 days",
    from: moment().add(1, "day"),
    to: moment().add(3, "day"),
    message:
      "No Appointments---No appointments for next 3 days. When an appointment is created it will appear here",
  },
  {
    key: "7days",
    title: "+7 days",
    from: moment().add(1, "day"),
    to: moment().add(7, "day"),
    message:
      "No Appointments---No appointments for next 7 days. When an appointment is created it will appear here",
  },
  {
    key: "later",
    title: "Later",
    from: moment().add(8, "day"),
    to: moment().add(38, "day"),
    message:
      "No Appointments---No appointments for next 30 days. When an appointment is created it will appear here",
  },
  {
    key: "past",
    title: "Past",
    from: moment().subtract(12, "months"),
    to: moment().subtract(1, "day"),
    message:
      "No Appointments---No appointments in last 30 days. When an appointment is created it will appear here",
  },
];

function Appointments(props: IAppointmentsProps) {
  const { partners } = props.business;
  const [routes, setRoutes] = useState<Array<ITab>>([]);
  const IsFocussed = useIsFocused();
  const [selectedPartner, setSelectedPartner] = useState("");
  const [activeKey, setActiveKey] = useState("today");
  const [showOptions, setShowOptions] = useState(false);
  const offset = useRef(0);
  const viewAppointmentRef = useRef(false);
  const {
    appointments: { isLoading, data },
  } = props.booking;

  const { result = [] } = data.key === activeKey ? data : {};

  //get initial data
  useEffect(() => {
    setRoutes(initialTabs);
    partners.length === 1 && setSelectedPartner(partners[0].partner);
  }, []);

  //resetting the basket details here
  useEffect(() => {
    if (IsFocussed) {
      // if (!viewAppointmentRef.current) {
      getInitialData();
      offset.current = 0;
      // }
      viewAppointmentRef.current = false;
      props.resetBasket();
    }
  }, [IsFocussed]);

  //get initial data
  const getInitialData = async () => {
    offset.current = 0;
    const idx = initialTabs.findIndex((item) => item.key === activeKey);
    if (idx !== -1) {
      getBookings(initialTabs[idx]);
    }
  };

  function handleTabChange(key) {
    offset.current = 0;
    const route = routes.find((item) => item.key === key);
    getBookings(route);
    setActiveKey(key);
  }

  async function getBookings(route, partner?: string) {
    const { from, to, key } = route;
    const linkedId = await LocalStorage.get("linkedId");
    const payload = {
      from: from,
      to: to,
      offset: offset.current * 10,
      limit: 10,
      key: key,
      linkedId,
      partner: partner === "" ? "" : partner || selectedPartner,
    };
    props.getBookings(payload);
  }
  const noDataMessage = routes.find((item) => item.key === activeKey)
    ? routes.find((item) => item.key === activeKey)?.message
    : "";

  const handlePartner = (val) => {
    if (val === selectedPartner) {
      return;
    }
    setSelectedPartner(val);
    offset.current = 0;
    const route = routes.find((item) => item.key === activeKey);
    getBookings(route, val);
  };

  const loadDataLazily = (options) => {
    offset.current = options.offset;
    const route = routes.find((item) => item.key === activeKey);
    getBookings(route);
  };
  return (
    <>
      <Appbar title={"Appointments"} hideBack={true} />
      <View style={styles.body}>
        {props.business.partners.length ? (
          <View style={{ marginBottom: 10, marginLeft: 15 }}>
            <TextUI weight="500">Select Business</TextUI>
            <PartnersList
              data={props.business.partners}
              action={handlePartner}
              selected={selectedPartner}
            />
          </View>
        ) : (
          <View />
        )}
        <View style={styles.tabs}>
          {routes.map((item) => {
            const tabClass: any = {
              borderBottomWidth: 2,
              paddingBottom: 10,
              fontWeight: "500",
              borderBottomColor:
                item.key === activeKey ? "#2196F3" : "transparent",
              textAlign: "center",
              color: item.key === activeKey ? "#2196F3" : "#24272C",
              opacity: 0.9,
            };
            return (
              <TouchableOpacity
                key={item.key}
                style={styles.tab}
                onPress={() => handleTabChange(item.key)}>
                <TextUI style={tabClass}>{item.title}</TextUI>
              </TouchableOpacity>
            );
          })}
        </View>
        <View style={{ flex: 1 }}>
          <AppointmentList
            appointmentsInfo={{ loading: isLoading, data: result }}
            action={(type, id) => {
              props.navigation.navigate(RouteNames.Booking.name, {
                bid: id,
              });
              viewAppointmentRef.current = true;
            }}
            options={{
              total: props.booking.appointments.data.total,
              offset: offset.current,
            }}
            getData={loadDataLazily}
            noDataMessage={noDataMessage}
          />
        </View>
      </View>
      {partners.length !== 0 && (
        <FabUI
          showOptions={showOptions}
          action={(val) => {
            if (val && partners.length == 1) {
              props.navigation.navigate(RouteNames.NewBasket.name, {
                pid: partners[0].partner,
              });
            } else {
              setShowOptions(val);
            }
          }}
          options={
            <View style={styles.partnerSelectPopup}>
              <TextUI style={{ height: 20, lineHeight: 20, marginBottom: 4, fontWeight: "500" }}>
                Select Partner
              </TextUI>
              {partners.map((item: IBusinessStore["partners"][0], idx) => (
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={() => {
                    setShowOptions(false);
                    props.navigation.navigate(RouteNames.NewBasket.name, {
                      pid: item.partner,
                    });
                  }}
                  key={item.partner}>
                  <TextUI
                    key={item.partner}
                    style={{
                      paddingTop: 6,
                      paddingBottom: 6,
                      borderBottomWidth: idx >= partners.length - 1 ? 0 : 1,
                      borderColor: "lightgrey",
                    }}>
                    {item.name}
                  </TextUI>
                </TouchableOpacity>
              ))}
            </View>
          }
        />
      )}
    </>
  );
}
const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    flex: 1,
    paddingTop: 20,
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(0,0,0,.12)",
    marginHorizontal: 15,
  },
  tab: {
    flex: 1,
    alignItems: "center",
  },
  tabTitle: {
    fontSize: 15,
    fontWeight: "500",
    borderBottomWidth: 2,
    paddingBottom: 10,
    borderColor: "transparent",
    textAlign: "center",
  },
  activeTab: {
    borderBottomColor: "#2196F3",
    color: "#2196F3",
  },
  partnerSelectPopup: {
    backgroundColor: "white",
    padding: 10,
    width: 200,
    borderRadius: 4,
  },
});
const mapStateToProps = (store: any) => {
  return {
    booking: store.booking,
    business: store.business,
    basket: store.basket,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  getBookings: (payload: any) => dispatch(Actions.getBookings(payload)),
  resetBasket: () => dispatch(Actions.resetBasketFormData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
