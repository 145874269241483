// ===================================================================
// CUSTOM NETWORK COMPONENT
// ===================================================================
import React, { useCallback } from "react";
import { View, StyleSheet, ScrollView, ViewStyle } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import Appbar from "../../../Components/Appbar";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import TextInputUI from "../../../Components/TextInputUI";
import SectionLabel from "../../../Components/SectionLabel";
import { formatGetDuration } from "../../../Utils";
import TextArea from "../../../Components/TextArea";
import DurationPicker from "../../../Components/DurationPicker";
import DropDownUI from "../../../Components/Dropdown";
import EnumMapping from "../../../Utils/Constants/EnumMapping";
import { CommonStyles } from "../../../Styles/CommonStyles";
import ButtonUI from "../../../Components/ButtonUI";
import SelectNetworks from "../../../Components/SelectNetworks";
import Chips from "../../../Components/Chips";
import TextUI from "../../../Components/TextUI";
import LoaderUI from "../../../Components/Loader";
import EnableForBooking from "../../../Components/EnableForBooking";
import BottomActionBar from "../../../Components/BottomActionBar";
import Page from "../../../Components/Page";
import OutlineButton from "../../../Components/OutlineButton";
import Row from "../../../Components/Row";
import ImageUI from "../../../Components/ImageUI";
import Modal from "../../../Components/Modal";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getPartnerGroupIndustryLevel: (
    partnerId: string,
    industryId: string,
    callback: (data: any) => void
  ) => void;
  createPartnerService: (
    partnerId: string,
    isUpdate: boolean,
    payload: any,
    callback: (data: any) => void
  ) => void;
  getServiceDetails: (partnerId: string, callback: (data: any) => void) => void;
}
type IProps = IMapState &
  IMapDispatch & {
    serviceParam: string;
    partnerId: string;
    navigationPop: () => void;
  };
// ===================================================================
// COMPONENT
// ===================================================================
const bookingType = {};
const defaultValues = {
  name: "",
  desc: "",
  duration: 0,
  industry: "",
  serviceType: "1",
  costType: "1",
  serviceParent: "1",
  parentServices: [],
  message: "",
  before: 0,
  after: 0,
  serviceLevels: [],
  enableForBooking: false,
  employees: [],
};

const flow = {
  "clone": {
    name: "clone",
    shouldShowCloneButton: false
  },
  "add": {
    name: "add",
    shouldShowCloneButton: false
  },
  "default": {
    name: "default",
    shouldShowCloneButton: true
  }
}

function AddorUpdateService(props: IProps) {
  const [formData, setFormData] = React.useState(defaultValues);
  const [initialValidate, setInitialValidate] = React.useState(false);
  const [showSelectService, setShowSelectService] = React.useState(false);
  const [showErrorPopup, setShowErrorPopup] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [cloneService, setCloneService] = React.useState<boolean>(false);
  const [cloneServiceName, setCloneServiceName] = React.useState<string>(formData.name)
  const [notAllowedCostType, setNotAllowedCostType] = React.useState<string>("")
  const serviceParam = props.serviceParam;
  const [activeFlow, setFlow] = React.useState({ ...(serviceParam === "add" ? flow.add : flow.default) })

  useFocusEffect(
    useCallback(() => {
      handleChange(
        "industry",
        props.businessStore.industries.data?.[0]?.id || ""
      );

      if (serviceParam !== "add") {
        getServiceDetails();
      } else {
        getPartnerGroupIndustryLevel(
          props.businessStore.industries.data?.[0]?.id || ""
        );
      }
    }, [])
  );

  function getServiceDetails() {
    props.getServiceDetails(serviceParam, (data) => {
      if (!data.fail) {
        setData(data);
      }
    });
  }

  function setData(data) {
    const newFormData = { ...formData };
    newFormData.name = data.name;
    newFormData.desc = data.desc;
    newFormData.industry = data.industry.id;
    newFormData.duration = data.duration;
    newFormData.employees = data.cost?.[0]?.employee || [];
    newFormData.before = data.addTime.before || 0;
    newFormData.after = data.addTime.after || 0;
    newFormData.serviceLevels = data.cost.map(({ level, value }) => ({
      id: level.id,
      name: level.name,
      price: value,
    }));
    newFormData.serviceType = data.type.toString();
    newFormData.enableForBooking = data.enableForBooking;
    newFormData.costType = data.costType;
    newFormData.message = data.message;
    newFormData.serviceParent = data.parentInfo.length ? "2" : "1";
    newFormData.parentServices = data.parentInfo.map(({ id }) => id);
    setFormData((d: any) => newFormData);
    setCloneServiceName(() => newFormData.name)
  }

  const handleCloneServiceModal = () => {
    setCloneService((prevState) => !prevState)
  };

  const handleCloneServiceSubmit = () => {
    const payload = {
      name: cloneServiceName,
      desc: formData.desc,
      duration: formData.duration,
      industry: formData.industry,
      cost: formData.serviceLevels.map((item) => ({
        level: item.id,
        value: Number(item.price),
      })),
      message: formData.message,
      photo: [],
      form: [],
      addTime: {
        before: formData.before,
        after: formData.after,
      },
      discount: [],
      enableForBooking: formData.enableForBooking,
      type: Number(formData.serviceType),
      costType: Number(formData.costType),
      parent: formData.parentServices,
    };
    let id = props.partnerId;
    let isUpdate = false;
    props.createPartnerService(id, isUpdate, payload, (data) => {
      if (!data.fail) {
        handleCloneServiceModal();
        props.navigationPop();
      } else {
        setShowErrorPopup(true);
        setErrorMessage(data.mesg);
      }
    });
  };

  const validateForm = () => {
    const validateFields =
      formData.name !== "" &&
      formData.desc !== "" &&
      formData.duration >= 0 &&
      formData.industry !== "";
    return validateFields;
  };

  function getPartnerGroupIndustryLevel(id) {
    const partnerId = props.partnerId;
    const industryId = id;
    if (!id) {
      return;
    }
    props.getPartnerGroupIndustryLevel(partnerId, industryId, (data) => {
      if (data.fail) {
        return;
      }
      const levels = data.result[0].linked.level;
      setFormData((d: any) => ({
        ...d,
        serviceLevels: levels.map((item) => {
          item.price = 0;
          return item;
        }),
      }));
    });
  }

  function handleChange(type: string, value: any) {
    setFormData((d: any) => ({
      ...d,
      [type]: value,
    }));
  }

  function submitData() {
    if (!validateForm()) {
      !initialValidate && setInitialValidate(true);
      return;
    }
    const payload = {
      name: activeFlow.name === "clone" ? cloneServiceName : formData.name,
      desc: formData.desc,
      duration: formData.duration,
      industry: formData.industry,
      cost: formData.serviceLevels.map((item) => ({
        level: item.id,
        value: Number(item.price),
      })),
      message: formData.message,
      photo: [],
      form: [],
      addTime: {
        before: formData.before,
        after: formData.after,
      },
      discount: [],
      enableForBooking: formData.enableForBooking,
      type: Number(formData.serviceType),
      costType: Number(formData.costType),
      parent: formData.parentServices,
    };
    let id = props.partnerId;
    let isUpdate = false;
    if (serviceParam !== "add") {
      isUpdate = true;
      id = serviceParam;
    }
    if (activeFlow.name === "clone") {
      id = props.partnerId;
      isUpdate = false;
    }
    props.createPartnerService(id, isUpdate, payload, (data) => {
      if (!data.fail) {
        props.navigationPop();
      } else {
        setShowErrorPopup(true);
        setErrorMessage(data.mesg);
      }
    });
  }

  function renderServiceLevels() {
    const rowStyles: ViewStyle = {
      paddingHorizontal: 10,
      paddingVertical: 10,
      ...CommonStyles.rowSpaceBetween,
    };
    return (
      <View
        style={[
          { ...CommonStyles.boxContainer },
          { paddingHorizontal: 0, paddingVertical: 0, borderRadius: 4 },
        ]}
      >
        {/* Header */}
        <View style={[rowStyles, { backgroundColor: "rgba(36, 39, 44, 0.1)" }]}>
          <TextUI weight="500">{formData.costType === "1" ? "Service Level" : "Cost Unit"}</TextUI>
          <TextUI weight="500">Cost</TextUI>
        </View>
        {/* {formData.serviceLevels.map((item) => ( */}
        <View style={[rowStyles]}>
          <TextUI>{EnumMapping.ServiceLevel[formData.costType] || ""}</TextUI>
          <View style={[CommonStyles.rowSpaceBetween]}>
            <TextUI weight="500">£</TextUI>
            <View style={{ width: 60, marginLeft: 10 }}>
              <TextInputUI
                label=""
                value={formData?.serviceLevels[0]?.price.toString() || "0"}
                placeholder="0.0"
                onChangeText={(val) => {
                  const service = {
                    id: formData?.serviceLevels[0]?.id,
                    name: EnumMapping.ServiceLevel[formData.costType],
                    price: val
                  }
                  handleChange("serviceLevels", [service]);
                }}
              />
            </View>
          </View>
        </View>
        {/* ))} */}
      </View>
    );
  }

  return (
    <Page>
      <Appbar
        title={serviceParam !== "add" ? "Update Service" : "Add Service"}
      />
      <ScrollView style={styles.body}>
        <SectionLabel>Update your service details</SectionLabel>
        <View style={{ height: 15 }} />
        <TextInputUI
          label="Service Name"
          value={formData.name}
          error={initialValidate && formData.name === ""}
          onChangeText={(text) => handleChange("name", text)}
          errorHelpText={"Enter service name"}
        />
        <View style={{ height: 10 }} />
        <TextArea
          label="Service Description"
          value={formData.desc}
          error={initialValidate && formData.desc === ""}
          errorHelpText="Enter description"
          onChangeText={(val) => handleChange("desc", val)}
        />
        <View style={{ height: 10 }} />
        <DurationPicker
          value={formData.duration}
          onChangeText={(val) => handleChange("duration", val)}
        >
          <TextInputUI
            label="Select Duration"
            value={formatGetDuration(formData.duration)}
            onChangeText={(text) => { }}
            error={initialValidate && formData.duration < 0}
            errorHelpText={"Enter valid duration"}
          />
        </DurationPicker>
        <View style={{ height: 10 }} />
        <DropDownUI
          list={props.businessStore.industries.data}
          value={formData.industry}
          keyName={"name"}
          keyId={"id"}
          setValue={(val) => {
            getPartnerGroupIndustryLevel(val);
            handleChange("industry", val);
          }}
          label="Industry"
        />
        <View style={{ height: 10 }} />
        <DropDownUI
          list={Object.keys(EnumMapping.ServiceType).map((i) => ({
            name: EnumMapping.ServiceType[i],
            id: i,
          }))}
          value={formData.serviceType.toString()}
          keyName={"name"}
          keyId={"id"}
          setValue={(val) => handleChange("serviceType", val)}
          label="Service Booking Type"
        />
        {formData.serviceType === "2" && (
          <>
            <View style={{ height: 10 }} />
            <DropDownUI
              list={Object.keys(EnumMapping.ServiceParent).map((i) => ({
                name: EnumMapping.ServiceParent[i],
                id: i,
              }))}
              value={formData.serviceParent.toString()}
              keyName={"name"}
              keyId={"id"}
              setValue={(val) => handleChange("serviceParent", val)}
              label="Service Parent"
            />
          </>
        )}
        {formData.serviceParent === "2" && formData.serviceType === "2" && (
          <>
            <View style={{ height: 15 }} />
            <SectionLabel>Selected Parent Service</SectionLabel>
            <View style={{ height: 10 }} />
            <Chips>
              {props.businessStore.services.data
                .filter(({ id }) => formData.parentServices.includes(id))
                .map(({ name, id }) => (
                  <Chips.Selected
                    key={id}
                    action={() => {
                      handleChange(
                        "parentServices",
                        formData.parentServices.filter((x) => x !== id)
                      );
                    }}
                  >
                    {name}
                  </Chips.Selected>
                ))}
              <Chips.Add action={() => setShowSelectService(true)}>
                Add Service
              </Chips.Add>
            </Chips>
          </>
        )}
        {/* <View style={{ height: 15 }} />
        <ImageUpload tempUrl="" picture="" handleUpload={() => {}} />
        <View style={[CommonStyles.rowSpaceBetween]}>
          <SectionLabel>Service Photos</SectionLabel>
          <TouchableOpacity>
            <TextUI>+ Add photos</TextUI>
          </TouchableOpacity>
        </View> */}
        <View style={{ height: 15 }} />
        <SectionLabel>Service Cost</SectionLabel>
        <View style={{ height: 15 }} />
        <DropDownUI
          list={Object.keys(EnumMapping.CostType).map((i) => ({
            name: EnumMapping.CostType[i],
            id: i,
          }))}
          value={formData?.costType?.toString()}
          keyName={"name"}
          keyId={"id"}
          setValue={(val) => {
            handleChange("costType", val)
            if (["10", "11", "12"].includes(val)) {
              setNotAllowedCostType(val)
            }
          }}
          label="Cost Type"
        />
        <View style={{ height: 15 }} />
        <SectionLabel>Service Cost</SectionLabel>
        <View style={{ height: 10 }} />
        {renderServiceLevels()}
        <View style={{ height: 10 }} />
        <TextArea
          label="Message for clients"
          value={formData.message}
          onChangeText={(val) => handleChange("message", val)}
        />
        <View style={{ height: 20 }} />
        <SectionLabel>Staffs</SectionLabel>
        <View style={{ height: 15 }} />
        <Row>
          {formData.employees.map(({ id, name, image }) => {
            const firstName = (name && name.split(" ")?.[0]) || "";
            return (
              <View key={id} style={{ marginHorizontal: 10 }}>
                <ImageUI
                  source={image}
                  style={{ height: 70, width: 70, marginBottom: 10 }}
                />
                <TextUI weight="500" style={{ textAlign: "center" }}>
                  {firstName}
                </TextUI>
              </View>
            );
          })}
        </Row>
        {formData.employees.length === 0 && (
          <View style={{ marginVertical: 20 }}>
            <TextUI
              weight="500"
              size={16}
              style={{ textAlign: "center", opacity: 0.7 }}
            >
              No staff added
            </TextUI>
          </View>
        )}
        <View style={{ height: 20 }} />
        <SectionLabel>Additional Processing Time</SectionLabel>
        <View style={{ height: 10 }} />
        <DurationPicker
          value={formData.before}
          onChangeText={(val) => handleChange("before", val)}
        >
          <TextInputUI
            label="Before Appointment"
            value={formatGetDuration(formData.before)}
            onChangeText={(text) => { }}
            errorHelpText={"date of birth shouldn't be empty!"}
          />
        </DurationPicker>
        <View style={{ height: 10 }} />
        <DurationPicker
          value={formData.after}
          onChangeText={(val) => handleChange("after", val)}
        >
          <TextInputUI
            label="After Appointment"
            value={formatGetDuration(formData.after)}
            onChangeText={(text) => { }}
            errorHelpText={"date of birth shouldn't be empty!"}
          />
        </DurationPicker>
        {formData.employees.length !== 0 && (
          <>
            <View style={{ height: 20 }} />
            <EnableForBooking
              active={formData.enableForBooking}
              handleChange={(val) => handleChange("enableForBooking", val)}
            />
          </>
        )}
      </ScrollView>
      <BottomActionBar>
        <OutlineButton
          label="Cancel"
          onPress={() => props.navigationPop()}
          style={{ flex: 1 }}
          mode="outlined"
        />
        {(activeFlow.shouldShowCloneButton) && (<><View style={{ width: "2%" }} />
          <OutlineButton
            label="Clone"
            onPress={() => handleCloneServiceModal()}
            labelStyle={{ color: "white" }}
            style={{ flex: 1, backgroundColor: "#009688" }}
            mode="outlined"
          /></>)}
        <View style={{ width: "2%" }} />
        <ButtonUI
          label="Submit"
          onPress={submitData}
          style={{ flex: 1 }}
          disabled={["10", "11", "12"].includes(formData.costType)}
        />
      </BottomActionBar>
      {showSelectService && (
        <SelectNetworks
          data={props.businessStore.services.data.filter(
            ({ id }) => !formData.parentServices.includes(id)
          )}
          getData={() => { }}
          onDismiss={(data) => {
            setShowSelectService(false);
            if (data) {
              handleChange("parentServices", [
                ...formData.parentServices,
                ...data,
              ]);
            }
          }}
          headerLabel="Select Services"
        />
      )}
      {props.businessStore.isUpdating && <LoaderUI mode="long" />}
      {/* <ErrorPopup
        visible={showErrorPopup}
        onDismiss={() => setShowErrorPopup(false)}
        message={errorMessage}
      /> */}
      <Modal
        visible={notAllowedCostType !== ""}
        contentContainerStyle={{
          backgroundColor: "#007FFF",
          padding: 4,
          margin: 20,
          width: "95%",
        }}
      >
        <View>
          <TextUI
            style={{
              marginBottom: 20,
              fontWeight: "500",
              lineHeight: 22,
              opacity: 0.9,
              fontSize: 14,
            }}
          >
            {`${EnumMapping.CostType[formData.costType]} is not allowed currently. Please select other Cost Type.`}
          </TextUI>
          <ButtonUI
            label={"Okay"}
            onPress={() => {
              setNotAllowedCostType(() => "");
            }}
          />
        </View>
      </Modal>
      <Modal
        visible={cloneService}
        contentContainerStyle={{
          backgroundColor: "#007FFF",
          padding: 4,
          margin: 20,
          width: "95%",
        }}
      >
        <View style={{ padding: 6 }}>
          <TextUI
            style={{
              marginBottom: 10,
              fontWeight: "700",
              lineHeight: 22,
              opacity: 0.9,
              fontSize: 18,
            }}
          >
            Clone a new service
          </TextUI>
          <TextInputUI
            label={"Enter clone service name"}
            value={cloneServiceName}
            onChangeText={(val) => setCloneServiceName(val)}
          />
          <View style={{ display: "flex", flexDirection: "row", marginTop: 12 }}>
            <OutlineButton
              label="Cancel"
              onPress={() => {
                handleCloneServiceModal()
                setCloneServiceName(formData.name)
              }}
              style={{ flex: 1 }}
              mode="outlined"
            />
            <View style={{ width: "2%" }} />
            <ButtonUI
              label={"Okay"}
              onPress={() => {
                handleCloneServiceModal();
                handleChange("name", cloneServiceName)
                setFlow(flow.clone)
              }}
              disabled={cloneServiceName === ""}
              style={{ flex: 1 }}
            />
          </View>

        </View>
      </Modal>
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fabActionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default AddorUpdateService;
