// ===================================================================
// CUSTOM  COMPONENT
// ===================================================================
import React, { useCallback } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import Appbar from "../../../Components/Appbar";
import {
  getCustomersList,
  updatePartnerBookingSettings,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import TextUI from "../../../Components/TextUI";
import { StyleTheme } from "../../../Styles/CommonStyles";
import FabUI from "../../../Components/FabUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import InfiniteList from "../../../Components/InfiniteList";
import Row from "../../../Components/Row";
import ImageUI from "../../../Components/ImageUI";
import RouteNames from "../../../Utils/Constants/RouteNames";
import Page from "../../../Components/Page";
import LoaderUI from "../../../Components/Loader";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getCustomersList: (id: string, offset, limit) => void;
  updatePartnerBookingSettings: (data, id, callback) => void;
}
interface ICustomer {
  id: string;
  name: string;
  image: string;
  source: number;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================

function Customers(props: IProps) {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      getCustomers(true);
    }, [])
  );
  function getCustomers(initialOffset?) {
    props.getCustomersList(
      props.route.params.id,
      initialOffset ? 0 : props.businessStore.customers.offset,
      props.businessStore.customers.limit
    );
  }

  const renderFloatingActionBar = () => {
    return (
      <FabUI
        showOptions={showOptions}
        action={(val) => setShowOptions(val)}
        bottomSpace={30}
        options={
          <>
            <TouchableOpacity
              style={styles.fabActionItem}
              onPress={() => {
                setShowOptions(false);
                props.navigation.navigate(
                  RouteNames.BusinessCustomerOperations.name,
                  {
                    id: props.route.params.id,
                    customerParam: "add",
                  }
                );
              }}
            >
              <TextUI color="white">Add Customer</TextUI>
              <View style={{ width: 10 }} />
              <View
                style={{
                  backgroundColor: StyleTheme.APP_COLOR,
                  borderRadius: 100,
                  padding: 2,
                }}
              >
                <MaterialCommunityIcons name={"plus"} color="white" size={20} />
              </View>
            </TouchableOpacity>
          </>
        }
      />
    );
  };
  return (
    <Page>
      <Appbar title={"Customers"} />
      <View style={styles.body}>
        {props.businessStore.customers.data.length !== 0 && (
          <InfiniteList
            data={props.businessStore.customers.data}
            options={{
              isLoading: props.businessStore.isLoading,
              offset: props.businessStore.customers.offset,
              total: props.businessStore.customers.total,
            }}
            getData={() => getCustomers()}
            renderItem={(item) => {
              return (
                <TouchableOpacity
                  style={{ paddingVertical: 10, flex: 1 }}
                  onPress={() => {
                    props.navigation.navigate(
                      RouteNames.BusinessCustomerOperations.name,
                      {
                        id: props.route.params.id,
                        customerParam: item.id,
                      }
                    );
                  }}
                >
                  <Row>
                    <ImageUI
                      source={item.image}
                      style={{ width: 30, height: 30 }}
                    />
                    <View style={{ width: 10 }} />
                    <Row spaceBetween>
                      <TextUI weight="500">{item.name}</TextUI>
                      <MaterialCommunityIcons
                        name={"chevron-right"}
                        color="black"
                        size={20}
                        style={{}}
                      />
                    </Row>
                  </Row>
                </TouchableOpacity>
              );
            }}
          />
        )}
      </View>
      {renderFloatingActionBar()}
      {props.businessStore.isLoading &&
        props.businessStore.customers.data.length === 0 && (
          <LoaderUI mode="long" />
        )}
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 10,
    paddingVertical: 20,
    flex: 1,
  },
  fabActionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    backgroundColor: "rgba(0,0,0,0.7)",
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
});
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getCustomersList: (id: string, options, callback) =>
      dispatch(getCustomersList(id, options, callback)),
    updatePartnerBookingSettings: (payload, id, callback) =>
      dispatch(updatePartnerBookingSettings(payload, id, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
