import React from "react";
import { View, TouchableOpacity } from "react-native";
import { StyleTheme } from "../Styles/CommonStyles";
import { Switch as RNSwitch } from "react-native-paper";

function Switch(props: { selected: boolean; onPress?: () => void }) {
  return (
    <RNSwitch
      value={props.selected}
      onValueChange={props.onPress}
      color={StyleTheme.APP_COLOR}
    />
  );
}

export default Switch;
