import React from "react";
import { View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import TextUI from "../../Components/TextUI";
import ButtonUI from "../../Components/ButtonUI";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import moment from "moment";
import ImageUI from "../../Components/ImageUI";

interface IProps {
  action: () => void;
  data: any;
}
const images = {
  "Credit Card": require("@reachpartner/common/Assets/Images/payment/card_pay.png"),
  "Debit Card": require("@reachpartner/common/Assets/Images/payment/card_pay.png"),
  "Google Pay": require("@reachpartner/common/Assets/Images/payment/google_pay.png"),
  "Samsung Pay": require("@reachpartner/common/Assets/Images/payment/samsung_pay.png"),
  "Apple Pay": require("@reachpartner/common/Assets/Images/payment/apple_pay.png"),
};
export default function PaymentOptions(props: IProps) {
  let {
    payment: {
      cryptName = "",
      reference = "",
      gateway = "",
      status = "",
      mode = "",
      updatedOn = "",
    },
  } = props.data;
  function handleSubmit() {
    props.action();
  }
  let data = "",
    time = "";

  if (updatedOn) {
    data = moment(updatedOn).format("DD/MM/YYYY");
    time = moment(updatedOn).format("hh:mm A");
  }
  mode = EnumMapping.PaymentMode[mode];
  const paymentImage = images[mode];

  return (
    <View>
      <View
        style={{
          backgroundColor: "white",
        }}
      >
        <PaymentDetailItem
          label="Method"
          value={mode || ""}
          image={paymentImage}
          noTopLine
        />
        <PaymentDetailItem
          label="Payment Status"
          value={EnumMapping.PaymentStatus[status] || ""}
        />

        <PaymentDetailItem
          label="Gateway"
          image={
            gateway === 3
              ? require("@reachpartner/common/Assets/Images/reach-logo2.png")
              : gateway === 2
                ? require("@reachpartner/common/Assets/Images/payment/card_machine.png")
                : null
          }
          value={EnumMapping.PaymentGateway[gateway] || ""}
        />
        {status === 4 && <PaymentDetailItem label="Paid On" value={`${data} ${"\n"}${time}`} />}
        {reference !== "" && (
          <PaymentDetailItem label="Reference No" value={reference} />
        )}
        {cryptName !== "" && (
          <PaymentDetailItem label="Card No" value={cryptName} />
        )}
      </View>

      <ButtonUI label="Okay" onPress={handleSubmit} />
    </View>
  );
}
const styles = {
  value: {
    fontWeight: "700",
  },
};
function Title(props) {
  return (
    <TextUI size={13} weight="400">
      {props.children}
    </TextUI>
  );
}
function Value(props) {
  return (
    <TextUI weight="500" size={12} style={{ textAlign: "right" }}>
      {props.children}
    </TextUI>
  );
}
function PaymentDetailItem(props: {
  noTopLine?: boolean;
  label: string;
  value: string;
  image?: any;
  //   action: () => void;
  //   isActive: boolean;
}) {
  const width = props.image ? "33%" : "50%";
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "space-between",
        borderTopWidth: props.noTopLine ? 0 : 1,
        paddingTop: 12,
        borderColor: "#d8d4d4",
        marginBottom: 12,
      }}
    >
      <View style={{ width }}>
        <Title>{props.label}</Title>
      </View>
      {props.image && (
        <View style={{ width, alignItems: "center" }}>
          <ImageUI
            source={props.image}
            style={{ width: 40, height: 20 }}
            resizeMode="contain"
          />
        </View>
      )}
      <View style={{ width }}>
        <Value>{props.value}</Value>
      </View>
    </View>
  );
}
