import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { EMployeeInfo, UserInfo } from "../../Models/StoreInterfaces";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import TextUI from "../../Components/TextUI";
import { CountryList } from "../../Utils/Constants/AppLevelConfig";
import ImageUI from "../../Components/ImageUI";

interface TopbarInterface {
  action: (type: string) => void;
  employeeInfo: EMployeeInfo;
  weatherInfo: { temperature: String; icon: String };
  userInfo: UserInfo
}

function LandingTopBar(props: TopbarInterface) {
  const { employeeInfo, weatherInfo } = props;
  const { firstName, location, country, date, picture } = employeeInfo || {};
  const { temperature, icon } = weatherInfo;
  const countryName =
    CountryList.find(({ value }) => value === country)?.label || "";
  const fullName = firstName ? `Greetings, ${firstName}` : "";
  // const address = location || countryName ? `${location}, ${countryName}` : "";
  const address = props.userInfo?.cityTown || ""
  const tempAndDate = `${temperature ? temperature + "C, " : ""}${date}`;
  return (
    <View style={{ display: "flex", flexDirection: "row", paddingVertical: 5 }}>
      <TouchableOpacity
        activeOpacity={0.9}
        onPress={() => {
          props.action("profile");
        }}
      >
        <ImageUI
          style={{
            width: 50,
            height: 50,
            borderRadius: 4,
          }}
          source={picture}
          defaultSource={require("@reachpartner/common/Assets/Images/user-account.png")}
          resizeMode="cover"
        />
      </TouchableOpacity>
      <View
        style={{
          flex: 1,
          paddingHorizontal: 13,
          justifyContent: "center",
          marginRight: 50,
        }}
      >
        <TextUI
          size={16}
          weight="500"
          style={{
            marginBottom: 3,
          }}
        >
          {fullName}
        </TextUI>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ImageUI
            source={require("@reachpartner/common/Assets/Images/location-pin.png")}
            style={{ height: 14, width: 10, marginRight: 2 }}
            resizeMode="cover"
          />
          <Text
            style={{
              fontWeight: "500",
              fontSize: 13,
              opacity: 0.8,
            }}
          >
            {address}
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <ImageUI
            source={icon}
            style={{ height: 20, width: 25, marginLeft: -5 }}
            resizeMode="cover"
          />
          <Text
            style={{
              fontWeight: "500",
              fontSize: 13,
              opacity: 0.8,
            }}
          >
            {tempAndDate}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: 80,
          paddingTop: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          position: "absolute",
          right: 0,
        }}
      >
        <MaterialCommunityIcons
          name={"email-outline"}
          style={{ fontSize: 25, fontWeight: "900" }}
          onPress={() => props.action("email")}
        />
        <View style={{ width: 15 }}></View>
        <MaterialCommunityIcons
          name={"bell-outline"}
          onPress={() => props.action("notification")}
          style={{ fontSize: 26, fontWeight: "900" }}
        />
      </View>
    </View>
  );
}
const Styles = StyleSheet.create({
  profileImage: {
    width: 60,
    height: 65,
    borderRadius: 4,
  },
});
export default LandingTopBar;
