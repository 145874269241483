import React from "react";
import { View, TouchableOpacity } from "react-native";
import { List, Card } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";
import TextUI from "../../Components/TextUI";
import RadioButton from "../../Components/RadioButton";
import { StyleTheme } from "../../Styles/CommonStyles";
import ImageUI from "../../Components/ImageUI";
import TextInputUI from "../../Components/TextInputUI";
import ButtonUI from "../../Components/ButtonUI";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import { getEnumReverseMapping } from "../../Utils";

interface IProps {
  action: (payload) => void;
}

const paymentMode: any = getEnumReverseMapping(EnumMapping.PaymentMode);
const paymentStatus: any = getEnumReverseMapping(EnumMapping.PaymentStatus);
const paymentGateway: any = getEnumReverseMapping(EnumMapping.PaymentGateway);

export default function PaymentOptions(props: IProps) {
  const [type, setType] = React.useState("");
  const [isCardMachine, setIsCardMachine] = React.useState(false);
  const [voucher, setVoucher] = React.useState("");

  useFocusEffect(React.useCallback(() => { }, []));

  function handleType(type) {
    setType(type);
    if (["pay_by_link", "cash", "other"].includes(type)) {
      setIsCardMachine(false);
    }
  }

  function handleSubmit() {
    let mode, status, gateway, reference, trigger;
    // CASH
    if (type === "cash") {
      mode = paymentMode.cash;
      status = paymentStatus.payment_received;
      gateway = paymentGateway.none;
    } else if (type === "other") {
      mode = paymentMode.other;
      status = paymentStatus.payment_received;
      gateway = paymentGateway.none;
      reference = voucher;
    } else if (isCardMachine) {
      mode = paymentMode[type];
      status = paymentStatus.payment_received;
      gateway = paymentGateway.card_machine;
    } else if (type === "pay_by_link") {
      mode = paymentMode.pay_by_link;
      status = paymentStatus.payment_unpaid;
      gateway = paymentGateway.none;
      trigger = "immediate";
    } else if (type === "pay_by_bank_transfer") {
      mode = paymentMode.bank_transfer;
      status = paymentStatus.payment_received;
      gateway = paymentGateway.none;
    }
    const payload = {
      category: "payment",
      command: "set",
      param1: mode || "",
      param2: status || "",
      param3: gateway || "",
      param4: reference || "",
      param5: trigger || "",
    };
    props.action(payload);
  }

  return (
    <View>
      <View
        style={{
          paddingBottom: 20,
          backgroundColor: "white",
        }}
      >
        <PaymentItem
          action={() => handleType("pay_by_link")}
          isActive={type === "pay_by_link"}
        >
          Pay by link
        </PaymentItem>
        <PaymentItem
          action={() => handleType("pay_by_bank_transfer")}
          isActive={type === "pay_by_bank_transfer"}
        >
          Bank Transfer
        </PaymentItem>
        <PaymentItem
          action={() => handleType("cash")}
          isActive={type === "cash"}
        >
          Cash
        </PaymentItem>

        {/* <List.Section title="Accordions"> */}
        <List.Accordion
          title="Card Machine"
          left={(props) => <RadioButton selected={isCardMachine} />}
          right={() => <View />}
          expanded={true}
          onPress={() => {
            setIsCardMachine(true);
            setType("");
          }}
          style={{ paddingLeft: 0, paddingVertical: 0 }}
          titleStyle={{
            paddingLeft: 7,
            color: StyleTheme.DEFAULT_FONT_COLOR,
            fontWeight: "500",
            fontSize: 14,
          }}
        >
          {isCardMachine && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <MachineType
                image={require("@reachpartner/common/Assets/Images/payment/google_pay.png")}
                action={() => handleType("google_pay")}
                isActive={type === "google_pay"}
              />
              <MachineType
                image={require("@reachpartner/common/Assets/Images/payment/square_pay.png")}
                action={() => handleType("other_digital_wallet")}
                isActive={type === "other_digital_wallet"}
              />
              <MachineType
                image={require("@reachpartner/common/Assets/Images/payment/samsung_pay.png")}
                action={() => handleType("samsung_pay")}
                isActive={type === "samsung_pay"}
              />
              <MachineType
                image={require("@reachpartner/common/Assets/Images/payment/card_pay.png")}
                action={() => handleType("credit_card")}
                isActive={type === "credit_card"}
              />
              <MachineType
                image={require("@reachpartner/common/Assets/Images/payment/apple_pay.png")}
                action={() => handleType("apple_pay")}
                isActive={type === "apple_pay"}
              />
            </View>
          )}
        </List.Accordion>
        {/* </List.Section> */}
        <PaymentItem
          action={() => handleType("other")}
          isActive={type === "other"}
        >
          Other (Gift Card/Voucher)
        </PaymentItem>
        {type === "other" && (
          <View style={{ paddingHorizontal: 50 }}>
            <TextInputUI
              label="Reference"
              value={voucher}
              onChangeText={(val) => setVoucher(val)}
            />
          </View>
        )}
        {/* </ScrollView> */}
      </View>
      {/* <BottomActionBar> */}
      {/* <ButtonUI
          label="Cancel"
          onPress={() => {}}
          style={{ flex: 1 }}
          mode="outlined"
        /><ButtonUI */}
      {/* label="Cancel" onPress={() => {}}
      style={{ flex: 1 }}
      mode="outlined" */}
      {/* /> */}
      {/* <View style={{ width: 20 }} /> */}
      <ButtonUI
        label="Pay"
        onPress={handleSubmit}
        disabled={type === "other" ? voucher === "" : type === ""}
      />
      {/* </BottomActionBar> */}
    </View>
  );
}
function MachineType(props: {
  image: string;
  action: () => void;
  isActive: boolean;
}) {
  const activeStyle = props.isActive
    ? { borderWidth: 2, borderColor: StyleTheme.APP_COLOR }
    : {};
  return (
    <Card
      style={{
        height: 30,
        width: 60,
        padding: 5,
        marginRight: 20,
        ...activeStyle,
      }}
      elevation={3}
      onPress={props.action}
    >
      <ImageUI
        source={props.image}
        style={{ width: "100%", height: "100%" }}
        resizeMode="contain"
      />
    </Card>
  );
}
function PaymentItem(props: {
  children: string;
  action: () => void;
  isActive: boolean;
}) {
  return (
    <TouchableOpacity
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 0,
      }}
      onPress={props.action}
      activeOpacity={0.6}
    >
      <RadioButton selected={props.isActive} onPress={props.action} />
      <View style={{ width: 15 }} />
      <TextUI weight="500" size={14}>
        {props.children}
      </TextUI>
    </TouchableOpacity>
  );
}
