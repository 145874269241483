// ===================================================================
// CUSTOM NETWORK COMPONENT
// ===================================================================
import React, { useCallback } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import Appbar from "../../../Components/Appbar";
import {
  getPartnerBookingSettings,
  updatePartnerBookingSettings,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import TextInputUI from "../../../Components/TextInputUI";
import SectionLabel from "../../../Components/SectionLabel";
import { validateEmail } from "../../../Utils";
import TextArea from "../../../Components/TextArea";
import DropDownUI from "../../../Components/Dropdown";
import ButtonUI from "../../../Components/ButtonUI";
import TextUI from "../../../Components/TextUI";
import LoaderUI from "../../../Components/Loader";
import BottomActionBar from "../../../Components/BottomActionBar";
import RadioButton from "../../../Components/RadioButton";
import Row from "../../../Components/Row";
import Checkbox from "../../../Components/Checkbox";
import WindowSelectPopup from "./Components/WindowSelectPopup";
import Page from "../../../Components/Page";
import OutlineButton from "../../../Components/OutlineButton";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getPartnerBookingSettings: (id: string, callback) => void;
  updatePartnerBookingSettings: (data, id, callback) => void;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================
const dayPeriods = [
  { label: "1 Day", value: 1 },
  { label: "2 Days", value: 2 },
  { label: "3 Days", value: 3 },
  { label: "5 Days", value: 5 },
  { label: "1 Week", value: 7 },
  { label: "2 Weeks", value: 14 },
  { label: "1 Month", value: 30 },
  { label: "2 Months", value: 60 },
  { label: "3 Months", value: 90 },
];
const minutePeriods = [
  { label: "15 Mins", value: 15 },
  { label: "30 Mins", value: 30 },
  { label: "1 Hour", value: 60 },
  { label: "2 Hours", value: 120 },
  { label: "3 Hours", value: 180 },
  { label: "6 Hours", value: 360 },
  { label: "12 Hours", value: 720 },
  { label: "1 Day", value: 1440 },
  { label: "2 Days", value: 2880 },
];
interface ISettings {
  partner: string;
  booking: {
    automatic: boolean;
    smartSchedule: boolean;
    scheduleAdvanceTime: number;
    rescheduleAdvanceTime: number;
    cancelAdvanceTime: number;
    futureWindowPeriod: number;
    outsideOpenHours: {
      before: number;
      after: number;
    };
    minimumBasketValue: number;
    congestionCharge: boolean;
    parkingCharge: boolean;
    cancel: {
      policy: string;
      fee: {
        value: number;
        type: number;
      };
    };
    noShow: {
      policy: string;
      fee: {
        value: number;
        type: number;
      };
    };
    distanceCost: {
      enable: boolean;
      free: number;
      additional: number;
    };
    tax: { id: string; value: string }[];
  };
  tax: {
    option: number[];
    service: number;
    product: number;
  };
}
const defaultValues = {
  partner: "",
  booking: {
    automatic: true,
    smartSchedule: false,
    scheduleAdvanceTime: 15,
    rescheduleAdvanceTime: 15,
    cancelAdvanceTime: 15,
    futureWindowPeriod: 1,
    outsideOpenHours: {
      before: 0,
      after: 0,
    },
    cancel: {
      policy: "",
      fee: {
        value: 0,
        type: 0,
      },
    },
    noShow: {
      policy: "",
      fee: {
        value: 0,
        type: 0,
      },
    },
    distanceCost: {
      enable: false,
      free: 0,
      additional: 0,
    },
    minimumBasketValue: 0,
    congestionCharge: false,
    parkingCharge: false,
    tax: [],
  },
  tax: {
    option: [0, 5, 20],
    service: 0,
    product: 0,
  },
};

const isInValidInput = (input: string) => input.split(".").length > 2 ||
  input.split(".").length > 1 && input.split(".")[1].length > 2

function CustomNetwork(props: IProps) {
  const [formData, setFormData] = React.useState<ISettings>(defaultValues);
  const [isLoading, setIsLoading] = React.useState(true);

  const [initialValidate, setInitialValidate] = React.useState(false);
  // TIME SELECTION WINDOW START
  const [showSelectWindow, setShowSelectWindow] = React.useState(false);
  const [timeWindowConfig, setTimeWindowConfig] = React.useState<{
    title: string;
    keys: string[];
    list: { label: string; value: number }[];
  }>({
    title: "",
    keys: [],
    list: [],
  });
  // TIME SELECTION WINDOW END
  const [enableCancellation, setEnableCancellation] = React.useState(false);
  const [enableNoshow, setEnableNoshow] = React.useState(false);
  const [allowOutsideHours, setAllowOutsideHours] = React.useState(false);
  const partnerId = props.route.params.id || "";
  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      props.getPartnerBookingSettings(partnerId, (data) => {
        setIsLoading(false);
        if (data && !data.fail) {
          setData(data);
        }
      });
    }, [])
  );

  function setData(data: ISettings) {
    const { cancel, outsideOpenHours, noShow } = data.booking;
    if (outsideOpenHours?.after !== 0 || outsideOpenHours?.before !== 0) {
      setAllowOutsideHours(true);
    }
    if (cancel?.fee?.value > 0) {
      setEnableCancellation(true);
    }
    if (noShow?.fee?.value > 0) {
      setEnableNoshow(true);
    }
    setFormData((d: any) => data);
  }

  function handleChange(type: string | string[], value: any) {
    const data = { ...formData };
    if (typeof type === "object") {
      function setIn(obj = {}, [prop, ...rest]: any, value) {
        obj[prop] = rest.length ? setIn(obj[prop], rest, value) : value;
        return obj;
      }
      setIn(data, type, value);
    }
    setFormData(data);
  }

  function submitData() {
    formData.booking.distanceCost.additional = Number(formData.booking.distanceCost.additional)
    formData.booking.cancel.fee.value = Number(formData.booking.cancel.fee.value)
    formData.booking.noShow.fee.value = Number(formData.booking.noShow.fee.value)
    formData.booking.minimumBasketValue = Number(formData.booking.minimumBasketValue)
    const payload: any = {
      booking: { ...formData.booking, },
      tax: { ...formData.tax },
    };
    payload.booking.tax = payload.booking.tax.map(({ id }) => id);
    setIsLoading(true);
    props.updatePartnerBookingSettings(payload, partnerId, (data) => {
      setIsLoading(false);
      if (!data.fail) {
        props.navigation.pop();
      } else {
      }
    });
  }

  function SectionGap() {
    return <View style={{ height: 25 }} />;
  }

  function SectionTitle(node) {
    return (
      <>
        <TextUI weight="700">{node.children}</TextUI>
        <View style={{ height: 10 }} />
      </>
    );
  }

  function renderSelectWindowComponent(
    title,
    value,
    type: "day" | "minute",
    keyArray: string[],
    popupTitle: string
  ) {
    const list = type === "day" ? dayPeriods : minutePeriods;
    return (
      <View>
        <DropDownUI
          list={list}
          value={value}
          setValue={(val) => { }}
          label={title || "Future booking window"}
        />
        <TouchableOpacity
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
          onPress={() => {
            setShowSelectWindow(true);
            setTimeWindowConfig({ title: popupTitle, keys: keyArray, list });
          }}
        />
      </View>
    );
  }

  function renderCancellationSection() {
    const { fee, policy } = formData?.booking?.cancel;
    const { type, value } = fee;
    const inputPrefix = type === 1 ? "%" : "£";
    return (
      <>
        <Row>
          <Checkbox
            active={enableCancellation}
            onPress={() => {
              if (enableCancellation) {
                setEnableCancellation(false);
                handleChange(["booking", "cancel"], {
                  policy: "",
                  fee: { value: 0, type: 1 },
                });
              } else {
                setEnableCancellation(true);
              }
            }}
          />
          <View style={{ width: 15 }} />
          <SectionTitle>Enable Cancellation fee</SectionTitle>
        </Row>
        {enableCancellation && (
          <>
            <View style={{ height: 15 }} />
            <TextInputUI
              label="How much cancellation fee is charged ?"
              value={value?.toString()}
              prefix={inputPrefix}
              onChangeText={(text) => {
                if (isInValidInput(text)) return;
                handleChange(
                  ["booking", "cancel", "fee", "value"],
                  text
                );
              }}
            />
            <View style={{ height: 15 }} />
            <Row>
              <RadioButton
                selected={type === 1}
                onPress={() => {
                  handleChange(["booking", "cancel", "fee", "type"], 1);
                }}
              />
              <View style={{ width: 15 }} />
              <TextUI>Percentage</TextUI>
              <View style={{ width: 15 }} />
              <RadioButton
                selected={type === 2}
                onPress={() => {
                  handleChange(["booking", "cancel", "fee", "type"], 2);
                }}
              />
              <View style={{ width: 15 }} />
              <TextUI>Currency</TextUI>
            </Row>
            <View style={{ height: 15 }} />
            <TextArea
              label="Business Cancellation Policy (visible to client)"
              value={policy}
              onChangeText={(val) =>
                handleChange(["booking", "cancel", "policy"], val)
              }
            />
          </>
        )}
      </>
    );
  }

  function renderNoshowSection() {
    const { fee, policy } = formData?.booking?.noShow;
    const { type, value } = fee;
    const inputPrefix = type === 1 ? "%" : "£";
    return (
      <>
        <Row>
          <Checkbox
            active={enableNoshow}
            onPress={() => {
              if (enableNoshow) {
                setEnableNoshow(false);
                handleChange(["booking", "noShow"], {
                  policy: "",
                  fee: { value: 0, type: 1 },
                });
              } else {
                setEnableNoshow(true);
              }
            }}
          />
          <View style={{ width: 15 }} />
          <SectionTitle>Enable No Show fee</SectionTitle>
        </Row>
        {enableNoshow && (
          <>
            <View style={{ height: 15 }} />
            <TextInputUI
              label="How much no show fee is charged ?"
              value={value?.toString()}
              onChangeText={(text) => {
                if (isInValidInput(text)) return;
                handleChange(
                  ["booking", "noShow", "fee", "value"],
                  text
                );
              }}
              prefix={inputPrefix}
            />
            <View style={{ height: 15 }} />
            <Row>
              <RadioButton
                selected={type === 1}
                onPress={() => {
                  handleChange(["booking", "noShow", "fee", "type"], 1);
                }}
              />
              <View style={{ width: 15 }} />
              <TextUI>Percentage</TextUI>
              <View style={{ width: 15 }} />
              <RadioButton
                selected={type === 2}
                onPress={() => {
                  handleChange(["booking", "noShow", "fee", "type"], 2);
                }}
              />
              <View style={{ width: 15 }} />
              <TextUI>Currency</TextUI>
            </Row>
            <View style={{ height: 15 }} />
            <TextArea
              label="Business No Show Policy (visible to client)"
              value={policy}
              onChangeText={(val) =>
                handleChange(["booking", "noShow", "policy"], val)
              }
            />
          </>
        )}
      </>
    );
  }

  function renderServiceDistanceCharge() {
    const { free, additional } = formData?.booking?.distanceCost;
    return (
      <>
        <Row>
          <Checkbox
            active={formData.booking.distanceCost.enable}
            onPress={() => {
              handleChange(
                ["booking", "distanceCost", "enable"],
                !formData.booking.distanceCost.enable
              );
            }}
          />
          <View style={{ width: 15 }} />
          <SectionTitle>Enable Service Distance Charge</SectionTitle>
        </Row>
        {formData.booking.distanceCost.enable && (
          <>
            <View style={{ height: 15 }} />
            <TextInputUI
              label="How many free miles/kms are included in the service price?"
              value={free?.toString()}
              onChangeText={(text) => {
                if (text === "" || text === "0" || Number(text)) {
                  handleChange(
                    ["booking", "distanceCost", "free"],
                    text === "" ? text : Number(text)
                  );
                }
              }}
            />
            <View style={{ height: 15 }} />
            <TextInputUI
              label="How much cost per additional mile/km for the service distance?"
              value={additional?.toString()}
              onChangeText={(text) => {
                if (isInValidInput(text)) return;
                handleChange(
                  ["booking", "distanceCost", "additional"],
                  text
                );
              }}
              prefix={"£"}
            />
          </>
        )}
      </>
    );
  }

  function renderMinBasket() {
    const minimumBasketValue = formData?.booking?.minimumBasketValue;
    return (
      <>
        <View style={{ width: 15 }} />
        <SectionTitle>Minimum Basket Value</SectionTitle>
        <>
          <TextInputUI
            label="Minimum Basket Value"
            value={minimumBasketValue?.toString()}
            prefix={"£"}
            onChangeText={(text) => {
              if (isInValidInput(text)) return;
              handleChange(
                ["booking", "minimumBasketValue"],
                text
              );
            }}
          />
        </>
      </>
    );
  }

  function renderAdditionalCosts() {
    return (
      <>
        <View style={{ width: 15 }} />
        <SectionTitle>Additional Costs</SectionTitle>
        <View style={{ paddingBottom: 10 }} >
          <Row>
            <Checkbox
              active={formData.booking.congestionCharge}
              onPress={() => {
                handleChange(
                  ["booking", "congestionCharge"],
                  !formData.booking.congestionCharge
                );
              }}
            />
            <View style={{ width: 15 }} />
            <SectionTitle>London Congestion Charge</SectionTitle>
          </Row>
        </View>
        <Row>
          <Checkbox
            active={formData.booking.parkingCharge}
            onPress={() => {
              handleChange(
                ["booking", "parkingCharge"],
                !formData.booking.parkingCharge
              );
            }}
          />
          <View style={{ width: 15 }} />
          <SectionTitle>Parking Charges</SectionTitle>
        </Row>
      </>
    );
  }

  return (
    <Page>
      <Appbar title={"Booking Settings"} />
      <ScrollView style={styles.body}>
        <SectionLabel>Appointment Booking Settings</SectionLabel>
        <SectionGap />
        <>
          <SectionTitle>Booking Confirmation Method</SectionTitle>

          <Row>
            <RadioButton
              selected={formData.booking.automatic}
              onPress={() => {
                handleChange(["booking", "automatic"], true);
              }}
            />
            <View style={{ width: 15 }} />
            <TextUI>Automatic</TextUI>
            <View style={{ width: 15 }} />
            <RadioButton
              selected={!formData.booking.automatic}
              onPress={() => {
                handleChange(["booking", "automatic"], false);
              }}
            />
            <View style={{ width: 15 }} />
            <TextUI>Manual</TextUI>
          </Row>
        </>
        {/* <SectionGap />
        <>
          <Row>
            <Checkbox
              active={formData.booking.smartSchedule}
              onPress={() => {
                handleChange(
                  ["booking", "smartSchedule"],
                  !formData.booking.smartSchedule
                );
              }}
            />
            <View style={{ width: 15 }} />
            <SectionTitle>Enable Smart Scheduling Feature</SectionTitle>
          </Row>
        </> */}
        <SectionGap />
        <>
          <SectionTitle>
            How long the future booking window is open
          </SectionTitle>
          {renderSelectWindowComponent(
            "Future booking window",
            formData.booking.futureWindowPeriod,
            "day",
            ["booking", "futureWindowPeriod"],
            "How long the future booking window is open"
          )}
        </>
        <SectionGap />
        <>
          <SectionTitle>
            Minimum advance time for the appointment schedule
          </SectionTitle>
          {renderSelectWindowComponent(
            "Scheduling advance time",
            formData.booking.scheduleAdvanceTime,
            "minute",
            ["booking", "scheduleAdvanceTime"],
            "Minimum advance time for the appointment scheduling"
          )}
        </>
        <SectionGap />
        <>
          <SectionTitle>
            Minimum advance time for the appointment cancellation
          </SectionTitle>
          {renderSelectWindowComponent(
            "Cancellation advance time",
            formData.booking.cancelAdvanceTime,
            "minute",
            ["booking", "cancelAdvanceTime"],
            "Minimum advance time for appointment cancellation"
          )}
        </>
        {/* <SectionGap />
        <>
          <Row>
            <Checkbox
              active={allowOutsideHours}
              onPress={() => {
                if (allowOutsideHours) {
                  setAllowOutsideHours(false);
                  handleChange(["booking", "outsideOpenHours"], {
                    before: 0,
                    after: 0,
                  });
                } else {
                  setAllowOutsideHours(true);
                }
              }}
            />
            <View style={{ width: 15 }} />
            <SectionTitle>Allow Outside OpenHours booking</SectionTitle>
          </Row>
          {allowOutsideHours && (
            <>
              <View style={{ height: 15 }} />
              <Row spaceBetween>
                <View style={{ width: "48%" }}>
                  {renderSelectWindowComponent(
                    "Hours Before",
                    formData.booking.outsideOpenHours.before,
                    "minute",
                    ["booking", "outsideOpenHours", "before"],
                    "Outside hours before"
                  )}
                </View>
                <View style={{ width: "48%" }}>
                  {renderSelectWindowComponent(
                    "Hours after",
                    formData.booking.outsideOpenHours.after,
                    "minute",
                    ["booking", "outsideOpenHours", "after"],
                    "Outside hours after"
                  )}
                </View>
              </Row>
            </>
          )}
        </> */}
        <SectionGap />
        {/* CANCELLATION */}
        <>{renderCancellationSection()}</>
        <SectionGap />
        {/* NO SHOW */}
        <>{renderNoshowSection()}</>
        <SectionGap />
        {/* DISTANCE */}
        <>{renderServiceDistanceCharge()}</>
        <SectionGap />
        {renderMinBasket()}
        <SectionGap />
        {renderAdditionalCosts()}
        {/* <SectionGap />
        <>
          <SectionLabel>Enable VAT for Services</SectionLabel>
          <View style={{ height: 15 }} />
          <DropDownUI
            list={formData.tax.option.map((item) => ({
              label: `${item} %`,
              value: item,
            }))}
            value={formData.tax.service}
            setValue={(val) => handleChange(["tax", "service"], val)}
            label={"Service VAT"}
          />
        </> */}
        {/* <SectionGap />
        <>
          <SectionLabel>Enable VAT for Product</SectionLabel>
          <View style={{ height: 15 }} />
          <DropDownUI
            list={formData.tax.option.map((item) => ({
              label: `${item} %`,
              value: item,
            }))}
            value={formData.tax.product}
            setValue={(val) => {
              handleChange(["tax", "product"], val);
            }}
            label={"Service VAT"}
          />
        </> */}
      </ScrollView>
      <BottomActionBar>
        <OutlineButton
          label="Cancel"
          onPress={() => props.navigation.pop()}
          style={{ height: 40, width: "47%" }}
          mode="outlined"
        />
        <View style={{ width: "5%" }} />
        <ButtonUI
          label="Submit"
          onPress={submitData}
          style={{ height: 40, width: "47%" }}
        />
      </BottomActionBar>
      <WindowSelectPopup
        list={timeWindowConfig.list}
        title={timeWindowConfig.title}
        active={showSelectWindow}
        onDismiss={() => setShowSelectWindow(false)}
        onSelect={(val) => {
          handleChange(timeWindowConfig.keys, val);
          setTimeWindowConfig({ title: "", keys: [], list: [] });
          setShowSelectWindow(false);
        }}
      />
      {isLoading && <LoaderUI mode="long" />}
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 18,
    paddingVertical: 20,
  },
});
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getPartnerBookingSettings: (id: string, callback) =>
      dispatch(getPartnerBookingSettings(id, callback)),
    updatePartnerBookingSettings: (payload, id, callback) =>
      dispatch(updatePartnerBookingSettings(payload, id, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomNetwork);
