export const appConfiguration = {
  appId: 3,
  appVersion: "0.0.0",
  appPlatform: 3,
};

export const CountryList: { label: string; value: string }[] = [
  {
    label: "United Kingdom",
    value: "GBR",
  },
];
