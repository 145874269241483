// ===================================================================
// THIS COMPONENT IS FOR LOCATION SEARCH POPUP
// ===================================================================
import React, { useState, useRef, useEffect } from "react";
import { View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { Searchbar } from "react-native-paper";
import ContainerView from "./ContainerView";
import TextUI from "./TextUI";
import { StyleTheme } from "../Styles/CommonStyles";
import LoaderUI from "./Loader";
import FullViewModal from "./FullViewModal";
import { isValidPostCode } from "../Utils";
// ===================================================================
// INTERFACE
// ===================================================================
interface IProps {
  children: React.ReactNode;
  list: any[];
  onChangeText: (val: string) => void;
  getData?: (val: string) => void;
  label: string;
  placeholder: string;
  renderItem: (item: any) => React.ReactNode;
  hideInput?: boolean;
  loading?: boolean;
  noDataMessage?: string;
  onDismiss?: () => void;
  itemsLabel?: string;
  variant?: "AREA" | "PIN_CODE"
}
// ===================================================================
// COMPONENT
// ===================================================================
export default function LocationSearchInputModel(props: IProps) {
  const {
    onDismiss = () => { },
    hideInput = false,
    loading = false,
    noDataMessage = "",
    variant = "PIN_CODE"
  } = props;
  const [showModal, setShowMOdal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isValidCode, setIsValidPostCode] = useState<boolean>(true)
  const inputRef = useRef<any>(null);
  const listItems = !props.getData
    ? props.list
    : searchQuery.length > 2
      ? props.list
      : [];

  function filterList(val: string) {
    setSearchQuery(val);
    setIsValidPostCode(() => true);
  }

  const handleSearch = (input: string = "") => {
    if (variant === "PIN_CODE") {
      if (isValidPostCode(input)) {
        props.getData(input);
      } else {
        setIsValidPostCode(false)
      }
    } else {
      props.getData(input);
    }
  }

  useEffect(() => {
    let delayDebounceFn: any;
    if (searchQuery.length >= 3 && props.getData) {
      delayDebounceFn = setTimeout(() => {
        handleSearch(searchQuery)
      }, 1500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }
  }, [showModal]);
  function renderContent() {
    if (loading) {
      return <LoaderUI />;
    } else if (noDataMessage !== "") {
      return (
        <View style={{ padding: 20 }}>
          <TextUI style={{ textAlign: "center", fontWeight: "500" }}>
            {noDataMessage}
          </TextUI>
        </View>
      );
    }
    return (
      <ScrollView>
        <View
          style={{
            paddingVertical: 10,
            paddingHorizontal: 20,
          }}
        >
          {listItems.map((item, idx) => (
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "row",
                paddingVertical: 10,
                alignItems: "center",
                borderTopColor:
                  idx === 0 ? "white" : StyleTheme.BOX_BORDER_COLOR,
                borderTopWidth: 1,
              }}
              key={idx}
              onPress={() => {
                setShowMOdal(false);
                props.onChangeText(item);
              }}
            >
              {props.renderItem(item)}
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    );
  }
  return (
    <>
      <TouchableOpacity onPress={() => setShowMOdal(true)}>
        {props.children}
        <View
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      </TouchableOpacity>
      <FullViewModal
        visible={showModal}
        onClose={() => {
          setShowMOdal(false);
          onDismiss();
        }}
      >
        <ContainerView styles={{ backgroundColor: "white" }}>
          <View style={modalStyles.body}>
            <View style={modalStyles.header}>
              <TextUI style={{}} familyType="type-2" weight="500">
                {props.label}
              </TextUI>
              <TouchableOpacity
                onPress={() => {
                  setShowMOdal(false);
                }}
                style={modalStyles.btn}
              >
                <TextUI
                  size={12}
                  weight="500"
                  addOpacity={true}
                  style={{ backgroundColor: "rgb(248, 248, 248)" }}
                >
                  Cancel
                </TextUI>
              </TouchableOpacity>
            </View>
            {!hideInput && (
              <View style={{ paddingHorizontal: 15, paddingBottom: 15 }}>
                <Searchbar
                  placeholder={props.placeholder}
                  onChangeText={(val) => filterList(variant === "PIN_CODE" ? val?.toUpperCase() : val)}
                  value={searchQuery}
                  underlineColorAndroid={"transparent"}
                  theme={{
                    colors: {
                      primary: "white",
                    },
                  }}
                  style={{ borderColor: isValidCode || variant === "AREA" ? "transparent" : "red", borderWidth: 1 }}
                  ref={inputRef}
                />
                {!isValidCode && variant === "PIN_CODE" && <TextUI style={{ marginTop: 10, color: "red" }}>Please enter valid postcode</TextUI>}
              </View>
            )}
            <View style={{ flex: 1, backgroundColor: "white" }}>
              {renderContent()}
            </View>
          </View>
        </ContainerView>
      </FullViewModal>
    </>
  );
}

const modalStyles = StyleSheet.create({
  body: {
    // paddingTop: 40,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#f8f8f8",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    paddingHorizontal: 15,
  },
  btn: {
    borderColor: StyleTheme.BOX_BORDER_COLOR,
    paddingHorizontal: 20,
    paddingVertical: 8,
    borderRadius: 4,
  },
});
