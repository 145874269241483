import {
  REACH_SIGNIN_SUCCESS,
  FIREBASEINFO,
  CREATEUSER,
  EMPLOYEEINFO,
  SIGNOUT,
} from "./types";
import firebase from "../Firebase";
import { IAction } from "../Models/interface";

const reachSignInSuceess = (flag: boolean) => ({
  type: REACH_SIGNIN_SUCCESS,
  payload: flag,
});

const appSignout = () => {
  return {
    type: SIGNOUT,
  };
};

const getFirebaseUserInfo = (): IAction => {
  return {
    type: FIREBASEINFO,
    isFirebaseCall: true,
  };
};

const createUser = (
  userId: string,
  payload: any,
  callback: () => void
): IAction => {
  return {
    type: CREATEUSER,
    isApi: true,
    payload: {
      method: "POST",
      url: `user/${userId}/employee`,
      data: payload,
      callback,
      extraParams: payload,
    },
  };
};

const getEmployeeInfo = (linkedId: string): IAction => {
  return {
    type: EMPLOYEEINFO,
    isApi: true,
    payload: {
      method: "GET",
      url: `employee/${linkedId}`,
      data: {},
    },
  };
};

export {
  getFirebaseUserInfo,
  createUser,
  getEmployeeInfo,
  appSignout,
  reachSignInSuceess,
};
