import React from "react";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import Appbar from "../Components/Appbar";
import BookingInfoComponent from "./Components";
import ProviderUI from "../Components/ProviderUI";
import Actions from "../Actions";
import { BookingReducerInterface } from "../Models";
import { NavigationProps } from "../Models/interface";
import LoaderUI from "../Components/Loader";
import TextUI from "../Components/TextUI";
import RouteNames from "../Utils/Constants/RouteNames";
import { View } from "react-native";
import Page from "../Components/Page";
interface IProps extends NavigationProps {
  getBookingInfo: (bookingId: string) => void;
  bookingUpdate: (
    payload,
    bookingId,
    callback: (response: any) => void
  ) => void;
  resetBookingInfo: () => void;
  booking: BookingReducerInterface;
}

function BookingInfo(props: IProps) {
  const {
    viewBookingInfo: { data = {}, loading },
  } = props.booking;
  const { booking, partnerInfo } = data;

  useFocusEffect(
    React.useCallback(() => {
      const bookingId = props.route.params?.bid || "";
      props.getBookingInfo(bookingId);

      return () => {
        props.resetBookingInfo();
      };
    }, [])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (booking) {
        const { partner = "" } = partnerInfo || {};
        if (partner && partner !== "") {
        }
      }
    }, [booking])
  );

  const handleActions = (payload, bookingId) => {
    props.bookingUpdate(payload, bookingId, (response) => {
      if (response.fail) {
        return;
      }
      props.getBookingInfo(bookingId);
    });
  };

  function handleBack() {
    const routes = props.navigation.dangerouslyGetState().routes;
    const lastScreen = routes[routes.length - 2]?.name || "";
    if (lastScreen === RouteNames.BasketCheckout.name) {
      props.navigation.pop(4);
    } else {
      props.navigation.goBack();
    }
  }

  function shouldDisableViewProfile() {
    const { viewBookingInfo } = props.booking
    const { clientInfo, customer = "" } = viewBookingInfo.data;
    // disable if client object received
    return customer === "" && clientInfo?.cType === 1;
  }

  function getCustomerParam() {
    const { viewBookingInfo } = props.booking
    const { clientInfo, customer = "" } = viewBookingInfo.data;

    // valid customer
    if (customer !== "") {
      return customer;
    }
    // customer object
    if (clientInfo?.cType === 2) {
      return clientInfo?.client;
    }
    // legacy client
    return clientInfo?.client;
  }

  function goToCustomerProfile() {
    props.navigation.push(RouteNames.BusinessCustomerOperations.name, {
      id: props.booking.viewBookingInfo.data.partnerInfo.partner,
      customerParam: getCustomerParam(),
    });
  }
  return (
    <Page>
      <ProviderUI>
        <Appbar
          title={"View Appointment"}
          goBack={() => {
            handleBack();
          }}
        />
        {loading ? (
          <LoaderUI />
        ) : data.booking ? (
          <BookingInfoComponent
            data={props.booking.viewBookingInfo.data}
            action={handleActions}
            goToCustomerProfile={goToCustomerProfile}
            disableViewProfile={shouldDisableViewProfile()}
          />
        ) : (
          <View
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <TextUI>Failed to load appointment info</TextUI>
          </View>
        )}
      </ProviderUI>
    </Page>
  );
}

const mapStateToProps = (store) => {
  return {
    booking: store.booking,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getBookingInfo: (bookingId: string) =>
      dispatch(Actions.getBookingInfo(bookingId)),
    resetBookingInfo: () => {
      dispatch(Actions.resetBookingInfo());
    },
    bookingUpdate: (payload, bookindId, callback) =>
      dispatch(Actions.bookingUpdate(payload, bookindId, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingInfo);
