// ===================================================================
// CUSTOM  COMPONENT
// ===================================================================
import React from "react";
import { View } from "react-native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import {
  ILocationReqPayload,
  NavigationProps,
} from "../../../Models/interface";
import Actions from "../../../Actions";
import { IHomeStore } from "../../../Store/Reducers/homeReducer";
import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";
import { customerCreate } from "../../../Actions/businessActions";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  homeStore: IHomeStore;
}

interface IMapDispatch {
  customerCreate: (partnerId: string, payload, callback) => void;
  customerRead: (id: string, params: string[], callback) => void;
  customerUpdate: (customerId, payload, callback) => void;
  getLocations: (payload: ILocationReqPayload) => void;
  locationReset: () => void;
}

type IProps = IMapState & IMapDispatch & NavigationProps & {};
// ===================================================================
// COMPONENT
// ===================================================================
const tabs = [
  {
    title: "Profile",
    id: "profile",
  },
  {
    title: "Booking",
    id: "booking",
  },
  {
    title: "Serivce",
    id: "service",
  },
  {
    title: "Notes",
    id: "notes",
  },
];

function CustomerDetails(props: IProps) {
  if (props.route.params.customerParam === "add") {
    return (
      <CustomerAdd
        homeStore={props.homeStore}
        customerCreate={props.customerCreate}
        getLocations={props.getLocations}
        locationReset={props.locationReset}
        navigationPop={() => props.navigation.pop()}
        partnerId={props.route.params.id}
      />
    );
  } else if (props.route.params.customerParam?.length === 24) {
    return (
      <CustomerEdit
        homeStore={props.homeStore}
        customerUpdate={props.customerUpdate}
        customerRead={props.customerRead}
        getLocations={props.getLocations}
        locationReset={props.locationReset}
        customerId={props.route.params.customerParam}
        navigationPop={() => props.navigation.pop()}
      />
    );
  }
  return <View />;
}
// ===================================================================
// STYLES
// ===================================================================

// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    homeStore: state.home,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    customerRead: (id: string, params, callback) =>
      dispatch(Actions.customerRead(id, params, callback)),
    customerUpdate: (id: string, payload, callback) =>
      dispatch(Actions.customerUpdate(id, payload, callback)),
    getLocations: (val: any) => dispatch(Actions.getLocations(val)),
    locationReset: () => dispatch(Actions.locationReset()),
    customerCreate: (id: string, options, callback) =>
      dispatch(customerCreate(id, options, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
