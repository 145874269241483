import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Switch } from "react-native-paper";
import { CommonStyles, StyleTheme } from "../Styles/CommonStyles";
import TextUI from "./TextUI";

interface IProps {
  label?: string;
  active: boolean;
  handleChange: (val: boolean) => void;
  disable?: boolean;
  disabledAction?: () => void;
}

export default function EnableForBooking(props: IProps) {
  return (
    <View
      style={[
        CommonStyles.rowSpaceBetween,
        CommonStyles.boxContainer,
        { borderRadius: 50, paddingVertical: 10, borderWidth: 2 },
      ]}
    >
      <TextUI weight="500">{props.label || "Enable for booking"}</TextUI>
      <TouchableOpacity
        onPress={() => {
          if (props.disable) {
            props.disabledAction && props.disabledAction();
          } else {
            props.handleChange(!props.active);
          }
        }}
      >
        <Switch
          value={props.active}
          color={StyleTheme.APP_COLOR}
          onValueChange={() => {}}
        />
        <View style={{ width: "100%", height: "100%", position: "absolute" }} />
      </TouchableOpacity>
    </View>
  );
}
