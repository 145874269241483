import React from "react";
import EmailFormAbstract from "@reachpartner/common/+EmailForm";
import { connect } from "react-redux";
import firebase from "@reachpartner/common/Firebase";
import { NavigationProps } from "@reachpartner/common/Models/interface";
import Actions from "@reachpartner/common/Actions";
import { IUserStore } from "../../../common/Models";

interface IProps extends NavigationProps {
  getFirebaseUserInfo: () => void;
  user: IUserStore;
  errorData: (data: any) => void;
}
function EmailForm(props: IProps) {
  const googleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    socialAuthSigninWeb(provider);
  };
  const twitterSignIn = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    socialAuthSigninWeb(provider);
  };
  const facebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    socialAuthSigninWeb(provider);
  };
  const socialAuthSigninWeb = async (provider: any) => {
    try {
      await firebase.auth().signInWithPopup(provider);
      props.getFirebaseUserInfo();
    } catch (err) {
      console.log(err);
    }
  };
  const handleAction = (type: string) => {
    if (type === "google") {
      googleSignIn();
    } else if (type === "facebook") {
      facebookSignIn();
    } else if (type === "twitter") {
      twitterSignIn();
    }
  };
  const getFirebaseUser = (user: any) => {
    props.getFirebaseUserInfo();
  };

  return (
    <EmailFormAbstract
      route={props.route}
      getFirebaseUser={getFirebaseUser}
      action={handleAction}
      isLoading={props.user.isLoading}
      handleError={props.errorData}
    />
  );
}
const mapState = (store: any) => {
  return {
    user: store.user,
  };
};
const mapDispatch = (dispatch: any) => ({
  getFirebaseUserInfo: () => dispatch(Actions.getFirebaseUserInfo()),
  errorData: (data: any) => dispatch(Actions.errorData(data)),
});
export default connect(mapState, mapDispatch)(EmailForm);
