import { IAction } from "../Models/interface";
import axiosInstance from "./api";
import firebase from "../Firebase";
import { handleFirebaseInfo } from "../Utils";
import * as ActionTypes from "../Actions/types";
import RootNavigation from "../Navigation/RootNavigation";
import RouteNames from "../Utils/Constants/RouteNames";
import { reachSignInSuceess } from "../Actions/authActions";
import { appConfiguration } from "@reachpartner/common/Utils/Constants/AppLevelConfig";
import Actions from "../Actions";
import LocalStorage from "../LocalStorage";

var isInitialHandle = true;
var fetchingNewToken = false;
var failedActions: any[] = [];

interface ReachAuthResponse {
  fail: boolean;
  info: {
    sessionToken: string;
    tokenType: string;
    expiresIn: number;
    appId: number;
    callerInfo: {
      cityTown: string;
      country: string;
    };
    email: string;
  };
}

interface ReachSignInResponse {
  fail: boolean;
  info: {
    linked: string;
    user: string;
    email: string;
  };
}

const getFirebaseUserInfo = async (store, action) => {
  try {
    const fire_user = await firebase.auth().currentUser;
    const tokenDetails = await fire_user.getIdTokenResult(true);
    const { data, authInfo } = await handleFirebaseInfo({
      tokenDetails,
      fire_user,
    });
    store.dispatch({ type: `${action.type}_SUCCESS`, payload: data });
    const auth_req_params = {
      ...authInfo,
      ...appConfiguration,
    };
    const response: ReachAuthResponse = await axiosInstance.post(
      "user/auth",
      auth_req_params
    );
    if (response.fail) {
      return;
    }
    store.dispatch({
      type: ActionTypes.REACHAUTH_SUCCESS,
      payload: response.info,
    });
    const signin_payload = {
      email: response.info.email,
    };
    const signi_res: ReachSignInResponse = await axiosInstance.post(
      "user/signin",
      signin_payload
    );
    store.dispatch({
      type: ActionTypes.SIGNIN_SUCCESS,
      payload: {
        ...signi_res.info,
        signInProvider: tokenDetails.signInProvider,
      },
    });
    //remove the temporary user details
    const { linked } = signi_res.info;
    if (!linked || linked === "") {
      RootNavigation.navigationReset({
        index: 0,
        routes: [{ name: RouteNames.CreateUser.name }],
      });
      store.dispatch(reachSignInSuceess(true));
    } else {
      store.dispatch(Actions.getEmployeeInfo(linked));
    }
    //handling the 401 failed calls
    handleFailedActions(store);
    fetchingNewToken = false;
  } catch (err) {
    store.dispatch({
      type: ActionTypes.FIREBASEINFO_FAILURE,
      payload: {
      },
    });
    store.dispatch({ type: `${action.type}_FAILURE` });
    store.dispatch({ type: ActionTypes.ERRORDATA, payload: err });
    fetchingNewToken = false;
  }
};
const handleFailedActions = (store) => {
  function exeAction(action) {
    if (action) {
      store.dispatch(action);
      exeAction(failedActions.pop());
    }
  }
  exeAction(failedActions.pop());
};
const signOut = async () => {
  try {
    isInitialHandle = true;
    await LocalStorage.clearAll();
    await firebase.auth().signOut();

    RootNavigation.navigationReset({
      index: 0,
      routes: [{ name: RouteNames.Login.name }],
    });
  } catch (err) { }
};

export const apiMiddleWare = (store) => (next) => async (action) => {
  next(action);

  if (action.isFirebaseCall) {
    getFirebaseUserInfo(store, action);
    return;
  }
  if (action.type === ActionTypes.SIGNOUT) {
    signOut();
  }
  if (!action.isApi) return;
  const { type, payload }: IAction = action;
  const { url, method, data, extraParams, callback } = payload || {};
  try {
    const axiosPayload = {
      url,
      method,
      data,
    };
    if (method === "GET") {
      delete axiosPayload.data;
    }
    const response: any = await axiosInstance(axiosPayload);
    if (response.fail) {
      // HANDLE FAILURE
      store.dispatch({ type: `${type}_FAILURE`, payload: response });
      callback && callback(response);
      store.dispatch({ type: ActionTypes.ERRORDATA, payload: response });
    } else {
      const payload = response.info;
      if (extraParams) {
        payload.extraParams = extraParams;
      }
      store.dispatch({ type: `${type}_SUCCESS`, payload });
      callback && callback({ ...payload, fail: false });

      if (action.type === ActionTypes.EMPLOYEEINFO && isInitialHandle) {
        isInitialHandle = false;
        const { partners } = response.info;
        if (partners.length === 0) {
          RootNavigation.navigationReset({
            index: 0,
            routes: [{ name: RouteNames.Invitation.name }],
            // routes: [{ name: RouteNames.ModeSelection.name }],
          });
        } else {
          RootNavigation.navigationReset({
            index: 0,
            routes: [{ name: RouteNames.LandingPage.name }],
          });
        }
        store.dispatch(reachSignInSuceess(true));
      }
    }
  } catch (err) {
    const { response = {} } = err;
    if (response.status && response.status === 401) {
      failedActions.push({ ...action });
      if (!fetchingNewToken) {
        fetchingNewToken = true;
        store.dispatch(Actions.getFirebaseUserInfo());
      }
    } else {
      store.dispatch({ type: `${type}_FAILURE`, payload: response.data });
      callback && callback(response.data);
      store.dispatch({ type: ActionTypes.ERRORDATA, payload: response });
    }
  }
};
