import React, { useState, useCallback, useEffect } from "react";
import { ILocationReqPayload, NavigationProps } from "../Models/interface";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import NewBasketComponent from "./Components";
import { connect } from "react-redux";
import Actions from "../Actions";
import { IBasketStore } from "../Models";
import moment from "moment";
import RouteNames from "../Utils/Constants/RouteNames";
import LoaderUI from "../Components/Loader";
import { partnerProfileParser } from "../Store/Reducers/basketReducer";
import { IHomeStore } from "../Store/Reducers/homeReducer";
import { IBusinessStore } from "../Store/Reducers/businessReducer";

interface IProps extends NavigationProps {
  getPartnerProfile: (partnerId: string, callback: (data) => void) => void;
  home: IHomeStore;
  businessStore: IBusinessStore;
  basketStore: IBasketStore;
  getCustomersList: (partnerId: string) => void;
  getServiceList: (partnerId: string) => void;
  getLocations: (payload: ILocationReqPayload) => void;
  createBasketCustomer: (
    partnerId: string,
    payload: any,
    callback: (data: any) => void
  ) => void;
  createBasket: (
    partnerId: string,
    customerId: string,
    payload: any,
    callback: (data: any) => void
  ) => void;
  getBasket: (
    partnerId: string,
    customerId: string,
    callback: (data: any) => void
  ) => void;
  updateBasket: (
    basketId: string,
    payloadLany,
    callback: (data: any) => void
  ) => void;
  customerRead: (
    cid: string,
    params: string[],
    callback: (data: any) => void
  ) => void;
  customerUpdate: (cid: string, params: string[]) => void;
  resetBasketError: () => void;
  storeBasketFormdata: (data) => void;
  getAddOns: (partnerId, services, callback) => void;
}
const initialData: IBasketStore["basketFormData"] = {
  deliveryLocation: 0,
  customerType: "",
  customerName: "",
  firstName: "",
  lastName: "",
  phNumber: "",
  email: "",
  customerAddress: "",
  date: "",
  selectedServices: [],
  basketId: "",
  selectedAddons: []
};
const travelDistance: { fromTravelAddress: string; toTravelAddress: string } = {
  fromTravelAddress: "",
  toTravelAddress: "",
};
function BasketNew(props: IProps) {
  //state and props;

  const [formData, setFormdata] = useState({
    ...initialData,
    ...travelDistance,
    date: props.basketStore.basketFormData.date
  });
  const [showTravelDistance, setShowTravelDistance] = useState(false);
  const { errorMsg = "" } = props.basketStore.basketDetails;
  const isFocused = useIsFocused();
  const partnerId: string = props.route.params.pid || "";
  //handle form changes
  const handleChange = (name: string, value: any) => {
    if (name === "deliveryLocation") {
      let customerType =
        formData.customerType === "walk-in" && value !== 2
          ? "existing"
          : formData.customerType;
      setFormdata({
        ...formData,
        [name]: value,
        customerName: "",
        customerType,
        basketId: "",
      });
      return;
    } else if (name === "customerType") {
      setFormdata({
        ...formData,
        [name]: value,
        customerName: "",
        basketId: "",
      });
      if (value === "walk-in") {
        getBasket("4b1da0b0c0d0e0f0dacabaea");
      }
      return;
    } else if (name === "customerName") {
      setFormdata({ ...formData, [name]: value, basketId: "" });
      getBasket(value, true);
      return;
    }
    setFormdata({ ...formData, [name]: value });
    if (name === "date") {
      props.storeBasketFormdata({
        ...props.basketStore.basketFormData,
        date: value
      })
    }
  };

  useEffect(() => {
    if (isFocused) {
      getInitialData();
      setFormdata({ ...formData, date: props.basketStore.basketFormData.date.length ? props.basketStore.basketFormData.date : moment().format("DD-MM-YYYY") });
    }
  }, []);
  //effect to show the error message
  useFocusEffect(
    useCallback(() => {
      if (errorMsg) {
        props.resetBasketError();
      }
    }, [errorMsg])
  );

  function handleServiceLocations(data) {
    const { serviceLocation, isTravelDistance } = partnerProfileParser(data);
    const locs = serviceLocation || [];
    if (!locs.length) {
      return;
    }
    const value = locs[0].type;
    const customerType =
      value === 1 ? "existing" : value === 2 ? "walk-in" : "";

    setFormdata((d) => {
      return {
        ...d,
        deliveryLocation: value,
        customerType,
      };
    });
    setShowTravelDistance(isTravelDistance);
    if (value === 2) {
      getBasket("4b1da0b0c0d0e0f0dacabaea");
    }
  }
  //effect to update the basket details
  useFocusEffect(
    useCallback(() => {
      // if (basketStore.basketFormData.deliveryLocation !== 0) {
      const {
        itemInfo = [],
        deliveryInfo = {},
        basket = "",
      } = props.basketStore.basketDetails.data;
      const { address = "" } = deliveryInfo;

      if (basket) {
        setFormdata((d) => ({
          ...d,
          customerAddress: address,
          basketId: basket,
          selectedServices: itemInfo.map(({ item, name, subType }) => ({
            name,
            id: item,
            subType
          })).filter(({ subType }) => subType !== 2),
        }));
      }
      // }
    }, [props.basketStore.basketDetails.updatedTimeStamp])
  );

  //call these api's initially
  function getInitialData() {
    if (props.basketStore.basketFormData.deliveryLocation !== 0) {
      setFormdata({
        ...props.basketStore.basketFormData,
        fromTravelAddress: "",
        toTravelAddress: "",
      });
      return;
    }
    props.getPartnerProfile(partnerId, (data) => {
      handleServiceLocations(data);
    });
    props.getCustomersList(partnerId);
    props.getServiceList(partnerId);
  }
  // get basket call
  function getBasket(customerId, customerRead?) {
    props.getBasket(partnerId, customerId, (data) => {
      if (data.fail && customerRead) {
        props.customerRead(customerId, ["profile"], (data1) => {
          if (!data1.fail) {
            const deliveryAddress = data1.profile?.[0]?.deliveryAddress || {};
            const rank = deliveryAddress?.default || 1;
            const addressInfo = (deliveryAddress?.addressInfo || []).find(
              (item) => (item.rank = rank)
            );
            let address;
            if (addressInfo) {
              address = addressInfo.address;
            } else {
              address = deliveryAddress?.addressInfo?.[0]?.address || "";
            }
            setFormdata((d) => ({
              ...d,
              customerAddress: address,
            }));
          }
        });
      }
    });
  }

  // get location call
  function getLocations(val) {
    let payload = {
      locData: val,
      locType: "postcode",
    };
    props.getLocations(payload);
  }

  //create update command object
  function commandObj(category: string, command?: string, ...rest) {
    const [p1 = "", p2 = "", p3 = "", p4 = "", p5 = "", p6 = ""] = rest;
    return {
      category: category,
      command: command || "",
      param1: p1.toString(),
      param2: p2.toString(),
      param3: p3.toString(),
      param4: p4.toString(),
      param5: p5.toString(),
      param6: p6.toString(),
    };
  }

  // create customer call
  function createBasketCustomer() {
    const payload = {
      first: formData.firstName,
      last: formData.lastName,
      email: formData.email,
      mobile: formData.phNumber,
      deliveryAddress: {
        default: 0,
        addressInfo: [
          {
            rank: 0,
            address: formData.customerAddress,
          },
        ],
      },
      country: "GBR",
    };
    props.createBasketCustomer(partnerId, payload, (response) => {
      if (response.fail) {
        return;
      }
      createBasket(response.id);
    });
  }

  //handle form submit
  function submit() {
    if (formData.basketId) {
      updateBasketDetails();
      return;
    }
    if (formData.customerType === "existing") {
      createBasket(formData.customerName);
    } else if (formData.customerType === "new") {
      createBasketCustomer();
    } else if (formData.customerType === "walk-in") {
      createBasket("4b1da0b0c0d0e0f0dacabaea");
    }
  }

  //update basket call
  function updateBasketDetails() {
    const { itemInfo = [], date } = props.basketStore.basketDetails.data;
    const prevItems = itemInfo.map(({ item }) => item);
    const currItems = formData.selectedServices.map(({ id }) => id);
    const removedItems = prevItems
      .filter((x) => !currItems.includes(x))
      .map((id) => commandObj("item", "remove", "1", id));
    const newItems = currItems
      .filter((x) => !prevItems.includes(x))
      .map((id) => commandObj("item", "add", "1", id));
    const d_format = moment(date).format("DD-MM-YYYY");
    const d_items: any[] = [];
    if (d_format !== formData.date) {
      d_items.push(
        commandObj(
          "date",
          "update",
          `${formData.date.split("-").reverse().join("-")}T00:00:00.000Z`
        )
      );
    }
    if (showTravelDistance) {
      d_items.push(
        commandObj(
          "travel",
          "update",
          formData.deliveryLocation,
          formData.fromTravelAddress,
          formData.deliveryLocation,
          formData.toTravelAddress
        )
      );
    }
    const payload = [...removedItems, ...newItems, ...d_items];
    updateBasket(payload);
  }

  function updateBasket(payload) {
    props.updateBasket(formData.basketId, payload, (response) => {
      if (response && !response.fail) {
        maybeUpdateCustomerAddress(formData.customerName);
        goToViewBasket(response.basket);
      }
    });
  }
  // navigation to next page
  function goToViewBasket(basketId) {
    props.storeBasketFormdata(formData);
    const services = formData.selectedServices.map(({ id }) => id);
    props.getAddOns(basketId, services, (response) => {
      if (response && !response.fail) {
        const addOns = response.result.reduce((acc, item) => {
          acc = [...acc, ...item.addOns];
          return acc;
        }, []);
        if (addOns.length) {
          props.navigation.push(RouteNames.BasketAddon.name, {
            pid: basketId,
          });
          return;
        }
      }
      props.navigation.push(RouteNames.BasketConfig.name, {
        pid: basketId,
      });
    });
  }

  //create basket call
  function createBasket(customerId) {
    const payload = {
      type: formData.customerType === "walk-in" ? 3 : 2,
      date: moment(formData.date, "DD-MM-YYYY").utcOffset(0, true),
      deliveryInfo: {
        location: formData.deliveryLocation,
        address: formData.customerAddress,
        medium: 1,
        type: 1,
      },
      itemInfo: formData.selectedServices.map(({ oType, id }) => ({
        type: 1,
        item: id,
      })),
    };
    props.createBasket(partnerId, customerId, payload, (data) => {
      if (data.fail) {
        return;
      }
      maybeUpdateCustomerAddress(customerId);
      checkAndUpdatePostalAddress(data.basket);
    });
  }

  function maybeUpdateCustomerAddress(customerId) {
    props.customerRead(formData.customerName, ["profile"], (data) => {
      if (!data.fail) {
        const profile = data.profile?.[0];
        if (!profile) {
          return;
        }
        const deliveryAddress = profile.deliveryAddress || {};
        const isAlreadyAddressPresent = deliveryAddress.addressInfo?.find(
          ({ address }) => address === formData.customerAddress
        );
        if (!isAlreadyAddressPresent) {
          const updatedAddressInfo = { ...profile };
          updatedAddressInfo.deliveryAddress.addressInfo.push({
            address: formData.customerAddress,
            rank: updatedAddressInfo.deliveryAddress.addressInfo.length + 1,
          });
          props.customerUpdate(customerId, updatedAddressInfo);
        }
      }
    });
  }

  function checkAndUpdatePostalAddress(basketId) {
    if (showTravelDistance) {
      const payload = [
        commandObj(
          "travel",
          "update",
          formData.deliveryLocation,
          formData.fromTravelAddress,
          formData.deliveryLocation,
          formData.toTravelAddress
        ),
      ];
      props.updateBasket(basketId, payload, (response) => {
        goToViewBasket(basketId);
      });
    } else {
      goToViewBasket(basketId);
    }
  }

  return (
    <>
      <NewBasketComponent
        formData={formData}
        handleChange={handleChange}
        deliveryLocations={props.basketStore.partner.serviceLocation}
        closedDays={props.basketStore.partner.closedDays}
        customers={props.businessStore.customers.data}
        services={props.businessStore.services.data}
        getLocationList={getLocations}
        addressList={props.home.locationSearchItems.data}
        submit={submit}
        basketId={formData.basketId}
        showTravelDistance={showTravelDistance}
      />
      {props.basketStore.basketDetails.isSubmitting && <LoaderUI mode="long" />}
    </>
  );
}

const mapStateToProps = (store: any) => {
  return {
    home: store.home,
    basketStore: store.basket,
    businessStore: store.business,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getPartnerProfile: (partnerId, callback) =>
      dispatch(Actions.getPartnerProfile(partnerId, callback)),
    getCustomersList: (partnerId) =>
      dispatch(Actions.getCustomersList(partnerId)),
    getServiceList: (partnerId) =>
      dispatch(Actions.getPartnerServiceList(partnerId)),
    getLocations: (val) => dispatch(Actions.getLocations(val)),
    createBasketCustomer: (partnerId, payload, callback) =>
      dispatch(Actions.createBasketCustomer(partnerId, payload, callback)),
    createBasket: (partnerId, customerId, payload, callback) =>
      dispatch(
        Actions.createBasket({ partnerId, customerId, payload, callback })
      ),
    getBasket: (partnerId, customerId, callback) =>
      dispatch(Actions.getBasket({ partnerId, customerId, callback })),
    updateBasket: (basketId, payload, callback) =>
      dispatch(Actions.updateBasket({ basketId, payload, callback })),
    customerRead: (cid, params, callback) =>
      dispatch(Actions.customerRead(cid, params, callback)),
    customerUpdate: (customerId, payload) =>
      dispatch(Actions.customerUpdate(customerId, payload)),
    resetBasketError: () => dispatch(Actions.resetBasketError()),
    storeBasketFormdata: (payload) =>
      dispatch(Actions.storeBasketFormData(payload)),
    getAddOns: (partnerId, services, callback) =>
      dispatch(Actions.getAddOns(partnerId, services, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasketNew);
