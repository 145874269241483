// ===================================================================
// BUSINESS INDUSTRIES COMPONENT
// ===================================================================
import React, { useCallback, useEffect } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import TextUI from "../../../Components/TextUI";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Appbar from "../../../Components/Appbar";
import FlatList from "../../../Components/FlatList";
import { getPartnerEmployeeList } from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import ImageUI from "../../../Components/ImageUI";
import FabUI from "../../../Components/FabUI";
import { CommonStyles, StyleTheme } from "../../../Styles/CommonStyles";
import RouteNames from "../../../Utils/Constants/RouteNames";
import LoaderUI from "../../../Components/Loader";
import UpdateEmployee from "./UpdateEmployee";
import InviteEmployee from "./InviteEmployee";
import EnumMapping from "../../../Utils/Constants/EnumMapping";
import Page from "../../../Components/Page";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getPartnerEmployeeList: (id: string, offset, limit) => void;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================
function BusinessEmployee(props: IProps) {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [editEmployeeId, setEditEmployeeId] = React.useState("");
  const [inviteEmployee, setInviteEmployee] = React.useState(false);

  useFocusEffect(
    useCallback(() => {
      getEmployees(true);
    }, [])
  );
  function getEmployees(initialOffset?) {
    props.getPartnerEmployeeList(
      props.route.params.id,
      initialOffset ? 0 : props.businessStore.employees.offset,
      props.businessStore.employees.limit
    );
  }
  function handleEdit(empId) {
    if (!empId) {
      return;
    }
    props.navigation.navigate(RouteNames.BusinessEmployeeOperations.name, {
      id: props.route.params.id,
      employeeParam: empId,
    });
  }

  function renderServices() {
    if (props.businessStore.isLoading) {
      return <LoaderUI mode="long" />;
    }
    if (props.businessStore.employees.data.length === 0) {
      return (
        <View style={{ alignSelf: "center", margin: "auto" }}>
          <TextUI style={{ textAlign: "center" }} weight="500">
            No Employee found!
          </TextUI>
          <View style={{ height: 10 }} />
          <TextUI style={{ textAlign: "center" }} weight="500">
            Tap on + to add new employee
          </TextUI>
        </View>
      );
    }
    return (
      <FlatList
        items={props.businessStore.employees.data}
        renderItem={(item: IBusinessStore["employees"]["data"][0]) => {
          const enableForBooking = item.enableForBooking;
          const accessNane =
            EnumMapping.EmployeeAccessLevel.find(
              ({ value }) => value === item.access
            )?.label || "";
          return (
            <TouchableOpacity
              onPress={() => handleEdit(item.id)}
              style={{
                paddingVertical: 12,
                flex: 1,
                opacity: enableForBooking ? 1 : 0.8,
              }}
              key={item.id}
            >
              <View style={[CommonStyles.rowSpaceBetween]}>
                {enableForBooking && (
                  <>
                    <View
                      style={[
                        CommonStyles.rowSpaceBetween,
                        {
                          position: "absolute",
                          top: 0,
                          left: 25,
                          width: 17,
                          height: 17,
                          zIndex: 10,
                          borderRadius: 30,
                          backgroundColor: StyleTheme.APP_COLOR,
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <MaterialCommunityIcons
                        name={"check"}
                        style={{
                          color: "white",
                          fontSize: 12,
                        }}
                      />
                    </View>
                  </>
                )}
                <View style={styles.listItem}>
                  {item.image ? (
                    <ImageUI
                      source={item.image}
                      style={{ height: 40, width: 40, borderRadius: 4 }}
                    />
                  ) : (
                    <View
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 4,
                        alignItems: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name={"image-outline"}
                        style={{
                          color: "rgba(36, 39, 44, 0.5)",
                          fontSize: 35,
                        }}
                      />
                    </View>
                  )}
                  <View style={{ width: 10 }} />
                  <View style={{ flex: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <TextUI weight="500">
                        {item.first} {item.last}
                      </TextUI>
                      <View
                        style={{
                          borderWidth: 1,

                          borderColor:
                            item.access === 4
                              ? "rgba(0, 128, 0, 1)"
                              : "rgba(255, 0, 0, 1)",
                          backgroundColor:
                            item.access === 4
                              ? "rgba(0, 128, 0, 0.1)"
                              : "rgba(255, 0, 0, 0.1)",
                          borderRadius: 10,
                          marginLeft: 10,
                          padding: 2,
                          paddingHorizontal: 6,
                        }}
                      >
                        <TextUI
                          size={10}
                          color={
                            item.access === 4
                              ? "rgba(0, 128, 0, 1)"
                              : "rgba(255, 0, 0, 1)"
                          }
                        >
                          {accessNane}
                        </TextUI>
                      </View>
                    </View>
                    {item.status.map((x, idx) => (
                      <TextUI
                        weight="500"
                        size={12}
                        style={{ opacity: 0.7, marginTop: 3 }}
                        key={idx}
                      >
                        {x.txt}
                      </TextUI>
                    ))}
                  </View>
                </View>
                <View style={{ width: 30 }}>
                  <MaterialCommunityIcons
                    name={"chevron-right"}
                    style={{
                      color: "rgba(36, 39, 44, 0.5)",
                      fontSize: 30,
                    }}
                  />
                </View>
              </View>
            </TouchableOpacity>
          );
        }}
      />
    );
  }
  return (
    <Page>
      <Appbar
        title={"Employees"}
      // actions={
      //   <MaterialCommunityIcons
      //     name="delete-outline"
      //     size={20}
      //     color="red"
      //     onPress={() =>
      //       props.navigation.navigate(
      //         RouteNames.BusinessEmployeeOperations.name,
      //         {
      //           employeeParam: "delete",
      //           id: props.route.params.id,
      //         }
      //       )
      //     }
      //   />
      // }
      />
      <View style={styles.body}>{renderServices()}</View>
      <FabUI
        showOptions={showOptions}
        action={(val) => setShowOptions(val)}
        bottomSpace={30}
        options={
          <>
            <TouchableOpacity
              style={styles.fabActionItem}
              onPress={() => {
                setShowOptions(false);
                props.navigation.navigate(
                  RouteNames.BusinessEmployeeOperations.name,
                  {
                    id: props.route.params.id,
                    employeeParam: "add",
                  }
                );
              }}
            >
              <TextUI color="white">Invite Employee</TextUI>
              <View style={{ width: 10 }} />
              <View
                style={{
                  backgroundColor: StyleTheme.APP_COLOR,
                  borderRadius: 100,
                  padding: 2,
                }}
              >
                <MaterialCommunityIcons
                  name={"plus"}
                  color="white"
                  size={20}
                  style={{}}
                />
              </View>
            </TouchableOpacity>
          </>
        }
      />
      {/* {editEmployeeId !== "" && (
        <UpdateEmployee
          employeeData={props.businessStore.industries.employees.find(
            (x) => x.id === editEmployeeId
          )}
          partnerId={props.route.params.id}
          dismiss={() => {
            setEditEmployeeId("");
            props.getPartnerEmployeeList(props.route.params.id);
          }}
        />
      )}
      {inviteEmployee && (
        <InviteEmployee
          dismiss={() => {
            setInviteEmployee(false);
            props.getPartnerEmployeeList(props.route.params.id);
          }}
          partnerId={props.route.params.id}
        />
      )} */}
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    padding: 10,
    flex: 1,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  fabActionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    backgroundColor: "rgba(0,0,0,0.7)",
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
});
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getPartnerEmployeeList: (id: string, offset, limit) =>
      dispatch(getPartnerEmployeeList(id, offset, limit)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessEmployee);
