// ===================================================================
// SPLASH SCREEN COMPONENT
// ===================================================================
import React from "react";
import SplashScreenComponent from "./Components/index";
import { connect } from "react-redux";
import firebase from "../Firebase";
import LocalStorage from "../LocalStorage";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
import { NavigationProps } from "../Models/interface";
import { IStore, IUserStore } from "../Models";
import Actions from "../Actions";
import { IBusinessStore } from "../Store/Reducers/businessReducer";
import { IHomeStore } from "../Store/Reducers/homeReducer";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapStates {
  home: IHomeStore;
  user: IUserStore;
  business: IBusinessStore;
}
interface IMapDispatches {
  reachSignInSuceess: (flag: boolean) => void;
  getFirebaseInfo: () => void;
}
interface IProps extends NavigationProps, IMapStates, IMapDispatches {
  navigateTo: (
    route: null | {
      index: number;
      routes: [{ name: string }];
    }
  ) => void;
}
// ===================================================================
// COMPONENT
// ===================================================================
function SplashScreen(props: IProps) {
  const [isEvaluated, setIsEvaluated] = React.useState(false);
  const [isTimeout, setIsTimeout] = React.useState(false);
  const [route, setRoute] = React.useState<null | {
    index: number;
    routes: [{ name: string }];
  }>(null);

  React.useEffect(() => {
    setTimeout(() => {
      setIsTimeout(true);
    }, 0); //3 seconds
  });
  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        checkauth();
      });
    return () => unregisterAuthObserver();
  }, []);
  React.useEffect(() => {
    if (props.user.signInSuccess) {
      props.reachSignInSuceess(false);
      checkLInkedIdAndRoute();
    }
  }, [props.user.signInSuccess]);
  //navigate after 3 sec timeout or after the initial logic
  React.useEffect(() => {
    if (isEvaluated && isTimeout) {
      props.navigateTo(route);
    }
  }, [isEvaluated, isTimeout]);

  const checkLInkedIdAndRoute = async () => {
    const linkedId = await LocalStorage.get("linkedId");
    if (linkedId === "") {
      setRoute({
        index: 0,
        routes: [{ name: RouteNames.CreateUser.name }],
      });
    } else if (props.business.partners.length === 0) {
      setRoute({
        index: 0,
        routes: [{ name: RouteNames.Invitation.name }],
      });
    } else {
      setRoute(null);
    }
    setIsEvaluated(true);
    //check
  };
  //navigate to login screen if session doesn't exist
  const checkauth = async () => {
    //if session token doesn't exist, route user to
    const sessionDetail = await LocalStorage.get("sessionDetail");
    if (!sessionDetail || !sessionDetail.token || sessionDetail.token === "") {
      const { pathname = "" } = window!.location || {};
      setRoute({
        index: 0,
        routes: [
          {
            name:
              pathname === "/signup"
                ? RouteNames.Signup.name
                : RouteNames.Login.name,
          },
        ],
      });
      setIsEvaluated(true);
      return;
    } else {
      props.getFirebaseInfo();
    }
  };
  return <SplashScreenComponent />;
}
const mapStateToProps = (store: IStore): IMapStates => {
  return {
    user: store.user,
    home: store.home,
    business: store.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatches => ({
  reachSignInSuceess: (flag: boolean) =>
    dispatch(Actions.reachSignInSuceess(flag)),
  getFirebaseInfo: () => dispatch(Actions.getFirebaseUserInfo()),
});
export default connect<{}, {}, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(SplashScreen);
