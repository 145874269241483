import React from "react";
import Appbar from "../../Components/Appbar";
import { View, StyleSheet, ScrollView } from "react-native";
import TextUI from "../../Components/TextUI";
import GapBetweenUI from "../../Components/GapBetweenUI";
import BookingServiceCard from "../../+AppointmentInfo/Components/ServiceDetails";
import PaymentDetailTable from "../../+AppointmentInfo/Components/PaymentDetailTable";
import ButtonUI from "../../Components/ButtonUI";
import ServiceDeliveryAddress from "./ServiceDeliveryAddress";
import ServiceDetails from "./ServiceDetails";
import CircularProgress from "./CircularProgress";
import Switch from "../../Components/Switch";
import Row from "../../Components/Row";
import Chip from "../../Components/Chips";
import DropDownUI from "../../Components/Dropdown";
import { IRecurringState } from "..";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import DatepickerUI from "../../Components/DatepickerUI";
import TextInputUI from "../../Components/TextInputUI";
import moment from "moment";
import BottomActionBar from "../../Components/BottomActionBar";
import Page from "../../Components/Page";

interface IProps {
  data: any;
  handleSubmit: (type: string) => void;
  timer: number;
  timerRunOut: () => void;
  recurringState: IRecurringState;
  handleRecurringState: (key: string[], value: any) => void;
}

const periodTypes = Object.keys(EnumMapping.PeriodType)
  .map((x) => ({
    label: EnumMapping.PeriodType[x],
    value: Number(x),
  }))
  .filter(({ value }) => value !== 3 && value !== 5);

const recurrentEndTypes = Object.keys(EnumMapping.RecurrentEndType).map(
  (x) => ({
    label: EnumMapping.RecurrentEndType[x],
    value: Number(x),
  })
);

const periodDropdownItems = {
  days: [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
  ],
  weeks: [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
  ],
  months: [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
  ],
  default: [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
  ],
};
export default function BasketCheckoutComponent(props: IProps) {
  const {
    itemInfo = [],
    basket = "",
    currency = {},
    totalCost = 0,
    type = 0,
  } = props.data;
  if (!basket) {
  }
  const periodType = props.recurringState?.frequency?.type || 1;
  const periodString =
    periodType === 1
      ? "days"
      : periodType === 2
        ? "weeks"
        : periodType === 4
          ? "months"
          : periodType === 5
            ? "years"
            : "";
  const periodItems =
    periodDropdownItems[periodString] || periodDropdownItems.default;
  const pay = `${currency?.symbol || ""}${totalCost}`;
  function renderPeriodTypeEnd() {
    const endType = props.recurringState.end.type;
    if (endType === 1) {
      return (
        <>
          <View style={{ width: 70, marginRight: 15 }}>
            <DropDownUI
              list={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
              ]}
              label={""}
              setValue={(value) => {
                props.handleRecurringState(["end", "count"], value);
              }}
              value={props.recurringState.end.count}
            />
          </View>
          <TextUI>occurence</TextUI>
        </>
      );
    } else if (endType === 2) {
      const date = props.recurringState.end.date;
      return (
        <View style={{ width: 120 }}>
          <DatepickerUI
            onChangeText={(date) => {
              props.handleRecurringState(["end", "date"], date);
            }}
            value={date}
            range={{ startDate: new Date() }}
          >
            <TextInputUI
              label=""
              value={date ? moment(date).format("DD-MM-YYYY") : ""}
              placeholder="DD-MM-YYYY"
              onChangeText={(text) => { }}
            // error={hasInputError("dob", formData.dob)}
            // errorHelpText={"date of birth shouldn't be empty!"}
            />
          </DatepickerUI>
        </View>
      );
    } else {
      return <View />;
    }
  }
  return (
    <Page>
      <Appbar title={"Appointment Checkout"} />
      {!basket ? (
        <View />
      ) : (
        <>
          <ScrollView style={styles.body}>
            <GapBetweenUI />
            <View style={[styles.section, styles.top]}>
              <CircularProgress
                percent={props.timer}
                size={78}
                timerRunOut={props.timerRunOut}
              />
              <TextUI
                style={{
                  flex: 1,
                  fontWeight: "500",
                  fontSize: 15,
                  marginLeft: 20,
                  opacity: 0.7,
                }}
              >
                Selected employee’s availability are reserved for this online
                booking please complete it before the timer runs!
              </TextUI>
            </View>
            <GapBetweenUI />
            <ServiceDetails data={props.data} />
            <GapBetweenUI />
            <View style={[styles.section]}>
              <TextUI
                weight="500"
                familyType="type-2"
                size={16}
                style={{ marginBottom: 20 }}
              >
                Selected Services
              </TextUI>
              {itemInfo.map((item, idx) => (
                <BookingServiceCard data={item} key={item.id} />
              ))}
            </View>

            {props.data?.type === 2 && (
              <>
                <GapBetweenUI />
                <ServiceDeliveryAddress data={props.data} />
              </>
            )}
            <GapBetweenUI />
            <View
              style={[
                styles.section,
                { display: type === 3 ? "none" : "flex" },
              ]}
            >
              <Row spaceBetween>
                <TextUI
                  weight="500"
                  familyType="type-2"
                  size={18}
                  style={{ marginBottom: 0 }}
                >
                  Recurring Appointment
                </TextUI>
                <Switch
                  selected={props.recurringState?.enabled}
                  onPress={() => {
                    props.handleRecurringState(
                      ["enabled"],
                      !props.recurringState.enabled
                    );
                  }}
                />
              </Row>
              <View
                style={{
                  display: props.recurringState?.enabled ? "flex" : "none",
                }}
              >
                <View style={{ height: 10 }} />
                <TextUI>
                  How frequently do you want the appointment to repeat ?
                </TextUI>
                <View style={{ height: 10 }} />
                <Chip>
                  {periodTypes.map((item, idx) => {
                    if (props.recurringState.frequency.type === item.value) {
                      return (
                        <Chip.Selected
                          key={idx}
                          action={() => {
                            props.handleRecurringState(
                              ["frequency", "type"],
                              item.value
                            );
                          }}
                          hideIcon
                        >
                          {item.label}
                        </Chip.Selected>
                      );
                    }
                    return (
                      <Chip.GreyItem
                        key={idx}
                        action={() => {
                          props.handleRecurringState(
                            ["frequency", "type"],
                            item.value
                          );
                          props.handleRecurringState(["frequency", "count"], 1);
                        }}
                        hideIcon
                      >
                        {item.label}
                      </Chip.GreyItem>
                    );
                  })}
                </Chip>
                <View style={{ height: 10 }} />
                <Row>
                  <TextUI>for every</TextUI>
                  <View style={{ width: 70, marginHorizontal: 15 }}>
                    <DropDownUI
                      list={periodItems}
                      label={""}
                      setValue={(value) => {
                        props.handleRecurringState(
                          ["frequency", "count"],
                          value
                        );
                      }}
                      value={props.recurringState.frequency.count}
                    />
                  </View>
                  <TextUI>{periodString}</TextUI>
                </Row>
                <View style={{ height: 20 }} />
                <Row>
                  <TextUI>repeat ends</TextUI>
                  <View style={{ width: 100, marginHorizontal: 15 }}>
                    <DropDownUI
                      list={recurrentEndTypes}
                      label={""}
                      setValue={(value) => {
                        props.handleRecurringState(["end", "type"], value);
                      }}
                      value={props.recurringState.end.type}
                    />
                  </View>
                  {renderPeriodTypeEnd()}
                </Row>
              </View>
              {props.recurringState.enabled && props.recurringState.end.type === 1 && <TextUI style={{ marginTop: 10 }}>Repeat this appointment for every {props.recurringState.frequency.count} {periodString} until {props.recurringState.end.count} occurrences.</TextUI>}
              {props.recurringState.enabled && props.recurringState.end.type === 2 && <TextUI style={{ marginTop: 10 }}>Repeat this appointment for every {props.recurringState.frequency.count} {periodString} until {moment(props.recurringState.end.date).format("DD/MM/YYYY")}.</TextUI>}
            </View>
            <GapBetweenUI />

            <View style={styles.section}>
              <TextUI
                weight="500"
                familyType="type-2"
                size={18}
                style={{ marginBottom: 15 }}
              >
                Payment Summary
              </TextUI>
              <PaymentDetailTable data={props.data} />
            </View>
            <GapBetweenUI />
          </ScrollView>
          <BottomActionBar>
            {/* <TextUI
              style={{ marginBottom: 15, fontWeight: "500", fontSize: 18 }}
              familyType="type-2"
            >{`Select the payment mode to Pay ${pay}`}</TextUI>
            <View style={styles.payRow}>
              <RadioButton
                value="first"
                status={payType === 1 ? "checked" : "unchecked"}
                onPress={() => setPayType(1)}
                color={StyleTheme.APP_COLOR}
              />
              <TextUI>Pay now</TextUI>
            </View>
            <View style={styles.payRow}>
              <RadioButton
                value="first"
                status={payType === 2 ? "checked" : "unchecked"}
                onPress={() => setPayType(2)}
                color={StyleTheme.APP_COLOR}
              />
              <TextUI>Reserve Now</TextUI>
            </View> */}
            <ButtonUI
              label={"Book Now"}
              onPress={() => {
                props.handleSubmit("reserve");
              }}
              style={
                {
                  // marginTop: 15,
                }
              }
            />
          </BottomActionBar>
        </>
      )}
    </Page>
  );
}
const styles = StyleSheet.create({
  body: {},
  appointmentDetails: {},
  section: { padding: 20 },
  top: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  payRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
