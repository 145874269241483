import React from "react";
import { View } from "react-native";
import { Modal as RNModal, Portal } from "react-native-paper";
import { ViewStyle } from "react-native";
import { Appbar } from 'react-native-paper';

type IProps = {
  children: React.ReactNode;
  visible: boolean;
  onDismiss?: () => void;
  contentContainerStyle?: ViewStyle;
  title?: string;
  shouldShowCloseIcon?: boolean;
};

export default function Modal({
  children,
  contentContainerStyle,
  visible,
  onDismiss,
  title = "",
  shouldShowCloseIcon = false
}: IProps) {
  return (
    <Portal>
      <RNModal
        visible={visible}
        contentContainerStyle={{
          padding: 20,
          ...contentContainerStyle,
          alignSelf: "center",
          backgroundColor: "white",
          borderRadius: 4,
        }}
        onDismiss={() => {
          onDismiss && onDismiss();
        }}
      >
        <View style={{ flex: 1 }}>
          {shouldShowCloseIcon && <Appbar.Header style={{ backgroundColor: "white", elevation: 0 }}>
            <Appbar.Content title={title} />
            <Appbar.Action icon="close" onPress={() => {
              onDismiss && onDismiss();
            }} />
          </Appbar.Header>}
          {children}
        </View>
      </RNModal>
    </Portal>
  );
}
