import moment from "moment";
import React from "react";
import { View, TouchableOpacity } from "react-native";
import { TimePickerModal } from "react-native-paper-dates";

interface IProps {
  children: React.ReactNode;
  onChangeText: (val: any) => void;
  value?: string;
}

export default function TimepickerUI(props: IProps) {
  const [visible, setVisible] = React.useState(false);
  const onDismiss = () => {
    setVisible(false);
  };

  const onConfirm = ({ hours, minutes }) => {
    setVisible(false);
    onChangeText({ hours, minutes });
  };

  function onChangeText(data) {
    props.onChangeText(data);
  }

  const hour = moment(props.value).hour();
  const minutes = moment(props.value).minute();

  return (
    <>
      <TouchableOpacity onPress={() => setVisible(true)}>
        {props.children}
        <View
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      </TouchableOpacity>
      <TimePickerModal
        visible={visible}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        hours={hour || 12} // default: current hours
        minutes={minutes || 14} // default: current minutes
        label="Select time" // optional, default 'Select time'
        cancelLabel="Cancel" // optional, default: 'Cancel'
        confirmLabel="Ok" // optional, default: 'Ok'
        animationType="fade" // optional, default is 'none'
        locale={"en"} // optional, default is automically detected by your system
      />
    </>
  );
}
