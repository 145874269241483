// ===================================================================
// CUSTOM  COMPONENT
// ===================================================================
import React, { useCallback } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { IStore } from "../../../Models/StoreInterfaces";
import { connect } from "react-redux";
import Appbar from "../../../Components/Appbar";
import {
  getCampaignsList,
  updatePartnerBookingSettings,
} from "../../../Actions/businessActions";
import { NavigationProps } from "../../../Models/interface";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import TextUI from "../../../Components/TextUI";
import LoaderUI from "../../../Components/Loader";
import { StyleTheme } from "../../../Styles/CommonStyles";
import FabUI from "../../../Components/FabUI";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import InfiniteList from "../../../Components/InfiniteList";
import Row from "../../../Components/Row";
import ImageUI from "../../../Components/ImageUI";
import RouteNames from "../../../Utils/Constants/RouteNames";
import FlatList from "../../../Components/FlatList";
import Page from "../../../Components/Page";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  getCampaignsList: (
    id: string,
    options: { offset: number; limit: number },
    callback
  ) => void;
  updatePartnerBookingSettings: (data, id, callback) => void;
}
interface ICampaign {
  id: string;
  name: string;
  image: string;
  source: number;
}
type IProps = IMapState & IMapDispatch & NavigationProps;
// ===================================================================
// COMPONENT
// ===================================================================

function Campaigns(props: IProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [campaigns, setcampaigns] = React.useState<Array<ICampaign>>([]);
  const offset = React.useRef(0);
  const limit = 25;
  const [total, setTotal] = React.useState(99);

  useFocusEffect(
    useCallback(() => {
      getCampaigns({ offset: offset.current });
    }, [])
  );
  function getCampaigns(opt) {
    offset.current = opt.offset;
    setIsLoading(true);
    props.getCampaignsList(
      props.route.params.id,
      { offset: offset.current * limit, limit },
      (data) => {
        if (!data.fail) {
          setTotal(data.total);
          setcampaigns([...campaigns, ...data.result]);
        }
        setIsLoading(false);
      }
    );
  }

  const renderFloatingActionBar = () => {
    return (
      <FabUI
        showOptions={showOptions}
        action={(val) => setShowOptions(val)}
        bottomSpace={30}
        options={
          <>
            <TouchableOpacity
              style={styles.fabActionItem}
              onPress={() => {
                setShowOptions(false);
                props.navigation.navigate(
                  RouteNames.DigitalMarketingEdit.name,
                  {
                    businessId: props.route.params.id,
                    campaignId: "add",
                  }
                );
              }}
            >
              <TextUI color="white">Add Campaign</TextUI>
              <View style={{ width: 10 }} />
              <View
                style={{
                  backgroundColor: StyleTheme.APP_COLOR,
                  borderRadius: 100,
                  padding: 2,
                }}
              >
                <MaterialCommunityIcons
                  name={"plus"}
                  color="white"
                  size={20}
                  style={{}}
                />
              </View>
            </TouchableOpacity>
          </>
        }
      />
    );
  };
  return (
    <Page>
      <Appbar title={"Campaigns"} />
      <View style={styles.body}>
        {isLoading ? (
          <LoaderUI mode="long" />
        ) : campaigns.length ? (
          <FlatList
            items={campaigns}
            renderItem={(item) => {
              return (
                <TouchableOpacity
                  style={{ paddingVertical: 10, flex: 1 }}
                  onPress={() => {
                    props.navigation.navigate(
                      RouteNames.DigitalMarketingEdit.name,
                      {
                        businessId: props.route.params.id,
                        campaignId: item.id,
                      }
                    );
                  }}
                >
                  <Row>
                    <ImageUI
                      source={item.image}
                      style={{ width: 30, height: 30 }}
                    />
                    <View style={{ width: 10 }} />
                    <Row spaceBetween>
                      <TextUI weight="500">{item.name}</TextUI>
                      <MaterialCommunityIcons
                        name={"chevron-right"}
                        color="black"
                        size={20}
                        style={{}}
                      />
                    </Row>
                  </Row>
                </TouchableOpacity>
              );
            }}
          />
        ) : (
          <View
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 50,
            }}
          >
            <ImageUI
              source={require("../../../Assets/Images/campaign.png")}
              style={{ height: 100, width: 100 }}
            />
            <View style={{ height: 20 }} />
            <TextUI weight="500" size={16}>
              No Campaigns
            </TextUI>
            <View style={{ height: 5 }} />
            <TextUI style={{ opacity: 0.7, textAlign: "center" }}>
              You have not created any digital marketing campaign yet. Press the
              (+) button to start creating one now.
            </TextUI>
          </View>
        )}
      </View>
      {renderFloatingActionBar()}
    </Page>
  );
}
// ===================================================================
// STYLES
// ===================================================================
const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 10,
    paddingVertical: 20,
    flex: 1,
  },
  fabActionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    backgroundColor: "rgba(0,0,0,0.7)",
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
});
// ===================================================================
// Store
// ===================================================================
const mapStateToProps = (state: IStore): IMapState => {
  return {
    businessStore: state.business,
  };
};
const mapDispatchToProps = (dispatch: any): IMapDispatch => {
  return {
    getCampaignsList: (id: string, options, callback) =>
      dispatch(getCampaignsList(id, options, callback)),
    updatePartnerBookingSettings: (payload, id, callback) =>
      dispatch(updatePartnerBookingSettings(payload, id, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
