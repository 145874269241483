// ===================================================================
// BOTTOM SHEET COMPONENT
// ===================================================================
import React from "react";
import RBSheet from "react-native-raw-bottom-sheet";
import { View, Text, StyleSheet, Dimensions, Platform } from "react-native";
import ButtonUI from "./ButtonUI";
import { TouchableOpacity, Button } from 'react-native';

// ===================================================================
// INTERFACE
// ===================================================================
interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
  height?: number;
  onDismiss?: () => void;
}
// ===================================================================
// COMPONENT
// ===================================================================
function BottomSheet(props: IProps) {
  let _RBSheet: RBSheet;
  React.useEffect(() => {
    if (_RBSheet) {
      if (props.isOpen) {
        _RBSheet.open();
      } else {
        _RBSheet.close();
      }
    }
  }, [props.isOpen]);
  const dimensionWidth = Dimensions.get("window").width;
  return (
    <RBSheet
      ref={(ref) => {
        _RBSheet = ref;
      }}
      height={props.height}
      openDuration={250}
      customStyles={{
        container: {
          padding: 0,
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        },

        wrapper: {
          width: dimensionWidth,
          maxWidth: Platform.OS === "web" ? 500 : "100%",
          margin: "auto",
        },
      }}
      onClose={() => props.onDismiss()}
    >
      <>
        <View style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <ButtonUI onPress={() => {
            _RBSheet.close();
          }} label={"X"} mode="text" style={{ height: 20, width: 10, alignSelf: 'flex-end', boxShadow: "none" }} labelStyle={{ fontSize: 18, color: 'black' }} />
        </View>
        <View
          style={{ width: "100%", paddingLeft: 20, paddingRight: 20 }}
        >
          {props.children}
        </View>
      </>
    </RBSheet>
  );
}
const styles = StyleSheet.create({
  cancel: {
    fontWeight: "bold",
    fontSize: 18,
    position: "absolute",
    right: 20,
    opacity: 0.87,
    padding: 10
  },
});
export default BottomSheet;
