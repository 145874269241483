import React from "react";
import { Modal as RNModal, Portal } from "react-native-paper";
import { ModalProps, SafeAreaView, View, Platform } from "react-native";
import ContainerView from "./ContainerView";
import { Appbar as RNAppbar } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

interface IProps {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
export default function FullViewModal({ children, ...props }: IProps) {
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });
  return (
    <Portal theme={{ colors: { background: "red" } }}>
      <RNModal
        visible={props.visible}
        theme={{
          colors: { backdrop: Platform.OS === "web" ? "#f2f2f7" : "white" },
        }}
        style={{
          borderColor: "white",
        }}
        onDismiss={() => props.onClose && props.onClose()}
      >
        <SafeAreaView>
          <ContainerView>
            <View style={{ flex: 1, backgroundColor: "white" }}>
              {childrenWithProps}
            </View>
          </ContainerView>
        </SafeAreaView>
      </RNModal>
    </Portal>
  );
}
FullViewModal.Header = function (props: {
  children: string;
  onClose?: () => void;
}) {
  return (
    <RNAppbar
      style={{
        backgroundColor: "white",
        height: 50,
        zIndex: 1,
        paddingRight: 10,
      }}
    >
      <MaterialCommunityIcons
        name={"close"}
        style={{ fontSize: 24, fontWeight: "300", opacity: 0.7 }}
        onPress={props.onClose}
      />

      <RNAppbar.Content
        title={props.children}
        titleStyle={{
          fontFamily: "Rubik",
          fontWeight: "500",
          fontSize: 15,
          alignItems: "flex-start",
        }}
      />
    </RNAppbar>
  );
};
