import React from "react";
import { FlatList, View } from "react-native";

import LoaderUI from "./Loader";

interface IProps {
  data: any[];
  renderItem: (item: any) => React.ReactElement<any>;
  options: { total: number; offset: number; isLoading: boolean };
  getData: (options) => void;
}
export default function InfiniteList(props: IProps) {
  const enableScroll = React.useRef(false);

  React.useEffect(() => {
    const timer1 = setTimeout(() => {
      enableScroll.current = true;
    }, 1000);
    return () => {
      clearTimeout(timer1);
    };
  });

  function onEndReached() {
    if (
      enableScroll.current &&
      !props.options.isLoading &&
      props.data.length < props.options.total
    ) {
      props.getData({
        offset: props.options.offset + 1,
      });
    }
  }

  return (
    <>
      {props.data?.length === 0 && props.options.isLoading && (
        <LoaderUI mode="long" />
      )}
      <FlatList
        data={props.data}
        renderItem={({ item }) => {
          return props.renderItem(item);
        }}
        keyExtractor={(item, index) => index.toString()}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.1}
      />
      {props.data?.length !== 0 && props.options.isLoading && (
        <View style={{ paddingVertical: 7 }}>
          <LoaderUI size={20} />
        </View>
      )}
    </>
  );
  //   return <ScrollView style={{ flex: 1 }}>{renderList()}</ScrollView>;
}
