import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Linking,
  Platform,
  Text,
} from "react-native";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
import { useFocusEffect } from "@react-navigation/native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import SocialButtonUI from "../Components/SocialButtonUI";
import TextUI from "../Components/TextUI";
import ImageUI from "../Components/ImageUI";
import { NavigationProps } from "../Models/interface";

interface AbstractLoginProps extends NavigationProps {
  action: (type: string) => void;
}
function LoginContainer(props: AbstractLoginProps) {
  const [isSignUp, setIsSignUp] = React.useState(false);

  useFocusEffect(
    React.useCallback(() => {
      if (props.route.name === RouteNames.Signup.name) {
        setIsSignUp(true);
      }
    }, [])
  );

  const handleAction = (type: string) => {
    if (["google", "facebook", "twitter", "apple"].includes(type)) {
      props.action(type);
    } else if (type === "email") {
      props.navigation.navigate(RouteNames.EmailForm.name, {
        defaultState: isSignUp ? "" : "signup",
      });
    }
  };
  return (
    <View
      style={{
        paddingHorizontal: 5,
        paddingVertical: 15,
        display: "flex",
        height: "100%",
        alignItems: "center",
      }}
    >
      <View
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 10,
          marginRight: 15,
        }}
      >
        <Text
          style={{
            color: "#007fff",
            fontWeight: "500",
            fontSize: 16,
            marginRight: 5,
          }}
          onPress={async () => {
            Linking.openURL("https://help.reach.biz/en-gb/login");
          }}
        >
          Help?
        </Text>
        <MaterialCommunityIcons
          name={"message-bulleted"}
          style={{ color: "#007FFF", fontSize: 18 }}
        />
      </View>
      <View style={styles.logoWrapper}>
        <ImageUI
          resizeMode="contain"
          source={require("@reachpartner/common/Assets/Images/partner_logo.png")}
          style={{ width: 120, height: "80%" }}
        />
      </View>
      <View
        style={{
          flex: 1,
          width: "100%",
          maxWidth: 250,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SocialButtonUI
          label={`${isSignUp ? "Sign up" : "Sign in"} with Google`}
          type="google"
          action={() => handleAction("google")}
        />
        {Platform.OS === "web" && (
          <SocialButtonUI
            type="facebook"
            label={`${isSignUp ? "Sign up" : "Sign in"} with Facebook`}
            action={() => handleAction("facebook")}
          />
        )}
        {Platform.OS === "web" && (
          <SocialButtonUI
            type="twitter"
            label={`${isSignUp ? "Sign up" : "Sign in"} with Twitter`}
            action={() => handleAction("twitter")}
          />
        )}
        <SocialButtonUI
          type="email"
          label={`${isSignUp ? "Sign up" : "Sign in"} with Email`}
          action={() => {
            props.navigation.navigate(RouteNames.EmailForm.name, {
              defaultState: isSignUp ? "signup" : "",
            });
          }}
        />
        {Platform.OS === "ios" && (
          <SocialButtonUI
            type="apple"
            label={`${isSignUp ? "Sign up" : "Sign in"} with Apple`}
            action={() => {
              handleAction("apple");
            }}
          />
        )}
        <View>
          <Text style={styles.alreadyAccount}>
            {isSignUp
              ? "Already have a Partner account?"
              : "Don't have a Partner account?"}
          </Text>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate(RouteNames.EmailForm.name, {
                defaultState: isSignUp ? "" : "signup",
              });
            }}
          >
            <Text style={styles.signinnow}>
              {isSignUp ? "Sign in now" : "Sign up now"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          // height: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextUI size={13} weight="500" style={{ lineHeight: 25 }}>
          By continuing, you are indicating that you accept our{" "}
        </TextUI>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => Linking.openURL("https://reach.biz/en-gb/terms")}
          >
            <TextUI color="#007FFF" size={13} weight="500">
              Terms of Service
            </TextUI>
          </TouchableOpacity>
          <TextUI size={13} weight="500" style={{ marginHorizontal: 7 }}>
            and
          </TextUI>
          <TouchableOpacity
            onPress={() => Linking.openURL("https://reach.biz/en-gb/privacy")}
          >
            <TextUI color="#007FFF" size={13} weight="500">
              Privacy Policy
            </TextUI>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  icon: {
    width: 20,
    height: 20,
    fontSize: 20,
    marginRight: 10,
    alignItems: "center",
  },
  logoWrapper: {
    height: "25%",
    justifyContent: "center",
  },
  logoImage: {
    height: "80%",
    width: 120,
  },
  alreadyAccount: {
    textAlign: "center",
    color: "grey",
    fontWeight: "500",
    marginBottom: 5,
    marginTop: 10,
  },
  signinnow: {
    textAlign: "center",
    fontWeight: "500",
    color: "rgb(0, 127, 255)",
  },
});
export default LoginContainer;
