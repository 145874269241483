import React from "react";
import { TouchableOpacity, StyleSheet, Platform } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import TextUI from "./TextUI";
import ImageUI from "./ImageUI";

interface SocialButtonProps {
  label: string;
  type: "google" | "facebook" | "twitter" | "email" | "apple";
  action: () => void;
}
const SocialButtonUI = (props: SocialButtonProps): JSX.Element => {
  const { label = "", action = () => {}, type } = props;
  let color;
  let backgroundColor;
  let icon;
  if (type === "google") {
    backgroundColor = "white";
    color = "#636363";
    icon = (
      <ImageUI
        source={require("@reachpartner/common/Assets/Images/google.png")}
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
    );
  } else if (type === "facebook") {
    backgroundColor = "#3B5998";
    color = "white";
    icon = (
      <MaterialCommunityIcons
        name={"facebook"}
        style={[styles.icon, { color: "white" }]}
      />
    );
  } else if (type === "email") {
    backgroundColor = "#EF4742";
    color = "white";
    icon = (
      <MaterialCommunityIcons
        name={"email"}
        style={[styles.icon, { color: "white" }]}
      />
    );
  } else if (type === "apple") {
    backgroundColor = "black";
    color = "white";
    icon = (
      <MaterialCommunityIcons
        name={"apple"}
        style={[styles.icon, { color: "white" }]}
      />
    );
  } else {
    backgroundColor = "#007FFF";
    color = "white";
    icon = (
      <MaterialCommunityIcons
        name={"twitter"}
        style={[styles.icon, { color: "white" }]}
      />
    );
  }
  return (
    <TouchableOpacity
      onPress={() => action()}
      style={{
        height: 44,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        marginVertical: 5,
        paddingLeft: "15%",
        backgroundColor,
        borderRadius: 4,
        ...shadow(),
      }}
      activeOpacity={0.7}
    >
      {icon}
      <TextUI familyType="type-1" weight="500" color={color} size={15}>
        {label}
      </TextUI>
    </TouchableOpacity>
  );
};
function shadow() {
  return Platform.select({
    android: {
      elevation: 10,
    },
    web: {
      boxShadow: `0 3px 12px rgba(0,0,0,0.25)`,
    },
    ios: {
      shadowColor: "#000000",
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.25,
      shadowRadius: 3,
    },
  });
}
const styles = StyleSheet.create({
  icon: {
    width: 20,
    height: 20,
    fontSize: 20,
    marginRight: 10,
    alignItems: "center",
  },
  logoWrapper: {
    height: "35%",
    justifyContent: "center",
  },
  logoImage: {
    height: 160,
    width: 120,
  },
});
export default SocialButtonUI;
