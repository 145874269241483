const RouteNames = {
  LandingPage: { name: "Landing", path: "landing-page" },
  Invitation: { name: "Invitation", path: "invitation" },
  EmailForm: { name: "EmailForm", path: "email-form" },
  CreateUser: { name: "CreateUser", path: "create-user" },
  VerifyPhoneNumber: { name: "VerifyPhoneNumber", path: "verify-phone-number" },
  Login: { name: "Login", path: "login" },
  ResetPassword: { name: "ResetPassword", path: "reset-passwor" },
  ModeSelection: { name: "ModeSelection", path: "mode-selection" },
  Booking: { name: "Booking", path: "booking" },
  Signup: { name: "Signup", path: "signup" },
  Appointments: { name: "Appointments", path: "appointments" },
  Notifications: { name: "Notifications", path: "notifications" },
  Profile: { name: "Profile", path: "profile" },
  ProfileEdit: { name: "ProfileEdit", path: "profile-edit" },
  PrivacySettings: { name: "PrivacySettings", path: "privacy-settings" },
  SetUpBusiness: { name: "SetUpBusiness", path: "setup-business" },
  Reviews: { name: "Reviews", path: "reviews" },
  // *****************  APPOINTMENT ****************************
  NewBasket: { name: "NewBasket", path: "new" },
  BasketAddon:{name:"BasketAddon",path:"addons"},
  BasketConfig: { name: "BasketConfig", path: "config" },
  BasketCheckout: { name: "BasketCheckout", path: "checkout" },
  // *********************** BUSINESS ***********************************
  BusinessMenu: { name: "BusinessMenu", path: "business" },
  BusinessIdentity: { name: "BusinessIdentity", path: "profile" },
  BusinessIndustries: { name: "BusinessIndustries", path: "industries" },
  BusinessEmployee: { name: "BusinessEmployee", path: "employee" },
  BusinessServices: { name: "BusinessServices", path: "services" },
  BusinessServicesOperations: {
    name: "BusinessServicesOperations",
    path: "services",
  },
  BusinessIndustryOperations: {
    name: "BusinessIndustryOperations",
    path: "services",
  },
  BusinessEmployeeOperations: {
    name: "BusinessEmployeeOperations",
    path: "employee",
  },
  BusinessCustomerOperations: {
    name: "BusinessCustomerOperations",
    path: "customers",
  },
  BookingSettings: { name: "BookingSettings", path: "settings" },
  Customers: { name: "Customers", path: "customers" },
  // *********************** DIGITAL MARKETING ***********************************
  DigitalMarketingList: { name: "DigitalMarketingList", path: "marketing" },
  DigitalMarketingEdit: { name: "DigitalMarketingEdit", path: "details" },
  PartnerExtraInfo: { name: "PartnerExtraInfo", path: "extra" },
};

export default RouteNames;
