import React from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import ButtonUI from "../../../Components/ButtonUI";
import BottomActionBar from "../../../Components/BottomActionBar";
import TextInputUI from "../../../Components/TextInputUI";
import { IBusinessStore } from "../../../Store/Reducers/businessReducer";
import LoaderUI from "../../../Components/Loader";
import { CommonStyles } from "../../../Styles/CommonStyles";
import TextArea from "../../../Components/TextArea";
import PhoneNumberInput from "../../../Components/PhoneNumberInput";
import LocalStorage from "../../../LocalStorage";
import { validateEmail } from "../../../Utils";
import Page from "../../../Components/Page";
import Appbar from "../../../Components/Appbar";
import OutlineButton from "../../../Components/OutlineButton";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  businessStore: IBusinessStore;
}
interface IMapDispatch {
  addEmployee: (partnerId, employeeId, data, callback) => void;
}
type IProps = IMapState &
  IMapDispatch & {
    navigationPop: () => void;
    partnerId: string;
  };
// ===================================================================
// COMPONENT
// ===================================================================
function UpdateEmployee(props: IProps) {
  const [initialValidate, setInitialValidate] = React.useState(false);

  const [formData, setFormData] = React.useState({
    first: "",
    last: "",
    email: "",
    phone: "",
    designation: "",
    message:
      "You've been invited to join your team's Reach Partner account. Reach Partner is a personal service business management tool which powers a greater collaboration for everyday business activities.",
  });

  React.useEffect(() => { }, []);

  const hasInputError = (inputName: string, value: string) => {
    if (!initialValidate) {
      return false;
    }
    return hasError(inputName, value);
  };

  const hasError = (inputName: string, value: string) => {
    if (inputName === "email") {
      return !validateEmail(value);
    } else if (inputName === "phone") {
      return value.length < 13;
    }
    return value === "";
  };

  function handleChange(type: string, value: any) {
    setFormData((d: any) => ({
      ...d,
      [type]: value,
    }));
  }

  function SpaceBetween() {
    return <View style={{ height: 10 }} />;
  }
  const validate = () => {
    const errorFlags = [
      hasError("first", formData.first),
      hasError("last", formData.last),
      hasError("email", formData.email),
      hasError("designation", formData.designation),
      hasError("phone", formData.phone),
    ];
    return errorFlags.every((d) => !d);
  };
  async function submitData() {
    !initialValidate && setInitialValidate(true);
    if (!validate()) {
      return;
    }
    const data: any = { ...formData };
    const userId = await LocalStorage.get("linkedId");
    data.inviter = userId;
    data.expiresInDays = 7;
    props.addEmployee(props.partnerId, null, data, (data) => {
      if (!data.fail) {
        props.navigationPop();
      }
    });
  }

  function renderBody() {
    return <></>;
  }
  return (
    <Page>
      <Appbar title={`Invite Employee`} />
      <ScrollView style={{ flex: 1, padding: 15 }}>
        <SpaceBetween />
        <View style={[CommonStyles.rowSpaceBetween]}>
          <View style={{ width: "48%" }}>
            <TextInputUI
              label="First Name"
              value={formData.first}
              onChangeText={(val) => handleChange("first", val)}
              error={hasInputError("first", formData.first)}
              errorHelpText={"First name can't be empty"}
            />
          </View>
          <View style={{ width: "48%" }}>
            <TextInputUI
              label="Last Name"
              value={formData.last}
              onChangeText={(val) => handleChange("last", val)}
              error={hasInputError("last", formData.last)}
              errorHelpText={"Last name can't be empty"}
            />
          </View>
        </View>
        <SpaceBetween />
        <TextInputUI
          label="Email Address"
          value={formData.email}
          onChangeText={(val) => handleChange("email", val)}
          error={hasInputError("email", formData.email)}
          errorHelpText={
            formData.email === "" ? "Email can't be empty!" : "Invalid email"
          }
        />
        <SpaceBetween />
        {/* <TextInputUI
            label="Phone Number"
            value={formData.phone}
            onChangeText={(val) => handleChange("phone", val)}
          /> */}
        <PhoneNumberInput
          value={formData.phone}
          onChange={(val) => handleChange("phone", val)}
          error={hasInputError("phone", formData.phone)}
        />
        <SpaceBetween />
        <TextInputUI
          label="Designation"
          value={formData.designation}
          onChangeText={(val) => handleChange("designation", val)}
          error={hasInputError("designation", formData.designation)}
          errorHelpText={"Designation can't be empty!"}
        />

        <SpaceBetween />
        <TextArea
          label="Invitation Message"
          value={formData.message}
          onChangeText={(val) => handleChange("message", val)}
        />
      </ScrollView>
      <BottomActionBar>
        <View
          style={Styles.inviteButton}
        >
          <OutlineButton label="Cancel" onPress={() => props.navigationPop()} style={{ flex: 1 }} />
          <View style={{ width: 20 }} />
          <ButtonUI label="Invite" onPress={submitData} style={{ flex: 1 }} />
        </View>
      </BottomActionBar>
      {props.businessStore.isUpdating && <LoaderUI mode="long" />}
    </Page>
  );
}

const Styles = StyleSheet.create({
  inviteButton: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: 'space-between'
  },
});

export default UpdateEmployee;
