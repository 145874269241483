import React from "react";
import AppRouter from "@reachpartner/common/App";
import { createStackNavigator } from "@react-navigation/stack";
import LoginScreen from "./Screens/Login.web";
import EmailFormWeb from "./Screens/EmailForm.web";
import RouteNames from "@reachpartner/common/Utils/Constants/RouteNames";
const Stack = createStackNavigator();

export default function App() {
  return (
    <AppRouter>
      <Stack.Screen name={RouteNames.Login.name} component={LoginScreen} />
      <Stack.Screen name={RouteNames.Signup.name} component={LoginScreen} />
      <Stack.Screen name={RouteNames.EmailForm.name} component={EmailFormWeb} />
    </AppRouter>
  );
}
