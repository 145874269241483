import React, { useState } from "react";
import DropDown from "../../Components/Dropdown";
import { TextInput } from "react-native-paper";
import { View, Text, StyleSheet } from "react-native";
import ButtonUI from "../../Components/ButtonUI";
import EnumMapping from "../../Utils/Constants/EnumMapping";
import Modal from "../../Components/Modal";

interface IProps {
  modalVisible: boolean;
  setModalVisible: (flag: boolean) => void;
  languages: { label: string; value: string }[];
  submit: (data: { code: string; level: number }) => void;
}
function LanguageModal(props: IProps) {
  const { modalVisible, setModalVisible, languages, submit } = props;
  const [lang, setLang] = useState<any>("");
  const [level, setLevel] = useState<any>("");
  const levels = Object.keys(EnumMapping.LanguageProficientLevel).reduce(
    (acc, item) => {
      acc.push({
        label: EnumMapping.LanguageProficientLevel[item],
        value: item,
      });
      return acc;
    },
    [] as { label: string; value: string }[]
  );
  function update() {
    if (lang !== "" && level !== "") {
      submit({
        code: lang,
        level: Number(level),
      });
    }
    setModalVisible(false);
    setLang("");
    setLevel("");
  }

  return (
    <Modal
      visible={modalVisible}
      contentContainerStyle={{
        padding: 15,
        width: 300,
      }}
      onDismiss={() => setModalVisible(false)}
    >
      <View style={styles.body}>
        <Text style={styles.header}>Language Skills</Text>
        <DropDown
          label={"Language"}
          value={lang}
          setValue={(val) => setLang(val)}
          list={languages || []}
          inputProps={{
            right: <TextInput.Icon name={"menu-down"} />,
          }}
        />
        <View style={{ height: 15 }} />

        <DropDown
          label={"Level"}
          value={level}
          setValue={(val) => setLevel(val)}
          list={levels}
          inputProps={{
            right: <TextInput.Icon name={"menu-down"} />,
          }}
        />
        <View style={{ height: 15 }} />
        <View style={styles.actBtnWrapper}>
          <ButtonUI
            style={{ backgroundColor: "white", flex: 1 }}
            labelStyle={{ color: "black" }}
            onPress={() => {
              setModalVisible(false);
            }}
            label="Cancel"
          />
          <View style={{ width: 30 }} />
          <ButtonUI style={{ flex: 1 }} onPress={update} label="Update" />
        </View>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  body: {},
  header: {
    marginBottom: 10,
    fontWeight: "500",
    fontSize: 18,
  },
  actBtnWrapper: {
    display: "flex",
    flexDirection: "row",
  },
});

export default LanguageModal;
