import {
  LayoutChangeEvent,
  ScrollView,
  View,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { Menu, useTheme } from "react-native-paper";
import React, { useEffect, useState } from "react";
import TextInputUI from "./TextInputUI";
type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

interface IProps {
  value: any;
  setValue: (_value: string) => void;
  label: string;
  placeholder?: string | undefined;
  inputProps?: any;
  list: Array<any>;
  dropDownContainerMaxHeight?: number;
  dropDownStyle?: ViewStyle;
  dropDownItemStyle?: ViewStyle;
  keyName?: string;
  keyId?: string;
  disabled?: boolean;
}

const DropDownUI = (props: IProps) => {
  const activeTheme = useTheme();
  const [show, setShow] = React.useState(false);
  const {
    value,
    setValue,
    label,
    list,
    dropDownContainerMaxHeight,
    dropDownStyle = {},
    dropDownItemStyle = {},
  } = props;
  const [displayValue, setDisplayValue] = useState("");
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onLayout = (event: LayoutChangeEvent) => {
    setInputLayout(event.nativeEvent.layout);
  };

  useEffect(() => {
    const obj = list.find(
      (_) => _[props.keyId ? props.keyId : "value"] === value
    );

    if (obj) {
      const _label = props.keyName ? obj[props.keyName] : obj.label;
      setDisplayValue(_label);
    }
  }, [list, value]);

  return (
    <Menu
      visible={show}
      onDismiss={() => setShow(false)}
      anchor={
        <TouchableOpacity
          onPress={() => !props.disabled && setShow(true)}
          onLayout={onLayout}
          activeOpacity={0.8}
        >
          <View
            pointerEvents={"none"}
            style={{ opacity: props.disabled ? 0.6 : 1 }}
          >
            <TextInputUI
              value={displayValue.toString()}
              label={label}
              pointerEvents={"none"}
            />
            <MaterialCommunityIcons
              name="chevron-down"
              // color="#007FFF"
              size={26}
              style={{ position: "absolute", right: 10, top: 13, opacity: 0.7 }}
            />
          </View>
        </TouchableOpacity>
      }
      style={{
        maxWidth: inputLayout?.width,
        width: inputLayout?.width,
        marginTop: inputLayout?.height,
        ...dropDownStyle,
      }}
    >
      <ScrollView style={{ maxHeight: dropDownContainerMaxHeight || 200 }}>
        {list.map((_item, _index) => {
          const label = props.keyName ? _item[props.keyName] : _item.label;
          const key = props.keyId ? _item[props.keyId] : _item.value;
          return (
            <Menu.Item
              key={_index}
              titleStyle={{
                color:
                  value === key
                    ? activeTheme.colors.primary
                    : activeTheme.colors.text,
                fontSize: 13,
              }}
              onPress={() => {
                setValue(key);
                setShow(false);
              }}
              title={label}
              style={{
                maxWidth: inputLayout?.width,
                ...dropDownItemStyle,
                ...{ height: 25 },
              }}
            />
          );
        })}
      </ScrollView>
    </Menu>
  );
};

export default DropDownUI;
