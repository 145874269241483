// ===================================================================
// CUSTOM  COMPONENT
// ===================================================================
import React, { useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import Appbar from "../../../Components/Appbar";
import {
  ILocationReqPayload,
  NavigationProps,
} from "../../../Models/interface";
import CustomerForm from "./CustomerForm";
import { IHomeStore } from "../../../Store/Reducers/homeReducer";
import Page from "../../../Components/Page";
// ===================================================================
// INTERFACE
// ===================================================================
interface IMapState {
  homeStore: IHomeStore;
}
interface IMapDispatch {
  customerCreate: (partnerId: string, payload, callback) => void;
  getLocations: (payload: ILocationReqPayload) => void;
  locationReset: () => void;
  navigationPop: () => void;
  partnerId: string;
}

type IProps = IMapState & IMapDispatch;
// ===================================================================
// COMPONENT
// ===================================================================

function CustomerAdd(props: IProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  useFocusEffect(useCallback(() => {}, []));

  const getLocation = (val: string) => {
    const payload = {
      locData: val,
      locType: "postcode",
    };
    props.getLocations(payload);
  };

  function customerAdd(customerPayload) {
    setIsLoading(true);
    props.customerCreate(props.partnerId, customerPayload, (data) => {
      if (!data.fail) {
        props.navigationPop();
      }
    });
  }
  const locationConfig = {
    ...props.homeStore.locationSearchItems,
    getLocations: (val) => getLocation(val),
    locationReset: () => props.locationReset(),
  };
  return (
    <Page>
      <Appbar title={"Customer Add"} />
      <CustomerForm
        onSubmit={customerAdd}
        onDismiss={() => props.navigationPop()}
        isUpdate={false}
        loader={isLoading}
        locationConfig={locationConfig}
      />
    </Page>
  );
}

export default CustomerAdd;
